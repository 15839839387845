// import App from "../../components/App";
import { Arc3d, Cone, CurveChainWithDistanceIndex, GeometryQuery, IndexedPolyface, IndexedPolyfaceVisitor, LineSegment3d, LineString3d, Loop, Path, Point2d, Point3d, PolyfaceBuilder, Sphere, Transform, Vector3d } from "@itwin/core-geometry";
import { ColorDef,LinePixels } from "@itwin/core-common";
import { BeButtonEvent, DecorateContext, Decorator, EventHandled, GraphicBranch, GraphicBuilder, GraphicType, HitDetail, IModelApp, Marker,RenderGraphic} from "@itwin/core-frontend";
import { Id64String } from "@itwin/core-bentley";
import { SyncUiEventDispatcher, UiFramework, StagePanelLocation, WidgetState } from "@itwin/appui-react";
import { } from "@itwin/appui-abstract";
// import { SampleToolWidget } from "../frontstages/SampleToolWidget";

//Tools utilising this Decorator 
import { AddEditPolygonShapeTool } from "../../components/tools/AddEditPolygonShapeTool";
import { store } from "../../../store/rootReducer";
import { ObjectCategoryTypes } from "../../../store/detectedData/apiDataTypes";
// -------------------------------------------------------
// Implements visualising mount pipes in an Imodel view.
// -------------------------------------------------------
export interface CustomGeometry {// Custom container for compartmentalising decorator infomation
    positions: Point3d[],
    geometry: GeometryQuery;
    color: ColorDef;
    fill: boolean;
    fillColor: ColorDef;
    lineThickness: number;
    edges: boolean;
    linePixels: LinePixels;
    transientId: Id64String;
    name: string;
    modelData?: any;
}
// ------------------------------------------------------
export class PolygonShapeDecorator implements Decorator {
    // ------------------------------------------------------
    // VARIABLES
    // ------------------------------------------------------
    private polys: CustomGeometry[];
    private geometryGraphics: RenderGraphic[];
    private needUpdate: boolean = false;                                        // Will track if the polys Array count is updated/changed. This will trigger if we need to make a new update the as _lastSelectedPolygonShape
    private static markerSet: Marker[] = [];

    private _isEditable: boolean = true;                                        // Manages if the editing feature for this decorator will be used. Helps diffrenciate usage for AddpolygonShapetool and HiglightmountTool.
    private _nameIdMap: Map<string, Id64String> = new Map<string, Id64String>();//Store the transient ids with the name of the object to manage geomentry loading and hilighting etc.
    public objectIdMap: Map<Id64String, string> = new Map<Id64String, string>();//Store the transient ids with the name of the object to manage geomentry loading and hilighting etc.

    public decoratorName: string = "PolygonShapeDecorator";
    private static _isSelected: boolean = false;                                // Tracks the state if this object is clicked on.
    private static _selectedGeometry: CustomGeometry | undefined;               // Static convert to a readOnly getter.
    private static _lastSelectedPolygonShape: CustomGeometry | undefined;       // Used to keep track of the last/current selected polygon shape even if any other shape is selected, like spheres or markers.
    public lastSelection: {name: string, Pos: Point3d[]} = {name: "", Pos: []};
    public previousSelection: {name: string, Pos: Point3d[]} = {name: "", Pos: []};

    // this is very important for high density decorators , improves performance
    // apparently the parent uses this public variable to know how to
    // draw the primitives wrt this decorator child.
    // In the default secanrio the decorator validates and rebinds primitive information , which is inefficent,
    // Use this to optimise ,where info is not rebound but buffer handle/ref is reused.
    // https://www.itwinjs.org/learning/frontend/viewdecorations/#cached-decorations. for more info
    public readonly useCachedDecorations = true;
    //-------------------------------------------------------------------------------------------
    // CONSTRUCTOR
    //-------------------------------------------------------------------------------------------
    constructor() {
        this.polys = [];
        // this.geometryBuilders = [];
        this.geometryGraphics = [];
    }
    //-------------------------------------------------------------------------------------------
    // FUNCTIONS
    //-------------------------------------------------------------------------------------------
    public set isEditable(isIt: boolean) {
        this._isEditable = isIt;
    }
    public get nameIdMap() {
        return this._nameIdMap;
    }
    /*
    * Functonalty to add markers to a markerset array
    * the markerset is then used in the decorate() to
    * be added to the current Decorator context
    * See: in decorate()
    * 
    * Not in use, there is a bug in Imodel.js instantiating markers and primitves together.
    * More scrutiniy required
    */
    public addMarkers(pos: Point3d) {
        const marker = new Marker(pos, new Point2d(50, 50));
        marker.position = pos;
        // marker1.visible = true;
        marker.setImageUrl("image/defect-camera-icon2.png");
        PolygonShapeDecorator.markerSet.push(marker);
        IModelApp.viewManager.invalidateDecorationsAllViews();
    }
    //-----------------------------------------------------
    /*
     * Creates a marker on Point, .
     */
    public async addMarkerOnPoint(point: Point3d) {
        const marker = new Marker(point, new Point2d(50, 50));
        marker.position = point;
        marker.setImageUrl("image/location.SVG");

        // As per the structure for markers 
        // this allows for mouse event to triger on interaction with the same marker refrence
        marker.onMouseLeave = () => {
        };
        marker.onMouseEnter = (_ev: BeButtonEvent) => {
        };
        marker.onMouseButton = (_ev: BeButtonEvent) => {
            return true;
        };

        PolygonShapeDecorator.markerSet.push(marker);
        IModelApp.viewManager.selectedView?.invalidateCachedDecorations(this);
    }
    // -------------------------------------------------------
    /*
     * Clear the elements from the existing arrays
     * that will be bound in the decorator() ovveride callback
    */
    private clearMarkers() {
        PolygonShapeDecorator.markerSet.length = 0;
        IModelApp.viewManager.invalidateDecorationsAllViews();
    }
    //-------------------------------------------------------------------------------------------
    /*
     * Return the shape that is currently selected if its.
     * Used to do targeted transformantions on selection.
     * Check 'CustomGeometry' for the member properties.
     */ 
    public static get selectedGeometry(): CustomGeometry {
        return PolygonShapeDecorator._selectedGeometry!;
    }
    //-------------------------------------------------------------------------------------------
    /*
     * Returns the value of the statically held current polygon object that is selected, 
     * Will be replaced with info of antother poly when selected.
     */ 
    public static get lastSelectedPolygonShape(): CustomGeometry {
        return PolygonShapeDecorator._lastSelectedPolygonShape!;
    }
    //-------------------------------------------------------------------------------------------
    /*
     * Use to manually set the last selected polygon is required .
     * in this case it is used in the AddEditPolygonShapeTool.ts->onDataButtonDown()
     * after the plyong shape create even is trigered from the click points collected
     */ 
    public setLastSelectedPolygonShape(name: string) {
        for (let d = 0; d < this.polys.length; d++) {
            if (name == this.polys[d].name) {
                PolygonShapeDecorator._lastSelectedPolygonShape! = this.polys[d];
            }
        }
    }
    //-------------------------------------------------------------------------------------------
    /* Return the refrence of the CutomGeometry that matches the name with wich it was created
     */ 
    public findCustomGeomtryObjectByName(name: string): CustomGeometry | undefined {
        for (let d = 0; d < this.polys.length; d++) {
            if (name == this.polys[d].name) {
                return this.polys[d];
            }
        }
        return undefined;
    }
    //-------------------------------------------------------------------------------------------
    /* Clears all Geometry and Graphics objects from the 
     * Decrator context Instance.
     */
    public clearGeometry() {
        this.polys = [];
        this.geometryGraphics = [];
        IModelApp.viewManager.invalidateDecorationsAllViews();
    }
    //-------------------------------------------------------------------------------------------
    /*
     * Searches for objects that are named joint and clears them from the geometry array
     */ 
    public clearJointGeometry() {
        for (let j = 0; j < this.polys.length; j++) {
            if (this.polys[j].name.substring(0, 3) == "joi") {
                this.polys.splice(j, 1);
                this.clearJointGeometry();//call this reursively
            }
        }
        this.geometryGraphics = [];
        IModelApp.viewManager.invalidateDecorationsAllViews();
        this.needUpdate = true;//change this to true if you want the decorator objects to update.
    }
    //-------------------------------------------------------------------------------------------
    /*
     * Searches for objects that are named grating and clears them from the geometry array
     */
    public clearGratingSupportGeometry() {
        for (let j = 0; j < this.polys.length; j++) {
            if (this.polys[j].name.substring(0, 3) == "gra") {
                this.polys.splice(j, 1);
                this.clearGratingSupportGeometry();//call this reursively
            }
        }
        this.geometryGraphics = [];
        IModelApp.viewManager.invalidateDecorationsAllViews();
        this.needUpdate = true;//change this to true if you want the decorator objects to update.
    }
    //-------------------------------------------------------------------------------------------
    public clearPolygonByName(name : string) {
        for (let j = 0; j < this.polys.length; j++) {
            if (this.polys[j].name == name) {
                this.polys.splice(j, 1);
            }
        }
        this.geometryGraphics = [];
        IModelApp.viewManager.invalidateDecorationsAllViews();
        this.needUpdate = true;//change this to true if you want the decorator objects to update.
    }
    //-------------------------------------------------------------------------------------------
    /* The array for all CustomGeometries created in the decorator
     */
    public get getGeometryArray(): CustomGeometry[]{
        return this.polys;
    }
    /*
     * This function will  update the current transientID of 
     * recreted geomentry by comparing names.
     * THis is needed for hilighting and keepig track of the 
     * transient id that gets changed after each recreation of
     * existing geometries while translating or transforming.
     */
    private syncLastSelectedPolygonsTransientId() {
        for (let u = 0; u < this.polys.length; u++) {
            if (this.polys[u].name == PolygonShapeDecorator._lastSelectedPolygonShape?.name) {
                PolygonShapeDecorator._lastSelectedPolygonShape!.transientId = this.polys[u].transientId;
            }
        }
    }
    //-------------------------------------------------------------------------------------------
    /* Calculate the normals for set of 3 points.
    */
    private calcNormals(o: Point3d, a: Point3d, b: Point3d): Vector3d {
        let dir: any;
        dir = Vector3d.createCrossProductToPoints(o, a, b).normalize();
        return dir;
    }
    //-------------------------------------------------------------------------------------------
    /* Extruedes the polygon to get give the shape some thickeness.
     * Not being utilised for now , WIP , not complete.
     */ 
     private generateExtrudedPoints(currPoints: Point3d[]): Point3d[] {
         let exPoints: Point3d[] = [];

         for (let p = 0; p < currPoints.length; p++) {

             let iter = p;
             let iterPrev = p - 1;
             let iterNext = p + 1;

             iterPrev < 0 ? iterPrev = currPoints.length - 1 : iterPrev = iterPrev;
             iterNext > currPoints.length - 1 ? iterNext = 0 : iterNext = iterNext;
            
             let dir = this.calcNormals(currPoints[iter], currPoints[iterPrev], currPoints[iterNext]);

             let extrPoint = new Point3d(0);
             extrPoint.x = currPoints[p].x * (dir.x + 1.2);
             extrPoint.y = currPoints[p].y * (dir.y + 1.2);
             extrPoint.z = currPoints[p].z * (dir.z + 1.2);

             exPoints.push(extrPoint);
         }
         return exPoints;
     }
    //-------------------------------------------------------------------------------------------
    /*
     * Delete the spehre from polys
     */ 
    public deleteSpheresMarkerSFromDecorator() {

        for (let p = 0; p < this.polys.length; p++) {
            let name = this.polys[p].name.substring(0, 3);
            if (name == "Sph") {
                this.polys.splice(p, 1);//remove the item
                this.deleteSpheresMarkerSFromDecorator();
            }
        }
        IModelApp.viewManager.invalidateCachedDecorationsAllViews(this);//call this for View to reload.
        this.needUpdate = true;//change this to true if you want the decorator objects to update.
    }
    //------------------------------------------------------------------------------------
    /*
     * Creates a Cylinder between points
     */ 
    public createPipeBetweenPoints(a: Point3d, b: Point3d, color: ColorDef, pname: string, objInfo: any) {//Visualisation helpers

        const pipe = Cone.createAxisPoints(a, b, 0.04, 0.04, true);//uses a cone to create a line
        let mesh: any;
        if (pipe !== undefined) {
            const poly = PolyfaceBuilder.create();
            poly.addCone(pipe!);
            mesh = poly.claimPolyface(false);
        }
        // init CustomGeometry var with values.

        let points: Point3d[] = []
        points.push(a);
        points.push(b);//in this case only one point denoting the position
        const p: CustomGeometry = ({
            positions: points,
            geometry: mesh,
            color: color,
            fill: true,
            // fillColor: ColorDef.fromTbgr(ColorDef.withTransparency(color.tbgr, 255 - Math.round(255 * SampleToolWidget.iModelTransparency.mount))),
            fillColor: ColorDef.fromTbgr(ColorDef.withTransparency(color.tbgr, 255 - Math.round(255 * 0.7))),
            lineThickness: 0.5,
            edges: true,
            linePixels: LinePixels.Solid,
            transientId: IModelApp.viewManager.selectedView!.iModel.transientIds.next,      // generate and store a unique ID each time
            name: pname,
            modelData: objInfo
        });
        this._nameIdMap.set(`gratingGeom#${p.name}@${p.modelData.mountFace}@${p.modelData.parentMountType}@${p.modelData.parentMount}`, p.transientId);
        this.objectIdMap.set(p.transientId, `gratingGeom#${p.name}@${p.modelData.mountFace}@${p.modelData.parentMountType}@${p.modelData.parentMount}`);
        // push to array.
        this.polys.push(p);

        IModelApp.viewManager.invalidateCachedDecorationsAllViews(this);//call for update of screen as viewmanger decorators as well.
        this.needUpdate = true;//change this to true , this will invoke an update for this decorator and rebind the primitives to buffer/cache in createGraphics().
    }
    //-------------------------------------------------------------------------------------------
    /*
     * Takes points as data and uses them to create a polygon primitive.
     */ 
    public createPolygonFromPoints(points: Point3d[], color: ColorDef, pname: string, objInfo: any) {
        const builder = PolyfaceBuilder.create();
        // let extPoints = this.generateExtrudedPoints(points);//work in progress
        builder.addPolygon(points);
        builder.endFace();
        let mesh = builder.claimPolyface(true);

        //let c = this.calcCenteroid(points);
        //let mrkr = this.createMarkerOnPosition(c);

        // init CustomGeometry var with values.
        const p: CustomGeometry = ({
            positions: points,
            geometry: mesh,
            color: color,
            fill: true,
            // fillColor: ColorDef.fromTbgr(ColorDef.withTransparency(color.tbgr, 255 - Math.round(255 * SampleToolWidget.iModelTransparency.mount))),
            fillColor: ColorDef.fromTbgr(ColorDef.withTransparency(color.tbgr, 255 - Math.round(255 * 0.7))),
            lineThickness: 0.5,
            edges: true,
            linePixels: LinePixels.Solid,
            transientId: IModelApp.viewManager.selectedView!.iModel.transientIds.next,      // generate and store a unique ID each time
            name: pname,
            modelData: objInfo
        });
        this._nameIdMap.set(`gratingGeom#${p.name}@${p.modelData.mountFace}@${p.modelData.parentMountType}@${p.modelData.parentMount}`, p.transientId);
        this.objectIdMap.set(p.transientId, `gratingGeom#${p.name}@${p.modelData.mountFace}@${p.modelData.parentMountType}@${p.modelData.parentMount}`);
        // push to array.
        this.polys.push(p);

        IModelApp.viewManager.invalidateCachedDecorationsAllViews(this);//call for update of screen as viewmanger decorators as well.
        this.needUpdate = true;//change this to true , this will invoke an update for this decorator and rebind the primitives to buffer/cache  in createGraphics().
    }
    //-------------------------------------------------------------------------------------------
    /*
     * Takes points as data and uses them to create a polygon primitive.
     * used to depict the clicked points for User aid. //Not used at the moment
     */
    public createSphereOnPointPosition(point: Point3d,size : number, color: ColorDef, pname: string, objInfo: any) {

        const builder = PolyfaceBuilder.create();
        const sphere = Sphere.createCenterRadius(point, size);
        builder.addSphere(sphere);
        builder.endFace();
        let mesh = builder.claimPolyface(true);

        //let mrkr = this.createMarkerOnPosition(point);

        // init CustomGeometry var with values.
        let points: Point3d[] = []
        points.push(point);//in this case only one point denoting the position
        const p: CustomGeometry = ({
            positions: points,
            geometry: mesh,
            color: color,
            fill: true,
            // fillColor: ColorDef.fromTbgr(ColorDef.withTransparency(color.tbgr, 255 - Math.round(255 * SampleToolWidget.iModelTransparency.mount))),
            fillColor: ColorDef.fromTbgr(ColorDef.withTransparency(color.tbgr, 255 - Math.round(255 * 0.7))),
            lineThickness: 0.5,
            edges: true,
            linePixels: LinePixels.Solid,
            transientId: IModelApp.viewManager.selectedView!.iModel.transientIds.next, // generate and store a unique ID each time
            name:"Sph" + pname,
            modelData: objInfo
        });
        this._nameIdMap.set(`gratingGeom#${p.name}@${p.modelData.mountFace}@${p.modelData.parentMountType}@${p.modelData.parentMount}`, p.transientId);
        this.objectIdMap.set(p.transientId, `gratingGeom#${p.name}@${p.modelData.mountFace}@${p.modelData.parentMountType}@${p.modelData.parentMount}`);
        // push to array.
        this.polys.push(p);

        IModelApp.viewManager.invalidateCachedDecorationsAllViews(this);
        this.needUpdate = true;//change this to true , this will invoke an update for this decorator and rebind the primitives to buffer/cache in createGraphics().
    }
    //-------------------------------------------------------------------------------------------
    /*
    * Load up the geometry info for pipes into a builder object and
    * return a graphic object and store it in the geometryGraphics[].
    * These grapic objects will be added to the the context via context.addDecoration()
    * Utilised in deocrate overload See: in decorate();
    */
    private createGraphics(context: DecorateContext) {
        
        if (this.geometryGraphics.length === 0 || this.needUpdate == true) {                        // Check if Geometry Graphic objects are created and if not then load up in array. oR if the decorator needs update after any new geometry is added.
            this.geometryGraphics = [];                                                             // Clear graphics if it exists, then recreate, to update the geometries in this decorator.
            for (const shape of this.polys) {                                                       // Iterate over each  object loaded in array and create graphics for it.
                let transientId = context.viewport.iModel.transientIds.next;
                if (shape.transientId === "") {
                    shape.transientId = transientId;
                    this._nameIdMap.set(`gratingGeom#${shape.name}`, shape.transientId);
                    this.objectIdMap.set(shape.transientId, `gratingGeom#${shape.name}`);
                } else {
                    transientId = shape.transientId;
                }
                // Speicify unique transientId so that geometry are considered as sperate entites.
                const builder = context.createGraphicBuilder(GraphicType.Scene, Transform.identity, transientId);
                // builder.wantNormals = true;
                //
                const geometry = shape.geometry;
                // let fillColor: ColorDef = ColorDef.white;
                // builder.setSymbology(ColorDef.black,pipe.fillColor, 1, LinePixels.Code0);        // Sets geometry asthetics.
                builder.setBlankingFill(shape.fillColor);                                           
                this.createGraphicsForGeometry(geometry, false, builder);
                //
                const graphic = builder.finish();
                this.geometryGraphics.push(graphic);                                                // Create graphic obj and push to array to be used in a GraphicBranch.
            }

            this.needUpdate = false;                                                                //once geometries are updated, revert it back to false to stop rebinding the geometry data by entering this condition.

            //These steps force higlight the lastSelected polygonshape every time a call to redraw geomentries happen ie when translating or transforming.
            this.syncLastSelectedPolygonsTransientId(); 
            if((this.lastSelection.Pos.length && store.getState().detectedData.selectedObjectInformation.objectProperties.objectCategoryType == ObjectCategoryTypes.Grating_RM)){
                PolygonShapeDecorator._lastSelectedPolygonShape ? UiFramework.getIModelConnection()!.selectionSet.add(PolygonShapeDecorator._lastSelectedPolygonShape!.transientId) : null;//add to selection set to hilight                                                        
            }
        }//2n -> n = polys.length single run

        /*
        If the Graphics are already created  and geometryGraphics array length is greater than 0 then
        add all the grapics objects as decorations to the scene. This helps avoid having to bind primitive data into
        buffers each time decorate is called which is costly.
        Also see : useCachedDecorations that implicityl re-uses buffers.
        */
        for (const geo of this.geometryGraphics) {
            // Some branch object presets
            const overrides = {};
            // const overrides = new ViewFlagOverrides();
            // overrides.setShowVisibleEdges(false);
            // overrides.setApplyLighting(true);
 
            const branch = new GraphicBranch(false);                                                 // Create new SceneGraph //dont know why we do this every time is inefficient, but it is as per api/docs.
            branch.setViewFlagOverrides(overrides);
            
            branch.add(geo);                                                                         // Add to SceneGraph graphic branch Note : this is a workaround to improve not having to rebind geometry info.
            const graphic = context.createBranch(branch, Transform.identity);

            context.addDecoration(GraphicType.Scene, graphic);
        }
    }//callback -> 3n n = polys.length , decorate update loop.

    //-------------------------------------------------------------------------------------------
    /*
    * This is a boilerplate for loading up all types of geometry queries
    * into the builder object . This function is a as per the IModel doc samples.
    * Keep as is.
    */
    private createGraphicsForGeometry(geometry: GeometryQuery, wantEdges: boolean, builder: GraphicBuilder) {
        if (geometry instanceof LineString3d) {
            builder.addLineString(geometry.points);
        } else if (geometry instanceof Loop) {
            builder.addLoop(geometry);
            if (wantEdges) {
                // Since decorators don't natively support visual edges,
                // We draw them manually as lines along each loop edge/arc
                builder.setSymbology(ColorDef.black, ColorDef.black, 2);
                const curves = geometry.children;
                curves.forEach((value) => {
                    if (value instanceof LineString3d) {
                        let edges = value.points;
                        const endPoint = value.pointAt(0);
                        if (endPoint) {
                            edges = edges.concat([endPoint]);
                        }
                        builder.addLineString(edges);
                    } else if (value instanceof Arc3d) {
                        builder.addArc(value, false, false);
                    }
                });
            }
        } else if (geometry instanceof Path) {
            builder.addPath(geometry);
        } else if (geometry instanceof IndexedPolyface) {
            builder.addPolyface(geometry, true); // this is what will be used to generate the pipes.
            if (wantEdges) {
                // Since decorators don't natively support visual edges,
                // We draw them manually as lines along each facet edge
                builder.setSymbology(ColorDef.black, ColorDef.black, 2);
                const visitor = IndexedPolyfaceVisitor.create(geometry, 1);
                let flag = true;
                while (flag) {
                    const numIndices = visitor.pointCount;
                    for (let i = 0; i < numIndices - 1; i++) {
                        const point1 = visitor.getPoint(i);
                        const point2 = visitor.getPoint(i + 1);
                        if (point1 && point2) {
                            builder.addLineString([point1, point2]);
                        }
                    }
                    flag = visitor.moveToNextFacet();
                }
            }
        } else if (geometry instanceof LineSegment3d) {
            const pointA = geometry.point0Ref;
            const pointB = geometry.point1Ref;
            const lineString = [pointA, pointB];
            builder.addLineString(lineString);
        } else if (geometry instanceof Arc3d) {
            builder.addArc(geometry, false, false);
        } else if (geometry instanceof CurveChainWithDistanceIndex) {
            this.createGraphicsForGeometry(geometry.path, wantEdges, builder);
        }
    }
    // -------------------------------------------------------
    // CALLBACKS
    // -------------------------------------------------------
    /*
    * Ovveride from Decorator parent.
    * Is called to validate Decoratror objects to draw in the current view context.
    * Note :
    * Not a real render callback/loop not usable for Animations,
    * as the loop is not syncronus and runs intermittently, or when the current view comes into focus 'ie on mousclick or mouse move'.
    */
    public decorate(context: DecorateContext): void {
        // Create and add the Geometry to Context
        this.createGraphics(context);
        // Bind markers to Context to be drawn. 
        //for (let i = 0; i < PolygonShapeDecorator.markerSet.length; i++) { PolygonShapeDecorator.markerSet[i].addDecoration(context); }
        PolygonShapeDecorator.markerSet?.forEach((marker) => {
            marker.addDecoration(context);
        });
    }
    //-------------------------------------------------------------------------------------------
    /*
     */ 
    private onMouseClickUp = (event) => {
        event.preventDefault();   
    }
    //-------------------------------------------------------------------------------------------
    /*
    * Override
    * called when decorator object get clicked on
    * will return  the refrence of the hit obect
    */
    public async onDecorationButtonEvent(hit: HitDetail, _ev: BeButtonEvent): Promise<EventHandled> {
        //Is editable is a boolean that controlls if the state of this Polygon is for Edit and creation or Higlight and Data Visualisation   
        if (this._isEditable == true)// This segrgates the functonality betweeen , 'Create Grating From Click Points/AddPolygonShape Tool' and 'Highlight Grating' Tools.
        {  
            //let div = document.getElementsByClassName("imodeljs-vp")[0];
            let div = _ev.viewport?.canvas
            div?.addEventListener('mouseup', this.onMouseClickUp);
            const sourceId = hit.sourceId;

            PolygonShapeDecorator._isSelected = false;
            for (let d = 0; d < this.polys.length; d++)
            {
                //When the Sphere Helpers are clicked on do this.
                if (sourceId == this.polys[d].transientId && this.polys[d].name.substring(0, 3) == "Sph") {//spheres
                    
                    AddEditPolygonShapeTool.setEditMode(true);              //Set the Tool edit flag to true when a sphere is selected and Run the primitive tool.;
                    IModelApp.tools.run(AddEditPolygonShapeTool.toolId);    //run the Addpolygonshape primitve tool , check AddPolygonShapeTool -> onDataButtonDown()

                    // FrontstageManager.activeFrontstageDef?.getZoneDef(ZoneLocation.CenterRight)?.findWidgetDef("PoligonTransform")?.setWidgetState(WidgetState.Closed);
                    // FrontstageManager.activeFrontstageDef?.getZoneDef(ZoneLocation.CenterRight)?.findWidgetDef("PoligonTransform")?.setWidgetState(WidgetState.Open);
                }
                //When the Grating Polygon is clided do this.
                if (sourceId == this.polys[d].transientId && this.polys[d].name.substring(0, 3) == "Pol") {//polygons
                    PolygonShapeDecorator._isSelected = true;
                    PolygonShapeDecorator._lastSelectedPolygonShape = this.polys[d];//update/keep track of the selected polygon info into the static var.

                    this.deleteSpheresMarkerSFromDecorator();//On poligon/Groid object selection , delete the existing spheres and recreate them on the lastselected Polygon shape.
                    AddEditPolygonShapeTool.addSphereHelpersOnPolygonByName(PolygonShapeDecorator._lastSelectedPolygonShape.name);

                    //Open the Edit widget.
                    AddEditPolygonShapeTool.pointCountPerPolygon = this.polys[d].positions.length;//update the static variable to dipict the currently selected poly's point count in UI.

                    //close the side pannel widget if open.
                    // FrontstageManager.activeFrontstageDef?.getStagePanelDef(StagePanelLocation.Right)?.findWidgetDef("PropertyListWidget")?.setWidgetState(WidgetState.Hidden);
                    //We close then open as this forces a refesh of the values in the html segments (hack workaround). in this case to correctly show the point count for each polygon.
                    // FrontstageManager.activeFrontstageDef?.getZoneDef(ZoneLocation.CenterRight)?.findWidgetDef("PoligonTransform")?.setWidgetState(WidgetState.Closed);
                    // FrontstageManager.activeFrontstageDef?.getZoneDef(ZoneLocation.CenterRight)?.findWidgetDef("PoligonTransform")?.setWidgetState(WidgetState.Open);
                }
                //No funtonlity added when the Grating Supporting joint pipes are being clicked on .
                //Implement here for future features if requirement comes up.
                if (sourceId == this.polys[d].transientId && this.polys[d].name.substring(0, 3) == "joi") {//joints/supporting pipes
                    //AddEditPolygonShapeTool.setEditMode(true);
                    //IModelApp.tools.run(AddEditPolygonShapeTool.toolId);

                    //FrontstageManager.activeFrontstageDef?.getZoneDef(ZoneLocation.CenterRight)?.findWidgetDef("PoligonTransform")?.setWidgetState(WidgetState.Closed);
                    //FrontstageManager.activeFrontstageDef?.getZoneDef(ZoneLocation.CenterRight)?.findWidgetDef("PoligonTransform")?.setWidgetState(WidgetState.Open);
                }
            }
        }
        else { //if not editable as per flag , show the property UI list instead of opening the editing feature.
            const sourceId = hit.sourceId;
            for (let d = 0; d < this.polys.length; d++) {
                if (sourceId == this.polys[d].transientId && this.polys[d].name.substring(0, 3) == "Pol") {//polygons

                    PolygonShapeDecorator._lastSelectedPolygonShape = this.polys[d];//update up the selected polygon info to the static member.

                    this.previousSelection = this.lastSelection;
                    this.lastSelection = {name: this.polys[d].name,Pos: [new Point3d(this.polys[d].positions[0].x, this.polys[d].positions[0].y, this.polys[d].positions[0].z), new Point3d(this.polys[d].positions[1].x, this.polys[d].positions[1].y, this.polys[d].positions[1].z), new Point3d(this.polys[d].positions[2].x, this.polys[d].positions[2].y, this.polys[d].positions[2].z)]};

                    this.deleteSpheresMarkerSFromDecorator();//On poligon/Groid object selection , delete the existing spheres and recreate them on the lastselected Polygon shape.
                    AddEditPolygonShapeTool.addSphereHelpersOnPolygonByName(PolygonShapeDecorator._lastSelectedPolygonShape.name);

                    // FrontstageManager.activeFrontstageDef?.getZoneDef(ZoneLocation.CenterRight)?.findWidgetDef("PoligonTransform")?.setWidgetState(WidgetState.Closed);
                    //close the side pannel first to force update.
                    // FrontstageManager.activeFrontstageDef?.getStagePanelDef(StagePanelLocation.Right)?.findWidgetDef("PropertyListWidget")?.setWidgetState(WidgetState.Hidden);
                    //Open and populate the side pannel
                    SyncUiEventDispatcher.dispatchSyncUiEvent("grating-selected");//send an event dispacth to the PropertyListWidget.tsx that then opens the GratingPropertyList.tsx
                    // FrontstageManager.activeFrontstageDef?.getStagePanelDef(StagePanelLocation.Right)?.findWidgetDef("PropertyListWidget")?.setWidgetState(WidgetState.Open);
                }
                if (sourceId == this.polys[d].transientId && this.polys[d].name.substring(0, 3) == "joi") {//joints/supporting pipes

                    //Pending implementation to select Grating if supporting piepes are clicked , WIP , Spec not clear.
                    //in case reuqirement comes up use the template below.

                    //let tpol = this.polys;
                    //let min: number = 99999999999;
                    //for (let pp = 0; pp < tpol.length; pp++) {
                    //    if (tpol[pp].name = "P") {
                    //        let pointA = new Point3d(this.polys[d].positions[0].x, this.polys[d].positions[0].y, this.polys[d].positions[0].z);
                    //        let pointB = new Point3d(tpol[pp].positions[0].x, tpol[pp].positions[0].y, tpol[pp].positions[0].z);
                    //        let distance = pointA.distance(pointB);
                    //        if (min > distance) {
                    //            min = distance;
                    //        }
                    //    }
                    //}

                    //for (let pp = 0; pp < tpol.length; pp++) {

                    //}

                    //PolygonShapeDecorator._lastSelectedPolygonShape = this.polys[d];//update up the selected polygon info to the static member.

                    //this.deleteSpheresMarkerSFromDecorator();//On poligon/Groid object selection , delete the existing spheres and recreate them on the lastselected Polygon shape.
                    //AddEditPolygonShapeTool.addSphereHelpersOnPolygonByName(PolygonShapeDecorator._lastSelectedPolygonShape.name);

                    //FrontstageManager.activeFrontstageDef?.getZoneDef(ZoneLocation.CenterRight)?.findWidgetDef("PoligonTransform")?.setWidgetState(WidgetState.Closed);
                    ////close the side pannel first to force update.
                    //FrontstageManager.activeFrontstageDef?.getStagePanelDef(StagePanelLocation.Right)?.findWidgetDef("PropertyListWidget")?.setWidgetState(WidgetState.Hidden);
                    ////Open and populate the side pannel
                    //SyncUiEventDispatcher.dispatchSyncUiEvent("grating-selected");//send an event dispacth to the PropertyListWidget.tsx that then opens the GratingPropertyList.tsx
                    //FrontstageManager.activeFrontstageDef?.getStagePanelDef(StagePanelLocation.Right)?.findWidgetDef("PropertyListWidget")?.setWidgetState(WidgetState.Open);
                }
            }
        }
        return EventHandled.No;
    }
    //-------------------------------------------------------------------------------------------
    //if this return true then the onDecorationButtonEvent() triggers
    public testDecorationHit(hitid: string): boolean {

        //let isSelected: boolean = false;
        for (const p of this.polys) {
            if (p.transientId === hitid) {
                PolygonShapeDecorator._selectedGeometry = p; // static member used to get the current selected object of this decorator.
                PolygonShapeDecorator._isSelected = true;
                return true;
            }
        }

        if (PolygonShapeDecorator._isSelected && hitid.length !== 0) {
            PolygonShapeDecorator._isSelected = false;
            return false;
        }
        return false;
    }

    public resetGrating(lastPosition: any) {
        if (PolygonShapeDecorator.lastSelectedPolygonShape != undefined && lastPosition.Pos.length) {        
            for (let n = 0; n < AddEditPolygonShapeTool.poligonInfoArray.length; n++) {
                if (AddEditPolygonShapeTool.poligonInfoArray[n]?.name == lastPosition.name) {
                    for (let m = 0; m < AddEditPolygonShapeTool.poligonInfoArray[n].poligonPoints.length; m++) {
                        AddEditPolygonShapeTool.poligonInfoArray[n].poligonPoints[m].y = lastPosition?.Pos[m].y;
                        AddEditPolygonShapeTool.poligonInfoArray[n].poligonPoints[m].x = lastPosition?.Pos[m].x;
                        AddEditPolygonShapeTool.poligonInfoArray[n].poligonPoints[m].z = lastPosition?.Pos[m].z;
                    }
                }
            }
            AddEditPolygonShapeTool.createPoligonFromPolygonInfoArray(AddEditPolygonShapeTool.poligonInfoArray);
        }
    }
    //-------------------------------------------------------------------------------------------
}
