/*---------------------------------------------------------------------------------------------
|  $Copyright: (c) 2018 Bentley Systems, Incorporated. All rights reserved. $
 *--------------------------------------------------------------------------------------------*/
// tslint:disable:no-console
import { reject } from "lodash";
import { ConfigManager } from "../config/ConfigManager";
import { store } from "../store/rootReducer";
// import { NineZoneSampleApp } from "../app/NineZoneSampleApp";
// import { reject } from "q";
// import App from "../components/App";

type LogType = "Critical" | "Debug" | "Error" | "Information" | "Trace" | "Warning";

export class Logger {
  private static async log(logType: LogType, logMessage: string, logMetadata?: any): Promise<void> {
    const metadata: string = JSON.stringify(logMetadata);
    const windowPath: string = decodeURIComponent(window.location.origin);
    const loggerUrl: string = windowPath + "/Logging/Log";
    const activityId: string = (ConfigManager.activityId !== undefined && ConfigManager.activityId !== "") 
      ? ConfigManager.activityId : '';
    // if (App.accessToken == undefined) {
    //   return;
    // }
    const token: string = store.getState().auth.accessTokenStatePrivateAPI.accessToken!;
    const response: any = fetch(loggerUrl, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
         Authorization: token,
         "x-correlation-id": activityId
      },
      body: JSON.stringify({
        LogType: logType,
        Message: logMessage,
        Parameters: metadata,
      }),
    })
      .then((body) => {
        if (response.ok) {
          response.parsedBody = body;
        } else {
          reject(response);
        }
      })
      .catch((err) => {
        reject(err);
      });
  }

  public static fatal(logMessage: string, logMetadata?: any): void {
    this.log("Critical", logMessage, logMetadata);
  }

  public static error(logMessage: string, logMetadata?: any): void {
    this.log("Error", logMessage, logMetadata);
  }

  public static warn(logMessage: string, logMetadata?: any): void {
    this.log("Warning", logMessage, logMetadata);
  }

  public static info(logMessage: string, logMetadata?: any): void {
    this.log("Information", logMessage, logMetadata);
  }

  public static debug(logMessage: string, logMetadata?: any): void {
    this.log("Debug", logMessage, logMetadata);
  }

  public static trace(logMessage: string, logMetadata?: any): void {
    this.log("Trace", logMessage, logMetadata);
  }
}
