import gql from "graphql-tag";

export const towerCapabilities = gql`
      query towerCapabilities($towerId: String, $applicationId: Int){
        towerCapabilities(towerId: $towerId, applicationId: $applicationId) {
            capabilityId
            code
        }
      }`;


export const projectAttributes = gql`
      query projectAttributes($projectId: String!){
        projectAttributes(projectId: $projectId) {
          projectId
          unitSystemId
        }
      }`;

export const projectStatus = gql`
      query projectStatus($projectId: String!){
        projectStatus(projectId: $projectId) {
          isDesignModelAvailable
          isInsightAvailable
          isRealityModelAvailable
          towerType
        }
      }`;

export const mountCatalogInfo = gql
`query mountModelNameFromCatalog($mountType:String!,$ultimateId:String!){
    mountModelNameFromCatalog(mountType:$mountType,ultimateId:$ultimateId){
        manufacturer,
        modelName,
        isGlobal
      }
  }`;

  export const retrieveMountCatalogSharedAccessKey = gql`
  query retrieveMountCatalogSharedAccessKey($mountDetails: MountDetailsInputType) {
    retrieveMountCatalogSharedAccessKey(mountDetails: $mountDetails) {
    fileSharedAccessService,
      }
    }`; 


export const mountiModelModifyRequestMutation = gql
`mutation  mountiModelModifyRequest(
  $mountDetails: IModelMountInputType,
  $projectId: Guid!
){
  mountiModelModifyRequest(mountDetails: $mountDetails,projectId: $projectId) 
  {
    httpStatusCode,
    statusMessage,
    connectorDefinitionId
  }
}`;

export const elevationOperatorMappingMutation = gql
  `mutation  updateTowerOperatorsSetting(
  $contextId: String, $properties: TowerOperatorsSettingPropertiesInputType
  ){
    updateTowerOperatorsSetting(
      contextId: $contextId,
      properties: $properties
    )
  }`;
  
export const retrieveElevationOperatorMapping = gql`
  query towerOperatorsSetting($contextId: String) {
    towerOperatorsSetting(contextId: $contextId) {
      operators {
        elevation
        operator
      }
  }
}`; 

export const retrieveTowerElevationSetting = gql`
  query towerElevationsSetting($contextId: String) {
    towerElevationSetting(contextId: $contextId) {
      flatMapElevation,
      terrainElevation
   }
}`; 

export const updateTowerElevationSetting = gql`
  mutation updateTowerElevationSetting($contextId: String, $properties: TowerElevationSettingPropertiesInputType){
    updateTowerElevationSetting(
      contextId: $contextId,
      properties: $properties
      )
  }
`;

export const GetProjectByIdQuery = gql`
    query projectById($instanceId:String){
    projectById(instanceId:$instanceId){
        instanceId
        properties{  
          assetId
          name
          number
          ultimateRefId
        }
    }
}`;


export const projectAddOnProductLicense = gql`
  mutation handleProjectAddOnProductLicense($addOnProductProject: OrganizationAddOnProductProjectInputType!) {
    handleProjectAddOnProductLicense(addOnProductProject: $addOnProductProject) 
  }
`;

export const addOnProductsForOrganization = gql`
query organizationAddOnProductsByUltimateRefId($ultimateRefId: Guid!){
  organizationAddOnProductsByUltimateRefId(ultimateRefId: $ultimateRefId){
      addOnProductId
      description
      displayName
      licenseExpiresOn
      name
      remainingLicense
      totalLicense
      overUsage
      overUsageLimit
   }
}`;

export const globalAddOnProducts = gql`
query addOnProductsGlobal {
    addOnProducts {
      addOnProductId
      name
      description
      displayName
      description
      productId
      overUsageLimit
    }
  }
`;

export const projectAddOnProducts = gql`
  query projectAddOnProducts($projectId: String!){
    projectAddOnProducts(projectId: $projectId){
      addOnProductId
      consumedOn
      id
    }
  }
`;

export const towerTier = gql`
query towerTier($towerId: String){
  towerTier(towerId: $towerId) {
      displayName
      tierId
      name
  }
}`;

export const lastRunDetailsSetting = gql
    `query lastRunDetails($projectId: Guid!){
     lastRunDetails(projectId: $projectId) {
       httpStatusCode,
       connectionDefinationId,
       result,
       state
     }
   }`;

export const addUserRequest = gql`
  mutation addUserRequest($userRequest: UserRequestInputType !) {
  addUserRequest(userRequest: $userRequest)
}`;

export const postUserRequestProcessMessage = gql`
  mutation postUserRequestProcessMessage($userRequestId: String!,$source: UserRequestSource!){
  postUserRequestProcessMessage(userRequestId:$userRequestId,source: $source)
}`;

export const userRequestById = gql
    `query userRequestById($userRequestId: String!){
        userRequestById(userRequestId:$userRequestId){
        responseData
        responseInfo
        status
  }
   }`;

