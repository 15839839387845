import { BeButtonEvent, DecorateContext, EventHandled, HitDetail, IModelApp, InteractiveTool, LocateFilterStatus, LocateResponse, PrimitiveTool, Viewport } from "@itwin/core-frontend";
import { SyncUiEventDispatcher, WidgetState } from "@itwin/appui-react";
import { EquipmentsTable } from "../Tables/EquipmentsTable";
import { RealityDataDecorator } from "../../tools/decorators/RealityDataDecorator";

// tslint:disable:naming-convention
export class SelectAntennaTool extends PrimitiveTool {
    public static override toolId = "selectAntenna";
    public static override iconSpec = "icon-cursor";

    private selectedText: string | undefined;
    private selectedAntenna: number | undefined; // index of antenna model
    private currentDecorator: RealityDataDecorator | undefined;
    private updated: boolean = false;
    public static selectedAntennaName = "";

    public override isCompatibleViewport(_vp: Viewport | undefined, _isSelectedViewChange: boolean): boolean {
        return true;
    }

    public override run(): Promise<boolean> {
        super.run();
        const { toolAdmin, viewManager } = IModelApp;
        if (!this.isCompatibleViewport(viewManager.selectedView, false) || !toolAdmin.onInstallTool(this)) {
            return Promise.resolve(false);
        }

        toolAdmin.startPrimitiveTool(this);
        toolAdmin.onPostInstallTool(this);
        return Promise.resolve(true);
    }

    public override onInstall(): Promise<boolean> {
        return Promise.resolve(true);
    }

    public override async filterHit(hit: HitDetail, _out?: LocateResponse): Promise<LocateFilterStatus> {
        const tooltip = await hit.getToolTip();

        if (!tooltip)
            return LocateFilterStatus.Reject;

        const text = typeof (tooltip) === "string" ? tooltip : ((tooltip instanceof HTMLElement) ? tooltip.innerText : undefined);

        if (!text)
            return LocateFilterStatus.Reject;

        if (this.currentDecorator) {
            const found = IModelApp.viewManager.dropDecorator(this.currentDecorator);
        }

        this.updated = false;
        this.selectedText = text;

        return LocateFilterStatus.Accept;
    }

    public override decorate(context: DecorateContext): void {
        if (!this.updated && this.selectedText) {
            this.handleSelection(context, this.selectedText);
            if (this.selectedAntenna !== null && this.currentDecorator) {
                this.currentDecorator.decorate(context);
            }
            this.updated = true;
        }
    }

    public async handleSelection(context: DecorateContext, text: string): Promise<void> {
        const indices: number[] = [];//MainPage.antennaModelIndices;
        const vp = context.viewport;
        let index = 0;

        // show equipment widget

        vp.view.displayStyle.forEachRealityModel(async (model) => {
            if (indices.includes(index)) {
                if (model.name === text) {
                    this.selectedAntenna = index;
                    SelectAntennaTool.selectedAntennaName = model.name.replace(/-3SM/i, "");
                    this.currentDecorator = new RealityDataDecorator(index);
                    SyncUiEventDispatcher.dispatchSyncUiEvent("equipmentselected");
                }
            }
            index++;
        });
    }

    public static async fetchEquipmentData(name: string): Promise<any> {
        return EquipmentsTable.getSelectedEquipmentInfoJson(name);
    }

    public override async onDataButtonDown(ev: BeButtonEvent): Promise<EventHandled> {
        const hit = await IModelApp.locateManager.doLocate(new LocateResponse(), true, ev.point, ev.viewport, ev.inputSource);

        return EventHandled.No;
    }

    public override onPostInstall() {
        super.onPostInstall();
        IModelApp.accuSnap.enableSnap(true);
        return Promise.resolve();
        // this.initLocateElements(); // Enable AccuSnap locate, set view cursor, add CoordinateLockOverrides to disable unwanted pre-locate point adjustments...
    }

    public override onSelectedViewportChanged(_previous: Viewport | undefined, current: Viewport | undefined): Promise<void> {
        if (this.isCompatibleViewport(current, true))
            return Promise.resolve();
        this.onRestartTool();
        return Promise.resolve();
    }

    public override async onResetButtonUp(_ev: BeButtonEvent): Promise<EventHandled> {
        if (this.currentDecorator) {
            IModelApp.viewManager.dropDecorator(this.currentDecorator);
        }
        this.clearSelections();

        this.exitTool();
        return EventHandled.No;
    }

    public clearSelections(): void {
        const vp = IModelApp.viewManager.selectedView;
        const l = vp!.displayStyle.contextRealityModelStates[0];
        l!.appearanceOverrides = undefined;

        // vp?.dropRealityModelAppearanceOverride(-1);
        SyncUiEventDispatcher.dispatchSyncUiEvent("equipmentunselected");

        this.selectedAntenna = undefined;
        this.selectedText = undefined;
        this.currentDecorator = undefined;
        this.selectedText = "";
    }

    public override async onSuspend() {
        // if (this.currentDecorator)
        //     IModelApp.viewManager.dropDecorator(this.currentDecorator);
        // this.clearSelections();
    }

    public onRestartTool(): Promise<void> {
        const tool = new SelectAntennaTool();
        if (!tool.run()) {
            this.clearSelections();
            this.exitTool();
        }
        return Promise.resolve();
    }
}
