// import { EquipmentType } from "../app-ui/frontstages/SampleToolWidget"
import { pecInputTagTypes, pecDataValueTypes, pecRows, pecGridStructure, pecComponentProps, pecStrucType, pecTableButton, PropertyEditorComponent, numericValue } from "../components/PropertyEditorComponent"

import React, { ReactNode, useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { IModelApp, NotifyMessageDetails, OutputMessagePriority, OutputMessageType } from "@itwin/core-frontend"
import { UiFramework } from "@itwin/appui-react"
import EquipmentClient from "../api/equipment"
import { Vector3d, YawPitchRollAngles } from "@itwin/core-geometry"
import { RootState } from "../../store/States"
import { ObjectCategoryTypes } from "../../store/detectedData/apiDataTypes"
import { EquipmentType, selectionObjective } from "../../store/redux-types"
import { store } from "../../store/rootReducer"
import { EquipmentShapeDecorator, ShapeType } from "./decorators/EquipmentShapeDecorator"
import { EquipmentRotationWidget } from "./widgets/EquipmentRotationWidget"
import { EquipmentsTable } from "../components/Tables/EquipmentsTable"
import { fetchEquipmentData } from "./GetDetectedObjectsData"

export enum EquipmentRotationType {
  Azimuth = 0,
  Tilt = 1,
  Roll = 2,
}
let runTimer: any;

const DetectedEquipmentEditHandler = (props ) => {
  const [equipmentEditData, setEquipmentEditData] = useState<pecGridStructure>(props.equipmentMenuStruc);
  const [dataUpdated, setDataUpdated] = useState<boolean>(false);
  const [saveButton, setSaveButton] = useState<{disabled: boolean, text: string}>({disabled: false, text: "Save"});
  const [untouchedEquipJson, setUntouchedEquipJson] = useState<any>(undefined);
  const move = 0.01;
  const radiusMove = 0.02;
  
  useEffect(() => {
    setEquipmentEditData(getFieldStructure(props.selEquipName).equipmentMenuStruc);
    // return () => {}
  }, [props.selEquipName])
  
  useEffect(() => {
    setUntouchedEquipJson(props.equipCurrJson);
    // return () => {}
  }, [])
  

  const saveData = async (posData, modified): Promise<boolean> => {
    let retResp: boolean = false;
    if (posData && modified) {
      const equipData={...EquipmentsTable.equipmentData.filter((e)=>{return e.name === posData.Equipment_Name})[0]};
      const theIndex=EquipmentsTable.equipmentData.findIndex((e)=>{return e.name === posData.Equipment_Name});
      const currentState = store.getState();
      const putData: any = {                                                                
          name: posData.Equipment_Name,
          displayName: equipData.displayName,
          manufacturer: posData.Manufacturer,
          model: posData.Model,
          type: equipData.type,
          operator: equipData.operator,
          height: posData.Height,
          elevationHeight: 0,
          width: posData.Width,
          depth: posData.Thicness,
          weight: equipData.weight,
          azimuth: posData.Azimuth,
          tilt: posData.Tilt,
          xPosition: posData.x_position,
          yPosition: posData.y_position,
          zPosition: posData.z_position,
          roll: posData.Roll,
          area: equipData.area,
          bandTechnology: equipData.bandTechnology,
          reference: equipData.reference,
      };
      putData.elevationHeight =  posData.Elevation_Height == undefined ? (posData.z_position-currentState.detectedData.siteCoordinate.utm.z): posData.Elevation_Height;
      const infoData = currentState.detectedData.selectedObjectInformation.objectProperties;
      if (infoData && posData && currentState.detectedData.siteCoordinate) {
          await EquipmentClient.putEquipmentJson(currentState.auth.accessTokenStatePrivateAPI.accessToken!, putData, "v1.1")
          // tslint:disable-next-line:no-shadowed-variable
          .then(async (res) => {
              if (!res) {
                  IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Error,
                      `Error occured: ${name} edit changes not saved.`, "", OutputMessageType.Toast));
                      retResp = false;
              } else {
                equipData.name=posData.Equipment_Name;
                equipData.manufacturer=posData.Manufacturer;
                equipData.model=posData.Model;
                equipData.height=posData.Height;
                equipData.elevationHeight=putData.elevationHeight;
                equipData.width=posData.Width;
                equipData.depth=posData.Thicness;
                equipData.azimuth=posData.Azimuth;
                equipData.tilt=posData.Tilt;
                equipData.xPosition=posData.x_position;
                equipData.yPosition=posData.y_position;
                equipData.zPosition=posData.z_position;
                equipData.roll=posData.Roll;
                equipData.dimensions=`${posData.Height}x${posData.Width}x${posData.Thicness}`;

                // (IModelApp as any).listCallback(ListEnum.Equipment);y
                EquipmentsTable.equipmentData[theIndex] = equipData;
                const imc = UiFramework.getIModelConnection();
                imc?.selectionSet.emptyAll();
                imc?.selectionSet.add([...currentState.detectedData.selectedObjectInformation.objectProperties.objectIds][0])

                IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Info,
                    `Success: ${equipData.displayName} changes saved.`, "", OutputMessageType.Toast));
                    retResp = true;
              }
          });
        } else {
          IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Error,
          `Error occured: ${equipData.displayName} edit changes not saved.`, "", OutputMessageType.Toast));
          retResp = false;
        }
      }
      return Promise.resolve(retResp);

  }

  const onModalBtnClick = async (event: any) => {
    const id = event.target.id;
    let decorator = IModelApp.viewManager.decorators.filter((e) => e.constructor.name.includes("EquipmentShapeDecorator"))[0] as EquipmentShapeDecorator;
    switch (id) {
      case "Save#1": {
        // const decorator = IModelApp.viewManager.decorators.filter(e=>e.constructor.name.includes("TowerStructureDecorator"))[0] as TowerStructureDecorator;
        // decorator.saveTower(props.selObjProps.selectedObjectNST.name);
        const theUpdatedJson = {...decorator?.currJson.find((e) => e.Equipment_Name === props.selEquipName)};
        let temp: numericValue = {
          currentValue: 0,
          min: 0,
          max: 0,
          precision: 0,
          step: 0
        };
        temp = equipmentEditData.rows[3].columns[1].value as numericValue;
        theUpdatedJson.Azimuth=temp.currentValue;
        temp = equipmentEditData.rows[4].columns[1].value as numericValue;
        theUpdatedJson.Roll=temp.currentValue;
        temp = equipmentEditData.rows[5].columns[1].value as numericValue;
        theUpdatedJson.Tilt=-1*temp.currentValue;
        setSaveButton({disabled: true, text: "Saving..."});
        await saveData(theUpdatedJson, true);
        fetchEquipmentData(props.privateToken)
        props.onClose();

        // ModifyHandleDecoration.clear();
        break;
      }
      case "Cancel#2": {
        // let decorator = IModelApp.viewManager.decorators.filter((e) => e.constructor.name.includes("EquipmentShapeDecorator"))[0] as EquipmentShapeDecorator;
        const newYpr = YawPitchRollAngles.createDegrees(untouchedEquipJson.Azimuth, untouchedEquipJson.Tilt, untouchedEquipJson.Roll);
        // const theIndex=EquipmentsTable.equipmentData.findIndex((e)=>{return e.name === props.selectedObject.selectedObjectNST.name});
        const theIndex=decorator.currJson.findIndex((e)=>{return e.Equipment_Name === props.selEquipName});
        if(props.selEquipName == untouchedEquipJson.Equipment_Name){
          decorator.currJson[theIndex] = untouchedEquipJson;
          if (props.subType === EquipmentType.Antenna || props.subType === EquipmentType.RRU) {
            // modDecorator._hasEditedBox = true;
            decorator.updateBoxRotation(props.selEquipName, newYpr);
            const box = decorator.constructBoxGeometry(untouchedEquipJson!, new Vector3d());
            const boxIndex = decorator.boxes.findIndex((entry) => entry.name === props.selEquipName);
            decorator.updateBox(box!, boxIndex, false, false, untouchedEquipJson);
          } else if (props.subType === EquipmentType.Microwave) {
            // SampleToolWidget.isModifiedData = true;
            decorator.updateCylinderRotation(props.selEquipName, newYpr);
            decorator.updateCylinder(props.selEquipName, untouchedEquipJson, new Vector3d());
          }
          const temp: pecGridStructure = {...equipmentEditData};
          let tempVal: any = temp.rows[3].columns[1].value;
          tempVal.currentValue = untouchedEquipJson.Azimuth;
          tempVal = temp.rows[4].columns[1].value;
          tempVal.currentValue = untouchedEquipJson.Roll;
          tempVal = temp.rows[5].columns[1].value;
          tempVal.currentValue = untouchedEquipJson.Tilt;
          setEquipmentEditData(temp);
        }
        props.onClose();
        // UiFramework.dialogs.modeless.close("Edit Dialog");
        // store.dispatch(DTVActions.setEditModeFlag(false));
        break;
      }
    }
  }
  
  const onFieldUpdate = (event) => {
    let currentEvent= event;
  }
  
  const onFieldChange = async (event, inputId: string) => {
    const temp: pecGridStructure = {...equipmentEditData};
    const eventType = event!.constructor?.name;
    // return ;
    if((eventType == "SyntheticBaseEvent") || (typeof event === 'object' && event !== null && eventType != "Array")){
      const {id: theId, name: theName, value: theValue} = event.target;
      const [inputType, rowIndex, columnIndex] = theId.split("@")[0].split('-');

      
      switch (inputType) {
        case pecInputTagTypes.SELECT:
          temp.rows[rowIndex].columns[columnIndex].value = temp.rows[rowIndex].columns[columnIndex].data![theValue];
          break;
        case pecInputTagTypes.SLIDER_INPUT:
          break;
        case pecInputTagTypes.CHECKBOX:
          temp.rows[rowIndex].columns[columnIndex].value = !temp.rows[rowIndex].columns[columnIndex].value;
          break;
      
        default:
          break;
      }
      
      
    } else {    
      const inputIdSplit = inputId.split("@");
      const [inputType, rowIndex, columnIndex] = inputIdSplit[0].split('-');
      switch (inputType) {
      case "sis":
        temp.rows[rowIndex].columns[columnIndex].value.currentValue = event[0];
        handleAngleChange(event[0]!, EquipmentRotationType[inputIdSplit[1]])
        break;
      case "sii":
        temp.rows[rowIndex].columns[columnIndex].value.currentValue = event;
        handleAngleChange(event!, EquipmentRotationType[inputIdSplit[1]])
      break;
      
      default:
        break;
      }

    }

    setEquipmentEditData(temp);
  }
  
  const handleAngleChange = async (value: number, rotationVal: EquipmentRotationType) => {
    const equipName = props.selEquipName;
    if (undefined === value) return;
    const fixedVal = Number(value.toFixed(2));
    if (rotationVal === 0) {
        // setYaw(fixedVal);
    } else if (rotationVal === 1) {
        // setPitch(fixedVal);
    } else {
        // setRoll(fixedVal);
    }
    let name = "";
    if (equipName.match(/Antenna/i)) {
        name = "Antenna";
    } else if (equipName.match(/Micro_Wave/i)) {
        name = "Microwave";
    } else if (equipName.match(/RRU/i)) {
        name = "RRU";
    }

    // setEditObjName(name);
    // if(currentObjType === "Equipment"){
        if (equipName.match(/Micro_Wave/i)) { // cylinder
            await EquipmentRotationWidget.sliderChange(rotationVal == EquipmentRotationType.Tilt ? -1*value : value, rotationVal, ShapeType.Cylinder);
        } else {
            await EquipmentRotationWidget.sliderChange(rotationVal == EquipmentRotationType.Tilt ? -1*value : value, rotationVal, ShapeType.Box);
        }
    // } else {
    //     // await MountRotationWidget.sliderChange(value, rotationVal, ShapeType.Cylinder);
    // }
};


  
  const onFieldClick = (event, inputIds) => {
    let currentEvent= event;
    let inputId = inputIds
  }
  
  const onMouseUp = (event, inputIds) => {
    let currentEvent= event;
    let inputId = inputIds
    clearInterval(runTimer);
  }
  
  
  const onMouseDown = (event) => {
    const temp: pecGridStructure = {...equipmentEditData};
    
    // const eventType = event!.constructor?.name;
    const {id: theId, name: theName, value: theValue} = event.target.tagName != "SPAN" ? event.target : event.target.parentElement;
    const idSplit = theId.split("@");
    // const [inputType, rowIndex, columnIndex] = idSplit[0].split('-');
    let movementVector: Vector3d = new Vector3d();
    let moveDirection: number = 0;
    enum editMode {
      radial = "Radial",
      positional = "Positional",
      dimensional = "Dimensional"
    }
    let mode: editMode = editMode.positional;
    switch(idSplit[1]){
      case "moveLeft":
        movementVector = new Vector3d(move, 0, 0);        
      break;
      case "moveRight": 
        movementVector = new Vector3d(-move, 0, 0);        
      break;
      case "moveFront":
        movementVector = new Vector3d(0, move, 0);        
        break;
      case "moveBack": 
        movementVector = new Vector3d(0, -move, 0);        
      break;
      case "moveUp":
        movementVector = new Vector3d(0, 0, move);        
      break;
      case "moveDown": 
        movementVector = new Vector3d(0, 0, -move);        
      break;
      case "increaseThickness": 
        mode = editMode.dimensional;
        moveDirection = move;
        break;
      case "decreaseThickness": 
        mode = editMode.dimensional;
        moveDirection = -move;
      break;
      case "increaseDiameter": 
        mode = editMode.radial;
        moveDirection = move;
        break;
      case "decreaseDiameter": 
        mode = editMode.radial;
        moveDirection = -move;
      break;
    }


    setDataUpdated(true);
    if(mode == editMode.positional){
      runTimer=setInterval(()=>EquipmentRotationWidget.translate(movementVector.x, movementVector.y, movementVector.z), 50);
    } else if (mode == editMode.dimensional) {
      runTimer=setInterval(()=>EquipmentRotationWidget.cylinderDepthChange(moveDirection), 50);
    } else if (mode == editMode.radial) {
      runTimer=setInterval(()=>EquipmentRotationWidget.cylinderRadiusChange(moveDirection), 50);
    }
    setTimeout(() => clearInterval(runTimer), 10000);
  }
  
  
  let modalBtns: pecTableButton[] = [
    {name: saveButton.text, value: saveButton.text, class: "dialogBox-save-btn", disable: saveButton.disabled, onInteract: onModalBtnClick},
    {name: "Cancel", value: "Cancel", class: "dialogBox-cancel-btn", disable: false, onInteract: onModalBtnClick},
  ];
  
  
  let equipmentEditorStructure: pecComponentProps = {
    structureType: pecStrucType.STRUCTURE,
    title: {
      label: "Equipment Edit",
      // styles: undefined
    },
    grid: equipmentEditData,
    gridButtons: modalBtns,
    eventHandlers: {
      onChange: onFieldChange,
      onUpdate: onFieldUpdate,
      onClick: onFieldClick,
      onMouseUp: onMouseUp,
      onMouseDown: onMouseDown,
    }
  }

  


  return (
    <PropertyEditorComponent {...equipmentEditorStructure} />
  )
}

const mapStateToProps = (state: RootState) => {
  const {equipmentMenuStruc, equipCurrJson} = getFieldStructure(EquipmentShapeDecorator.equipmentName);
  const selEquipName = EquipmentShapeDecorator.equipmentName;
  const subType = selEquipName?.match(/Ant/ig) ? EquipmentType.Antenna : selEquipName?.match(/RRU/ig) ? EquipmentType.RRU : EquipmentType.Microwave;
  const retVal = {
    equipmentMenuStruc,
    equipCurrJson,
    selEquipName,
    subType,
    privateToken: state.auth.accessTokenStatePrivateAPI.accessToken
  };
  return retVal;
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(DetectedEquipmentEditHandler)


const getFieldStructure = (selEquipName?: string) => {
  let decorator = IModelApp.viewManager.decorators.filter((e) => e.constructor.name.includes("EquipmentShapeDecorator"))[0] as EquipmentShapeDecorator;
  const equipCurrJson = decorator.currJson.find((i) => i.Equipment_Name === selEquipName);
  const {Azimuth = 0, Roll = 0,  Tilt = 0} = equipCurrJson || {};
  const equipmentMenuStruc: pecGridStructure = {
    rows: [
        {
          columns: [
            {
              size: 8,
              type: pecInputTagTypes.LABEL,
              customClass: "",
              value: "Move Left or Right",
              disabled: false,
              name: "mvLorR",
              fieldType: pecDataValueTypes.INTEGER,
              modified: false,
              styles: {parentClass: "mb-2", childClass: ""}
            },
            {
              size: 2,
              type: pecInputTagTypes.BUTTON,
              customClass: "",
              value: "L",
              disabled: false,
              name: "moveLeft",
              fieldType: pecDataValueTypes.INTEGER,
              modified: false,
              styles: {parentClass: "mb-2", childClass: "number-input-class"}
            },      
            {
              size: 2,
              type: pecInputTagTypes.BUTTON,
              customClass: "",
              value: "R",
              disabled: false,
              name: "moveRight",
              fieldType: pecDataValueTypes.INTEGER,
              modified: false,
              styles: {parentClass: "mb-2", childClass: "number-input-class"}
            },      
          ]
          
        },
        {
          columns: [
            {
              size: 8,
              type: pecInputTagTypes.LABEL,
              customClass: "",
              value: "Move Front or Back",
              disabled: false,
              name: "mvForB",
              fieldType: pecDataValueTypes.INTEGER,
              modified: false,
              styles: {parentClass: "mb-2", childClass: ""}
            },
            {
              size: 2,
              type: pecInputTagTypes.BUTTON,
              customClass: "",
              value: "F",
              disabled: false,
              name: "moveFront",
              fieldType: pecDataValueTypes.INTEGER,
              modified: false,
              styles: {parentClass: "mb-2", childClass: "number-input-class"}
            },      
            {
              size: 2,
              type: pecInputTagTypes.BUTTON,
              customClass: "",
              value: "B",
              disabled: false,
              name: "moveBack",
              fieldType: pecDataValueTypes.INTEGER,
              modified: false,
              styles: {parentClass: "mb-2", childClass: "number-input-class"}
            },      
          ]
          
        },
        {
          columns: [
            {
              size: 8,
              type: pecInputTagTypes.LABEL,
              customClass: "",
              value: "Move Up or Down",
              disabled: false,
              name: "mvUorD",
              fieldType: pecDataValueTypes.INTEGER,
              modified: false,
              styles: {parentClass: "mb-2", childClass: ""}
            },
            {
              size: 2,
              type: pecInputTagTypes.BUTTON,
              customClass: "",
              value: "U",
              disabled: false,
              name: "moveUp",
              fieldType: pecDataValueTypes.INTEGER,
              modified: false,
              styles: {parentClass: "mb-2", childClass: "number-input-class"}
            },      
            {
              size: 2,
              type: pecInputTagTypes.BUTTON,
              customClass: "",
              value: "D",
              disabled: false,
              name: "moveDown",
              fieldType: pecDataValueTypes.INTEGER,
              modified: false,
              styles: {parentClass: "mb-2", childClass: "number-input-class"}
            },      
          ]
        },
        {
          columns: [
            {
              size: 3,
              type: pecInputTagTypes.LABEL,
              customClass: "",
              value: "Azimuth",
              disabled: false,
              name: "label-2",
              fieldType: pecDataValueTypes.INTEGER,
              modified: false,
              styles: {parentClass: "", childClass: ""}
            },
            {
              size: 9,
              type: pecInputTagTypes.SLIDER_INPUT,
              customClass: "",
              value: {currentValue: Azimuth, min: 0, max: 360, precision: 2, step: 0.5},
              disabled: false,
              name: "Azimuth",
              fieldType: pecDataValueTypes.INTEGER,
              modified: false,
              styles: {parentClass: "", childClass: JSON.stringify({"sliderClass":"slider-width","inputClass":"numericValue-width"})},
            },
          ]
        },
        {
          columns: [
            {
              size: 3,
              type: pecInputTagTypes.LABEL,
              customClass: "",
              value: "Roll",
              disabled: false,
              name: "label-2",
              fieldType: pecDataValueTypes.INTEGER,
              modified: false,
              styles: {parentClass: "", childClass: ""}
            },
            {
              size: 9,
              type: pecInputTagTypes.SLIDER_INPUT,
              customClass: "",
              value: {currentValue: Roll, min: -180, max: 180, precision: 2, step: 0.5},
              disabled: false,
              name: "Roll",
              fieldType: pecDataValueTypes.INTEGER,
              modified: false,
              styles: {parentClass: "", childClass: JSON.stringify({"sliderClass":"slider-width","inputClass":"numericValue-width"})},
            },
          ]
        },
        {
          columns: [
            {
              size: 3,
              type: pecInputTagTypes.LABEL,
              customClass: "",
              value: "Down Tilt",
              disabled: false,
              name: "label-2",
              fieldType: pecDataValueTypes.INTEGER,
              modified: false,
              styles: {parentClass: "", childClass: ""}
            },
            {
              size: 9,
              type: pecInputTagTypes.SLIDER_INPUT,
              customClass: "",
              value: {currentValue: -1*Tilt, min: -180, max: 180, precision: 2, step: 0.5},
              disabled: false,
              name: "Tilt",
              fieldType: pecDataValueTypes.INTEGER,
              modified: false,
              styles: {parentClass: "", childClass: JSON.stringify({"sliderClass":"slider-width","inputClass":"numericValue-width"})},
            },
          ]
        },
      ]
  }

  if(selEquipName && selEquipName.match(/Micro_Wave/gi)){
    equipmentMenuStruc.rows.push(
      {
        columns: [
          {
            size: 8,
            type: pecInputTagTypes.LABEL,
            customClass: "",
            value: "Thickness",
            disabled: false,
            name: "mvLorR",
            fieldType: pecDataValueTypes.INTEGER,
            modified: false,
            styles: {parentClass: "mb-2", childClass: ""}
          },
          {
            size: 2,
            type: pecInputTagTypes.BUTTON,
            customClass: "",
            value: "+",
            disabled: false,
            name: "increaseThickness",
            fieldType: pecDataValueTypes.INTEGER,
            modified: false,
            styles: {parentClass: "mb-2", childClass: "number-input-class"}
          },      
          {
            size: 2,
            type: pecInputTagTypes.BUTTON,
            customClass: "",
            value: "-",
            disabled: false,
            name: "decreaseThickness",
            fieldType: pecDataValueTypes.INTEGER,
            modified: false,
            styles: {parentClass: "mb-2", childClass: "number-input-class"}
          },      
        ]
      },
      {
        columns: [
          {
            size: 8,
            type: pecInputTagTypes.LABEL,
            customClass: "",
            value: "Diameter",
            disabled: false,
            name: "mvLorR",
            fieldType: pecDataValueTypes.INTEGER,
            modified: false,
            styles: {parentClass: "mb-2", childClass: ""}
          },
          {
            size: 2,
            type: pecInputTagTypes.BUTTON,
            customClass: "",
            value: "+",
            disabled: false,
            name: "increaseDiameter",
            fieldType: pecDataValueTypes.INTEGER,
            modified: false,
            styles: {parentClass: "mb-2", childClass: "number-input-class"}
          },      
          {
            size: 2,
            type: pecInputTagTypes.BUTTON,
            customClass: "",
            value: "-",
            disabled: false,
            name: "decreaseDiameter",
            fieldType: pecDataValueTypes.INTEGER,
            modified: false,
            styles: {parentClass: "mb-2", childClass: "number-input-class"}
          },      
        ]
        
      },
    )
  }
  return {equipmentMenuStruc, equipCurrJson: {...equipCurrJson}};
}
// const temppecColumns: pecColumn[] = [
//     //   {
//     //     size: 3,
//     //     type: pecInputTagTypes.LABEL,
//     //     customClass: "",
//     //     value: "theLabelValue",
//     //     disabled: false,
//     //     name: "label-1",
//     //     fieldType: pecDataValueTypes.INTEGER,
//     //     modified: false,
//     //     styles: {parentClass: "", childClass: ""}
//     // },
//     // {
//     //     size: 3,
//     //     type: pecInputTagTypes.SELECT,
//     //     customClass: "",
//     //     value: "theInputValue",
//     //     disabled: false,
//     //     name: "input-2",
//     //     fieldType: pecDataValueTypes.INTEGER,
//     //     modified: false,
//     //     styles: {parentClass: "", childClass: "dialogBox-dropdown"},
//     //     data: ["theInputValue", "theInputValue1", "theInputValue2", "theInputValue3"]
//     // },
//     // {
//     //     size: 3,
//     //     type: pecInputTagTypes.INPUT,
//     //     customClass: "",
//     //     value: "theInputValue",
//     //     disabled: false,
//     //     name: "input-1",
//     //     fieldType: pecDataValueTypes.INTEGER,
//     //     modified: false,
//     //     styles: {parentClass: "", childClass: ""}
//     // },
//     {
//         size: 2,
//         type: pecInputTagTypes.LABEL,
//         customClass: "",
//         value: "theLabelValue",
//         disabled: false,
//         name: "label-2",
//         fieldType: pecDataValueTypes.INTEGER,
//         modified: false,
//         styles: {parentClass: "", childClass: ""}
//     },
//     {
//         size: 10,
//         type: pecInputTagTypes.SLIDER_INPUT,
//         customClass: "",
//         value: "&#60;",
//         disabled: false,
//         name: "input-2",
//         fieldType: pecDataValueTypes.INTEGER,
//         modified: false,
//         styles: {parentClass: "", childClass: "RTC-yrp-button-height left"},
//     },
//     // {
//     //     size: 3,
//     //     type: pecInputTagTypes.BUTTON,
//     //     customClass: "",
//     //     value: "&#60;",
//     //     disabled: false,
//     //     name: "input-2",
//     //     fieldType: pecDataValueTypes.INTEGER,
//     //     modified: false,
//     //     styles: {parentClass: "", childClass: "RTC-yrp-button-height left"},
//     // },
//     // {
//     //     size: 3,
//     //     type: pecInputTagTypes.LABEL,
//     //     customClass: "",
//     //     value: "theLabelValue",
//     //     disabled: false,
//     //     name: "label-2",
//     //     fieldType: pecDataValueTypes.INTEGER,
//     //     modified: false,
//     //     styles: {parentClass: "", childClass: ""}
//     // },
//     // {
//     //     size: 3,
//     //     type: pecInputTagTypes.SLIDER,
//     //     customClass: "",
//     //     value: "theInputValue",
//     //     disabled: false,
//     //     name: "input-2",
//     //     fieldType: pecDataValueTypes.INTEGER,
//     //     modified: false,
//     //     styles: {parentClass: "", childClass: ""},
//     //     data: ["theInputValue", "theInputValue1", "theInputValue2", "theInputValue3"]
//     // },
//     ]

// const equipmentPecColumns: pecColumn[] = [
//       {
//         size: 4,
//         type: pecInputTagTypes.LABEL,
//         customClass: "",
//         value: "Pattern",
//         disabled: false,
//         name: "Pattern",
//         fieldType: pecDataValueTypes.INTEGER,
//         modified: false,
//         styles: {parentClass: "", childClass: ""}
//     },
//     {
//         size: 8,
//         type: pecInputTagTypes.SELECT,
//         customClass: "",
//         value: "theInputValue",
//         disabled: false,
//         name: "input-2",
//         fieldType: pecDataValueTypes.INTEGER,
//         modified: false,
//         styles: {parentClass: "", childClass: "dialogBox-dropdown"},
//         data: ["--Select--", "X", "D-Up", "D-Down", "K-Up", "K-Down"]
//     },
//   ]
    
//     const temppecRows: pecRows = {
//       columns: temppecColumns,
//     }
    
//     const equipmentPecRows: pecRows = {
//       columns: equipmentPecColumns,
//     }
