import { UiFramework } from "@itwin/appui-react";
import { PrimitiveTool, IModelApp, NotifyMessageDetails, OutputMessagePriority, OutputMessageType, SelectionTool, BeButtonEvent, EventHandled, Viewport } from "@itwin/core-frontend";
import { RootState } from "../../../store/States";
import { addToBuilt3DObjectIdsMap, setLadderData, setLadderData3DMaps, updateBuilt3DObjectIdsMap } from "../../../store/detectedData/apiDataActionTypes";
import { store } from "../../../store/rootReducer";
import { HighlightLadderTool } from "./HighlightLadderTool";
import { LadderDecorator } from "../../tools/decorators/LadderDecorator";
import { DecoratorHelper } from "../../tools/decorators/DecoratorHelper";
import { postSingleLadderApi, postUpdateAllLadderData, putLadderApi } from "../../api/ladderClient";
//------------------------------------------------------------------------------------
function select(state: RootState, dataKey: string) {
    return state.detectedData[dataKey];
}

export class AddLadderTool extends PrimitiveTool {

    public static override toolId = "AddLadderTool";
    
    public static callback: any | undefined;
    private static uiInfo: any;

    public static fdata: any;                           //This holds all the relevant fetched data for used from the database. TowerStructure and Bracings Data
    public static saveBracingArray : any;
    //Variables being set in the Side popout UI
    public static topPoint: [any | undefined] = [undefined];            //End point for bracing.
    public static bottomPoint: [any | undefined] = [undefined];         //Start point for bracing.
    public static ismhChecked: boolean = false;         //Mid horizontal checkbox.
    public static isthChecked: boolean = false;         //Top horizontal checkbox.
    //--------------------------------------------
    /*
    * 
    */
    public override run(UIInfo : any, callback=undefined): Promise<any> {
        super.run();
        const { toolAdmin, viewManager } = IModelApp;
        if (!this.isCompatibleViewport(viewManager.selectedView, false) || !toolAdmin.onInstallTool(this)) {
            return Promise.resolve(false);
        }
        //-------------
        //  AddLadderTool.bracingDecorator = new BracingDecorator();
         AddLadderTool.callback = callback;
        //  AddLadderTool.fetchData();//get bracing data from dbase.

        //Note : for top and bottom point the points have to be collected in the tool itelsef
        //so only a flag/boolean to collect point/altitude(here) is sent via the UI , 
        //but for Bracing Type a specific string is sent to tell what the bracing shape should be.
         AddLadderTool.uiInfo = UIInfo;
        toolAdmin.startPrimitiveTool(this);
        toolAdmin.onPostInstallTool(this);
        // FrontstageManager.activeFro ntstageDef?.getZoneDef(ZoneLocation.CenterRight)?.findWidgetDef("BracingAddEdit")?.setWidgetState(WidgetState.Open);
        //-------------
        return Promise.resolve({resolve:true,topPoint: AddLadderTool.topPoint,bottomPoint: AddLadderTool.bottomPoint});
    }
    //-------------------------------------------------------------------//
    // Get the data from the API's
    //-------------------------------------------------------------------//
    private static async fetchData() {
        // let bracingsData = await BracingsClient.getBracingsData(App.accessToken);
        // let towerStructure = await BracingsClient.getTowerStructureJson(App.accessToken);

        let towerStructure = select(store.getState(), "towerStructureData");
        let towerBracings = select(store.getState(), "towerBracingsData");

        if (towerBracings && towerStructure) {
             AddLadderTool.fdata = {
                "tower-structure": towerStructure,
                "bracings": [...towerBracings]
            };
        }
        else {
             AddLadderTool.fdata = "empty";//Project has no data.
        }
    }

    public static runFetchData(){
         AddLadderTool.fetchData();
    }
    //------------------------------------------------------------------------------------
    /* Runs when the tool is rerun after being instanciated once.
     */
    public onRestartTool(): Promise<void> {
        const tool = new  AddLadderTool();
        if (!tool.run( AddLadderTool.uiInfo))
            this.exitTool();
        return Promise.resolve();
    }
    //------------------------------------------------------------------------------------
    /* Callback event from dom. See: OnDataButtonDown()
     * The event is loaded in the onDataButtonDown() for this tool,
     * So event will remain isolated to this context only.
     */
    private onMouseClickUp = (event) => {
        event.preventDefault();
        //event.button == 2 signifies the right click on mouse. So this runs on mouseup for right click
        if (event.button == 2) {
            IModelApp.tools.run(SelectionTool.toolId);//run the default tool and stop this Primitive tool.
        }
    }
    //------------------------------------------------------------------------------------
    /*
    * Not in use
    */
   
    //------------------------------------------------------------------------------------
    /* Overrides Gets called when the Tool is run() from SampleToolsWidget.tsx.
     * does object click  to surface testing via the depth buffer.
     * _ev return data about the intersecting object and point of intersection.
     */
    //------------------------------------------------------------------------------------
    public override async onDataButtonDown(_ev: BeButtonEvent): Promise<EventHandled> {
        //create the element contenxt on left button down.
        // let div = document.getElementsByClassName("imodeljs-vp")[0];
        // div?.addEventListener('mouseup', this.onMouseClickUp);

        //----------------
        const iModel = UiFramework.getIModelConnection()!;
        if(AddLadderTool.topPoint[0]==undefined && AddLadderTool.topPoint[0]==undefined &&AddLadderTool.bottomPoint[0] == undefined ){
            AddLadderTool.topPoint=[(_ev.point)];
            AddLadderTool.bottomPoint=[undefined];
        }
        else if( AddLadderTool.topPoint[0] != undefined && AddLadderTool.bottomPoint[0] == undefined){
        AddLadderTool.bottomPoint[0]=(_ev.point)
        IModelApp.tools.run(SelectionTool.toolId);
        AddLadderTool.callback( AddLadderTool.topPoint,  AddLadderTool.bottomPoint);
        this.exitTool();
    }else if (AddLadderTool.topPoint[0] != undefined && AddLadderTool.bottomPoint[0] != undefined){
        AddLadderTool.topPoint=[(_ev.point)];
            AddLadderTool.bottomPoint=[undefined];
    }

        //-----
        //  AddLadderTool.updatePopoutPannel();//update on button down.
        //-----
        // IModelApp.tools.run(SelectionTool.toolId);
        return EventHandled.No;
    }

    public static async saveLadder(){
        
        let id = store.getState().detectedData.selectedObjectInformation.objectProperties.selectedObjectNST.name.split("@")[0].split("_")[0]
        const selectedLadder:any = LadderDecorator.ladderData.filter(ladder=>ladder.ladderId==id)
        if(store.getState().detectedData.selectedObjectInformation.objectProperties.selectedObjectNST.name.split("@").includes("NEW")){
            // let utms:any = this.handleLocalToUtm(selectedLadder[0].coordinates)
            let res= await postSingleLadderApi({ladderId:selectedLadder[0].ladderId,coordinates:selectedLadder[0].coordinates})
            return res;
         
        }
        else{
            let res = await putLadderApi(selectedLadder[0].ladderId,{ladderId:selectedLadder[0].ladderId,coordinates:selectedLadder[0].coordinates});
            return res;
        }
        return null;
    }

    public static handleLocalToUtm = (data) => {
        let utms: any= []
        for(let i=0;i<data.length;i++){
            const iModel = UiFramework.getIModelConnection()!;
       const stPtCart = iModel!.spatialToCartographicFromEcef(data[i]);
       const stPtUtm = DecoratorHelper.convertWGS2UTM(stPtCart);
       
    let copyUtm = {
        "x": stPtUtm[0],
        "y": stPtUtm[1],
        "z": stPtCart.height
    }
    utms.push(copyUtm)
    }
    return utms;
    }
   public static  handleElevation:any = async(topEl, botEl)=>{
       if(topEl && botEl){
           let coordinatesData = [...botEl,...topEl]
            
            
            let utms = this.handleLocalToUtm(coordinatesData)
            utms = [utms[0],{...utms[0],z:utms[0].z-1},utms[1],{...utms[1],z:utms[1].z-1}]
            
            let ladderId = LadderDecorator.ladderData.length !=0 ? LadderDecorator.ladderData[LadderDecorator.ladderData.length-1].ladderId+1:501
            let dataForLadder = [{"ladderId": ladderId,"coordinates":utms}]
            let toolRetVal = await IModelApp.tools.run(HighlightLadderTool.toolId, [...dataForLadder], true, ["NEW"]);

   
       if(toolRetVal){
        // eventExecutionHandler({mode: "Edit", title: "Edit Ladder Properties", component: targetComponentList.RM_LadderEditor, onClose: () => {UiFramework.dialogs.modeless.close("LadderEditComponent")}})
        const md = IModelApp.viewManager.decorators.filter(e => e.constructor.name.includes("LadderDecorator"))[0] as LadderDecorator;
        store.dispatch(setLadderData3DMaps(new Map(md.nameIdMap)));
        store.dispatch(addToBuilt3DObjectIdsMap(new Map(md.objectIdMap)));
       }
    }
      }

    public static async getNewLadderData() {
        let b = ({
            topElevationFlag: true,
            bottomElevationFlag: undefined,
        })
        let res= await IModelApp.tools.run(AddLadderTool.toolId,b,this.handleElevation);
        return res;
    }

    public static async deleteLadder (){

        let nameOfLadder = store.getState().detectedData.selectedObjectInformation.objectProperties.selectedObjectNST.name.split("@")
        let id = nameOfLadder[0].split("_")[0]
        let typeOfLadder = nameOfLadder[4];
        let filteredData:any = LadderDecorator.ladderData.filter(ladder=>ladder.ladderId!=id)
         LadderDecorator.editing = false;
        if(typeOfLadder == "NEW") await this.loadLadder(filteredData)     
        else{ 
          if(filteredData!=0){
              let res = await postUpdateAllLadderData(filteredData)
              await this.loadLadder(filteredData)
              return res;
          }else IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Info, "Ladder cannot be deleted. You can edit it.", "", OutputMessageType.Toast))
       }
    }

    public static async deletePreview () {
        let nameOfLadder = store.getState().detectedData.selectedObjectInformation.objectProperties.selectedObjectNST.name.split("@")
        let id = nameOfLadder[0].split("_")[0]
        let typeOfLadder = nameOfLadder[1];
        LadderDecorator.editing = false;
        let filteredData:any = LadderDecorator.ladderData.filter(ladder=>ladder.ladderId!=id)
        LadderDecorator.editing = false;
        await this.loadLadder(filteredData)     
      
    }

    public static async loadLadder (data){
        const md = IModelApp.viewManager.decorators.filter(e => e.constructor.name.includes("LadderDecorator"))[0] as LadderDecorator;

        md. clearAndReload(data)
      
    }
    //------------------------------------------------------------------------------------
    public override async onDataButtonUp(_ev: BeButtonEvent): Promise<EventHandled> {
        //SyncUiEventDispatcher.dispatchSyncUiEvent("imodel-display-toggle");//revert the pannel to default
        //FrontstageManager.activeFrontstageDef?.getStagePanelDef(StagePanelLocation.Right)?.findWidgetDef("PropertyListWidget")?.setWidgetState(WidgetState.Open);
        return EventHandled.Yes;
        this.exitTool();
    }
    //------------------------------------------------------------------------------------
    public override async onMouseMotion(_ev: BeButtonEvent): Promise<void> {
        //empty
    }
    //------------------------------------------------------------------------------------
    public override async onMouseStartDrag(_ev: BeButtonEvent): Promise<EventHandled> {
        return EventHandled.No;
    }
    //------------------------------------------------------------------------------------
    public override async onMouseEndDrag(_ev: BeButtonEvent): Promise<EventHandled> {
        // this.exitTool();
        return EventHandled.No;
    }
    //------------------------------------------------------------------------------------
    public override async onCleanup() {
    }
    //------------------------------------------------------------------------------------
    public override onPostInstall(): Promise<void> {
        super.onPostInstall();
        // Enable AccuSnap so that Decorator can be created by snapping on existing model
        IModelApp.accuSnap.enableSnap(true);//this will enable the intersect pointer that only returns points on existing Reality model
        return Promise.resolve();
    }
    //------------------------------------------------------------------------------------
    public override isCompatibleViewport(_vp: Viewport | undefined, _isSelectedViewChange: boolean): boolean {
        return true;
    }
    //------------------------------------------------------------------------------------
}


