import { DecoratorHelper } from "./DecoratorHelper";
import { StagePanelLocation, SyncUiEventDispatcher, UiFramework, WidgetState } from "@itwin/appui-react";
import { Id64String } from "@itwin/core-bentley";
import { ColorDef, LinePixels } from "@itwin/core-common";
import { Decorator, RenderGraphic, HitDetail, BeButtonEvent, EventHandled, IModelApp, SelectionTool, DecorateContext, GraphicType, GraphicBranch, GraphicBuilder } from "@itwin/core-frontend";
import { GeometryQuery, Point3d, Cone, PolyfaceBuilder, LineString3d, Loop, Arc3d, Path, IndexedPolyface, IndexedPolyfaceVisitor, LineSegment3d, CurveChainWithDistanceIndex, Transform, Vector3d } from "@itwin/core-geometry";
import { RootState } from "../../../store/States";
import { towerStructureData } from "../../../store/detectedData/apiDataTypes";
import { store } from "../../../store/rootReducer";
import { ConfigManager } from "../../../config/ConfigManager";
// -------------------------------------------------------
// Custom geometry container to help on construction of the bracings (pipe)
// -------------------------------------------------------
export interface CustomGeometry {
    geometry: GeometryQuery;
    fill: boolean;
    fillColor: ColorDef;
    lineThickness: number;
    edges: boolean;
    linePixels: LinePixels;
    transientId: Id64String;
    name: string;
    uid: string;
    startPos: Point3d;
    endPos: Point3d;
    modelData: any;
    thickness: number;
    length: number;
}

function select(state: RootState, dataKey: string) {
    return state.detectedData[dataKey];
}
let towerStructure = select(store.getState(), "towerStructureData");

export class BracingDecorator implements Decorator {
    // ---------------------------------------------
    public decoratorName = "BracingDecorator";
    public pipes: CustomGeometry[];
    public geometryGraphics: RenderGraphic[];
    public nameIdMap: Map<string, Id64String> = new Map<string, Id64String>();
    public objectIdMap: Map<Id64String, string> = new Map<Id64String, string>();
    public static sourceId: any;
    public selectedBracings: any;
    public pipesMapping: any;
    public static selectedBracing: any;
    //-----------------------------------------------
    private static elevationToWidthMapping: any[] = [];
    private static topandBottomPoints: any[] = [];
    private static isShiftPressed: boolean = false;
/*    private static multiselectBracingSet: any[] =[];*/
    // ---------------------------------------------
    // ---------------------------------------------
    // this is very important for high density decorators , improves performance
    // apparently the parent uses this public variable to know how to
    // draw the primitives wrt this decorator child.
    // In the default secanrio the decorator validates and rebinds primitive information , which is inefficent,
    // Use this to optimise ,where info is not rebound but buffer handle/ref is reused.
    // https://www.itwinjs.org/learning/frontend/viewdecorations/#cached-decorations. for more info
    public readonly useCachedDecorations = true;
    // ---------------------------------------------
    // CONSTRUCTOR
    // ---------------------------------------------
    constructor() {
        // array to save all the pipes to show
        this.pipes = [];
        this.geometryGraphics = [];
        this.pipesMapping = {};

        //create the element contenxt on left button down.
        // let div = document.getElementsByClassName("imodeljs-vp")[0];
        // div?.addEventListener('mouseup', this.onMouseClickUp);
    }
    // ---------------------------------------------
    // Highlights all relvant pipes on all faces of the 
    // Relevant pipe
    // ---------------------------------------------
    private async highlightAllFaces() {
        // get the iModel
        const iModel = UiFramework.getIModelConnection();
        iModel!.selectionSet.emptyAll();
        // get the selected data from the selected pipe
        if (this.selectedBracings != undefined && this.selectedBracings.modelData != undefined) {
            const selectedData = this.selectedBracings.modelData;
            if (selectedData) {
                // lets highlight/select all the child pipes of the bracing
                if (selectedData.parentBracing in this.pipesMapping && selectedData.parentFace) {
                    for (let face in this.pipesMapping[selectedData.parentBracing]) {
                        for (let pipeID in this.pipesMapping[selectedData.parentBracing][face]) {
                            iModel!.selectionSet.add(this.pipesMapping[selectedData.parentBracing][face][pipeID]); //All added transitent ids will be highlighted with this method.
                        }
                    }
                }
            }
        }
    }
    // ---------------------------------------------
    /**
     * Highlights One Face of the Bracing Selected
     */
    // ---------------------------------------------
    private async highlightOneFace() {
        // get the iModel
        const iModel = UiFramework.getIModelConnection();
        iModel!.selectionSet.emptyAll();//empty if currently anything is highlighted.
        // get the selection data from the selected pipe
        if (this.selectedBracings != undefined && this.selectedBracings.modelData != undefined) {
            const selectedData = this.selectedBracings.modelData;

            if (selectedData) {
                // lets highlight/select only the child pipes of the face
                if (selectedData.parentBracing in this.pipesMapping && selectedData.parentFace) {
                    this.pipesMapping[selectedData.parentBracing][selectedData.parentFace].forEach((tempPipeID) => {
                        iModel!.selectionSet.add(tempPipeID);//All added transitent ids will be highlighted with this method.
                    });
                }
            }
        }
    }

    // ---------------------------------------------
    /**
     * @param bracingToHighgligh : the bracing info that is to be highlighted
     */
    public async highlightBracingFromStore(bracingToHighgligh: any) {
        SyncUiEventDispatcher.dispatchSyncUiEvent("bracings-selected");
        UiFramework.frontstages.activeFrontstageDef?.getStagePanelDef(StagePanelLocation.Right)?.findWidgetDef("PropertyListWidget")?.setWidgetState(WidgetState.Open);
        IModelApp.tools.run(SelectionTool.toolId);
        // SampleToolWidget.selectedList = ListEnum.Bracings;
        if (bracingToHighgligh && bracingToHighgligh != undefined && bracingToHighgligh.modelData != undefined) {
            // get the iModel
            const iModel = UiFramework.getIModelConnection();
            iModel!.selectionSet.emptyAll();
            // get the selected data from the selected pipe
            const selectedData = bracingToHighgligh.modelData;
            if (selectedData && selectedData.parentBracing in this.pipesMapping && selectedData.parentFace) {
                // lets highlight/select all the child pipes of the bracing
                for (let face in this.pipesMapping[selectedData.parentBracing]) {
                    for (let pipeID in this.pipesMapping[selectedData.parentBracing][face]) {
                        iModel!.selectionSet.add(this.pipesMapping[selectedData.parentBracing][face][pipeID]); //All added transitent ids will be highlighted with this method.
                    }
                }
            }
        }
    }
    // ---------------------------------------------
    // Return tooltip message for the decoration
    // ---------------------------------------------
    public async getDecorationToolTip(_hit: HitDetail): Promise<HTMLElement | string> {
        const sourceId = _hit.sourceId;
        const name = Array.from(this.nameIdMap.keys()).find((key) => this.nameIdMap.get(key) === sourceId);
        const parentBracing = "Bracing " + (this.selectedBracings.modelData.parentBracing).toString();
        if (!parentBracing) {
            return "Unknown Bracing";
        }
        return parentBracing;
    }

    //------------------------------------------------------------------------------------
    /* Callback event from dom. See: OnDataButtonDown()
     * The event is loaded in the onDataButtonDown() for this tool,
     * So event will remain isolated to this context only.
     */
    private onMouseClickUp = (event) => {
        event.preventDefault();
        //event.button == 2 signifies the right click on mouse. So this runs on mouseup for right click
        if (event.button == 2) {
            IModelApp.tools.run(SelectionTool.toolId);//run the default tool and stop this Primitive tool.
        }

        if (event.shiftKey) {
            BracingDecorator.isShiftPressed = true;
        } else {
            BracingDecorator.isShiftPressed = false;
        }
    }
    // ---------------------------------------------
    // funtion to organize the clicks (single and double)
    // ---------------------------------------------
    public async onDecorationButtonEvent(_hit: HitDetail, _ev: BeButtonEvent): Promise<EventHandled> {
        let sourceId = _hit.sourceId;
        //const pipeData = this.pipes.filter(e=>e.transientId==sourceId);
        if (_ev.isDoubleClick) {
            //create the double click event
            SyncUiEventDispatcher.dispatchSyncUiEvent("bracings-selected");
            this.highlightAllFaces();
            UiFramework.frontstages.activeFrontstageDef?.getStagePanelDef(StagePanelLocation.Right)?.findWidgetDef("PropertyListWidget")?.setWidgetState(WidgetState.Open);
            IModelApp.tools.run(SelectionTool.toolId);
            // SampleToolWidget.selectedList = ListEnum.Bracings;
        } else {
            //create the single click event
            SyncUiEventDispatcher.dispatchSyncUiEvent("bracings-face-selected");
            this.highlightOneFace();
            UiFramework.frontstages.activeFrontstageDef?.getStagePanelDef(StagePanelLocation.Right)?.findWidgetDef("PropertyListWidget")?.setWidgetState(WidgetState.Open);
            IModelApp.tools.run(SelectionTool.toolId);
            // SampleToolWidget.selectedList = ListEnum.Bracings;
        }

        return EventHandled.No;
    }
    // ---------------------------------------------
    // function that will assign the data to the selected object
    // and handle the clicks (single and double)
    // ---------------------------------------------
    public testDecorationHit(_id: string): boolean {

        // Setting the selectedBracings property
        let isbracingSlected: boolean = false;
        for (const pipe of this.pipes) {
            if (pipe.transientId == _id) {
                // static member used in BracingsPropertylist.tsx
                this.selectedBracings = pipe;
                BracingDecorator.selectedBracing = this.selectedBracings;
                //if (BracingDecorator.isShiftPressed) {
                //    BracingDecorator.multiselectBracingSet.push(this.selectedBracings);
                //}
                isbracingSlected = true;
                break;
            }
        }
        if (isbracingSlected && _id.length !== 0) {
            return true;
        }
        return false;
        //return Array.from(this.nameIdMap.values()).includes(_id);
    }
    // ---------------------------------------------
    // CLEAN THE GEOMETRY
    // empty the pipe and geometry graphics array
    // create a new instance of the nameIdMap
    // ---------------------------------------------
    private clearGeometry() {
        this.pipes = [];
        this.geometryGraphics = [];
        this.nameIdMap = new Map<string, Id64String>();
        /* 
        This will invalidate all view decorations in all views in the current iModel, 
        forcing them to be redrawn the next time the view is displayed.
        */
        IModelApp.viewManager.invalidateDecorationsAllViews();
    }
    // ---------------------------------------------
    // Run, outside, when finishing and exiting this tool.
    // --------------------------------------------- 
    public terminate() {
        this.clearGeometry();
        /* 
        This will invalidate all view decorations in all views in the current iModel, 
        forcing them to be redrawn the next time the view is displayed.
        */
        IModelApp.viewManager.invalidateDecorationsAllViews();
        /* 
        When you create a view decoration, it is often more efficient to cache the 
        graphical representation of the decoration rather than recreating it from 
        scratch every time the view is rendered. However, if you change the 
        properties of the decoration, you need to invalidate the cached version 
        to force it to be recreated with the new properties.
        */
        IModelApp.viewManager.selectedView?.invalidateCachedDecorations(this);
    }
    // ---------------------------------------------
    // Take an input for a single pipe geometry to be created in scene.
    // input: 'objInfo' => (object) realityData object created on 'createPipes()'
    // input: 'bayParent' => (string) parent bracing id
    // input: 'parentFace' => (string) parent face id
    // ---------------------------------------------
    public addPipeWithBracingInfo(objInfo: any, bayParent: any, parentFace: any) {
        // create cyliner using Cone geometry generated via api.
        const uniqName = `${objInfo.name}`;
        const pipe = Cone.createAxisPoints(objInfo.pointA, objInfo.pointB, objInfo.thickness, objInfo.thickness, true);
        // create a cone using a Cone object in Polyface Builder.
        const poly = PolyfaceBuilder.create();
        if (pipe !== undefined) {
            poly.addCone(pipe!);
            const polyface = poly.claimPolyface(false);
            const transientId: string = IModelApp.viewManager.selectedView!.iModel.transientIds.next
            // create the geometry data
            const geometryData: CustomGeometry = this.addGeometry(polyface, objInfo, transientId, uniqName);
            this.nameIdMap.set(`bracingGeom#${geometryData.name}`, geometryData.transientId);
            this.objectIdMap.set(geometryData.transientId, `bracingGeom#${geometryData.name}`);

            if (!(bayParent in this.pipesMapping)) {
                this.pipesMapping[bayParent] = {};
            }
            if (!(parentFace in this.pipesMapping[bayParent])) {
                this.pipesMapping[bayParent][parentFace] = [];
            }

            this.pipes.push(geometryData);
            this.pipesMapping[bayParent][parentFace].push(geometryData.transientId);
        }
    }
    // ---------------------------------------------
    //Aditya
    public addPipe(objInfo: any) {
        // create cyliner using Cone geometry generated via api.
        const uniqName = `${objInfo.name}`;
        const pipe = Cone.createAxisPoints(objInfo.pointA, objInfo.pointB, objInfo.thickness, objInfo.thickness, true);
        // create a cone using a Cone object in Polyface Builder.
        const poly = PolyfaceBuilder.create();
        if (pipe !== undefined) {
            poly.addCone(pipe!);
            const polyface = poly.claimPolyface(false);
            const transientId: string = IModelApp.viewManager.selectedView!.iModel.transientIds.next
            // create the geometry data
            const geometryData: CustomGeometry = this.addGeometry(polyface, objInfo, transientId, uniqName);
            this.nameIdMap.set(`${geometryData.name}`, geometryData.transientId);
            this.objectIdMap.set(geometryData.transientId, `bracingGeom#${geometryData.name}`);

            this.pipes.push(geometryData);
        }
    }
    // ---------------------------------------------
    // CREATE THE GEOMETRY OBJECT
    // input: 'objInfo' => (object) realityData object created on 'createPipes()'
    // ---------------------------------------------
    public addGeometry(geometry: GeometryQuery, objInfo: any, transientId: string, uniqName: string) {
        const redColor = objInfo.color;
        // transparency => 0% = 255 || 100% = 0
        const semiTransparentRed = redColor.withTransparency(objInfo.transparency);

        const geometryObject: CustomGeometry = ({
            geometry: geometry,
            fill: true,
            fillColor: semiTransparentRed,
            lineThickness: 0.5,
            thickness: objInfo.thickness,
            length: objInfo.pointA.distance(objInfo.pointB),
            edges: true,
            linePixels: LinePixels.Solid,
            transientId: transientId,
            name: objInfo.name,
            uid: uniqName,
            modelData: objInfo,
            startPos: objInfo.pointA,
            endPos: objInfo.pointB,
        });
        return geometryObject;
    }
    // ---------------------------------------------
    // convert the UTM coordinates to the 'local' coordinates sytem
    // ---------------------------------------------
    public convertPoints(point1: any, point2: any, epsg_code: any) {
        try {
            const towerStructure = store.getState().detectedData.towerStructureData as towerStructureData;
            const iModel = UiFramework.getIModelConnection()!;
            const points = [point1, point2] // [ [x,y,z] , [x,y,z] , [x,y,z] ]
            const cA = new Point3d(points[0][0], points[0][1], points[0][2]);
            const cB = new Point3d(points[1][0], points[1][1], points[1][2]);
            const o = iModel.spatialToCartographicFromEcef(iModel.projectExtents.high!);
            const ecefA = DecoratorHelper.utm2localCoords(o, cA.x, cA.y, cA.z, iModel, epsg_code);
            const ecefB = DecoratorHelper.utm2localCoords(o, cB.x, cB.y, cB.z, iModel, epsg_code);
            const dot = ecefA.dotVectorsToTargets(new Point3d(ecefB.x, ecefB.y, 0), ecefB);
            const lengthA = ecefA.distance(ecefB);
            const lengthB = ecefA.distance(new Point3d(ecefB.x, ecefB.y, 0));
            const theta = Math.acos(dot / (lengthA * lengthB));
            const deg = theta * 180 / Math.PI; // 1Rad × 180/π
            const utms = points.map((e: any) => e[2]);
            const altitude = (utms.reduce((sum: number, e: number) => sum + e, 0) / utms.length);
            const elevation = altitude - towerStructure.base_altitude;
            return { ecefA: ecefA, ecefB: ecefB, altitude: altitude, elevation: elevation, deg: deg };
        } catch (error) {
            return null;
        }
    }
    // ---------------------------------------------
    /* Not in use , spec updated
     */ 
    // ---------------------------------------------
    private populateNewJason(orignalData: any, altWidthSet : any[]) : any {
        if (orignalData.length > 0 && altWidthSet.length > 0) {
            let newJason : any[] = [];
            for (let i = 0; i < orignalData.length; i++) {
                let newj = {
                    bottomElevation: orignalData[i].start,
                    bottomWidth: 0,
                    horizMid: orignalData[i].horizontalMid,
                    horizTop: orignalData[i].horizontalTop,
                    topElevation: orignalData[i].end,
                    topWidth: 0,
                    type: orignalData[i].type
                }
                for (let j = 0; j < altWidthSet.length; j++) {

                    if (newj.bottomElevation == altWidthSet[j].elevation) {
 
                        newj.bottomElevation = orignalData[i].start;
                        newj.bottomWidth     = altWidthSet[j].width;                       
                    }
                    if (newj.topElevation == altWidthSet[j].elevation) {
                        newj.topElevation    = orignalData[i].end;
                        newj.topWidth        = altWidthSet[j].width;
                    }
                }
                newJason.push(newj);
            }
            return newJason;
        }
    }
    // ---------------------------------------------
    // function to handle the creation of the pipes
    // input: 'data' => (object) information about the tower structure and the bracings
    // data = {"tower-structure": towerStructureData, "bracings": bracingsData}
    // ---------------------------------------------
    public async createPipes(data: any) {
        if (Object.keys(data).length === 0) {
            return false;
        }
        else {
            if (data.bracings.length === 0) return false;

            const THICKNESS: number = 0.05;
            const RADIUS: number = 0.02;
            const BASE_ALTITUDE = data["tower-structure"]["base_altitude"];

            var bracCoords = await this.getBracingCoordinatesFromPattern(data["bracings"], data["tower-structure"]);

            for (var i = 0; i < bracCoords.length; i++) {
                const BRACING_TOP_HEIGHT: number = bracCoords[i].bracingTopH;
                const BRACING_BOTTOM_HEIGHT: number = bracCoords[i].bracingBotH;
                const HORIZONTAL_MIDDLE_BAY: boolean = bracCoords[i].HORIZONTAL_MIDDLE_BAY;
                const HORIZONTAL_TOP_BAY: boolean = bracCoords[i].HORIZONTAL_TOP_BAY;
                const PATTERN: string = ((bracCoords[i].PATTERN).toLocaleUpperCase()).replaceAll("_", " ");

                var coordinates = bracCoords[i].coords;// Pipe coordinates.
                var parentBracing = bracCoords[i].parent;
                var parentFace = bracCoords[i].face;

                for (var k = 0; k < coordinates.length; k++) {
                    //id of the single pipe
                    const id = "pipe_bracing" + parentBracing.toString() + "_face" + parentFace.toString() + "_n" + k.toString();
                    var pointA = coordinates[k][0];
                    var pointB = coordinates[k][1];
                    let convertedPoints: any = this.convertPoints(pointA, pointB, data["tower-structure"]["epsg_code"]);

                    if (convertedPoints != undefined && convertedPoints.ecefA != undefined && convertedPoints.ecefA != undefined) {
                        const realityData: any = {//Set this custom data container with the relevant data , this will be shared with the side pannel on Click events.
                            name: id,
                            parentBracing: parentBracing,
                            parentBracingHeights: {
                                bracingTopH: BRACING_TOP_HEIGHT,
                                bracingBotH: BRACING_BOTTOM_HEIGHT
                            },
                            parentFace: parentFace,
                            baseAltitude: BASE_ALTITUDE,
                            horizMiddleBay: HORIZONTAL_MIDDLE_BAY,
                            horizTopBay: HORIZONTAL_TOP_BAY,
                            pattern: PATTERN,
                            length: (convertedPoints.ecefA.distance(convertedPoints.ecefB).toFixed(2)),
                            radius: RADIUS,
                            startUTMPoint: pointA,
                            endUTMpoint: pointB,
                            tilt: convertedPoints.deg.toFixed(2),
                            altitude: convertedPoints.altitude,
                            elevation: convertedPoints.elevation,
                            pointA: convertedPoints.ecefA,
                            pointB: convertedPoints.ecefB,
                            thickness: THICKNESS,
                            color: ColorDef.from(0, 0, 255),
                            transparency: 130,// transparency => 0% = 255 || 100% = 0
                        };
                        // create the pipe for each entry
                        this.addPipeWithBracingInfo(realityData, parentBracing, parentFace);
                    }
                }
            }
            return true;
        }
    }
    //---------------------------------------------
    /**
     * 
     * @param newData new Data from TNX+OTXML workflow for some associations.
     * @param olddata Base data that is created by the AI detection(old data with limited shape options implemented) 
     * @param bracingInfo The preprocessed info used to create pipes.
     * 
     * This takes the genrated OTXML based TNX generated pipe data
     * and uses it to create the pipes.
     */
    //---------------------------------------------
    public createPipesWithOTDData(newData: any, olddata: any, bracingInfo : any) {
        if (newData != undefined && newData.Members != undefined) {
            for (let m = 0; m < newData.Members.length; m++) {
                let p1 : any
                let p2 : any

                for (let n = 0; n < newData.Nodes.length; n++) {
                    if (newData.Nodes[n].id == newData.Members[m].StartNodeID) {
                        p1 = newData.Nodes[n]
                    }
                    if (newData.Nodes[n].id == newData.Members[m].EndNodeID) {
                        p2 = newData.Nodes[n]
                    }
                }

                let sum1 = p1.x + p1.y + p1.z;
                let sum2 = p2.x + p2.y + p2.z;
                if (sum1 != 0 && sum2 != 0) {
                    let out = this.convertPoints([p1.x, p1.y, p1.z], [p2.x, p2.y, p2.z], olddata["tower-structure"]["epsg_code"])

                    let currentModelInfo : any[] = [];
                    for (let n = 0; n < bracingInfo.length; n++) {
                        if (bracingInfo[n].parent == newData.Members[m].BracingId) {
                            currentModelInfo.push(bracingInfo[n]);
                        } else if(bracingInfo[n].BracingId == newData.Members[m].BracingId)currentModelInfo.push(bracingInfo[n]);
                    }
                    const TYPE: string = currentModelInfo[0].PlanBracings != undefined ? "Plan Bracing" : "Face Bracing";

                    let BRACING_TOP_HEIGHT, BRACING_BOTTOM_HEIGHT, BASE_ALTITUDE, HORIZONTAL_MIDDLE_BAY, HORIZONTAL_TOP_BAY, PATTERN;
                    BASE_ALTITUDE = olddata["tower-structure"]["base_altitude"];
                    if(TYPE == "Face Bracing"){
                        BRACING_TOP_HEIGHT = currentModelInfo[0].bracingTopH;
                        BRACING_BOTTOM_HEIGHT = currentModelInfo[0].bracingBotH;

                        HORIZONTAL_MIDDLE_BAY = currentModelInfo[0].hmbay;
                        HORIZONTAL_TOP_BAY = currentModelInfo[0].htbay;
                    
                        PATTERN = (currentModelInfo[0].pattern).replaceAll("_", " ");
                    } else {
                        const theParentPipe = olddata.bracings.filter(e=>e.bracingId==currentModelInfo[0].BracingId)[0];
                        HORIZONTAL_MIDDLE_BAY = theParentPipe.planBracingTop == null; 
                        HORIZONTAL_TOP_BAY = !HORIZONTAL_MIDDLE_BAY; 
                    }
                    let nPointA = out?.ecefA;
                    let nPointB = out?.ecefB;

                    const id = TYPE == "Face Bracing" ? 
                    "pipe_bracing_" + newData.Members[m].BracingId.toString() + "_face-" + newData.Members[m].Face + "_n" + m.toString() :
                    "plan_bracing_" + newData.Members[m].BracingId.toString() + "_n" + m.toString() ;

                    if (out != undefined && nPointA != undefined && nPointB != undefined) {
                        let custom: any = {}//Set this custom data container with the relevant data , this will be shared with the side pannel on Click events.
                        custom = TYPE == "Face Bracing" ?
                        {
                        name: id,
                            parentBracingHeights: {
                                bracingTopH: BRACING_TOP_HEIGHT,
                                bracingBotH: BRACING_BOTTOM_HEIGHT
                            },
                            parentFace: newData.Members[m].Face,
                            parentBracing: newData.Members[m].BracingId,
                            baseAltitude: BASE_ALTITUDE,
                            horizMiddleBay: HORIZONTAL_MIDDLE_BAY,
                            horizTopBay: HORIZONTAL_TOP_BAY,
                            pattern: PATTERN,
                            length: nPointA.distance(nPointB).toFixed(2),
                            radius: 0.02,
                            startUTMPoint: p1,
                            endUTMpoint: p2,
                            pointA: nPointA,
                            pointB: nPointB,
                            thickness: 0.03,
                            type: TYPE,
                            color: ColorDef.from(10, 50, 255),
                            transparency: 130,// transparency => 0% = 255 || 100% = 0
                        } :
                        {
                            name: id,
                            bracingHeights: currentModelInfo[0].Node1.Z,
                            type: TYPE,
                            parentBracing: newData.Members[m].BracingId,
                            baseAltitude: BASE_ALTITUDE,
                            horizMiddleBay: HORIZONTAL_MIDDLE_BAY,
                            horizTopBay: HORIZONTAL_TOP_BAY,
                            pattern: currentModelInfo[0].BracingType,
                            length: nPointA.distance(nPointB).toFixed(2),
                            radius: 0.02,
                            startUTMPoint: p1,
                            endUTMpoint: p2,
                            pointA: nPointA,
                            pointB: nPointB,
                            thickness: 0.03,
                            color: ColorDef.from(10, 50, 255),
                            transparency: 130,// transparency => 0% = 255 || 100% = 0
                        };
                        this.addPipeWithBracingInfo(custom, newData.Members[m].BracingId, newData.Members[m].Face);
                        //this.addPipe(custom);
                    }
                }
            }

            //TEST VALUES FOR TILT ROTATION//REMOVE LATER !!Critical!!

            //let tilt = new Vector3d(SampleToolWidget.towerStructure.tower_tilt_x, SampleToolWidget.towerStructure.tower_tilt_y, 1)
            //let originUpVec = new Vector3d(0, 0, 1);
            //let cross = originUpVec.crossProduct(tilt);

            //let out0 = this.convertPoints([421453.0726026585, 3600702.8753761887, 14.226643081651128], [421453.0726026585, 3600702.8753761887, 14], data["tower-structure"]["epsg_code"])
            //const custom0: any = {//Set this custom data container with the relevant data , this will be shared with the side pannel on Click events.
            //    length: "",//(nPointA.distance(nPointB).toFixed(2)),
            //    radius: 0.02,
            //    startPoint: out0?.ecefA,
            //    endpoint: out0?.ecefB,
            //    pointA: out0?.ecefA,
            //    pointB: out0?.ecefB,
            //    thickness: 0.2,
            //    color: ColorDef.red,
            //    transparency: 130,// transparency => 0% = 255 || 100% = 0
            //};
            //this.addPipe(custom0);

            //let out1 = this.convertPoints([421453.0726026585, 3600702.8753761887, 14.226643081651128], [421453.0726026585, 3600702.8753761887, 14], data["tower-structure"]["epsg_code"])
            //const custom1: any = {//Set this custom data container with the relevant data , this will be shared with the side pannel on Click events.
            //    length: "",//(nPointA.distance(nPointB).toFixed(2)),
            //    radius: 0.02,
            //    startPoint: out1?.ecefA,
            //    endpoint: out1?.ecefB,
            //    pointA: out1?.ecefA,
            //    pointB: out1?.ecefB,
            //    thickness: 0.2,
            //    color: ColorDef.red,
            //    transparency: 130,// transparency => 0% = 255 || 100% = 0
            //};
            //this.addPipe(custom1);

            //let out2 = this.convertPoints([421453.0646629219, 3600702.862886503, 6.532013191850237], [421453.0646629219, 3600702.862886503, 6], data["tower-structure"]["epsg_code"])
            //const custom2: any = {//Set this custom data container with the relevant data , this will be shared with the side pannel on Click events.
            //    length: "",//(nPointA.distance(nPointB).toFixed(2)),
            //    radius: 0.02,
            //    startPoint: out2?.ecefA,
            //    endpoint: out2?.ecefB,
            //    pointA: out2?.ecefA,
            //    pointB: out2?.ecefB,
            //    thickness: 0.2,
            //    color: ColorDef.red,
            //    transparency: 130,// transparency => 0% = 255 || 100% = 0
            //};
            //this.addPipe(custom2);
        }
    }
    // ---------------------------------------------
    // Calculate straight line eq  -->  y = mx + b
    // ---------------------------------------------
    private straightLineEq(line: any) {
        let x0: number, y0: number, x1: number, y1: number, m: number, b: number;
        x0 = line[0][0];
        y0 = line[0][1];
        x1 = line[1][0];
        y1 = line[1][1];
        // avoid div by 0
        if (x1 == x0) {
            m = 9999;
        }
        else {
            m = (y1 - y0) / (x1 - x0);
        }
        b = y0 - x0 * m;
        return { m, b };
    }
    // ---------------------------------------------
    // convert an angle from degrees to radians
    // ---------------------------------------------
    private radians(degrees: number) {
        return degrees * (Math.PI / 180);
    }

    //---------------------------------------------
    /**
     * @param bracCoords : bracing info data is used to restroed in a  Backend model specfic format. 
     * tempModelBracingData is returend to be used with bracingsClient.postBracingDetectorGeneratorData();
     */
    //---------------------------------------------
    public async modelFromBracingFaceData(bracCoords: any) {

        let model: any[] = [];//the data that will be used to create the bracing shapes for display.
        for (let b = 0; b < bracCoords.length; b++) {
            let braceType: string = "";
            if (bracCoords[b].pattern == "x") {
                braceType = "X Brace"
            } else if (bracCoords[b].pattern == "k_down") {
                braceType = "K Brace Down"
            } else if (bracCoords[b].pattern == "k_up") {
                braceType = "K Brace Up"
            } else if (bracCoords[b].pattern == "unkown"){
                continue;
            } else if(bracCoords[b].pattern == "d_up"){
                braceType = "Diagonal UP"
            } else if (bracCoords[b].pattern == "d_down") {
                braceType = "Diagonal Down"
            }
            else {
                braceType = bracCoords[b].pattern;
            }

            let dd = {
                "Node1": {
                    "X": bracCoords[b].node1[0],
                    "Y": bracCoords[b].node1[1],
                    "Z": bracCoords[b].node1[2]
                },
                "Node2": {
                    "X": bracCoords[b].node2[0],
                    "Y": bracCoords[b].node2[1],
                    "Z": bracCoords[b].node2[2]
                },
                "Node3": {
                    "X": bracCoords[b].node3[0],
                    "Y": bracCoords[b].node3[1],
                    "Z": bracCoords[b].node3[2]
                },
                "Node4": {
                    "X": bracCoords[b].node4[0],
                    "Y": bracCoords[b].node4[1],
                    "Z": bracCoords[b].node4[2]
                },
                "Face": bracCoords[b].face,
                "BracingType": braceType,
                "BracingId": bracCoords[b].parent,
                "HorizMid": bracCoords[b].hmbay == "Yes"? true : false,
                "HorizTop": bracCoords[b].htbay == "Yes" ? true : false
            }
            model.push(dd);
        }
        return model;
    }
    // ---------------------------------------------
    // Get the legs coordinates at a specific altitude 
    // input: towerData - tower information from strucureDetection module
    // input: altitude - elevation above sea level
    // input: radius_offset - defaults to 0
    // ---------------------------------------------
    private async getLegsCoordinatesAtAltitude(towerData: any, altitude: number, height_offset: number = 0, radius_offset: number = 0) {
        var leg_coords: any = [];
        var w: number;

        if ((towerData['type'] == 'lattice') || (towerData['type'] == 'guyed')) {
            // identify the vertical segment of analysis 
            var section_idx: any = null;

            if (towerData['tower_vertical_segments'].length > 0) {
                for (var j = 0; j < towerData['tower_vertical_segments'].length; j++) {
                    var section = towerData['tower_vertical_segments'][j];
                    if ((section['altitude_bottom'] <= altitude) && (altitude < section['altitude_top'])) {
                        section_idx = j;
                    }
                }
            }
            // sraight line equation that define the tower width
            if (section_idx != null) {
                var section: any = towerData['tower_vertical_segments'][section_idx]
                var { m, b } = this.straightLineEq([[section['width_bottom'], section['altitude_bottom']], [section['top_width'], section['altitude_top']]]);
            }
            else {
                var { m, b } = this.straightLineEq([[towerData['base_width'], towerData['base_altitude']], [towerData['top_width'], towerData['top_altitude']]]);
            }
            // width at a specific altitude
            w = (altitude - b) / m;
            let em = { elevation: altitude, width: w }
            //New step : 03-05-2023
            //For the OTD API spec.
            BracingDecorator.elevationToWidthMapping.push(em);
            //
            for (var i = 0; i < towerData['nLegs']; i++) {
                var leg_angle = (this.radians(towerData["bearing"]) + (2 * Math.PI / towerData['nLegs']) * i) % (2 * Math.PI);
                // tower radius - distance from center to leg
                var r = ((w / 2) / Math.sin(Math.PI / towerData['nLegs'])) + radius_offset;
                // deviation on x/y axis due model tilt
                var x_deviation = (altitude - towerData['base_altitude']) * Math.tan(this.radians(towerData["tower_tilt_x"]));
                var y_deviation = (altitude - towerData['base_altitude']) * Math.tan(this.radians(towerData["tower_tilt_y"]));
                var x = towerData['base_center'][0] + r * Math.sin(leg_angle) + x_deviation;
                var y = towerData['base_center'][1] + r * Math.cos(leg_angle) + y_deviation;
                var z = altitude + height_offset;
                leg_coords.push([x, y, z]);
            }
        }
        return leg_coords;
    }
    // ---------------------------------------------
    // Get the mean point between two points
    // ---------------------------------------------
    private mean(coord1: any, coord2: any) {
        return [(coord1[0] + coord2[0]) / 2, (coord1[1] + coord2[1]) / 2, (coord1[2] + coord2[2]) / 2]
    }
    // ---------------------------------------------
    // Get tower width at a specific altitude
    // Args:
    // towerData: tower information from strucureDetection module
    // altitude: elevation above sea level
    // ---------------------------------------------
    private getTowerWidth(towerData: any, altitude: any) {
        // identify the vertical segment of analysis
        var section_idx: any = null;
        if (towerData['tower_vertical_segments'].length > 0) {
            for (const [j, section] of towerData['tower_vertical_segments'].entries()) {
                if (section['altitude_bottom'] <= altitude && altitude < section['altitude_top']) {
                    section_idx = j;
                }
            }
        }
        // sraight line equation that define the tower width
        if (section_idx != null) {
            var section = towerData['tower_vertical_segments'][section_idx];
            var { m, b } = this.straightLineEq([[section['width_bottom'], section['altitude_bottom']], [section['top_width'], section['altitude_top']]]);
        }
        else {
            var { m, b } = this.straightLineEq([[towerData['base_width'], towerData['base_altitude']], [towerData['top_width'], towerData['top_altitude']]]);
        }
        // width at a specific altitude
        const w = (altitude - b) / m;
        return w;
    }
    //---------------------------------------------
    // 
    //---------------------------------------------
    private getMidBay(towerData, start, end) {
        const widthStart = this.getTowerWidth(towerData, start);
        const widthEnd = this.getTowerWidth(towerData, end);

        const mid = start + ((widthStart * (end - start)) / (widthStart + widthEnd));
        return mid;
    }
    // ---------------------------------------------
    // Not in use : Values not Validated
    // ---------------------------------------------
    private axisAngleRotate(v: Vector3d, k: Vector3d, theta: number): Vector3d {

        let rad = (theta * (Math.PI / 180)).toFixed(5);
        let cos_theta = Math.cos(parseFloat(rad))
        let sin_theta = Math.sin(parseFloat(rad))

        let vcos = new Vector3d(v.x * cos_theta, v.y * cos_theta, v.z * cos_theta);
        let cross = Vector3d.createCrossProduct(k.x, k.y, k.z, v.x, v.y, v.z);
        let crossSin = new Vector3d(cross.x * sin_theta, cross.y * sin_theta, cross.z * sin_theta);
        let dotkv = k.dotProduct(v);
        let kdotkv = new Vector3d(k.x * dotkv, k.y * dotkv, k.z * dotkv);

        let a = new Vector3d(vcos.x + crossSin.x + kdotkv.x, vcos.y + crossSin.y + kdotkv.y, vcos.z + crossSin.z + kdotkv.z)
        let rotated = new Vector3d(a.x * (1 - cos_theta), a.y * (1 - cos_theta), a.z * (1 - cos_theta));

        //let rotated = (v * cos_theta) + Vector3d.createCrossProduct(k.x, k.y, k.z, v.x, v.y, v.z) * sin_theta) + (k * glm:: dot(k, v)) * (1 - cos_theta);

        return rotated;
    }
    // ---------------------------------------------
    /**
     * 
     * @param points Points[] that are to be rotated 
     * @param center Point around wich rotation will occour
     * @param axis The Vector Oreantation around witch the rotaion will rotate.
     * @param angle Amount of ratation CCW , in radians.
     * 
     * Values are validated. 
     */
    private static rotatePointsAroundAxis(pointsArr, center, axis, angle) : number[] {
        // Translate the points to make the center the origin(0,0,0)
        let points = JSON.parse(JSON.stringify(pointsArr));
        for (let i = 0; i < points.length; i++) {
            points[i][0] -= center[0];
            points[i][1] -= center[1];
            points[i][2] -= center[2];
        }

        // Compute the unit vector of the rotation axis (Normalize the vector)
        let ux, uy, uz;
        if (axis[0] > 1 || axis[1] > 1 || axis[2] > 1) {
            let axisLength = Math.sqrt(axis[0] ** 2 + axis[1] ** 2 + axis[2] ** 2);
            ux = axis[0] / axisLength;
            uy = axis[1] / axisLength;
            uz = axis[2] / axisLength;
        }
        else {
            ux = axis[0];
            uy = axis[1];
            uz = axis[2];
        }

        // Compute the rotation matrix
        let cosA = Math.cos(angle);
        let sinA = Math.sin(angle);
        let oneMinusCosA = 1 - cosA;
        let R = [[cosA + ux ** 2 * oneMinusCosA,       ux * uy * oneMinusCosA - uz * sinA,  ux * uz * oneMinusCosA + uy * sinA],
                 [uy * ux * oneMinusCosA + uz * sinA,  cosA + uy ** 2 * oneMinusCosA,       uy * uz * oneMinusCosA - ux * sinA],
                 [uz * ux * oneMinusCosA - uy * sinA,  uz * uy * oneMinusCosA + ux * sinA,  cosA + uz ** 2 * oneMinusCosA]];

        // Rotate the points around the axis (Matrix multiplication)
        for (let i = 0; i < points.length; i++) {
            let x = points[i][0];
            let y = points[i][1];
            let z = points[i][2];
            points[i][0] = R[0][0] * x + R[0][1] * y + R[0][2] * z;
            points[i][1] = R[1][0] * x + R[1][1] * y + R[1][2] * z;
            points[i][2] = R[2][0] * x + R[2][1] * y + R[2][2] * z;
        }

        // Translate the points back to their original position
        for (let i = 0; i < points.length; i++) {
            points[i][0] += center[0];
            points[i][1] += center[1];
            points[i][2] += center[2];
        }

        return points;
    }
    //------------------------------------------------------
    private getNormal(v1, v2, v3) {
        // Calculate two vectors in the plane of the triangle
        const vec1 = [v2[0] - v1[0], v2[1] - v1[1], v2[2] - v1[2]];
        const vec2 = [v3[0] - v1[0], v3[1] - v1[1], v3[2] - v1[2]];

        // Calculate the cross product of the two vectors
        const cross = [
            vec1[1] * vec2[2] - vec1[2] * vec2[1],
            vec1[2] * vec2[0] - vec1[0] * vec2[2],
            vec1[0] * vec2[1] - vec1[1] * vec2[0]
        ];

        // Normalize the cross product to get the unit normal vector
        const length = Math.sqrt(cross[0] ** 2 + cross[1] ** 2 + cross[2] ** 2);
        return [cross[0] / length, cross[1] / length, cross[2] / length];
    }
    //------------------------------------------------------
    private static calculateCentroid(points) : number[] {
        let xSum = 0;
        let ySum = 0;
        let zSum = 0;

        //Summation all the same axis values.
        for (let i = 0; i < points.length; i++) {
            xSum += points[i][0];
            ySum += points[i][1];
            zSum += points[i][2];
        }

        const xCentroid = xSum / points.length;
        const yCentroid = ySum / points.length;
        const zCentroid = zSum / points.length;

        return [xCentroid, yCentroid, zCentroid];
    }
    //------------------------------------------------------
    //Temp placeholder for working Rotation around axis and centroid
    private static genNewPoints(points, normal: Vector3d , angle) {

        if (points && normal) {
            //let me = this.mean(l1_end, l2_end);

            let centroid = BracingDecorator.calculateCentroid(points);
            let nNorm = normal; //this.getNormal([l1_start[0], l1_start[1], l1_start[2]], [l2_start[0], l2_start[1], l2_start[2]], [me[0], me[1], me[2]]);

            //let points = [[l1_start[0], l1_start[1], l1_start[2]], [l2_start[0], l2_start[1], l2_start[2]], [me[0], me[1], me[2]]];

            //// Define the center point, the axis of rotation, and the angle of rotation
            let center = [centroid[0], centroid[1], centroid[2]]//[xc, yc, zc];
            let axis = [nNorm.x, nNorm.y, nNorm.z];
            //let angle = angle//1.5708;  // rad ,rotate by 90 degrees

            let out = BracingDecorator.rotatePointsAroundAxis(points, center, axis, angle);

            //coords.push([out[0], out[2]]);
            //coords.push([out[1], out[2]]);

            return out;
        }
        return undefined;
    }

    /*
    * Calc the Normal specific determinant base angle between two vectors.
    * The output also indicates which side the vector lies to the primary subject vector.
    * Require a fixed normal 0,0,1  with z as up ,as input. (This function increments angles degrees in a clockwise direction). 
    */
    private static calcAngleBetweenVectorsOnTheSamePlane(v1: Vector3d, v2: Vector3d, normal: Vector3d): number {
        let dot = v1.x * v2.x + v1.y * v2.y + v1.z * v2.z;//scalar product.
        let det = v1.x * v2.y * normal.z + v2.x * normal.y * v1.z + normal.x * v1.y * v2.z - v1.z * v2.y * normal.x - v2.z * normal.y * v1.x - normal.z * v1.y * v2.x;//determinant
        let angle = Math.atan2(det, dot);
        return angle;
    }


	public getLegsCoordinatesAtAltitudeNoDeviation = (towerData,altitude,height_offset=0,radius_offset=0) => {
		var leg_coords: any = [];
		var w;
		if ((towerData['type'] == 'lattice') || (towerData['type'] == 'guyed')){ 
			// identify the vertical segment of analysis 
			var section_idx: any = null;
			if (towerData['tower_vertical_segments'].length > 0){
				for (var j = 0; j < towerData['tower_vertical_segments'].length;j++){ 
					var section = towerData['tower_vertical_segments'][j];
					if ((section['altitude_bottom'] <= altitude) && (altitude < section['altitude_top'])){
						section_idx = j;
					}
				}
			}
			// sraight line equation that define the tower width
			if (section_idx != null){
				var section = towerData['tower_vertical_segments'][section_idx]
				var {m,b} = this.straightLineEq([[section['width_bottom'],section['altitude_bottom']],[section['top_width'],section['altitude_top']]]);
			}
			else{
				var {m,b} = this.straightLineEq([[towerData['base_width'],towerData['base_altitude']],[towerData['top_width'],towerData['top_altitude']]]);
			}
			// width at a specific altitude
			w = (altitude-b)/m;
			
			for (var i = 0; i < towerData['nLegs'] ;i++){            
				var leg_angle = (this.radians(towerData["bearing"]) + (2*Math.PI/towerData['nLegs'])*i) %(2*Math.PI);
				// tower radius - distance from center to leg
				var r = ((w/2)/Math.sin(Math.PI/towerData['nLegs']))+radius_offset;                                   
				// deviation on x/y axis due model tilt
				//var x_deviation = (altitude-towerData['base_altitude'])*Math.tan(radians(towerData["tower_tilt_x"]));
				//var y_deviation = (altitude-towerData['base_altitude'])*Math.tan(radians(towerData["tower_tilt_y"]));
				var x = towerData['base_center'][0] + r*Math.sin(leg_angle);
				var y = towerData['base_center'][1] + r*Math.cos(leg_angle);
				var z =	altitude+height_offset;
				leg_coords.push([x,y,z]);
			}
		}
		return leg_coords;
	}

    //--------------------------------------------------------------------------------------------------------
    /**
     * 
     * @param bay_pattern
     * @param towerData
     * @param height_offset
     * Create the precursor to the Model data that will help generate the memeber data from the 
     * new OTD  backend generator.
     * This data will be used in , this.modelFromBracingFaceData(); called externally.
     */
    public async getBracingFaceDataForOTD(bay_pattern: any, towerData: any, height_offset: number = 0) {
        BracingDecorator.topandBottomPoints = [];

        if ((towerData['type'] != 'lattice') && (towerData['type'] != 'guyed')) {
            return [];
        }
        for (var k = 0; k < bay_pattern.length; k++) {
            // one bracing (from the data is one dict on the list of bracings)
            var bay = bay_pattern[k]
            var startLegCoord = await this.getLegsCoordinatesAtAltitude(towerData, bay['start'], height_offset = height_offset);
            var endLegCoord = await this.getLegsCoordinatesAtAltitude(towerData, bay['end'], height_offset = height_offset);

            const PATTERN = bay["type"];
            const HORIZONTAL_MIDDLE_BAY = ((bay["horizontalMid"] == false) ? "No" : "Yes");
            const HORIZONTAL_TOP_BAY = ((bay["horizontalTop"] == false) ? "No" : "Yes");

            //var mid = this.getMidBay(towerData, bay['start'], bay['end']);
            //var centerLegCoord = await this.getLegsCoordinatesAtAltitude(towerData, mid, height_offset = height_offset);

            // loop for each face
            for (var i = 0; i < towerData['nLegs']; i++) {
                // coords to send
                var coords: any = [];

                // 1st leg bottom and top coordinate
                var l1_idx = i - 1;
                if (l1_idx < 0) {
                    l1_idx = towerData['nLegs'] - 1
                }
                var l1_start: number = startLegCoord[l1_idx];
                var l1_end: number = endLegCoord[l1_idx];
                // 2nd leg bottom and top coordinate
                var l2_start: number = startLegCoord[i];
                var l2_end: number = endLegCoord[i];
                // coords.push([l1_start,l2_start])

                //Aditya new----------
                BracingDecorator.topandBottomPoints.push({
                    parent: bay['bracingId'],
                    face: "Face " + i.toString(),
                    pattern: PATTERN,
                    hmbay: HORIZONTAL_MIDDLE_BAY,
                    htbay: HORIZONTAL_TOP_BAY,
                    bracingTopH: bay['end'],
                    bracingBotH: bay['start'],
                    //node1: l2_end,
                    //node2: l1_end,
                    //node3: l1_start,
                    //node4: l2_start                    
                    node1: l2_start,
                    node2: l1_start,
                    node3: l1_end,
                    node4: l2_end//l2_start
                });
                //Work on propery storing each top and bottom horizontal points to rotate
               // BracingDecorator.topandBottomPoints.push({ pattern: PATTERN, hmbay: HORIZONTAL_MIDDLE_BAY, htbay: HORIZONTAL_TOP_BAY, l2_end: l2_end, l1_end: l1_end, l1_start: l1_start, l2_start: l2_start });
            }
        }
        return BracingDecorator.topandBottomPoints;
    }
    // ---------------------------------------------
    // From the pattern getting the all bracings coordinates
    // ---------------------------------------------
    private async getBracingCoordinatesFromPattern_bkp(bay_pattern: any, towerData: any, height_offset: number = 0) {
        var response2send: any = [];

        if ((towerData['type'] != 'lattice') && (towerData['type'] != 'guyed')) {
            return [];
        }
        for (var k = 0; k < bay_pattern.length; k++) {
            // one bracing (from the data is one dict on the list of bracings)
            var bay = bay_pattern[k]
            var startLegCoord = await this.getLegsCoordinatesAtAltitude(towerData, bay['start'], height_offset = height_offset);
            var endLegCoord = await this.getLegsCoordinatesAtAltitude(towerData, bay['end'], height_offset = height_offset);

            const PATTERN = bay["type"];
            const HORIZONTAL_MIDDLE_BAY = ((bay["horizontalMid"] == false) ? "No" : "Yes");
            const HORIZONTAL_TOP_BAY = ((bay["horizontalTop"] == false) ? "No" : "Yes");

            var mid = this.getMidBay(towerData, bay['start'], bay['end']);
            var centerLegCoord = await this.getLegsCoordinatesAtAltitude(towerData, mid, height_offset = height_offset);

            // loop for each face
            for (var i = 0; i < towerData['nLegs']; i++) {
                // coords to send
                var coords: any = [];
                // 1st leg bottom and top coordinate
                var l1_idx = i - 1;
                if (l1_idx < 0) {
                    l1_idx = towerData['nLegs'] - 1
                }
                var l1_start: number = startLegCoord[l1_idx];
                var l1_end: number = endLegCoord[l1_idx];
                // 2nd leg bottom and top coordinate
                var l2_start: number = startLegCoord[i];
                var l2_end: number = endLegCoord[i];
                // coords.push([l1_start,l2_start])
                //--------------------
                if (['d_down', 'x'].includes(bay['type'])) {
                    coords.push([l1_start, l2_end]);
                }
                if (['d_up', 'x'].includes(bay['type'])) {
                    coords.push([l1_end, l2_start]);
                }
                if (['k_down'].includes(bay['type'])) {

                    coords.push([l1_start, this.mean(l1_end, l2_end)]);
                    coords.push([l2_start, this.mean(l1_end, l2_end)]);
                }
                if (['k_up'].includes(bay['type'])) {
                    coords.push([l1_end, this.mean(l1_start, l2_start)]);
                    coords.push([l2_end, this.mean(l1_start, l2_start)]);
                }
                if (['diamond'].includes(bay['type'])) {
                    coords.push([l1_end, this.mean(l1_start, l2_start)]);
                    coords.push([l2_end, this.mean(l1_start, l2_start)]);
                }
                if (bay['horizontalTop']) {
                    if (['diamond'].includes(bay['type']) ||
                        ['k_up'].includes(bay['type']) ||
                        ['k_down'].includes(bay['type']) ||
                        ['d_up', 'x'].includes(bay['type']) ||
                        ['d_down', 'x'].includes(bay['type'])) {

                        coords.push([l1_end, l2_end]);
                    }
                }
                if (bay['horizontalMid']) {
                    if (['diamond'].includes(bay['type']) ||
                        ['k_up'].includes(bay['type']) ||
                        ['k_down'].includes(bay['type']) ||
                        ['d_up', 'x'].includes(bay['type']) ||
                        ['d_down', 'x'].includes(bay['type'])) {
                        coords.push([centerLegCoord[l1_idx], centerLegCoord[i]]);
                    }
                }
                response2send.push({
                    parent: k,
                    coords: coords,
                    face: "Face " + i.toString(),
                    bracingTopH: bay['end'],
                    bracingBotH: bay['start'],
                    PATTERN: PATTERN,
                    HORIZONTAL_MIDDLE_BAY: HORIZONTAL_MIDDLE_BAY,
                    HORIZONTAL_TOP_BAY: HORIZONTAL_TOP_BAY
                });
            }
        }

        //TEST SCENARIO FOR TILT ROTATION//REMOVE LATER !!Critical!!

        //Create tilt vector
        //let degToRadX = towerData.tower_tilt_x * (Math.PI / 180)
        //let degToRadY = towerData.tower_tilt_y * (Math.PI / 180)
        //let px = BracingDecorator.rotatePointsAroundAxis([[0, 0, 1]], [0, 0, 0], [1, 0, 0], degToRadX)//0.785398
        //let py = BracingDecorator.rotatePointsAroundAxis([[0, 0, 1]], [0, 0, 0], [0, 1, 0], degToRadY)

        ////let np = [0.05908643794508584, -0.09286665454728874, 0.9956785547922555];
        //let np = [0.04288268046427565, -0.0018163673006968424, 0.9999983504035539];
        
        //let tilt = new Vector3d(np[0],np[1],np[2])//new Vector3d(towerData.tower_tilt_x, towerData.tower_tilt_y, 1)
        //let originUpVec = new Vector3d(0, 0, 1);
        //let cross = originUpVec.crossProduct(tilt);
        //let crossA = [cross.x,cross.y, cross.z]
        //let angle = BracingDecorator.calcAngleBetweenVectorsOnTheSamePlane(originUpVec, tilt, cross);//in radians

        ////Aditya
        //let n = towerData['nLegs'];
        //let nlegs : number = 0;
        //if (n == '4') {
        //    nlegs = 4;
        //}
        //if (n == '3') {
        //    nlegs = 3
        //}
        //for (let tb : number = 0; tb < BracingDecorator.topandBottomPoints.length; tb += nlegs) {
        //    if (nlegs == 3) {

        //        let f1 = JSON.parse(JSON.stringify(BracingDecorator.topandBottomPoints[tb]));
        //        let f2 = JSON.parse(JSON.stringify(BracingDecorator.topandBottomPoints[tb + 1]));
        //        let f3 = JSON.parse(JSON.stringify(BracingDecorator.topandBottomPoints[tb + 2]));

        //        let centroidT = BracingDecorator.calculateCentroid([f1.l2_end, f1.l1_end, f2.l2_end, f2.l1_end, f3.l2_end, f3.l1_end]);
        //        let centroidB = BracingDecorator.calculateCentroid([f1.l1_start, f1.l2_start, f2.l1_start, f2.l2_start, f3.l1_start, f3.l2_start]);

        //        let outT = BracingDecorator.rotatePointsAroundAxis([f1.l2_end, f1.l1_end, f2.l2_end, f2.l1_end, f3.l2_end, f3.l1_end], centroidT, crossA, angle);
        //        let outB = BracingDecorator.rotatePointsAroundAxis([f1.l1_start, f1.l2_start, f2.l1_start, f2.l2_start, f3.l1_start, f3.l2_start], centroidB, crossA, angle);

        //        f1.l2_end = outT[0];
        //        f1.l1_end = outT[1];
        //        f1.l1_start = outB[0]
        //        f1.l2_start = outB[1];

        //        f2.l2_end = outT[2];
        //        f2.l1_end = outT[3];
        //        f2.l1_start = outB[2]
        //        f2.l2_start = outB[3]

        //        f3.l2_end = outT[4];
        //        f3.l1_end = outT[5];
        //        f3.l1_start = outB[4]
        //        f3.l2_start = outB[5]

        //        BracingDecorator.topandBottomPoints[tb] = f1;
        //        BracingDecorator.topandBottomPoints[tb + 1] = f2;
        //        BracingDecorator.topandBottomPoints[tb + 2] = f3;
        //        //let out1 = BracingDecorator.genNewPoints([[f1.l1_start[0], f1.l1_start[1], f1.l1_start[2]], [l2_start[0], l2_start[1], l2_start[2]]], cross, 0.349066);
        //    }
        //    else if (nlegs == 4) {

        //        let f1 = JSON.parse(JSON.stringify(BracingDecorator.topandBottomPoints[tb]));
        //        let f2 = JSON.parse(JSON.stringify(BracingDecorator.topandBottomPoints[tb + 1]));
        //        let f3 = JSON.parse(JSON.stringify(BracingDecorator.topandBottomPoints[tb + 2]));
        //        let f4 = JSON.parse(JSON.stringify(BracingDecorator.topandBottomPoints[tb + 3]))

        //        let centroidT = BracingDecorator.calculateCentroid([f1.l2_end, f1.l1_end, f2.l2_end, f2.l1_end, f3.l2_end, f3.l1_end, f4.l2_end, f4.l1_end]);
        //        let centroidB = BracingDecorator.calculateCentroid([f1.l1_start, f1.l2_start, f2.l1_start, f2.l2_start, f3.l1_start, f3.l2_start, f4.l1_start, f4.l2_start]);

        //        let outT = BracingDecorator.rotatePointsAroundAxis([f1.l2_end, f1.l1_end, f2.l2_end, f2.l1_end, f3.l2_end, f3.l1_end, f4.l2_end, f4.l1_end], centroidT, crossA, angle);
        //        let outB = BracingDecorator.rotatePointsAroundAxis([f1.l1_start, f1.l2_start, f2.l1_start, f2.l2_start, f3.l1_start, f3.l2_start, f4.l1_start, f4.l2_start], centroidB, crossA, angle);

        //        f1.l2_end = outT[0];
        //        f1.l1_end = outT[1];
        //        f1.l1_start = outB[0]
        //        f1.l2_start = outB[1];

        //        f2.l2_end = outT[2];
        //        f2.l1_end = outT[3];
        //        f2.l1_start = outB[2]
        //        f2.l2_start = outB[3]

        //        f3.l2_end = outT[4];
        //        f3.l1_end = outT[5];
        //        f3.l1_start = outB[4]
        //        f3.l2_start = outB[5]

        //        f4.l2_end = outT[6];
        //        f4.l1_end = outT[7];
        //        f4.l1_start = outB[6]
        //        f4.l2_start = outB[7]

        //        BracingDecorator.topandBottomPoints[tb] = f1;
        //        BracingDecorator.topandBottomPoints[tb + 1] = f2;
        //        BracingDecorator.topandBottomPoints[tb + 2] = f3;
        //        BracingDecorator.topandBottomPoints[tb + 3] = f4;
        //    }
        //}

        //for (let t = 0; t < BracingDecorator.topandBottomPoints.length; t++) {

        //    let l1_start = BracingDecorator.topandBottomPoints[t].l1_start;
        //    let l2_end   = BracingDecorator.topandBottomPoints[t].l2_end;
        //    let l1_end = BracingDecorator.topandBottomPoints[t].l1_end;
        //    let l2_start = BracingDecorator.topandBottomPoints[t].l2_start;
        //    var coords: any = [];

        //    if (['d_down', 'x'].includes(BracingDecorator.topandBottomPoints[t].pattern)) {
        //        coords.push([l1_start, l2_end]);
        //    }
        //    if (['d_up', 'x'].includes(BracingDecorator.topandBottomPoints[t].pattern)) {
        //        coords.push([l1_end, l2_start]);
        //    }
        //    if (['k_down'].includes(BracingDecorator.topandBottomPoints[t].pattern)) {

        //        coords.push([l1_start, this.mean(l1_end, l2_end)]);
        //        coords.push([l2_start, this.mean(l1_end, l2_end)]);
        //    }
        //    if (['k_up'].includes(BracingDecorator.topandBottomPoints[t].pattern['type'])) {
        //        coords.push([l1_end, this.mean(l1_start, l2_start)]);
        //        coords.push([l2_end, this.mean(l1_start, l2_start)]);
        //    }

        //    response2send.push({
        //        parent: k,
        //        coords: coords,
        //        face: "Face " + t.toString(),
        //        bracingTopH: bay['end'],
        //        bracingBotH: bay['start'],
        //        PATTERN: "x",
        //        HORIZONTAL_MIDDLE_BAY: "false",
        //        HORIZONTAL_TOP_BAY: "false"
        //    });
        //}
        return response2send;
    }



	public getBracingCoordinatesFromPattern = (bay_pattern,towerData,height_offset=0) => {
		var coords:any = [];
		var kCompound = ["K1 Down","K1B Down","K2 Down","K2A Down","K3 Down","K3A Down","K4 Down","K4A Down",
				 		 "K1 UP","K2 UP","K3 UP","K4 UP"];
		if ((towerData['type'] != 'lattice') && (towerData['type'] != 'guyed')){
			return coords;
		}
		var specialNodes: any = [];
		var bracingID = 1;
		var matchBayWithID = {}
		var matchPlanBayWithID = {}
		var bayID = 1;
		var matchbayIndexWithID: any = [];
		var matchPlanbayIndexWithID: any = [];
		var bracingsDescription: any = [];
		var planBracings: any = [];
		var planBracingID = 1;
		for (var k = 0; k < bay_pattern.length ;k++){
			var tempCoods: any = [];
			var bay = bay_pattern[k];
			if (bay['type'] == "unkown"){
				continue
			}
			var startLegCoord = this.getLegsCoordinatesAtAltitudeNoDeviation(towerData,bay['start'],height_offset=height_offset,0);
			var endLegCoord = this.getLegsCoordinatesAtAltitudeNoDeviation(towerData,bay['end'],height_offset=height_offset,0);
			// apply height offset
			var mid = this.getMidBay(towerData, bay['start'], bay['end']);
            var centerLegCoord = this.getLegsCoordinatesAtAltitudeNoDeviation(towerData,mid,height_offset=height_offset);
			// loop for each face
			for (var i = 0; i < towerData['nLegs'] ;i++){
				var l1_idx = i-1;
				if (l1_idx < 0) {
					l1_idx = towerData['nLegs']-1
				}
				var l1_start = startLegCoord[l1_idx];
				var l1_end = endLegCoord[l1_idx];
				// 2nd leg bottom and top coordinate
				var l2_start = startLegCoord[i];
				var l2_end = endLegCoord[i];
			// 	if (kCompound.includes(bay['type'])){
			// 		if (bay['horiz_top'] == true){
			// 			tempCoods.push([l1_end,l2_end,"RED"]);
			// 		}
			// 		if (bay['horiz_mid'] == true){
			// 			tempCoods.push([centerLegCoord[l1_idx], centerLegCoord[i],"RED"]);
			// 		};
			// 		var tempNodes = this.getFaceBracingsNode(i,l1_idx,bay["start"],bay["end"],bay['type'],towerData,height_offset);
			// 		tempNodes["BracingType"] = bay['type'];
			// 		tempNodes["BracingId"] = bracingID;
			// 		specialNodes.push(tempNodes);
			// 		if (!(Object.keys(matchBayWithID).includes(bayID.toString()))){
			// 			matchBayWithID[bayID.toString()] = [];
			// 		}
			// 		matchBayWithID[bayID.toString()].push(bracingID);
			// 		bracingID += 1;
			// 	}
			// 	else{
			// 		if (['Diagonal Down','X Brace',"x","d_down"].includes(bay['type'])){
			// 			tempCoods.push([l1_start,l2_end,"RED"]);
			// 		}
			// 		if (['Diagonal UP','X Brace',"x","d_up"].includes(bay['type'])){
			// 			tempCoods.push([l1_end,l2_start,"RED"]);
			// 		}
			// 		if (['K Brace Down',"k_down"].includes(bay['type'])){
			// 			tempCoods.push([l1_start,this.mean(l1_end,l2_end),"RED"]);
			// 			tempCoods.push([l2_start,this.mean(l1_end,l2_end),"RED"]);
			// 		}
			// 		if (['K Brace Up',"k_up"].includes(bay['type'])){
			// 			tempCoods.push([l1_end,this.mean(l1_start,l2_start),"RED"]);
			// 			tempCoods.push([l2_end,this.mean(l1_start,l2_start),"RED"]);
			// 		}
			// 		if (bay['horiz_top'] == true){
			// 			tempCoods.push([l1_end,l2_end,"RED"]);
			// 		}
			// 		if (bay['horiz_mid'] == true){
			// 			tempCoods.push([centerLegCoord[l1_idx], centerLegCoord[i],"RED"]);
			// 		};
			// 	}
			}
			if (kCompound.includes(bay['type'])){
				matchbayIndexWithID.push(bayID-1);
			}
			//====
			if (bay["planBracingMid"] != null){
				planBracings.push(this.getPlanBracingNode(mid,bay["bracingId"],bay["planBracingMid"]['type'],towerData,height_offset));
				if (!(Object.keys(matchPlanBayWithID).includes(bayID.toString()))){
					matchPlanBayWithID[bayID.toString()] = [];
				}
				matchPlanBayWithID[bayID.toString()].push(bay["bracingId"]);
				matchPlanbayIndexWithID.push(bayID-1);
				planBracingID += 1;
			}
			if (bay["planBracingTop"] != null){
				planBracings.push(this.getPlanBracingNode(bay['end'],bay["bracingId"],bay["planBracingTop"]['type'],towerData,height_offset));
				if (!(Object.keys(matchPlanBayWithID).includes(bayID.toString()))){
					matchPlanBayWithID[bayID.toString()] = [];
				}
				matchPlanBayWithID[bayID.toString()].push(bay["bracingId"]);
				matchPlanbayIndexWithID.push(bayID-1);
				planBracingID += 1;
			}
			//====
			coords.push(tempCoods);
			bracingsDescription.push(bay);
			bayID += 1;
		}
		return {coords,specialNodes,matchBayWithID,matchbayIndexWithID,bracingsDescription,planBracings,matchPlanBayWithID,matchPlanbayIndexWithID}
	}

	public getFaceBracingsNode = (i,l1_idx,zMin,zMax,_type,towerData,height_offset) => {
		var tempLegCoord_0 = this.getLegsCoordinatesAtAltitudeNoDeviation(towerData,zMin,height_offset=height_offset,0);
		var tempLegCoord_1 = this.getLegsCoordinatesAtAltitudeNoDeviation(towerData,zMax,height_offset=height_offset,0);

        var A = tempLegCoord_0[l1_idx];              //Bottom Vertice of the Leg 0
        var C = tempLegCoord_0[i];                	 //Bottom Vertice of the Leg 1
        var D = tempLegCoord_1[l1_idx];              //Top Vertice of the Leg 0
        var E = tempLegCoord_1[i];                   //Top Vertice of the Leg 1 
		
		const nodes = {
			"Node1":{
				"X": A[0],
				"Y": A[1],
				"Z": A[2] 
			},
			"Node2":{
				"X": C[0],
				"Y": C[1],
				"Z": C[2] 
			},
			"Node3":{
				"X": E[0],
				"Y": E[1],
				"Z": E[2]
			},
			"Node4":{
				"X": D[0],
				"Y": D[1],
				"Z": D[2]
			}
		}
		return nodes
	}

	public getPlanBracingNode = (z,id,type,towerData,height_offset) => {
		var tempLegCoord_0 = this.getLegsCoordinatesAtAltitudeNoDeviation(towerData,z,height_offset=height_offset,0);
		const nodes = {}
		for (var l=0; l < tempLegCoord_0.length; l++){
			nodes['Node' + (l+1).toString()] = {"X": tempLegCoord_0[l][0], "Y": tempLegCoord_0[l][1], "Z": tempLegCoord_0[l][2]}
		}
		nodes["BracingType"] = type;
		// nodes["BracingType"] = `${type[0]}${type.substring(1).toLowerCase()}`;
		nodes["BracingId"] = id;
		nodes["PlanBracings"] = 1	
		return nodes
	}

    // ---------------------------------------------
    // [same from mountDecorator]
    // Load up the geometry info for pipes into a builder object and
    // return a graphic object and store it in the geometryGraphics[].
    // These grapic objects will be added to the the context via context.addDecoration()
    // Utilised in deocrate overload See: in decorate();
    // ---------------------------------------------
    private createGraphics(context: DecorateContext) {
        // Check if Geometry Graphic objects are created and if not then load up in array.
        const geometryGraphics: RenderGraphic[] = [];
        if (geometryGraphics.length === 0) {
            // Iterate over each pipe object loaded with the imported jason params and build the graphics for it.
            for (const pipe of this.pipes) {
                let transientId = context.viewport.iModel.transientIds.next;
                if (pipe.transientId === "") {
                    pipe.transientId = transientId;
                    this.nameIdMap.set(`bracingGeom#${pipe.name}`, pipe.transientId);
                    this.objectIdMap.set(pipe.transientId, `bracingGeom#${pipe.name}`);
                } else {
                    transientId = pipe.transientId;
                }
                // Speicify unique transientId so that geometry are considered as sperate entites.
                const builder = context.createGraphicBuilder(GraphicType.Scene, Transform.identity, transientId);
                // builder.wantNormals = true;
                const geometry = pipe.geometry;
                builder.setBlankingFill(pipe.fillColor);
                this.createGraphicsForGeometry(geometry, false, builder);
                const graphic = builder.finish();
                // create graphic obj and push to array to be used in a GraphicBranch
                geometryGraphics.push(graphic);
            }
        }
        // If the Graphics are already created  and geometryGraphics array length is greater than 0 then
        // add all the grapics objects as decorations to the scene. This helps avoid having to bind primitive data into
        // buffers each time decorate is called which is costly.
        // Also see : useCachedDecorations that implicityl re-uses buffers , rather than having to mange them here like so.
        for (const geo of geometryGraphics) {
            // Some branch object presets
            const overrides = {visibleEdges: false, lighting: true}; //new ViewFlagOverrides();
            // overrides.setShowVisibleEdges(false);
            // overrides.setApplyLighting(true);
            // Create new SceneGraph //dont know why we do this every time seems inefficient, but it is as per docs.
            const branch = new GraphicBranch(false);
            branch.setViewFlagOverrides(overrides);
            // add to SceneGraph graphic branch Note : this is a workaround to improve not having to rebind geometry info.
            branch.add(geo);
            const graphic = context.createBranch(branch, Transform.identity);
            context.addDecoration(GraphicType.Scene, graphic);
        }
    }
    // ---------------------------------------------
    // [same from mountDecorator]
    // This is a boilerplate for loading up all types of geometry queries
    // into the builder object . This function is a as per the IModel doc samples.
    // Keep as is.
    // ---------------------------------------------
    private createGraphicsForGeometry(geometry: GeometryQuery, wantEdges: boolean, builder: GraphicBuilder) {
        if (geometry instanceof LineString3d) {
            builder.addLineString(geometry.points);
        } else if (geometry instanceof Loop) {
            builder.addLoop(geometry);
            if (wantEdges) {
                // Since decorators don't natively support visual edges,
                // We draw them manually as lines along each loop edge/arc
                builder.setSymbology(ColorDef.black, ColorDef.black, 2);
                const curves = geometry.children;
                curves.forEach((value) => {
                    if (value instanceof LineString3d) {
                        let edges = value.points;
                        const endPoint = value.pointAt(0);
                        if (endPoint) {
                            edges = edges.concat([endPoint]);
                        }
                        builder.addLineString(edges);
                    } else if (value instanceof Arc3d) {
                        builder.addArc(value, false, false);
                    }
                });
            }
        } else if (geometry instanceof Path) {
            builder.addPath(geometry);
        } else if (geometry instanceof IndexedPolyface) {
            builder.addPolyface(geometry, true); // this is what will be used to generate the pipes.
            if (wantEdges) {
                // Since decorators don't natively support visual edges,
                // We draw them manually as lines along each facet edge
                builder.setSymbology(ColorDef.black, ColorDef.black, 2);
                const visitor = IndexedPolyfaceVisitor.create(geometry, 1);
                let flag = true;
                while (flag) {
                    const numIndices = visitor.pointCount;
                    for (let i = 0; i < numIndices - 1; i++) {
                        const point1 = visitor.getPoint(i);
                        const point2 = visitor.getPoint(i + 1);
                        if (point1 && point2) {
                            builder.addLineString([point1, point2]);
                        }
                    }
                    flag = visitor.moveToNextFacet();
                }
            }
        } else if (geometry instanceof LineSegment3d) {
            const pointA = geometry.point0Ref;
            const pointB = geometry.point1Ref;
            const lineString = [pointA, pointB];
            builder.addLineString(lineString);
        } else if (geometry instanceof Arc3d) {
            builder.addArc(geometry, false, false);
        } else if (geometry instanceof CurveChainWithDistanceIndex) {
            this.createGraphicsForGeometry(geometry.path, wantEdges, builder);
        }
    }
    // ---------------------------------------------
    // Ovveride from Decorator parent.
    // Is called to validate Decoratror objects to draw in the current view context.
    // Note :
    // Not a real render callback/loop not usable for Animations,
    // as the loop is not syncronus and runs intermittently, or when the current view comes into focus 'ie on mousclick or mouse move'.
    // ---------------------------------------------
    public decorate(context: DecorateContext): void {
        // Create and add the Geometry to Context
        this.createGraphics(context);
    }
}
