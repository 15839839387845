import { IModelApp, IModelConnection, NotifyMessageDetails, OutputMessagePriority, OutputMessageType } from "@itwin/core-frontend";
import axios from "axios";
import React from "react";
import "../Lists/List.scss";

import { connect } from 'react-redux'
import { UiFramework } from "@itwin/appui-react";
import { AccessToken } from "@itwin/core-bentley";
import { getValueInRequiredUnit } from "../Tables/BaseTable";
import { Logger } from "../../api/logging";
import { unitSystemType } from "../../../store/redux-types";
import { store } from "../../../store/rootReducer";
import { ConfigManager } from "../../../config/ConfigManager";
import DatabaseApiClient from "../../../services/graphql/database-api/database-api-client";
import { setRadLevelWorkflow } from "../../../store/detectedData/apiDataActionTypes";
import { RootState } from "../../../store/States";
import ModalDialog from "../TreeVisualizer/dialog/dialog";
import { AppSettings } from "../../../config/AppSettings";

interface EdProps {
    data: any;
    type?: string;
    editData?: any;

    unitSystem?: unitSystemType;
    projectId?: string;
    openToweriQUrl?: string;
    accessToken?: AccessToken;
}

interface editDataProps {
    elevation?: Number,
    width?: Number,
    height?: Number,
    standoff?: Number,
    weight?: Number,
    azimuth?: Number,
    manufacturer?: String,
    modelName?: String,
    location?: String,
    type?: String,
    name?: String
}


const AddMountElevationPopup: React.FC<EdProps> = (props) => {
    const [showModal, setShowModal] = React.useState<boolean>(false);
    const [mountType, setMountType] = React.useState("Platform");
    const [mountModelNameFromCatalog, setMountModelNameFromCatalog] = React.useState<any>([])
    const [selectedMountManufacturer, setSelectedMountManufacture] = React.useState("--Select--");
    const [selectedMountModel, setSelectedMountModel] = React.useState("--Select--");
    const [platformType, setPlatFormType] = React.useState("--Select--");
    const [formState, setFormState] = React.useState({ elevation: 0, width: 0, height: 0, standoff: 0, weight: 0, azimuth: 0 })
    const [mountDetails, setMountDetails] = React.useState<any>([])
    const [towerDetails, setTowerDetails] = React.useState({ towerType: "", towerHeight: "", noOfLegs: 1 })
    const [location, setLocation] = React.useState("FaceA")
    const [designModelData, setDesignModelData] = React.useState<any>({})
    const [imodelData, setImodelData] = React.useState(props.data)
    const [unitSystem, setUnitSystem] = React.useState({ type:unitSystemType.IMPERIAL , distanceUnit: 0, weightUnit: 0 })
    const [editData, setEditData] = React.useState<editDataProps>({})
    const [widthname, setWidthname] = React.useState("Width");
    const [saveBtnClicked, setSaveBtnClicked ] = React.useState(false)
    //--------------------
    React.useEffect(() => {
        if(props.projectId)getMountModels()
    }, [mountType])
    //--------------------
    React.useEffect(() => {
        getInitialData()
        if (AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.IMPERIAL) {
            setUnitSystem({ type: unitSystemType.IMPERIAL, distanceUnit: 0.3048, weightUnit: 1 })
        } else if (AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.METRIC) setUnitSystem({ type:unitSystemType.METRIC, distanceUnit: 1, weightUnit: 1/2.2046 })
        setShowModal(true)
    }, [props.data])
    //--------------------
    const getInitialData = async () => {
        let designData = await getAsDesignModelData()
        if (designData && designData.hasOwnProperty("Staad")) {
            let towerType = designData.Staad.TowerInformation.TowerType
            let noOfLegs = towerType == "SelfSupported" ||  towerType == "Guyed"? designData.Staad?.Panel[0]?.Legs?.Leg ?? 1 : 1;
            setTowerDetails({ towerType: designData.Staad.TowerInformation.TowerType, towerHeight: designData.Staad.TowerInformation.TowerHeight, noOfLegs: noOfLegs?.length })
            setDesignModelData(designData.Staad)
            if (props.type == "edit") {
                let mountData = await getMountData(imodelData)
                let mountLocation = ""
                let tempMountType = ""
                let copyFormState = { ...formState }
                let selectedMount = store.getState().detectedData.selectedObjectInformation.objectProperties.objectIds[0];

                let particularMount = mountData.filter(mount => mount[0] == selectedMount)
                if(particularMount){
                    particularMount = particularMount[0]
                    copyFormState.elevation = AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.IMPERIAL? particularMount[1] : (particularMount[1]*0.3048).toFixed(2) ;
                    copyFormState.azimuth = AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.IMPERIAL? particularMount[6] : (particularMount[6]*0.3048).toFixed(2) ;
                    copyFormState.standoff = AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.IMPERIAL? particularMount[7]: (particularMount[7]*0.3048).toFixed(2);
                    copyFormState.weight = AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.IMPERIAL? particularMount[8]: (particularMount[8]/2.2046).toFixed(2);
                    copyFormState.width = AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.IMPERIAL? particularMount[9]:  (particularMount[9]*0.3048).toFixed(2);
                    copyFormState.height = AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.IMPERIAL? particularMount[10]:( particularMount[10]*0.3048).toFixed(2);
                    setSelectedMountManufacture(particularMount[2])
                    setSelectedMountModel(particularMount[3])
                    setLocation(particularMount[4])
                    setMountType(particularMount[5])
                    setMountType(particularMount[5].includes("Platform") ? "Platform" : particularMount[5])

                    // setFormState(copyFormState)
                    tempMountType = particularMount[5]
                    setMountDetails(particularMount)
                }
               
                if (tempMountType == "Platform" || tempMountType == "FramedPlatform"){
                    if(copyFormState.height == 0)setPlatFormType(`${towerType == "SelfSupported" ||towerType == "Guyed"?noOfLegs?.length:3}-sided`)
                    else setPlatFormType(`${towerType == "SelfSupported" || towerType == "Guyed"?noOfLegs?.length:3}-sided with handrail`)
                } 
                 else if(tempMountType == "CircularPlatform" || tempMountType == "FramedCircularPlatform"){
                    if(copyFormState.height == 0)setPlatFormType(`Circular`)
                    else setPlatFormType(`Circular with handrail`)
                }
                else setPlatFormType(tempMountType)
                setFormState(copyFormState)

                if (mountData) {
                    let particularMount = mountData.find(mount =>  (AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.METRIC ? mount.elevation*0.3048 : mount.elevation) <= parseFloat(copyFormState.elevation.toString()) + 1 &&  (AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.METRIC ? mount.elevation*0.3048 : mount.elevation) >= parseFloat(copyFormState.elevation.toString()) - 1 && mount.location == mountLocation)
                    if (particularMount) setMountDetails(particularMount)

                    if (particularMount != undefined) {
                        if (particularMount.type == "CircularPlatform") {
                            var nodes1 = document.getElementById("dialogBox-container-Azimuth")?.getElementsByTagName('*') as HTMLSelectElement;
                            if (nodes1) {
                                for (var i = 0; i < nodes1.length; i++) {
                                    nodes1[i].disabled = true;
                                }
                            }
                            var nodes2 = document.getElementById("dialogBox-container-StandOff")?.getElementsByTagName('*') as HTMLSelectElement;
                            if (nodes2) {
                                for (var i = 0; i < nodes2.length; i++) {
                                    nodes2[i].disabled = true;
                                }
                            }
                            var nodes3 = document.getElementById("dialogBox-container-Height")?.getElementsByTagName('*') as HTMLSelectElement;
                            if (nodes3) {
                                for (var i = 0; i < nodes3.length; i++) {
                                    nodes3[i].disabled = true;
                                }
                            }
                        }
                        if (particularMount.type == "FramedCircularPlatform") {
                            var nodes1 = document.getElementById("dialogBox-container-Azimuth")?.getElementsByTagName('*') as HTMLSelectElement;
                            if (nodes1) {
                                for (var i = 0; i < nodes1.length; i++) {
                                    nodes1[i].disabled = true;
                                }
                            }
                            var nodes2 = document.getElementById("dialogBox-container-StandOff")?.getElementsByTagName('*') as HTMLSelectElement;
                            if (nodes2) {
                                for (var i = 0; i < nodes2.length; i++) {
                                    nodes2[i].disabled = true;
                                }
                            }
                            var nodes3 = document.getElementById("dialogBox-container-Height")?.getElementsByTagName('*') as HTMLSelectElement;
                            if (nodes3) {
                                for (var i = 0; i < nodes3.length; i++) {
                                    nodes3[i].disabled = false;
                                }
                            }
                        }
                        if (particularMount.type == "T-Arm") {
                            var nodes3 = document.getElementById("dialogBox-container-Height")?.getElementsByTagName('*') as HTMLSelectElement;
                            if (nodes3) {
                                for (var i = 0; i < nodes3.length; i++) {
                                    nodes3[i].disabled = false;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    const getEnvironmentPrefix = () => {
        let prefix = ""
        if(ConfigManager.regionCode == 103)prefix="dev-"
        else if(ConfigManager.regionCode == 102)prefix="qa-"
        return prefix
    }
    //--------------------
    const getAsDesignModelData = (): Promise<any> => {
        const projectId = props.projectId;
        const token = props.accessToken!;
        const url = `${ConfigManager.openToweriQUrl}/v1.0/${projectId}/AsDesignModel`;
        return fetch(url, {
            method: "get",
            headers: {
                Authorization: token,
            },
        }).then(async (response: Response) => {
            if (!response.ok) {
                return null;
            }
            return response.json();
        }).then((data: any) => {
            return data;
        });
    }
    //--------------------
    const getMountData = async (imodel: IModelConnection) => {
        try {
            if (imodel.isBlank) {
                return undefined;
            }
            let records: any = []
            for await (const record of imodel.query("select  ECInstanceId, Elevation, Manufacturer, ModelName,Location, Type,Azimuth,StandOff,Weight,Width,Height from OpenTower.Mount")) {
                records.push(record);
            }
            return records
        } catch (e) {
          Logger.error("Error when fetching As Designed Mount data",e);
        }
        return undefined;
    }
    //--------------------
    const getMountModels = async () => {
        let mountTypeValue = mountType.includes('Platform') ? 'platform' : mountType.toLowerCase();
        mountTypeValue = mountTypeValue.includes('t-arm') ? 'tarm' : mountTypeValue;
        let projectId = props.projectId!;
        let data = await DatabaseApiClient.mountModel(mountTypeValue, projectId!)
        if (data) {
            setMountModelNameFromCatalog(data.mountModelNameFromCatalog)
        }
    }
    //--------------------
    const closingOps = () => {
        setShowModal(false);
        UiFramework.content.dialogs.close("AddNewRad");
        }
    //--------------------
    const handleMountManufacturer = (e) => {
        if (e){
             setSelectedMountManufacture(e.target.value)
             setSelectedMountModel("--Select--")
        }
        if (props.type == "edit") setEditData({ ...editData, manufacturer: e.target.value });
    }
    //--------------------
    const handleMountType = (e) => {
        if (e) {
            setMountType(e.target.value)
            setLocation("--Select--")
            setPlatFormType("--Select--")
            if (props.type == "edit") setEditData({ ...editData, type: e.target.value })
           setFormState ({ ...formState, width: 0, height: 0, standoff: 0, weight: 0, azimuth: 0 })
        }
        if (e.target.value == "Platform") {
            setLocation("FaceA")
            setPlatFormType("--Select--")
            if (props.type == "edit") setEditData({ ...editData, location: "FaceA", type: e.target.value })
        }
        if(e.target.value == "None"){
            setFormState({ ...formState, width: 0, height: 0, standoff: 0, weight: 0, azimuth: 0 })
        }
        if (e.target.value == "T-Arm" || e.target.value == "T-arm") {
            var heightnodes = document.getElementById("dialogBox-container-Height")?.getElementsByTagName('*') as HTMLSelectElement;
            if (heightnodes) {
                for (var i = 0; i < heightnodes.length; i++) {
                    heightnodes[i].disabled = true;
                }
            }
        }
        else {
            var heightnodes = document.getElementById("dialogBox-container-Height")?.getElementsByTagName('*') as HTMLSelectElement;
            if (heightnodes) {
                for (var i = 0; i < heightnodes.length; i++) {
                    heightnodes[i].disabled = false;
                }
            }
        }
        setSelectedMountManufacture("--Select--")
        setSelectedMountModel("--Select--")
    }
    //--------------------
    const handleMountModel = (e) => {
        if (e) {
            setSelectedMountModel(e.target.value)
            getMountDetails(e.target.value)
        }
    }
    //--------------------
    const handleFormState = (e) => {
        if (e) {
            let value =  e.target.value;
            if(e.target.name == "height" ||  e.target.name =="width") value = value>0?value:0
            let copyFormState = { ...formState }
            copyFormState[e.target.name] = value;
            setFormState(copyFormState)
            if (props.type == "edit") setEditData({ ...editData, [e.target.name]: e.target.value.toString() })
        }
    }
    //--------------------
    const handleLocation = (e) => {
        if (e) setLocation(e.target.value)
        if (props.type == "edit") setEditData({ ...editData, location: e.target.value })

    }
    //--------------------
    const getMountDetails = async (modelName) => {
        setSaveBtnClicked(true)
        let mountTypeValue = mountType.includes('Platform') ? 'platform' : mountType.toLowerCase();
        mountTypeValue = mountTypeValue.includes('t-arm') ? 'tarm' : mountTypeValue;
        let mountDetails = {
            manufacturer: selectedMountManufacturer,
            modelName,
            mountType: mountTypeValue
        }
        let data = await generateMountData(mountDetails, formState.elevation)
        if (data && data.hasOwnProperty("MountDetails")) {
            let copyFormState = { ...formState }
            let mountDetails = data.MountDetails[0]
            copyFormState.height = Math.round((parseFloat(mountDetails.Height.split([" "])[0])) * (AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.METRIC ? 0.3048 : 1))
            copyFormState.width = Math.round((parseFloat(mountDetails.Width.split([" "])[0])) * (AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.METRIC ? 0.3048 : 1))
            copyFormState.standoff = Math.round((parseFloat(mountDetails.StandOff.split([" "])[0])) * (AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.METRIC ? 0.3048 : 1)*10000)/10000//10000 is to get upto four decimal point
            copyFormState.azimuth = parseFloat(mountDetails.Azimuth.split([" "])[0])
            copyFormState.weight = Math.round((parseFloat(mountDetails.Weight.split([" "])[0]) * unitSystem.weightUnit))
            setFormState(copyFormState)
            setEditData({ ...editData, height: copyFormState.height, width: copyFormState.width, standoff: copyFormState.standoff, weight: copyFormState.weight, modelName: modelName, name: modelName, azimuth: copyFormState.azimuth })
        }
        setSaveBtnClicked(false)
    }
    //--------------------
    const handlePlatformType = (e) => {
        if (e) {
            setPlatFormType(e.target.value);
        }

        if (e.target.value == "3-sided" || e.target.value == "4-sided") setFormState({ ...formState, height: 0 })
        if (props.type == "edit") {
            if (e.target.value == "3-sided" || e.target.value == "4-sided") setEditData({ ...editData, type: e.target.value, height: 0 })
            else setEditData({ ...editData, type: e.target.value })
        }

        //----------------
        if (e.target.value == "Circular") {
            setFormState({...formState,standoff:0})
            setWidthname("Diameter");
            //disable dialogue boxes base on selecton------------------
            var nodes1 = document.getElementById("dialogBox-container-Azimuth")?.getElementsByTagName('*') as HTMLSelectElement;
            if (nodes1) {
                for (var i = 0; i < nodes1.length; i++) {
                    nodes1[i].disabled = true;
                }
            }
            var nodes2 = document.getElementById("dialogBox-container-StandOff")?.getElementsByTagName('*') as HTMLSelectElement;
            if (nodes2) {
                for (var i = 0; i < nodes2.length; i++) {
                    nodes2[i].disabled = true;
                }
            }
            var nodes3 = document.getElementById("dialogBox-container-Height")?.getElementsByTagName('*') as HTMLSelectElement;
            if (nodes3) {
                for (var i = 0; i < nodes3.length; i++) {
                    nodes3[i].disabled = true;
                }
            }
        }
        if (e.target.value == "Circular with handrail") {
            setFormState({...formState,standoff:0})
            setWidthname("Diameter");
            //disable dialogue boxes base on selecton------------------
            var nodes1 = document.getElementById("dialogBox-container-Azimuth")?.getElementsByTagName('*') as HTMLSelectElement;
            if (nodes1) {
                for (var i = 0; i < nodes1.length; i++) {
                    nodes1[i].disabled = true;
                }
            }
            var nodes2 = document.getElementById("dialogBox-container-StandOff")?.getElementsByTagName('*') as HTMLSelectElement;
            if (nodes2) {
                for (var i = 0; i < nodes2.length; i++) {
                    nodes2[i].disabled = true;
                }
            }
            var nodes3 = document.getElementById("dialogBox-container-Height")?.getElementsByTagName('*') as HTMLSelectElement;
            if (nodes3) {
                for (var i = 0; i < nodes3.length; i++) {
                    nodes3[i].disabled = false;
                }
            }
        }
        if (e.target.value == "T-Arm") {
            var nodes3 = document.getElementById("dialogBox-container-Height")?.getElementsByTagName('*') as HTMLSelectElement;
            if (nodes3) {
                for (var i = 0; i < nodes3.length; i++) {
                    nodes3[i].disabled = true;
                }
            }
        }
        //---------------
        if (e.target.value == "3-sided" || e.target.value == "3-sided with handrail") {
            setWidthname("Width");

            //enable dialoge boxes in case disabled.------------------
            var nodes1 = document.getElementById("dialogBox-container-Azimuth")?.getElementsByTagName('*') as HTMLSelectElement;
            if (nodes1) {
                for (var i = 0; i < nodes1.length; i++) {
                    nodes1[i].disabled = false;
                }
            }
            var nodes2 = document.getElementById("dialogBox-container-StandOff")?.getElementsByTagName('*') as HTMLSelectElement;
            if (nodes2) {
                for (var i = 0; i < nodes2.length; i++) {
                    nodes2[i].disabled = false;
                }
            }
            var nodes3 = document.getElementById("dialogBox-container-Height")?.getElementsByTagName('*') as HTMLSelectElement;
            if (nodes3) {
                for (var i = 0; i < nodes3.length; i++) {
                    nodes3[i].disabled = false;
                }
            }
        }
        //---------------
    }
    //--------------------
    const uniqueArrayValues = (array, name) => {
        var flags: any = [], output: any = [], l = array.length, i;
        for (i = 0; i < l; i++) {
            if (flags[array[i][name]]) continue;
            flags[array[i][name]] = true;
            output.push(array[i][name]);
        }
        return output.sort((a, b) => b - a);
    }
    //--------------------
    const mountManufacturerJsx = () => {
        let manufacturerArr = uniqueArrayValues(mountModelNameFromCatalog ?? [], 'manufacturer')
        manufacturerArr = ["--Select--", ...manufacturerArr];
        return <div style={{ justifyContent: "space-between", display: 'flex' }}>
            <div className="dialogBox-heading-coloun">

                <div className="dialogBox-dropdown-heading">Manufacturer  </div>
                <span > : </span>
            </div>

            <select id="manufacturer-type-dropdown" className="dialogBox-dropdown" value={selectedMountManufacturer} onChange={handleMountManufacturer} disabled = {mountType== "None"?true:false}>
                {
                    manufacturerArr.map((x: any, i: any) => <option key={i} value={x}>{x}</option>)
                }
            </select>
        </div>
    }
    //--------------------
    const mountModelNameJsx = () => {
        let filterMountModel = mountModelNameFromCatalog.filter(mount => mount.manufacturer == selectedMountManufacturer)
        let mountModelName = [{ isGlobal: true, modelName: "--Select--" }]
        mountModelName = [...mountModelName, ...filterMountModel]
        return <div style={{ display: 'flex', justifyContent: "space-between" }}>
            <div className="dialogBox-heading-coloun">

                <div className="dialogBox-dropdown-heading">Model</div>
                <span > : </span>
            </div>

            <select id="mount-model-dropdown" className="dialogBox-dropdown" onChange={handleMountModel} value={selectedMountModel} disabled = {mountType== "None"?true:false} >
                {
                    mountModelName.map((x: any, i: any) => <option key={i} value={x.modelName}>

                        {x.isGlobal || x.modelName == "--Select--" ? "" : "* "}{x.modelName}</option>)

                }
            </select>
        </div>
    }
    //--------------------
    const generateMountData = async (mountDetails, selectedElevation) => {
        let accessUrls: any = '';

        try {
            accessUrls = await DatabaseApiClient.retrieveMountCatalogSharedAccessKey(mountDetails);

            if (!accessUrls) {
                return []
            }
        }
        catch (e) {
            return []
        }

        try {
            const model_data = await axios(accessUrls.fileSharedAccessService, {
                method: "GET"
            });
            if (model_data) {
                let mountAnalysisData = { ...model_data.data }


                let data = mountAnalysisData.MountDetails.map(mounts => {
                    let copyMountInfo = { ...mounts }
                    copyMountInfo['Elevation'] = `${selectedElevation} ft`
                    return copyMountInfo

                })

                let copyFaceLine = [...mountAnalysisData.FaceLine];
                if (mountAnalysisData.FaceLine.length === 1) {
                    copyFaceLine[0]['FaceID'] = mountAnalysisData.MountDetails[0].Location;
                }
                mountAnalysisData = { ...mountAnalysisData, "MountDetails": data, "FaceLine": copyFaceLine };

                if (mountAnalysisData.hasOwnProperty("Section") || mountAnalysisData.hasOwnProperty("ConnectionDetail")) {

                    mountAnalysisData["SectionDetails"] = mountAnalysisData["Section"]
                    mountAnalysisData["ConnectionDetails"] = mountAnalysisData["ConnectionDetail"]
                    delete mountAnalysisData["Section"];
                    delete mountAnalysisData["ConnectionDetail"];
                }

                return mountAnalysisData;
            }

        } catch (ex) {
            return []

        }
    }
    //--------------------
    const getMountType = () => {
        if (mountType == "Platform" && (platformType == "3-sided with handrail" || platformType == "4-sided with handrail")) {
            return "FramedPlatform"
        }
        if (mountType == "Platform" && (platformType == "Circular")) {
            return "CircularPlatform";
        }
        if (mountType == "Platform" && (platformType == "Circular with handrail")) {
            return "FramedCircularPlatform";
        }
        if (mountType == "T-arm") {
            return "T-Arm"
        }
        if (mountType == "standOff") {
            return "SideArm"
        } 
        if(mountType == "Pipe")return "PipeMount"
        return mountType;
    }
    //--------------------
    const getFaceValues = () => {
        let firstFace = "", secondFace = ""
        if (towerDetails.noOfLegs == 3) {
            if (location == "FaceA") { firstFace = "A", secondFace = "C" }
            else if (location == "FaceB") { firstFace = "A", secondFace = "B" }
            else if (location == "FaceC") { firstFace = "B", secondFace = "C" }
        }
        else if (towerDetails.noOfLegs == 4) {
            if (location == "FaceA") { firstFace = "A", secondFace = "D" }
            else if (location == "FaceB") { firstFace = "A", secondFace = "B" }
            else if (location == "FaceC") { firstFace = "B", secondFace = "C" }
            else if (location == "FaceD") { firstFace = "C", secondFace = "D" }

        }
        return { firstFace, secondFace }
    }
    //--------------------
    const checkElevation = () => {
        let check = false;
        if (AppSettings.defaultDisplaySettings.unitSystem  ==  unitSystemType.IMPERIAL) check = formState.elevation <= parseFloat(towerDetails.towerHeight) ? true : false
        else if (AppSettings.defaultDisplaySettings.unitSystem  == unitSystemType.METRIC) check = formState.elevation <= (parseFloat(towerDetails.towerHeight) * 0.3048) ? true : false

        return check;
    }

    const getMountName = () => {
        if(mountType == "standOff")return "Stand off"
        if(mountType=="None")return ""
        if(mountType == "Pipe") return "Pipe Mount"
        return selectedMountModel
    }
    //--------------------
    const handleSaveBtn = async () => {
        setSaveBtnClicked(true)
        let check = checkElevation()
        if (check) {

            let variable: any = {

                mountAdditionGeometryDetails: {
                    mountSectionGeometryDetails: [],

                }, mountChangeGeometryDetails: {
                    mountSectionGeometryDetails: []
                }
            }

            let heighti = (formState.height * unitSystem.distanceUnit).toString();
            if (platformType == "Circular") {
                heighti = "0";
            }
            variable = {
                mountAdditionGeometryDetails: {
                    mountSectionGeometryDetails: [
                        {
                            azimuth: formState.azimuth.toString(),
                            eCComponentType: "Mount",
                            elevation: (formState.elevation * unitSystem.distanceUnit).toString(),
                            height: heighti,
                            horizontalOffset: "0",
                            weight: (formState.weight * (AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.METRIC ? 1 : 1/2.2046)).toString(),
                            width: (formState.width * unitSystem.distanceUnit).toString(),
                            standoff: (formState.standoff * unitSystem.distanceUnit).toString(),
                            name: getMountName(),
                            manufacturer:mountType=="None"?"": selectedMountManufacturer,
                            modelName: mountType=="None"?"":selectedMountModel,
                            type: getMountType(),
                            location: location,
                        }
                    ]
                }, mountChangeGeometryDetails: {
                    mountSectionGeometryDetails: []
                }
            }

            let path = variable.mountAdditionGeometryDetails.mountSectionGeometryDetails[0]
            if (props.type == "edit") {
                let copyEditData = { ...editData }
                let editChanges = [copyEditData].map(parameter => {
                    let copyMount: any = { ...parameter }
                    if (copyMount && copyMount.hasOwnProperty("elevation")) copyMount["elevation"] = AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.METRIC?copyMount.elevation: (copyMount.elevation * unitSystem.distanceUnit).toString()
                    if (copyMount && copyMount.hasOwnProperty("height")) copyMount["height"] = AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.METRIC?copyMount.height: (copyMount.height * unitSystem.distanceUnit).toString()
                    if (copyMount && copyMount.hasOwnProperty("weight")) copyMount["weight"] = AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.METRIC?copyMount.weight:(copyMount.weight *  0.453592).toString()
                    if (copyMount && copyMount.hasOwnProperty("width")) copyMount["width"] =AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.METRIC?copyMount.width: (copyMount.width * unitSystem.distanceUnit).toString()
                    if (copyMount && copyMount.hasOwnProperty("standoff")) copyMount["standoff"] =AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.METRIC?copyMount.standoff: (copyMount.standoff * unitSystem.distanceUnit).toString()
                    if (copyMount && copyMount.hasOwnProperty("azimuth")) copyMount["azimuth"] = (copyMount.azimuth).toString()
                    return copyMount
                })

                editChanges[0]["eCComponentType"] = "Mount"
                editChanges[0]["iModelElementId"] = mountDetails[0]
                editChanges[0]["horizontalOffset"] = "0"

                variable = {
                    mountAdditionGeometryDetails: {
                        mountSectionGeometryDetails: [],

                    }, mountChangeGeometryDetails: {
                        mountSectionGeometryDetails: editChanges
                    }
                }
                if (editChanges && editChanges[0].hasOwnProperty("type")) editChanges[0]["type"] = getMountType()
                path = variable.mountChangeGeometryDetails.mountSectionGeometryDetails[0]
            }

            let elevation = AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.METRIC ? formState.elevation * 3.28084 : formState.elevation
            if (towerDetails.towerType == "Monopole") {
                let poleProperty = designModelData.PoleProperties.PoleProperty
                if(!Array.isArray(designModelData.PoleProperties.PoleProperty))poleProperty = [{...poleProperty}]
                let particularPole = poleProperty.find(pole => elevation <= parseFloat(pole.TopElevation) && elevation >= parseFloat(pole.BottomElevation))
                path["topTipDiameter"] = (parseFloat(particularPole.TopFlatDiameter) * 0.3048).toString()
                path["bottomTipDiameter"] = (parseFloat(particularPole.BottomTipDiameter) * 0.3048).toString()
                path["topElevation"] = (parseFloat(particularPole.TopElevation) * 0.3048).toString()
                path["bottomElevation"] = (parseFloat(particularPole.BottomElevation) * 0.3048).toString()
                if (mountType == "Platform")  path["location"] = "FaceACentroid";

            }else if(towerDetails.towerType == "Guyed Monopole"){
                elevation =  AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.METRIC ? formState.elevation  : formState.elevation*0.3048
                let panelProperty = designModelData.PanelProperties.PanelProperty.find(panel => elevation <=parseFloat( Number(panel.TopElevation).toLocaleString('fullwide', {useGrouping:false})) && elevation >= parseFloat( Number(panel.BottomElevation).toLocaleString('fullwide', {useGrouping:false})));
             
                let particularPanel = designModelData.Panel.find(panel => panel.ID == panelProperty.PanelID) 
                let section = designModelData.Sections.Section.find(section => section.ID == particularPanel.ID) 
                path["topTipDiameter"] = ((parseFloat(section.Radius)*2*0.0254)).toString()
                path["bottomTipDiameter"] = ((parseFloat(section.Radius)*2*0.0254)).toString()
                path["topElevation"] = (parseFloat(panelProperty.TopElevation)).toString()
                path["bottomElevation"] = (parseFloat(panelProperty.BottomElevation)).toString()
                if (mountType == "Platform")  path["location"] = "FaceACentroid";
            }
            else if (towerDetails.towerType && (towerDetails.towerType.match("SelfSupported") || towerDetails.towerType.match("Guyed"))) {
                const heightInFeet =AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.IMPERIAL? formState.height:parseFloat(heighti)*3.28084
                let panelData = designModelData.PanelProperties.hasOwnProperty("PanelProperty")?designModelData.PanelProperties.PanelProperty:designModelData.PanelProperties.Panel
                let panelProperty = panelData.find(panel => elevation <= parseFloat(panel.TopElevation) && elevation >= parseFloat(panel.BottomElevation))
                if(platformType.includes("handrail")){
                    panelProperty = panelData.find(panel => (elevation-((heightInFeet)/2)) <= parseFloat(panel.TopElevation) &&  (elevation-((heightInFeet)/2)) >= parseFloat(panel.BottomElevation))
               }
                let particularPanel = designModelData.Panel.find(panel => panel.ID == panelProperty.PanelID)
                if (mountType == "Platform") {
                   path["location"] = "FaceACentroid";
                    particularPanel.Legs.Leg.map(refLine => {
                        let startNode = designModelData.Nodes.Node.find(node => refLine.PhysicalMember.StartNodeID == node.ID)
                        let endNodeId = designModelData.Nodes.Node.find(node => refLine.PhysicalMember.EndNodeID == node.ID)
                        path[`legRefLine${refLine.Name}`] = `${startNode.X},${startNode.Y},${startNode.Z},${endNodeId.X},${endNodeId.Y},${endNodeId.Z}`

                    })
                } else {
                    if (location.includes("Leg")) {
                        particularPanel.Legs.Leg.map(refLine => {
                            if (`Leg${refLine.Name}` == location) {
                                let startNode = designModelData.Nodes.Node.find(node => refLine.PhysicalMember.StartNodeID == node.ID)
                                let endNodeId = designModelData.Nodes.Node.find(node => refLine.PhysicalMember.EndNodeID == node.ID)
                                path[`legRefLineA`] = `${startNode.X},${startNode.Y},${startNode.Z},${endNodeId.X},${endNodeId.Y},${endNodeId.Z}`
                            }
                        })
                    } else {
                        let { firstFace, secondFace } = getFaceValues()
                        particularPanel.Legs.Leg.map(refLine => {
                            if (`Face${refLine.Name}` == `Face${firstFace}`) {
                                let startNode = designModelData.Nodes.Node.find(node => refLine.PhysicalMember.StartNodeID == node.ID)
                                let endNodeId = designModelData.Nodes.Node.find(node => refLine.PhysicalMember.EndNodeID == node.ID)
                                path[`legRefLineA`] = `${startNode.X},${startNode.Y},${startNode.Z},${endNodeId.X},${endNodeId.Y},${endNodeId.Z}`
                            } else if (`Face${secondFace}` == `Face${refLine.Name}`) {
                                let startNode = designModelData.Nodes.Node.find(node => refLine.PhysicalMember.StartNodeID == node.ID)
                                let endNodeId = designModelData.Nodes.Node.find(node => refLine.PhysicalMember.EndNodeID == node.ID)
                                path[`legRefLineB`] = `${startNode.X},${startNode.Y},${startNode.Z},${endNodeId.X},${endNodeId.Y},${endNodeId.Z}`

                            }
                        })
                    }
                }
            }
            let radStatus = {
                status : true,
                typeOfWorkflow: props.type == "edit"?"Edition":"Addition"
            }
                let data = await DatabaseApiClient.mountImodelModify(variable, props.projectId)
                if (data && data.httpStatusCode == 202) {
                    IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.None, `${props.type == 'edit' ? 'Edit' : 'New'} Mount Process has been started`, "", OutputMessageType.Toast));
                    closingOps()
                store.dispatch(setRadLevelWorkflow(radStatus))
                }
                else if (data && data.httpStatusCode == 400) {
                    setSaveBtnClicked(false)
                    IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Error, data.statusMessage, "", OutputMessageType.Toast));
                }
        }
        else {
            IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Error, "Elevation cannot be greater than Tower Height", "", OutputMessageType.Toast));
            setSaveBtnClicked(false)
        }
    }

    //--------------------
    const checkDisabled = (name) => {
        let check = false
        if(name != "location" && name!="elevation" && mountType == "None") check = true;
        if ((name == "height" ) && mountType == "Platform"  && (platformType == "3-sided" || platformType == "4-sided")) check = true
        if ((name == "azimuth") && mountType == "Platform") check = true
        return check
    }
    //--------------------
    const handleSaveDisabled = () => {
        let check = false;
        let copyFormState:any = {...formState}
        if(saveBtnClicked)return true
        if (props.type == "edit") check = Object.keys(editData).length > 0 ? false : true
        if (selectedMountManufacturer == "--Select--" && mountType!="None") check = true
        if (selectedMountModel == "--Select--"&& mountType!="None") check = true
        if (mountType == "Platform" && platformType == "--Select--") check = true
        if (mountType != "Platform" && location == "--Select--") check = true
        if (copyFormState.height === "") check = true
        if (copyFormState.width === "") check = true
        return check
    }
    //--------------------
    const getUnits = (name) => {
        let units = ""
        if (AppSettings.defaultDisplaySettings.unitSystem  == unitSystemType.IMPERIAL) {
            if (name == "elevation") units = "ft"
            if (name == "height") units = "ft"
            if (name == "width") units = "ft"
            if (name == "weight") units = "lb"
            if(name == "standoff")units = "ft"
        } else if (AppSettings.defaultDisplaySettings.unitSystem  ==  unitSystemType.METRIC) {
            if (name == "elevation") units = "m"
            if (name == "height") units = "m"
            if (name == "width") units = "m"
            if (name == "weight") units = "kg"
            if(name == "standoff")units = "m"
        }
        return units
    }
    //--------------------
    const handlePlatformTypeJsx = () => {

        let platFormType = ["--Select--","3-sided", "3-sided with handrail", "Circular", "Circular with handrail"]
        let selfSupportedList = ["--Select--",`${ towerDetails.noOfLegs}-sided`, `${towerDetails.noOfLegs}-sided with handrail`, "Circular", "Circular with handrail"]
        let monopoleList = ["--Select--","3-sided", "3-sided with handrail", "Circular", "Circular with handrail"]
        if(towerDetails.towerType == "SelfSupported"||towerDetails.towerType == "Guyed")platFormType = selfSupportedList
        else if(towerDetails.towerType == "Monopole")platFormType = monopoleList
        // if(designModelData)platFormType = ["--Select--","3-sided","3-sided with handrail","4-sided","4-sided with handrail","Circular","Circular with handrail"]
   
        return <div style={{ display: 'flex', justifyContent: "space-between" }}>
            <div className="dialogBox-heading-coloun" >

                <div className="dialogBox-dropdown-heading" >Platform Type  </div>
                <span > : </span>
            </div>
            <select id="platform-type-dropdown" className="dialogBox-dropdown" value={platformType}  onChange={handlePlatformType}>
                {
                    platFormType.map((x: any, i: any) => <option key={i} value={x}>{x}</option>)
                }
            </select>
        </div>
    }
    //--------------------
    const handleLocationJsx = () => {
        let allLocation = ["--Select--", "FaceA", "FaceB", "FaceC"]
        if (towerDetails.towerType == "Monopole") allLocation = ["--Select--","FaceA", "FaceB", "FaceC"]
        else {
            allLocation = towerDetails.noOfLegs == 3 ? [...allLocation, "LegA", "LegB", "LegC"] : [...allLocation, "FaceD", "LegA", "LegB", "LegC", "LegD"]
        }
        return <div style={{ display: 'flex', justifyContent: "space-between" }}>
            <div className="dialogBox-heading-coloun">

                <div className="dialogBox-dropdown-heading" >Location  </div>
                <span > : </span>
            </div>
            <select id="location-type-dropdown" className="dialogBox-dropdown" value={location} onChange={handleLocation}>
                {
                    allLocation.map((x: any, i: any) => <option key={i} value={x}>{x}</option>)
                }
            </select>
        </div>
    }
    //--------------------
    const mountTypeJsx = () => {
        return <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="dialogBox-heading-coloun">

                <div className="dialogBox-dropdown-heading" >
                    Mount Type
                </div>
                <span >:</span>
            </div>
            <select id="mount-type-dropdown" className="dialogBox-dropdown" value={mountType} onChange={handleMountType}>//Edit  : the id is the same as for the dropdown list above , rectify older.
                <option value="Platform"> Platform </option>
                <option value="SectorFrame">Sector Frame</option>
                <option value="T-arm"> T-Arm </option>
                <option value="standOff"> StandOff </option>
                <option value="Pipe"> Pipe Mount </option>
                <option value="None"> None </option>
            </select>
        </div>
    }
    //--------------------
    const updateMountType = () => {
        if (mountType == "T-Arm") {
            let e = document.getElementById('mount-type-dropdown') as HTMLSelectElement;
            if (e && e.options != undefined) {
                //e.selectedIndex = 2;
                e.options[0].text = 'T-Arm';
                e.options[0].value = 'T-Arm';
                e.options[1].text = 'Platform';
                e.options[1].value = 'Platform';
                e.options[2].text = 'Sector Frame';
                e.options[2].value = 'Sector Frame';
            }
            
            var nodes3 = document.getElementById("dialogBox-container-Height")?.getElementsByTagName('*') as HTMLSelectElement;
            if (nodes3) {
                for (var i = 0; i < nodes3.length; i++) {
                    nodes3[i].disabled = true;
                }
            }
        }
    }

    //--------------------
    const inputJsx = (title, value, type, name) => {
        let max = 1000;
        let units = ""
        if (name == "elevation") {
            max = AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.METRIC ? parseFloat(towerDetails.towerHeight) * 0.3048 : parseFloat(towerDetails.towerHeight)
        }
        units = getUnits(name)
        let idt = "dialogBox-container-" + title;
        return <>
            <div style={{ display: "flex", justifyContent: "space-between" }} id={idt}>
                <div className="dialogBox-heading-coloun">
                    <div className="dialogBox-dropdown-heading">
                        {title} {units != "" ? ` (${units})` : ""}
                    </div>
                    <span >:</span>
                </div>
                <input className="dialogBox-input"  name={name} type={type} value={value} min={0} max={max} onChange={handleFormState} disabled={checkDisabled(name) || name.match(/height|width|weight/i)} />
            </div>
        </>
    }
    //--------------------
    return (
        <>
            <div id="mount-dialog-box-container">

                {showModal && <ModalDialog height={390} width={700} title={`Level Details`} Y={130} onClose={() => closingOps()} contentClassName={"RadDialogContent"}>
                    <div style={{ display: 'flex' }}>
                        <div style={{ width: '50%' }}>
                            <>
                                {inputJsx("Elevation", formState.elevation, "number", "elevation")}
                                {mountManufacturerJsx()}
                                {mountType && mountType == "Platform" && handlePlatformTypeJsx()}
                                {mountType && mountType != "Platform" && handleLocationJsx()}
                                {inputJsx(widthname, formState.width, "number", "width")}
                                {inputJsx("Azimuth (°)", formState.azimuth, "number", "azimuth")}
                                {mountType && mountType == "T-Arm" && updateMountType()}
                            </>
                        </div>

                        <div style={{ width: '50%' }}>
                            {mountTypeJsx()}
                            {mountModelNameJsx()}
                            {inputJsx("Height", formState.height, "number", "height")}
                            {inputJsx("StandOff", formState.standoff, "number", "standoff")}
                            {inputJsx("Weight", formState.weight, "number", "weight")}
                        </div>
                    </div>
                    <hr/>
                    <div style={{ display: 'flex', justifyContent: "flex-end",marginTop:'10px' }}>
                        <button className="dialogBox-save-btn" onClick={handleSaveBtn} disabled={handleSaveDisabled()}>Save</button>
                        <button className="dialogBox-cancel-btn" onClick={closingOps}>Cancel</button>
                    </div>
                </ModalDialog>}
            </div>
        </>
    )
    //--------------------
}


const mapStateToProps = (state: RootState) => {
    const retVal = {
        openToweriQUrl: ConfigManager.openToweriQUrl,
        projectId: ConfigManager.projectId,
        accessToken: state.auth.accessTokenStatePrivateAPI.accessToken!,
    }
    return retVal;
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(AddMountElevationPopup)