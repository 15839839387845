import { Id64String } from "@itwin/core-bentley";
import { Box, Point3d,Vector3d } from "@itwin/core-geometry";
import { ColorDef } from "@itwin/core-common";
import { BeButtonEvent, CoordSystem, DecorateContext, Decorator, EditManipulator, GraphicType, HitDetail, IModelApp, NotifyMessageDetails, OutputMessagePriority, OutputMessageType, ScreenViewport, ViewClipTool } from "@itwin/core-frontend";
import EquipmentClient from "../../../api/equipment";
import { ModifyTool } from "./ModifyTool";
import { EquipmentsTable } from "../../Tables/EquipmentsTable"; // "../../components/Tables/EquipmentsTable";
import { UiFramework } from "@itwin/appui-react";
import { store } from "../../../../store/rootReducer";
import { DecoratorHelper } from "../../../tools/decorators/DecoratorHelper";
import { EquipmentPosition } from "../../../tools/decorators/EditableShapeDecorator";
import { MountDecorator } from "../../../tools/decorators/MountDecorator";
import { TowerStructureDecorator } from "../../../tools/decorators/TowerStructureDecorator";
import { ShapeDecorator } from "../../../tools/decorators/ShapeDecorator";
import { fetchEquipmentData } from "../../../tools/GetDetectedObjectsData";
import { resetObjectIds } from "../../HorizontalToolbarItems";

export class ControlArrow {
    public origin: Point3d;
    public direction: Vector3d;
    public sizeInches: number;
    public fill?: ColorDef;
    public outline?: ColorDef;
    public name?: string;
    public floatingOrigin?: Point3d;

    public constructor(origin: Point3d, direction: Vector3d, sizeInches: number, fill?: ColorDef, outline?: ColorDef, name?: string) {
        this.origin = origin;
        this.direction = direction;
        this.sizeInches = sizeInches;
        this.fill = fill;
        this.outline = outline;
        this.name = name;
    }
}

/** Controls to modify a box */
// tslint:disable:naming-convention
export class ModifyHandleDecoration extends EditManipulator.HandleProvider {
    public static _decorator?: ModifyHandleDecoration;
    public _shapeDecorator?: ShapeDecorator;
    public _shapeId?: string;
    public _shape?: any;
    public _controlIds: string[] = [];
    public _controls: ControlArrow[] = [];
    public _suspendDecorator = false;
    public _shapeName = "";
    public _shapeIndex: number = 0;
    protected _removeViewCloseListener?: () => void;
    public drawBox = true;
    public _hasEditedBox: boolean = false;
    public constructor(protected _view: ScreenViewport, id: Id64String, name: string, shapeIndex: number, shapeDecorator: ShapeDecorator) {
        super(_view.iModel);
        this._shapeId = id;
        this.updateDecorationListener(true);

        this._shapeIndex = shapeIndex;
        this._shapeName = name;
        this._shapeDecorator = shapeDecorator;
        this._shape = shapeDecorator.shapes[shapeIndex].modelData?.geometryEntry!.geometry as Box;
        this.iModel.selectionSet.replace(this._shapeId);
    }

    public getControlIndex(id: string): number { return this._controlIds.indexOf(id); }

    /** Sub-classes should override to display the pickable graphics for their controls. */
    public override decorate(context: DecorateContext): void {
        const vp = context.viewport;
        // if (!this._isActive) {
        //     return;
        // }

        const outlineColor = EditManipulator.HandleUtils.adjustForBackgroundColor(ColorDef.from(0, 0, 0, 50), vp);
        const fillVisColor = EditManipulator.HandleUtils.adjustForBackgroundColor(ColorDef.from(150, 250, 200, 175), vp);
        const fillHidColor = fillVisColor.withAlpha(225);
        const fillSelColor = fillVisColor.inverse().withAlpha(75);
        const shapePts = EditManipulator.HandleUtils.getArrowShape(0.0, 0.15, 0.55, 1.0, 0.3, 0.5, 0.1);

        if (!this._shape || !this._shapeDecorator) return;

        for (let iFace = 0; iFace < this._controlIds.length; iFace++) {
            const sizeInches = this._controls[iFace].sizeInches;
            if (0.0 === sizeInches)
                continue;

            // EditManipulator.HandleUtils.isPointVisible(this._controls[iFace].floatingOrigin!, vp, 0.1)
            if (undefined !== this._controls[iFace].floatingOrigin &&
                vp.isPointVisibleXY(this._controls[iFace].floatingOrigin!, CoordSystem.View, 0.1)) {
                this._controls[iFace].origin.setFrom(this._controls[iFace].floatingOrigin);
                this._controls[iFace].floatingOrigin = undefined;
            }

            const anchorRay = ViewClipTool.getClipRayTransformed(this._controls[iFace].origin, this._controls[iFace].direction);
            const transform = EditManipulator.HandleUtils.getArrowTransform(vp, anchorRay.origin, anchorRay.direction, sizeInches);

            if (undefined === transform)
                continue;

            // deep copy because we're using a builder transform w/addLineString...
            const visPts: Point3d[] = []; for (const pt of shapePts) visPts.push(pt.clone());
            const hidPts: Point3d[] = []; for (const pt of shapePts) hidPts.push(pt.clone());

            const arrowVisBuilder = context.createGraphicBuilder(GraphicType.WorldOverlay, transform, this._controlIds[iFace]);
            const arrowHidBuilder = context.createGraphicBuilder(GraphicType.WorldDecoration, transform);
            const isSelected = this.iModel.selectionSet.has(this._controlIds[iFace]);

            let outlineColorOvr = this._controls[iFace].outline;
            if (undefined !== outlineColorOvr) {
                outlineColorOvr = EditManipulator.HandleUtils.adjustForBackgroundColor(outlineColorOvr, vp);
                outlineColorOvr = outlineColorOvr.withAlpha(outlineColor.getAlpha());
            } else {
                outlineColorOvr = outlineColor;
            }

            let fillVisColorOvr = this._controls[iFace].fill;
            let fillHidColorOvr = fillHidColor;
            let fillSelColorOvr = fillSelColor;
            if (undefined !== fillVisColorOvr) {
                fillVisColorOvr = EditManipulator.HandleUtils.adjustForBackgroundColor(fillVisColorOvr, vp);
                fillVisColorOvr = fillVisColorOvr.withAlpha(fillVisColor.getAlpha());
                fillHidColorOvr = fillVisColorOvr.withAlpha(fillHidColor.getAlpha());
                fillSelColorOvr = fillVisColorOvr.inverse().withAlpha(fillSelColor.getAlpha());
            } else {
                fillVisColorOvr = fillVisColor;
            }

            arrowVisBuilder.setSymbology(outlineColorOvr, outlineColorOvr, isSelected ? 4 : 2);
            arrowVisBuilder.addLineString(visPts);
            arrowVisBuilder.setBlankingFill(isSelected ? fillSelColorOvr : fillVisColorOvr);
            arrowVisBuilder.addShape(visPts);
            context.addDecorationFromBuilder(arrowVisBuilder);

            arrowHidBuilder.setSymbology(fillHidColorOvr, fillHidColorOvr, 1);
            arrowHidBuilder.addShape(hidPts);
            context.addDecorationFromBuilder(arrowHidBuilder);
        }
    }

    protected override clearControls(): void {
        this.iModel.selectionSet.remove(this._controlIds); // Remove any selected controls as they won't continue to be displayed...
        super.clearControls();
    }

    /** The provider is responsible for checking if modification by controls is valid.
     * May still wish to present controls for "transient" geometry in non-read/write applications, etc.
     */
    protected async createControls(): Promise<boolean> {
        // if (store.getState().detectedData.selectedObjectInformation.objectProperties.selectedObjectNST.name.match(/Micro_Wave/i)) return false;
        if (ShapeDecorator.selectedEquipment.displayName.match(/Micro_Wave|SQUID/i)) return false;
        // if (SampleToolWidget.selectedBoxName?.match(/Micro_Wave/i)) return false;
        this.createClipShapeControls();

        return true;
    }

    public createClipShapeControls(): boolean {
        if (undefined === this._shapeDecorator)
        return false;
        this._isActive=true;
        let shapeIndex = this._shapeDecorator.shapes.findIndex((shape) => shape.modelData!.DisplayName === this._shapeName);
        if(shapeIndex == -1)return false;
        this._shape = this._shapeDecorator.shapes[shapeIndex].modelData?.geometryEntry?.geometry as Box;
        if (undefined === this._shape) {
            return false;
        }

        // Calculate rotation matrix and transform
      // todo: Madhav Please check why it's coming undefined when new cylinder is placed
        if (typeof this._shape.getBaseOrigin !== "undefined") {

        // Rotate clone to be axis aligned
        const newShape = this._shape.clone();
        const baseOrigin = newShape.getBaseOrigin();
        const topOrigin = newShape.getTopOrigin();
        const baseX = newShape.getBaseX(); // width
        const baseY = newShape.getBaseY(); // thickness
        const xVector = newShape.getVectorX();
        xVector.normalizeInPlace();
        const yVector = newShape.getVectorY();
        yVector.normalizeInPlace();
        const zVector = newShape.getVectorZ();
        zVector.normalizeInPlace();
        const height = topOrigin.distance(baseOrigin);
        const numControls = 6;
        const arrowLocations: Point3d[] = new Array(numControls); // 0 = Left, 1 = Right, 2 = Front, 3 = Back, 4 = Bottom, 5 = Top
        const arrowDirections: Vector3d[] = new Array(numControls);
        this.ensureNumControls(numControls);

        arrowLocations[0] = baseOrigin.plusScaled (zVector, 0.5 * height);
        arrowLocations[0] = arrowLocations[0].plusScaled (yVector, 0.5 * baseY);
        arrowDirections[0] = xVector.negate();
        this._controls[0] = new ControlArrow(arrowLocations[0], arrowDirections[0], 0.75);

        arrowLocations[1] = arrowLocations[0].clone();
        arrowLocations[1] = arrowLocations[1].plusScaled (xVector, baseX);
        arrowDirections[1] = xVector;
        this._controls[1] = new ControlArrow(arrowLocations[1], arrowDirections[1], 0.75);

        arrowLocations[2] = baseOrigin.plusScaled (zVector, 0.5 * height);
        arrowLocations[2] = arrowLocations[2].plusScaled (xVector, 0.5 * baseX);
        arrowDirections[2] = yVector.negate();
        this._controls[2] = new ControlArrow(arrowLocations[2], arrowDirections[2], 0.75);

        arrowLocations[3] = arrowLocations[2].clone();
        arrowLocations[3] = arrowLocations[3].plusScaled (yVector, baseY);
        arrowDirections[3] = yVector;
        this._controls[3] = new ControlArrow(arrowLocations[3], arrowDirections[3], 0.75);

        const zFillColor = ColorDef.from(150, 150, 250);
        arrowLocations[4] = topOrigin.plusScaled (xVector, 0.5 * baseX);
        arrowLocations[4] = arrowLocations[4].plusScaled (yVector, 0.5 * baseY);
        arrowDirections[4] = zVector;
        this._controls[4] = new ControlArrow(arrowLocations[4], arrowDirections[4], 0.75, zFillColor, undefined, "zLow");

        arrowLocations[5] = arrowLocations[4].clone();
        arrowLocations[5] = arrowLocations[5].plusScaled (zVector, -height);
        arrowDirections[5] = zVector.negate();
        this._controls[5] = new ControlArrow(arrowLocations[5], arrowDirections[5], 0.75, zFillColor, undefined, "zHigh");

        return true;
      }
        return false;
    }

    private ensureNumControls(numReqControls: number): void {
        const numCurrent = this._controlIds.length;
        if (numCurrent < numReqControls) {
            const transientIds = this.iModel.transientIds;
            for (let i: number = numCurrent; i < numReqControls; i++)
                this._controlIds[i] = transientIds.next;
        } else if (numCurrent > numReqControls) {
            this._controlIds.length = numReqControls;
        }
    }

    /**
     * @param _hit A provider can install an[[InputCollector]] to support interactive modification.
     * @return true if a tool was successfully run.
     * @see[[EditManipulator.HandleTool]]
     * @param _ev Button Event
     * @returns Boolean
     */
    protected modifyControls(_hit: HitDetail, _ev: BeButtonEvent): Promise<boolean> {
        if (!this._shape || !this._shapeDecorator?.currJson) {
            return Promise.resolve(false);
        }

        if (_hit.sourceId === this._shapeId) {
            return Promise.resolve(false);
        }

        this._shapeIndex = this._shapeDecorator.shapes.findIndex((shape) => shape.modelData!.DisplayName === this._shapeName);
        this._shape = this._shapeDecorator.shapes[this._shapeIndex].modelData?.geometryEntry?.geometry as Box;

        const boxObj = this._shapeDecorator.shapes[this._shapeIndex].modelData;
        const currJson = this._shapeDecorator?.shapes.find((i) => i.modelData!.DisplayName === boxObj?.DisplayName);
        const modifyTool = new ModifyTool(this, this._shape, this._shapeIndex, currJson!, this._view, _hit.sourceId, this._controlIds, this._controls);

        const ranSuccessfully = modifyTool.run();
        this._shapeIndex = this._shapeDecorator.shapes.findIndex((shape) => shape.modelData!.DisplayName === this._shapeName);
        this._shape = this._shapeDecorator.shapes[this._shapeIndex].modelData?.geometryEntry?.geometry;

        return ranSuccessfully;
    }

    public testDecorationHit(id: string): boolean { return (id === this._shapeId || this._controlIds.includes(id)); }

    public async getDecorationToolTip(_hit: HitDetail): Promise<HTMLElement | string> {
        return "Bounding box modification arrow";
    }

    public static create(vp: ScreenViewport, id: Id64String, name: string, boxIndex: number, shapeDecorator: ShapeDecorator): string | undefined {
        if (undefined !== ModifyHandleDecoration._decorator)ModifyHandleDecoration.clear();
        
        if(name.includes('face')){
            const retVal = shapeDecorator.faceToEquipSelect(name);
            name = retVal.name;
            id = retVal.id;
        }
    
        if (ShapeDecorator.selectedEquipment.displayName.match(/Micro_Wave|SQUID/i)) return undefined;
        // if (SampleToolWidget.selectedBoxName?.match(/Micro_Wave/i)) return undefined;
        ModifyHandleDecoration._decorator = new ModifyHandleDecoration(vp, id, name, boxIndex, shapeDecorator as ShapeDecorator);
        // this._isActive = true;
        return ModifyHandleDecoration._decorator._shapeId;
    }

    public static clear(): void {
        //Dropping decorator as multiple instances of MHD are being created. We can control creating the multiple instance of MHD creation and then remove this drop decorator code.: Praful
        const mhd = IModelApp.viewManager.decorators.filter(e=>e.constructor.name.includes("ModifyHandleDecoration"))[0];
        if(mhd)IModelApp.viewManager.dropDecorator(mhd as Decorator);
        const decorator = ModifyHandleDecoration._decorator;
        if (undefined === decorator)
        return;

        // Send changes to API
        // const name = decorator._shapeName;
        // const posData = decorator._shapeDecorator?.shapes.find((I) => I.modelData!.DisplayName === name)?.modelData;
        // const modified = decorator._hasEditedBox;
        // ModifyHandleDecoration.SaveData(posData, modified, name).then((res) => {
        //     decorator!._hasEditedBox = !res;
        // });

        decorator.stop();
        ModifyHandleDecoration._decorator = undefined;
    }

    public static async SaveTower(data: any, modVals) {
        const towDec=IModelApp.viewManager.decorators.filter(e=>e.constructor.name.includes("TowerStructureDecorator"))[0] as TowerStructureDecorator;        
        towDec.saveTower(data, modVals);
    }

    public static async SaveMount(): Promise<boolean> {
        const mountDec=IModelApp.viewManager.decorators.filter(e=>e.constructor.name.includes("MountDecorator"))[0] as MountDecorator;        
        const tokenString = store.getState().auth.accessTokenStatePrivateAPI.accessToken!;
        const selectedMount = mountDec.selectedMount;
        const iModel = UiFramework.getIModelConnection()!;
        // const stPtCart = iModel!.spatialToCartographicFromEcef(selectedMount.modelData.startPoint);
        const stPtCart = iModel!.spatialToCartographicFromEcef(selectedMount.startPos);
        const stPtUtm = DecoratorHelper.convertWGS2UTM(stPtCart);
        // const endPtCart = iModel!.spatialToCartographicFromEcef(selectedMount.modelData.endPoint);
        const endPtCart = iModel!.spatialToCartographicFromEcef(selectedMount.endPos);
        const endPtUtm = DecoratorHelper.convertWGS2UTM(endPtCart);

        const type = selectedMount.modelData.orientation === "Horizontal" ? "horizont_pipe" : "vertical_pipe";
  
        const updateData = {
          "mountId": selectedMount.modelData.parentMount,
          "faceId": selectedMount.modelData.mountFace,
          "mountPipeType": type,
          "mountType": selectedMount.modelData.parentMountType,
          "mountPipeId": selectedMount.modelData.name,
          "utm": [
              [stPtUtm[0], stPtUtm[1], stPtCart.height],
              [endPtUtm[0], endPtUtm[1], endPtCart.height]
          ]
        }
        /** 
         * Task 1385568: Disable the Mount Edit workflows temporarily until the mount detection logic is fixed.
        mountClient.putMountJson(tokenString, updateData).then(e=>{
            if(e==="Success."){
                IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Info, "Mount pipe saved successfully.", "", OutputMessageType.Toast));
            } else {
                IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Error,`Error occured: Mount edit changes not saved.`, "", OutputMessageType.Toast)); 
            }
        });
        */
        // SampleToolWidget.isModifiedData = false;
        return true;
    }

    public static async SaveData(posData: EquipmentPosition | undefined, modified: boolean, _name: string): Promise<boolean> {
        let flag: boolean = false;
        if (posData && modified) {
            const equipData={...EquipmentsTable.equipmentData.filter((e)=>{return e.name === posData.Equipment_Name})[0]};
            const theIndex=EquipmentsTable.equipmentData.findIndex((e)=>{return e.name === posData.Equipment_Name});
            const currentState = store.getState();
            const putData: any = {                                                                
                name: posData.Equipment_Name,
                displayName: equipData.displayName,
                manufacturer: posData.Manufacturer,
                model: posData.Model,
                type: equipData.type,
                isActive: posData.Active,
                operator: equipData.operator,
                height: posData.Height,
                elevationHeight: 0,
                width: posData.Width,
                depth: posData.Thicness,
                weight: equipData.weight,
                azimuth: posData.Azimuth,
                tilt: posData.Tilt,
                xPosition: posData.x_position,
                yPosition: posData.y_position,
                zPosition: posData.z_position,
                roll: posData.Roll,
                area: equipData.area,
                bandTechnology: equipData.bandTechnology,
                reference: equipData.reference,
                userDriven: posData.UserDriven,
                face: posData.Face,
            };
            putData.elevationHeight =  posData.Elevation_Height == undefined ? (posData.z_position-currentState.detectedData.siteCoordinate.utm.z): posData.Elevation_Height;
            const infoData = currentState.detectedData.selectedObjectInformation.objectProperties;
            if (infoData && posData && currentState.detectedData.siteCoordinate) { //  && modified
                await EquipmentClient.putEquipmentJson(currentState.auth.accessTokenStatePrivateAPI.accessToken!, putData, "v1.1")
                // tslint:disable-next-line:no-shadowed-variable
                .then(async (res) => {
                    if (!res) {
                        IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Error,
                            `Error occured: ${equipData.displayName} edit changes not saved.`, "", OutputMessageType.Toast));
                    } else {
                        const shapeDec=IModelApp.viewManager.decorators.filter(e=>e.constructor.name.includes("ShapeDecorator"))[0] as ShapeDecorator;        
                        const theData = await fetchEquipmentData(currentState.auth.accessTokenStatePrivateAPI.accessToken!);
                        shapeDec.loadShapes(theData.validEquipData);
                        const iModelConnection = UiFramework.getIModelConnection();
                        resetObjectIds(shapeDec);
                        const theShape = shapeDec.shapes.find(e=>e.modelData?.DisplayName==equipData.displayName);
                        ShapeDecorator.selectedEquipment.transId = theShape?.transientId!;
                        ShapeDecorator.selectedEquipment.displayName = theShape?.modelData?.DisplayName!;
                        // ShapeDecorator.selectedEquipment.displayName = theShape?.modelData?.DisplayName;
                        iModelConnection?.selectionSet.emptyAll();
                        iModelConnection?.selectionSet.add(theShape?.transientId as Id64String);
                        ShapeDecorator.selectedEquipment.isModified = false;
                        ShapeDecorator.selectedEquipment.created = false;
                        ShapeDecorator.selectedEquipment.creating = false;
                                        // flag = true;
                        // equipData.name=posData.Equipment_Name;
                        // equipData.manufacturer=posData.Manufacturer;
                        // equipData.model=posData.Model;
                        // equipData.faceName=posData.Face;
                        // equipData.height=posData.Height;
                        // equipData.elevationHeight=putData.elevationHeight;
                        // equipData.width=posData.Width;
                        // equipData.depth=posData.Thicness;
                        // equipData.azimuth=posData.Azimuth;
                        // equipData.tilt=posData.Tilt;
                        // equipData.xPosition=posData.x_position;
                        // equipData.yPosition=posData.y_position;
                        // equipData.zPosition=posData.z_position;
                        // equipData.roll=posData.Roll;
                        // equipData.dimensions=`${posData.Height}x${posData.Width}x${posData.Thicness}`;

                        // // (IModelApp as any).listCallback(ListEnum.Equipment);y
                        // EquipmentsTable.equipmentData[theIndex] = equipData;
                        IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Info,
                            `Success: ${equipData.displayName} changes saved.`, "", OutputMessageType.Toast));
                    }
                });
            } else {
                IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Error,
                   `Error occured: ${equipData.displayName} edit changes not saved.`, "", OutputMessageType.Toast));
            }
        }
        return flag;
    }

    public static get(vp: ScreenViewport): ModifyHandleDecoration | undefined {
        if (undefined === ModifyHandleDecoration._decorator || vp !== ModifyHandleDecoration._decorator._view)
            return undefined;
        return ModifyHandleDecoration._decorator;
    }

    // public static toggle(vp: ScreenViewport): string | undefined {
    // }
}
