import { ToolbarItemUtilities } from "@itwin/appui-abstract";
import { RenderMode } from "@itwin/core-common";
import { IModelApp } from "@itwin/core-frontend";
import EquipmentClient from "../api/equipment";
import BracingsClient from "../api/bracingsClient";
import { HighlightGratingTool } from "../components/tools/HighlightGratingTool";
import { SyncUiEventDispatcher } from "@itwin/appui-react";
import { AddEditPolygonShapeTool } from "../components/tools/AddEditPolygonShapeTool";
import { DTVActions } from "../../store/Actions";
import { RootState } from "../../store/States";
import { setSiteCoordinate, setTowerData, setBracingsData, setSelectedObjectStructure } from "../../store/detectedData/apiDataActionTypes";
import { SyncUiEventIds } from "../../store/redux-types";
import { store } from "../../store/rootReducer";
import { PolygonShapeDecorator } from "./decorators/PolygonShapeDecorator";
import { ObjectCategoryTypes, initialPropertyListObjectState } from "../../store/detectedData/apiDataTypes";
import { DigitalTwinViewerApp } from "../../api/DigitalTwinViewerApp";

function select(state: RootState, dataKey: string) {
    return state.detectedData[dataKey];
}
  
export const HighlightGratings = () => {

    return ToolbarItemUtilities.createActionButton(
        "Highlight-gratings", 
        110, 
        "icon-crop", 
        "Highlight Gratings",
        async () =>await executeHighlightGrating()
    );






    // async () => {
    //     // TrackTelemetryApiClient.trackTelemetry("TOWER_ITWIN_HIGHLIGHT_EQUIPMENT");
    //     // if (!this._isInsiteSupported) {
    //     //   this.showNotSupportedNotification();
    //     //   return;
    //     // }
    //     // this.siteCoord;
    //     if (!siteCoordinates) {
    //         const siteCoord = await EquipmentClient.setSiteCoordinates(store.getState().auth.accessTokenStatePrivateAPI.accessToken /*accessTokenPrivate is a string now*/);     // Storing site coordinate, for if equipments are edited & require update relative to site <coordinates className="" />
    //         store.dispatch(setSiteCoordinate(siteCoord));
    //     }
    //     if (!SampleToolWidget.equipNamePositionMap || SampleToolWidget.equipNamePositionMap.size === 0) {
    //         IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Error,`No Equipment detected in this tower.`, "", OutputMessageType.Toast));
    //         return;
    //     }
    //     let towerStructure = select(store.getState(), "towerStructureData");
        
    //     if (!towerStructure) {
    //         towerStructure = await EquipmentClient.getTowerStructureJson(store.getState().auth.accessTokenStatePrivateAPI.accessToken /*accessTokenPrivate is a string now*/);   // Storing tower structure, for if equipments are edited they can be validated with the tower elevation values.
    //         store.dispatch(setTowerData(towerStructure));
    //     }

    //     const vp = IModelApp.viewManager.selectedView;
    //     if (vp) {
    //         const viewFlags = vp.viewFlags.copy({renderMode: RenderMode.SmoothShade});
    //         vp.viewFlags = viewFlags;
    //     }

    //     let toolRetVal: boolean = false;

    //     setTimeout(async () => {
    //         toolRetVal = await IModelApp.tools.run(OutlineEquipmentTool.toolId);
    //         // (currTools.filter(e=>e.id == "Select")[0] as ActionButton).execute();   //  Making the select tool enabled after equipment loading for abetter user experience. 
    //         if(toolRetVal){
    //             IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Info, "Equipment are successfully loaded.", "", OutputMessageType.Toast));
    //             // this.setState({operatorFilterData: {...this.state.operatorFilterData, selectedEquipment: ["All"]}});
    //         } else {
    //             // this.setState({operatorFilterData: {...this.state.operatorFilterData, selectedEquipment: []}});
    //         }
    //         // this.setState({highlightStates: {...this.state.highlightStates, equipment: toolRetVal}});
    //         // this._resetOperatorFilterWidget();
    //     }, 0);

    //     // tslint:disable-next-line:tslint-react-set-state-usage
    //     // if (this.state.isVisible) {
    //         //   this.setState({ isVisible: false });
    //         // }
    //         // if (!this.state.disableCloneEquipment) {
    //     //   this.setState({ disableCloneEquipment: true });
    //     // }
    //     // (currTools.filter(e=>e.id == "Select")[0] as ActionButton).execute();   //  Making the select tool enabled after equipment loading for abetter user experience. 
    //     // this.setState({ disableEditEquipment: !this.state.disableEditEquipment });
    // });
};

export const executeHighlightGrating = async () => {
    const siteCoordinates = select(store.getState(), "siteCoordinate");

    // TrackTelemetryApiClient.trackTelemetry("TOWER_ITWIN_HIGHLIGHT_MOUNT");
    // if (!this._isInsiteSupported) {
    //   this.showNotSupportedNotification();
    //   return;
    // }
    AddEditPolygonShapeTool.mountingsData = store.getState().detectedData.mountingsData;
    const currentState: RootState = store.getState();
    
    if(DigitalTwinViewerApp.projectDetails.isInsightAvailable){
        if(currentState.dtvState.applicationState.highlightStates.grating){
            store.dispatch(DTVActions.updateHighlightState({...currentState.dtvState.applicationState.highlightStates, grating: false}));
            if(currentState.detectedData.selectedObjectInformation.objectProperties.objectCategoryType == ObjectCategoryTypes.Grating_RM)store.dispatch(setSelectedObjectStructure(initialPropertyListObjectState));

            executeUnhighlightGrating()
            return;
        } else {
            store.dispatch(DTVActions.updateHighlightState({...currentState.dtvState.applicationState.highlightStates, grating: true}));
            SyncUiEventDispatcher.dispatchSyncUiEvent(SyncUiEventIds.RM_Grating_Highlighted);
        }
    }

    if (!siteCoordinates) {
        const siteCoord = await EquipmentClient.setSiteCoordinates(store.getState().auth.accessTokenStatePrivateAPI.accessToken /*accessTokenPrivate is a string now*/);     // Storing site coordinate, for if equipments are edited & require update relative to site <coordinates className="" />
        store.dispatch(setSiteCoordinate(siteCoord));
    }

    // let mountsData = select(store.getState(), "mountingsData");
    let towerStructure = select(store.getState(), "towerStructureData");
    let towerBracings = select(store.getState(), "towerBracingsData");
    
    if (!towerStructure) {
        towerStructure = await EquipmentClient.getTowerStructureJson(store.getState().auth.accessTokenStatePrivateAPI.accessToken /*accessTokenPrivate is a string now*/);   // Storing tower structure, for if equipments are edited they can be validated with the tower elevation values.
        store.dispatch(setTowerData(towerStructure));
    }

    if (!towerBracings.length) {
        towerBracings = await BracingsClient.getBracingsData(store.getState().auth.accessTokenStatePrivateAPI.accessToken!);
        if(towerBracings)store.dispatch(setBracingsData(towerBracings));
    }
    // if (!this._isInsiteSupported) {
    //     this.showNotSupportedNotification();
    //     return;
    // }
    // if (!SampleToolWidget.siteCoordinates) {
    //     SampleToolWidget.siteCoordinates = await EquipmentClient.setSiteCoordinates(App.accessToken.toTokenString());// Storing site coordinate, for if equipments are edited & require update relative to site <coordinates
    // }
    const vp = IModelApp.viewManager.selectedView;
    if (vp) {
        const viewFlags = vp.viewFlags.copy({renderMode: RenderMode.SmoothShade});
        vp.viewFlags = viewFlags;
    }
    const retVal = await IModelApp.tools.run(HighlightGratingTool.toolId);
    if(retVal){
        let psd = IModelApp.viewManager.decorators.filter(e=>e.constructor.name.includes("PolygonShapeDecorator"))[0] as PolygonShapeDecorator;
        // store.dispatch(addToBuilt3DObjectIdsMap(new Map(psd.objectIdMap)));
    }
    // this.setState({highlightStates: {...this.state.highlightStates, grating: !this.state.highlightStates.grating}});

    // if (bracingsData && tdata) {
    //     if (tdata['nLegs'] >= 3) {//Only enable with sst+
    //         return true
    //     }
    //     else {
    //         return false
    //     }
    // }
    // else {
    //     return false;
    // }
        
}

export const executeUnhighlightGrating = () => {

    SyncUiEventDispatcher.dispatchSyncUiEvent(SyncUiEventIds.RM_Grating_UnHighlighted);
    HighlightGratingTool.terminateTool();
    return;
}
store.subscribe(HighlightGratings);