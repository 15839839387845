import { ActionButton, ConditionalBooleanValue, ConditionalStringValue, ToolbarItemUtilities } from "@itwin/appui-abstract";
import { RenderMode } from "@itwin/core-common";
import { IModelApp, NotifyMessageDetails, OutputMessagePriority, OutputMessageType } from "@itwin/core-frontend";
// import { CustomToolbarProvider } from "../CustomToolbarProvider";
import EquipmentClient from "../api/equipment";
// import { SampleToolWidget } from "../app-ui/frontstages/SampleToolWidget";
import { OutlineEquipmentTool } from "../components/tools/OutlineEquipmentTool";
import { SyncUiEventDispatcher, UiFramework, WidgetState } from "@itwin/appui-react";
import { getEquipment } from "./GetDetectedObjectsData";
import { EquipmentsTable } from "../components/Tables/EquipmentsTable";
import { DTVActions } from "../../store/Actions";
import { RootState } from "../../store/States";
import { setSelectedObjectStructure, setSiteCoordinate, setTowerData } from "../../store/detectedData/apiDataActionTypes";
import { SyncUiEventIds } from "../../store/redux-types";
import { store } from "../../store/rootReducer";
import { EquipmentPosition } from "./decorators/EditableShapeDecorator";
import { ObjectCategoryTypes, initialPropertyListObjectState } from "../../store/detectedData/apiDataTypes";

// export const HighlightEquipment = (props) => {
//   return (
//     <div>HighlightEquipment</div>
//   )
// }

function select(state: RootState, dataKey: string) {
    return state.detectedData[dataKey];
}

export const HighlightEquipment = () => {
    // return ToolbarItemUtilities.createActionButton ({id: "string", itemPriority: 10, icon: "string", label: "string", execute: () => void, overrides?: Partial<ActionButton> | undefined}) => ActionButton;
    return ToolbarItemUtilities.createActionButton(
        "icon-3d", 
        110, 
        "icon-3d", 
        "Highlight Equipment",
        async () => await executeHighlightEquipment(true),
        {
            isActive: true, 
        }
    );

};

export const executeHighlightEquipment = async(showMessage:boolean = true)=> {
    const siteCoordinates = select(store.getState(), "siteCoordinate");

    // TrackTelemetryApiClient.trackTelemetry("TOWER_ITWIN_HIGHLIGHT_EQUIPMENT");
    // if (!this._isInsiteSupported) {
    //   this.showNotSupportedNotification();
    //   return;
    // }
    const currentState = store.getState();
    const highlightStates = currentState.dtvState.applicationState.highlightStates;
    if(currentState.dtvState.applicationState.projectStatus.isInsightAvailable){
        store.dispatch(DTVActions.updateHighlightState({...highlightStates, equipment: !highlightStates.equipment}))
        if(highlightStates.equipment){
            executeUnhighlightEquipment()
        } else SyncUiEventDispatcher.dispatchSyncUiEvent(SyncUiEventIds.RM_Equipment_Highlighted);       
    }            

    // this.siteCoord;
    if (!siteCoordinates) {
        const siteCoord = await EquipmentClient.setSiteCoordinates(currentState.auth.accessTokenStatePrivateAPI.accessToken /*accessTokenPrivate is a string now*/);     // Storing site coordinate, for if equipments are edited & require update relative to site <coordinates className="" />
        store.dispatch(setSiteCoordinate(siteCoord));
    }
    let equipmentDataMaps;//select(currentState, "equipmentDataMaps");
    if (!EquipmentsTable.equipNamePositionMap || (EquipmentsTable.equipNamePositionMap as Map<string, EquipmentPosition>).size === 0) {
        const equipFetchVal = await getEquipment(currentState.auth.accessTokenStatePrivateAPI.accessToken!);
        if(equipFetchVal === false){
            IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Error,`No Equipment detected in this tower.`, "", OutputMessageType.Toast));
            return;
        } else {
            equipmentDataMaps = equipFetchVal;
            if(equipFetchVal){

                EquipmentsTable.equipNameInfoMap = new Map(equipFetchVal.equipNameInfoMap);
                 EquipmentsTable.equipNamePositionMap = new Map(equipFetchVal.equipNamePositionMap);
                 EquipmentsTable.equipmentData = [...equipFetchVal.equipmentData];
            }
        }

    }
    let towerStructure = select(currentState, "towerStructureData");
    
    if (!towerStructure) {
        towerStructure = await EquipmentClient.getTowerStructureJson(currentState.auth.accessTokenStatePrivateAPI.accessToken /*accessTokenPrivate is a string now*/);   // Storing tower structure, for if equipments are edited they can be validated with the tower elevation values.
        store.dispatch(setTowerData(towerStructure));
    }

    const vp = IModelApp.viewManager.selectedView;
    if (vp) {
        const viewFlags = vp.viewFlags.copy({renderMode: RenderMode.SmoothShade});
        vp.viewFlags = viewFlags;
    }

    let toolRetVal: boolean = false;

    setTimeout(async () => {
        toolRetVal = await IModelApp.tools.run(OutlineEquipmentTool.toolId);
        // store.dispatch(setEquipmentData3DMaps(ed.nameIdMap));

        // (currTools.filter(e=>e.id == "Select")[0] as ActionButton).execute();   //  Making the select tool enabled after equipment loading for abetter user experience. 
        if(toolRetVal){
            if(showMessage) IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Info, "Equipment are successfully loaded.", "", OutputMessageType.Toast));
            // this.setState({operatorFilterData: {...this.state.operatorFilterData, selectedEquipment: ["All"]}});
        } else {
            // this.setState({operatorFilterData: {...this.state.operatorFilterData, selectedEquipment: []}});
        }
        // this.setState({highlightStates: {...this.state.highlightStates, equipment: toolRetVal}});
        // this._resetOperatorFilterWidget();
    }, 0);

    // tslint:disable-next-line:tslint-react-set-state-usage
    // if (this.state.isVisible) {
        //   this.setState({ isVisible: false });
        // }
        // if (!this.state.disableCloneEquipment) {
    //   this.setState({ disableCloneEquipment: true });
    // }
    // (currTools.filter(e=>e.id == "Select")[0] as ActionButton).execute();   //  Making the select tool enabled after equipment loading for abetter user experience. 
    // this.setState({ disableEditEquipment: !this.state.disableEditEquipment });
}

export const executeUnhighlightEquipment = () => {
    const currentState = store.getState();
    SyncUiEventDispatcher.dispatchSyncUiEvent(SyncUiEventIds.RM_Equipment_UnHighlighted);
    if(currentState.detectedData.selectedObjectInformation.objectProperties.objectCategoryType == ObjectCategoryTypes.Equipment_RM){
        store.dispatch(setSelectedObjectStructure(initialPropertyListObjectState));
        UiFramework.dialogs.modeless.close("Edit Dialog");
        store.dispatch(DTVActions.setEditModeFlag(false));
    }
}
// const mapStateToProps = (state) => ({})

// const mapDispatchToProps = {}

// export default connect(mapStateToProps, mapDispatchToProps)(HighlightEquipment)

store.subscribe(HighlightEquipment);