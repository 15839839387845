import { IModelApp, NotifyMessageDetails, OutputMessagePriority, OutputMessageType, PrimitiveTool, ScreenViewport, Viewport } from "@itwin/core-frontend";
// import {  } from "@itwin/appui-abstract";
import { StagePanelLocation, UiFramework, WidgetState } from "@itwin/appui-react";
import FeederLineClient from "../../api/feederLinesClient";
import { store } from "../../../store/rootReducer";
import { FeederLineDecorator } from "../../tools/decorators/FeederLineDecorator";
import { addToBuilt3DObjectIdsMap } from "../../../store/detectedData/apiDataActionTypes";

// tslint:disable:naming-convention
export class HighlightFeedlineTool extends PrimitiveTool {
    public static override toolId = "HighlightFeedlineTool";
    public static override iconSpec = "icon-spatial-view-apply";
    public static data2send: any;
    public static newTestJasonData: any;

    public override isCompatibleViewport(_vp: Viewport | undefined, _isSelectedViewChange: boolean): boolean {
        return true;
    }
    //-------------------------------------------------------------------//
    // Help function to log information to the user
    //-------------------------------------------------------------------//
    private logToScreen(TYPE:any, TITLE:string, CONTENT:string=""){
        IModelApp.notifications.outputMessage(new NotifyMessageDetails(TYPE, TITLE, CONTENT, OutputMessageType.Toast));
    }
    //----------------------------------------.---------------------------//
    // Get the data from the API's
    //-------------------------------------------------------------------//
    private async fetchData() {
        //HighlightFeederLinesTool.newTestJasonData = await FeederLineClient.getFeederLineData();//Temporary example data.
       let data = await FeederLineClient.getFeedLineData();//Temporary example data.
        HighlightFeedlineTool.newTestJasonData = data;
    }
    //-------------------------------------------------------------------//
    // Create the pipes to make the Bracings
    //-------------------------------------------------------------------//
    private async startTool() {
        //Fetch data for feedlines
        await this.fetchData();

        //Highlight Feederlines
        let d = new FeederLineDecorator();
        let feederLineDecor = IModelApp.viewManager.decorators.filter(e => e.constructor.name.includes("FeederLineDecorator"))[0] as FeederLineDecorator;
        if (feederLineDecor === undefined) {
            // Create the decorator 
            feederLineDecor = new FeederLineDecorator();
            const retVal = await feederLineDecor.addBoxsForFeederLineInfo(HighlightFeedlineTool.newTestJasonData);

            // add decorator to the view manager
            IModelApp.viewManager.addDecorator(feederLineDecor);
            if(retVal){
                store.dispatch(addToBuilt3DObjectIdsMap(feederLineDecor.objectIdMap))
                IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Info, "Feeder Line data is available and shown.", "", OutputMessageType.Toast));
            } else IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Warning, "FeedLine Data Not Loaded.", "FeedLine data could not be found for this project.", OutputMessageType.Toast));

            // this.logToScreen(OutputMessagePriority.Info, "FeedLines Loaded")
        }
        else {
            feederLineDecor.terminate();
            for (const decor of IModelApp.viewManager.decorators) {
                const n = decor.constructor.name;
                if (n.includes("FeederLineDecorator")) {
                    (decor as FeederLineDecorator).terminate();
                    IModelApp.viewManager.dropDecorator(decor);
                }
            }
        }
    }           
    //------------------------------------------------------------------//

    public override run(isLoaded: boolean): Promise<boolean> {
        super.run();
        const { toolAdmin, viewManager } = IModelApp;
        // viewport not compatible 
        if (!this.isCompatibleViewport(viewManager.selectedView, false) || !toolAdmin.onInstallTool(this)) {
            return Promise.resolve(false);
        }
        //------------------------------------------------------------------//
        // Start a primitive tool and retrieves the viewport
        toolAdmin.startPrimitiveTool(this);
        const vp = IModelApp.viewManager.selectedView as ScreenViewport;
        // SampleToolWidget.setModelIndexValues(vp);
        // check if we load the tool
            this.startTool();
        if (isLoaded == true) {
            // create the bracings
        } 
        else {
            const isBlankConnection: boolean | undefined = UiFramework.getIModelConnection()?.isBlank;
            if (isBlankConnection) {
                UiFramework.frontstages.activeFrontstageDef?.getStagePanelDef(StagePanelLocation.Right)?.
                findWidgetDef("PropertyListWidget")?.setWidgetState(WidgetState.Hidden);
            } 
            else if ((IModelApp as any).listCallback) {
                // (IModelApp as any).listCallback(SampleToolWidget.currentList);
            }
            this.exitTool();
        }
        IModelApp.viewManager.selectedView?.invalidateDecorations();
        return Promise.resolve(true);
    }

    public onRestartTool(): Promise<void> {
        return Promise.resolve();
    }
}
