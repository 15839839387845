import { BeButtonEvent, EventHandled, IModelApp, PrimitiveTool, ScreenViewport, Viewport } from "@itwin/core-frontend";
import { StagePanelLocation, WidgetState, UiFramework } from "@itwin/appui-react";
import { AddBoundingBoxTool } from "./AddBoundingBoxTool";
import { ModifyHandleDecoration } from "./modification/ModifyHandleDecoration";
import { setEquipmentData3DMaps, addToBuilt3DObjectIdsMap } from "../../../store/detectedData/apiDataActionTypes";
import { store } from "../../../store/rootReducer";
import { EquipmentShapeDecorator } from "../../tools/decorators/EquipmentShapeDecorator";

export class OutlineEquipmentTool extends PrimitiveTool {
    public static override toolId = "outlineEquipmentShapes";
    public static override iconSpec = "icon-3d";

    public static dropDecorator: any | undefined;

    public override isCompatibleViewport(_vp: Viewport | undefined, _isSelectedViewChange: boolean): boolean {
        return true;
    }

    public override run(selectedEquipment: string[] = ["All"]): Promise<boolean> {
        super.run()
        const { toolAdmin, viewManager } = IModelApp;
        if (!this.isCompatibleViewport(viewManager.selectedView, false) || !toolAdmin.onInstallTool(this)) {
            return Promise.reject(false);
        }

        toolAdmin.startPrimitiveTool(this);

        const vp = IModelApp.viewManager.selectedView as ScreenViewport;
        // SampleToolWidget.setModelIndexValues(vp);
        if (OutlineEquipmentTool.dropDecorator && (selectedEquipment[0] == "All" || selectedEquipment[0] == "None")) {
            OutlineEquipmentTool.dropDecorator();
            OutlineEquipmentTool.dropDecorator = undefined;
            // SampleToolWidget.currEquipDecorator = undefined;
            ModifyHandleDecoration.clear();
            this.exitTool();
            const isBlankConnection: boolean | undefined = UiFramework.getIModelConnection()?.isBlank;
            // if (MainPage.workFlow === "" && MainPage.isiModelExists && (IModelApp as any).listCallback) {
            //     (IModelApp as any).listCallback(ListEnum.TowerInfo);
            // }
            if (isBlankConnection) {
            //   FrontstageManager.activeFrontstageDef?.getStagePanelDef(StagePanelLocation.Right)?.
            //     findWidgetDef("PropertyListWidget")?.setWidgetState(WidgetState.Hidden);
            } else if ((IModelApp as any).listCallback) {
                // (IModelApp as any).listCallback(SampleToolWidget.currentList);
            }
            // FrontstageManager.activeFrontstageDef?.getZoneDef(ZoneLocation.CenterRight)?.
            //     findWidgetDef("EquipmentRotation")?.setWidgetState(WidgetState.Hidden);
                return Promise.resolve(false);
        } else {
            // const existingEquipDataMaps: equipmentDataMaps = store.getState().detectedData.equipmentDataMaps;
            // let newEquipDataMaps: equipmentDataMaps;
            // const newEquipInfoMap = new Map(existingEquipDataMaps.equipNameInfoMap);
            // const newEquipInfoMaps: equipmentDataMaps = {...existingEquipDataMaps, equipNameInfoMap: newEquipInfoMap};

            let equipDec: any = IModelApp.viewManager.decorators.filter((e) => e.constructor.name.includes("EquipmentShapeDecorator"));
 
            if (!equipDec.length) {
                const decorator = new EquipmentShapeDecorator();
                // SampleToolWidget.currEquipDecorator = decorator;
                decorator.loadShapes();
                // newEquipDataMaps = {...existingEquipDataMaps, tempEquipMap: decorator.currJson};
                // newEquipDataMaps = {...existingEquipDataMaps};
                store.dispatch(setEquipmentData3DMaps(new Map(decorator.nameIdMap)));
                store.dispatch(addToBuilt3DObjectIdsMap(new Map(decorator.objectIdMap)));
                OutlineEquipmentTool.dropDecorator = IModelApp.viewManager.addDecorator(decorator);
            } else { // Add box tool already started equip decorator
                equipDec = equipDec[0] as EquipmentShapeDecorator
                // const decorator = SampleToolWidget.currEquipDecorator;
                OutlineEquipmentTool.dropDecorator = AddBoundingBoxTool.exitEquipDecorator;
                equipDec.loadShapes();
                // newEquipDataMaps = {...existingEquipDataMaps, tempEquipMap: decorator.currJson};
                // newEquipDataMaps = {...existingEquipDataMaps};
                store.dispatch(setEquipmentData3DMaps(new Map(equipDec.nameIdMap)));
                store.dispatch(addToBuilt3DObjectIdsMap(new Map(equipDec.objectIdMap)));
                // IModelApp.viewManager.selectedView?.invalidateCachedDecorations(this);
            }
            // store.dispatch(setEquipmentDataMaps(newEquipInfoMaps));
        }
        IModelApp.viewManager.selectedView?.invalidateDecorations();
        this.exitTool();

        return Promise.resolve(true);
    }
    public override async onResetButtonUp(_ev: BeButtonEvent): Promise<EventHandled> {
        // if (OutlineEquipmentTool.dropDecorator) {
        //     OutlineEquipmentTool.dropDecorator();
        //     OutlineEquipmentTool.dropDecorator = undefined;
        //     SampleToolWidget.currEquipDecorator = undefined;
        // }
        this.exitTool();

        return EventHandled.No;
    }

    public onRestartTool(): Promise<void> {
        return Promise.resolve();
    }
}
