export interface UserRequest {
    source?: UserRequestSource,
    requestData: string,
    requestApp?: UserRequestApp,
    towerId?: string,
    projectId?: string
}

export enum UserRequestSource {
    MountIdentificationPly = "MOUNT_IDENTIFICATION_PLY",
    K8SDetectionWorkflows = "K_8_S_DETECTION_WORKFLOWS"
}

export enum UserRequestApp {
    PORTAL = "PORTAL",
    API = "API"
}