import { Vector3d, YawPitchRollAngles } from "@itwin/core-geometry";
import { StagePanelLocation, UiFramework, WidgetState } from "@itwin/appui-react";
import { ConfigurableCreateInfo, SyncUiEventDispatcher, WidgetControl, WidgetDef } from "@itwin/appui-react";
import { CreateGeometryMode, FeedlineData, FeedlineDecorator, ShapeType } from "../decorators/FeedlineDecorator";
import { ModifyHandleDecoration } from "../../components/tools/modification/ModifyHandleDecoration";
import { IModelApp } from "@itwin/core-frontend";
import { EquipmentRotationType } from "../../tools/DetectedEquipmentEditHandler";
import { store } from "../../../store/rootReducer";
import { EquipmentsTable } from "../../components/Tables/EquipmentsTable";
import { RadioSection } from "../DetectedMountEditHandler";
import { editMode, getUnlinkedFeedline } from "../DetectedFeedlineEditHandler";
import { FeedlineSectionType } from "../../../store/detectedData/apiDataTypes";

export class FeedlineRotationWidget extends WidgetControl {

    // tslint:disable-next-line:naming-convention
    private currDef: WidgetDef | undefined;

    constructor(info: ConfigurableCreateInfo, options: any) {
        super(info, options);
        // this.reactNode = <RotationSliderControl />;

        this.currDef = UiFramework.frontstages.activeFrontstageDef?.getStagePanelDef(StagePanelLocation.Right)?.findWidgetDef("EquipmentRotation");

        SyncUiEventDispatcher.onSyncUiEvent.addListener((args) => {
            // if (args.eventIds.has("equipmentselected") && this.currDef?.state !== WidgetState.Open && SampleToolWidget.isEditModeActive) {
            //     this.setWidgetState(WidgetState.Open);
            // }

            if (args.eventIds.has("equipmentunselected") && this.currDef?.state !== WidgetState.Hidden) {
                this.setWidgetState(WidgetState.Hidden);
            }
        });
    }

    public static async sliderChange(_newVal: number, _rotationVal: number, _shapeType: ShapeType = ShapeType.Box): Promise<void> {
        // const shapeName = SampleToolWidget.selectedBoxName;

        const shapeName = store.getState().detectedData.selectedObjectInformation.objectProperties.selectedObjectNST.name;
        // const decorator = SampleToolWidget.currEquipDecorator;
        const decorator = IModelApp.viewManager.decorators.filter((e) => e.constructor.name.includes("FeedlineDecorator"))[0] as FeedlineDecorator;;
        // const modDecorator = ModifyHandleDecoration._decorator!;

        const newJsons = decorator.getUneditedBoxJson();
        newJsons.forEach(e=>decorator.createGeometry({...e, Azimuth: _newVal!}, CreateGeometryMode.New));
        const newJson = decorator.shapes.find(e=>e.modelData?.Id! == shapeName)?.modelData!;
        const newShapes = decorator.shapes.filter(e=>!e.modelData?.Id.match(/start|middle|segment|end/));
        decorator.shapes = newShapes;
        // decorator.objectIdMap.clear();
        
        newJson.Azimuth = _newVal;
        decorator.createGeometry(newJson, CreateGeometryMode.Edit);
        const index = decorator.shapes.findIndex(e=>e.modelData?.Id == shapeName);
        // const equipmentJson = decorator.shapes[index].modelData!;
        if (index !== -1)decorator.shapes.splice(index, 1);
        // const shapeIndex = decorator.shapes.findIndex(e=>e.modelData?.Id==newJson.Id);
        // if(shapeIndex != -1)decorator.shapes.splice(shapeIndex, 1);
    
        IModelApp.viewManager.invalidateDecorationsAllViews();
        IModelApp.viewManager.selectedView?.invalidateCachedDecorations(decorator);

        // decorator.updateBox(newBox!, index, true);

        // // update shape
        // if (shapeName && decorator) {
        //     const index = decorator!.currJson.findIndex((entry) => entry.Equipment_Name === shapeName);
        //     const currJson = decorator.currJson[index];
        //     if (rotationVal === EquipmentRotationType.Azimuth) {
        //         currJson.Azimuth = _newVal;
        //     } else if (rotationVal === EquipmentRotationType.Tilt) {
        //         currJson.Tilt = _newVal;
        //     } else {
        //         currJson.Roll = _newVal;
        //     }
        //     const newYpr = YawPitchRollAngles.createDegrees(currJson.Azimuth, 0, 0);
        //     // if (_shapeType === ShapeType.Box) {
        //         // modDecorator._hasEditedBox = true;
        //         decorator.updateBoxRotation(shapeName, newYpr);
        //     // } else if (_shapeType === ShapeType.Cylinder) {
        //     //     // SampleToolWidget.isModifiedData = true;
        //     //     decorator.updateCylinderRotation(shapeName, newYpr);
        //     // }
        // }
    }

    public static async updateBoxDimension(move: number, em: editMode): Promise<void> {
        // const shapeName = store.getState().detectedData.selectedObjectInformation.objectProperties.selectedObjectNST.name;
        const decorator = IModelApp.viewManager.decorators.filter((e) => e.constructor.name.includes("FeedlineDecorator"))[0] as FeedlineDecorator;;
        const shapeName = decorator.selectedFeedline.modelId;
        // const decorator = SampleToolWidget.currEquipDecorator;
        // const modDecorator = ModifyHandleDecoration._decorator!;

        // update Box
        if (shapeName && decorator) {
            // if (shapeName.match(/Micro_Wave/i)) {
            //     // SampleToolWidget.isModifiedData = true;
            //     decorator.updateCylinderPosition(shapeName, new Vector3d(dx, dy, dz));
            // } else {
                // modDecorator._hasEditedBox = true;
            // decorator.updateBoxPositionByName(shapeName, new Vector3d(dx, dy, dz));
            // const index = decorator.shapes.findIndex(e=>e.modelData?.Id == shapeName);
            // const equipmentJson = decorator.shapes[index].modelData!;
            let feedlinesJson = decorator.getUneditedBoxJson()!;
            // if(shapeName.match(/@/)){

            // }
            feedlinesJson.forEach((feedlineJson, _flIndex)=>{
                if(em == editMode.depth){
                    feedlineJson.Width+=move;
                    feedlineJson.CenterPositions.localBase.x = feedlineJson.CenterPositions.localBase.x - 0.5 * move;
                    feedlineJson.CenterPositions.localTop.x = feedlineJson.CenterPositions.localTop.x - 0.5 * move;
                    feedlineJson.ActualPositions.base.x = feedlineJson.ActualPositions.base.x - 0.5 * move;
                    feedlineJson.ActualPositions.top.x = feedlineJson.ActualPositions.top.x - 0.5 * move;
                } else if(em == editMode.width) {
                    feedlineJson.Thickness+=move;
                    feedlineJson.CenterPositions.localTop.y = feedlineJson.CenterPositions.localTop.y - 0.5 * move;
                    feedlineJson.CenterPositions.localBase.y = feedlineJson.CenterPositions.localBase.y - 0.5 * move;
                    feedlineJson.ActualPositions.top.y = feedlineJson.ActualPositions.top.y - 0.5 * move;
                    feedlineJson.ActualPositions.base.y = feedlineJson.ActualPositions.base.y - 0.5 * move;
                }

                if(shapeName.match(/@/)){
                    const newJsons = decorator.getUneditedBoxJson();
                    // newJsons.forEach(e=>decorator.createGeometry({...e, FeedlineType: {...e.FeedlineType, type: FeedlineSectionType.Segmented}}, CreateGeometryMode.New));
                    newJsons.forEach(e=>{

                        if(em == editMode.depth){
                            e.Width+=move;
                            e.CenterPositions.localBase.x = e.CenterPositions.localBase.x - 0.5 * move;
                            e.CenterPositions.localTop.x = e.CenterPositions.localTop.x - 0.5 * move;
                            e.ActualPositions.base.x = e.ActualPositions.base.x - 0.5 * move;
                            e.ActualPositions.top.x = e.ActualPositions.top.x - 0.5 * move;
                        } else if(em == editMode.width) {
                            e.Thickness+=move;
                            e.CenterPositions.localTop.y = e.CenterPositions.localTop.y - 0.5 * move;
                            e.CenterPositions.localBase.y = e.CenterPositions.localBase.y - 0.5 * move;
                            e.ActualPositions.top.y = e.ActualPositions.top.y - 0.5 * move;
                            e.ActualPositions.base.y = e.ActualPositions.base.y - 0.5 * move;
                        }
                          
                        const nj: FeedlineData = {
                            ...e,
                            // ActualPositions: {base: theActualBasePoint, top: theActualTopPoint},
                            // CenterPositions: {localBase: theCentralBasePoint, localTop: theCentralTopPoint},
                            // Width: feedlineJson.Width,
                            // Thickness: feedlineJson.Thickness
                        }
                        decorator.createGeometry(nj, CreateGeometryMode.New);
                    });
                }

                const newShapes = decorator.shapes.filter(e=>!e.modelData?.Id.match(/start|middle|segment|end/))
                decorator.shapes = newShapes;
                decorator.createGeometry(feedlineJson, CreateGeometryMode.Edit);
                // const shapeIndex = decorator.shapes.findIndex(e=>e.modelData?.Id==feedlineJson.Id);
                // if(shapeIndex != -1)decorator.shapes.splice(shapeIndex, 1);

            })
            
            IModelApp.viewManager.invalidateDecorationsAllViews();
            IModelApp.viewManager.selectedView?.invalidateCachedDecorations(decorator);
        
                    // }
        }

    }

    public static async translate(dx: number, dy: number, dz: number, section: RadioSection, segmentId=null): Promise<void> {
        // const shapeName = SampleToolWidget.selectedBoxName;
        // const shapeName = store.getState().dtvState.applicationState.selectedAsBuiltObject.object.name;
        let shapeName = store.getState().detectedData.selectedObjectInformation.objectProperties.selectedObjectNST.name;

        // const decorator = SampleToolWidget.currEquipDecorator;
        const decorator = IModelApp.viewManager.decorators.filter((e) => e.constructor.name.includes("FeedlineDecorator"))[0] as FeedlineDecorator;;
        // const modDecorator = ModifyHandleDecoration._decorator!;

        // update Box
        if (shapeName && decorator) {
            // if (shapeName.match(/Micro_Wave/i)) {
            //     // SampleToolWidget.isModifiedData = true;
            //     decorator.updateCylinderPosition(shapeName, new Vector3d(dx, dy, dz));
            // } else {
                // modDecorator._hasEditedBox = true;
            // decorator.updateBoxPositionByName(shapeName, new Vector3d(dx, dy, dz));
            // const index = decorator.shapes.findIndex(e=>e.modelData?.Id == shapeName);
            let feedlinesJson: FeedlineData[];
            const allSegments = decorator.shapes.filter(s=>s.modelData?.Id.match(/segment/));
            if(segmentId != null){
                // feedlinesJson = [decorator.shapes.find(e=>e.modelData?.Id==`${shapeName}-segment`)?.modelData!];
                feedlinesJson = [allSegments[parseInt(segmentId)-1].modelData!];
            } else feedlinesJson = decorator.getUneditedBoxJson()!;
            // const feedlineJson = decorator.shapes[index].modelData!;

            feedlinesJson.forEach((feedlineJson: FeedlineData, flIndex)=>{
                if((section == RadioSection.Start && flIndex == 0) || (section == RadioSection.End && flIndex == feedlinesJson.length-1) || section==RadioSection.Whole){
                    // feedlineJson = getUnlinkedFeedline([feedlineJson])[0];
                    switch (section) {
                            // feedlineJson.CenterPositions.localBase.x+=dx;
                            // feedlineJson.CenterPositions.localBase.y+=dy;
                            // feedlineJson.CenterPositions.localBase.z+=dz;
                    
                            // feedlineJson.ActualPositions.base.x+=dx;
                            // feedlineJson.ActualPositions.base.y+=dy;
                            // feedlineJson.ActualPositions.base.z+=dz;
                            
                            // feedlineJson.CenterPositions.localTop.x+=dx;
                            // feedlineJson.CenterPositions.localTop.y+=dy;
                            // feedlineJson.CenterPositions.localTop.z+=dz;
                    
                            // feedlineJson.ActualPositions.top.x+=dx;
                            // feedlineJson.ActualPositions.top.y+=dy;
                            // feedlineJson.ActualPositions.top.z+=dz;
                    
                            
                            // break;
                        case RadioSection.Start:
                        case RadioSection.Whole:
                            feedlineJson.CenterPositions.localBase.x+=dx;
                            feedlineJson.CenterPositions.localBase.y+=dy;
                            feedlineJson.CenterPositions.localBase.z+=dz;
                    
                            feedlineJson.ActualPositions.base.x+=dx;
                            feedlineJson.ActualPositions.base.y+=dy;
                            feedlineJson.ActualPositions.base.z+=dz;
                            
                            if(section !== RadioSection.Whole)break;
                        case RadioSection.End: 
                            feedlineJson.CenterPositions.localTop.x+=dx;
                            feedlineJson.CenterPositions.localTop.y+=dy;
                            feedlineJson.CenterPositions.localTop.z+=dz;
                    
                            feedlineJson.ActualPositions.top.x+=dx;
                            feedlineJson.ActualPositions.top.y+=dy;
                            feedlineJson.ActualPositions.top.z+=dz;
                            if(section !== RadioSection.Whole)break;
                        default:
                            break;
                    }

                }

                if(segmentId != null && shapeName.length){
                    shapeName=feedlineJson.Id.split('-')[0];
                    let suffix = shapeName.match(/segment/) ? "" : "-segment";
                    if(feedlineJson.FeedlineType.type == FeedlineSectionType.NonSegmented){
                        suffix="@0-segment";
                        feedlineJson.FeedlineType.type = FeedlineSectionType.Segmented;
                    }
                    const theShapeModel = decorator.shapes.find(e=>e.modelData?.Id! == shapeName+suffix);
                    const shapeIndex = decorator.shapes.findIndex(e=>e.modelData?.Id==theShapeModel?.modelData?.Id);
                    if(shapeIndex != -1){
                        // decorator.shapes[shapeIndex].modelData = feedlineJson!;
                        // decorator.shapes[shapeIndex].modelData!.Id = shapeName+"-segment"!;
                        // decorator.shapes.splice(shapeIndex, 1, );
                    
                    }
                    const newJsons = decorator.getUneditedBoxJson();
                    // newJsons.forEach(e=>decorator.createGeometry({...e, FeedlineType: {...e.FeedlineType, type: FeedlineSectionType.Segmented}}, CreateGeometryMode.New));
                    newJsons.forEach(e=>decorator.createGeometry(e, CreateGeometryMode.New));
                    const newJson = decorator.shapes.find(e=>e.modelData?.Id! == shapeName)?.modelData!;
                    const newShapes = decorator.shapes.filter(e=>!e.modelData?.Id.match(/start|middle|segment|end/));
                    decorator.shapes = newShapes;        
                    // decorator.objectIdMap.clear();
                    decorator.createGeometry(feedlineJson, CreateGeometryMode.Edit);
                    // for(let asi = 0; asi < allSegments.length; asi++){
                    //     const theIndex = decorator.shapes.findIndex(s=>s.modelData?.Id==`${decorator.selectedFeedline.modelId.split('@')[0]}@${asi}`)
                    //     decorator.shapes.splice(theIndex, 1);
                    // };
                } else if(shapeName.match(/@/) || allSegments.length){
                    // const newJsons = decorator.getUneditedBoxJson();
                    // newJsons.forEach(e=>decorator.createGeometry(e, CreateGeometryMode.New));
                    decorator.createGeometry(feedlineJson, CreateGeometryMode.New);
                    let newShapes = decorator.shapes.filter(e=>!e.modelData?.Id.match(/start|middle|segment|end/));
                    decorator.shapes = newShapes;        
                    if(flIndex == feedlinesJson.length-1)decorator.createGeometry(feedlineJson, CreateGeometryMode.Edit);
                    // allSegments.forEach((_as, asi)=>
                    // for(let asi = 0; asi < allSegments.length; asi++){
                    //     const theIndex = decorator.shapes.findIndex(s=>s.modelData?.Id==`${decorator.selectedFeedline.modelId.split('@')[0]}@${asi}`)
                    //     decorator.shapes.splice(theIndex, 1);
                    // };
                    // const shapeIndex = decorator.shapes.findIndex(e=>e.modelData?.Id==feedlineJson.Id);
                    // if(shapeIndex != -1)decorator.shapes.splice(shapeIndex, 1);
                } else {
                    const newShapes = decorator.shapes.filter(e=>!e.modelData?.Id.match(/start|middle|end/));
                    decorator.shapes = newShapes;        
                    decorator.createGeometry(feedlineJson, CreateGeometryMode.Edit);
                    const shapeIndex = decorator.shapes.findIndex(e=>e.modelData?.Id==feedlineJson.Id);
                    if(shapeIndex != -1)decorator.shapes.splice(shapeIndex, 1);
                }         
            });

        
            IModelApp.viewManager.invalidateDecorationsAllViews();
            IModelApp.viewManager.selectedView?.invalidateCachedDecorations(decorator);
        
                    // }
        }
        // const updatedJson = decorator?.currJson.find((e) => e.Equipment_Name === shapeName);
        // if (!updatedJson) return;

        // // const equipInfo = SampleToolWidget.equipNameInfoMap.get(shapeName!);
        // // const equipInfoMap = new Map(store.getState().detectedData.equipmentDataMaps.equipNameInfoMap);
        // // const equipInfo = {...equipInfoMap?.get(shapeName!)};
        // const equipInfo = EquipmentsTable.equipNameInfoMap?.get(shapeName!);
        // const data = {
        //     name: shapeName,
        //     lateralOffset: equipInfo.lateralOffset + dx,
        //     standoffDistance: equipInfo.standoffDistance + dy,
        //     verticalOffset: equipInfo.verticalOffset + dz,
        // };
        // equipInfo.lateralOffset = data.lateralOffset;
        // equipInfo.standoffDistance = data.standoffDistance;
        // equipInfo.verticalOffset = data.verticalOffset;

        // const existingMaps = store.getState().detectedData.equipmentDataMaps;
        // const newEquipInfoMap = new Map(existingMaps.equipNameInfoMap);
        // newEquipInfoMap.delete(shapeName);
        // newEquipInfoMap.set(shapeName, equipInfo);
        // const newEquipInfoMaps: equipmentDataMaps = {...existingMaps, equipNameInfoMap: newEquipInfoMap};
        // store.dispatch(setEquipmentDataMaps(newEquipInfoMaps));

    }

}
