import * as React from "react";
import { IModelApp, IModelConnection, NotifyMessageDetails, OutputMessagePriority, OutputMessageType } from "@itwin/core-frontend";
import { PropertyRecord } from "@itwin/appui-abstract";
// tslint:disable-next-line:no-direct-imports
import { Orientation } from "@itwin/core-react";
// tslint:disable-next-line:no-direct-imports
import "./List.scss";
import { SelectAntennaTool } from "../tools/SelectAntennaTool";
import ImageSlider from "../image-gallary/imageSlider";
import { getUnit, getValueInRequiredUnit } from "../Tables/BaseTable";
import { nvCell, NVRowsForPropEditModal, PropertyEditModal } from "./PropertyEditModal";
import { ModifyHandleDecoration } from "../tools/modification/ModifyHandleDecoration";
import { PropertyList } from "@itwin/components-react";
import { Logger } from "../../api/logging";
import { AppSettings } from "../../../config/AppSettings";
import { ConfigManager } from "../../../config/ConfigManager";
import { towerStructureData } from "../../../store/detectedData/apiDataTypes";
import { store } from "../../../store/rootReducer";
import { EquipmentShapeDecorator } from "../../tools/decorators/EquipmentShapeDecorator";
import { IndividualShapeDecorator } from "../../tools/decorators/IndividualShapeDecorator";
import { unitSystemType } from "../../../store/redux-types";

async function getData() {
    try {
        let equipName=IndividualShapeDecorator.selectedEquipName!;
        if(equipName && equipName!.includes('face')){
            const equipDec = IModelApp.viewManager.decorators.filter((e) => e.constructor.name.includes("EquipmentShapeDecorator"))[0] as EquipmentShapeDecorator;
            equipName=equipDec!.faceToEquipSelect(equipName)!.name;
        }
        IndividualShapeDecorator.selectedEquipName=equipName;
        const data = await SelectAntennaTool.fetchEquipmentData(equipName);
        if (!data) {
            return undefined;
        }
        // SampleToolWidget.selectedBoxInformation = data;
        return data;
    } catch (e) {
        // tslint:disable-next-line:no-console
        Logger.error("Error in EquipmentList file",e);

    }

    return undefined;
}

function createDataProvider(record: any) {
    const dataProvider: PropertyRecord[] = [];
    let positiveAzimuth = 0;
    if (record.azimuth < 0) {
        positiveAzimuth = 360.0 + record.azimuth;
    }else{
        positiveAzimuth = record.azimuth;
    }
    const man = record.manufacturer ? record?.manufacturer.match(/UPT:NewEquip/i) ? "" : record.manufacturer : "";
    const model = record.model ? record?.model.match(/UPT:NewEquip-001/i) ? "" : record.model : "";
    dataProvider.push(PropertyRecord.fromString(record.name, "Equipment Name"));
    dataProvider.push(PropertyRecord.fromString(record.type, "Equipment Type"));
    let operator = record.operator
    // if(SampleToolWidget.mountsData && SampleToolWidget.sampleToolWidget.state.showEOMapModal.existingMapping.length && record.plat){
    //     operator = SampleToolWidget.elOpMappings.filter(e=>e.mountName == record.plat);
    //     operator = operator.length && (operator[0].operator != "Select operator") ? operator[0].operator : "";
    //     dataProvider.push(PropertyRecord.fromString(operator, "Operator"));
    // }
    dataProvider.push(PropertyRecord.fromString(man, "Manufacturer"));
    dataProvider.push(PropertyRecord.fromString(model, "Model"));
    dataProvider.push(PropertyRecord.fromString(record.faceName === undefined ? "" : record.faceName.replace(ConfigManager.PasCaseToSpSepRegExp, "$1$4 $2$3$5"), "Mount Face"));
    dataProvider.push(PropertyRecord.fromString(getValueInRequiredUnit(record.elevationHeight) ?? "", "Elevation Height " + getUnit()));
    dataProvider.push(PropertyRecord.fromString(ReduceDecimalNumberToTwoPlaces(positiveAzimuth).toString(), "Azimuth (" + "\u00b0" + ")"));
    dataProvider.push(PropertyRecord.fromString(ReduceDecimalNumberToTwoPlaces(record.tilt).toString(), "Vertical Tilt (" + "\u00b0" + ")"));
    dataProvider.push(PropertyRecord.fromString(ReduceDecimalNumberToTwoPlaces(record.roll).toString(), "Roll (" + "\u00b0" + ")"));
    dataProvider.push(PropertyRecord.fromString(getValueInRequiredUnit(record.depth), "Thickness " + getUnit()));
    dataProvider.push(PropertyRecord.fromString(getValueInRequiredUnit(record.width), "Width " + getUnit()));
    dataProvider.push(PropertyRecord.fromString(getValueInRequiredUnit(record.height), "Height " + getUnit()));
    dataProvider.push(PropertyRecord.fromString(getValueInRequiredUnit(record.lateralOffset), "Lateral Offset " + getUnit()));
    dataProvider.push(PropertyRecord.fromString(getValueInRequiredUnit(record.standoffDistance), "Standoff Distance " + getUnit()));
    dataProvider.push(PropertyRecord.fromString(getValueInRequiredUnit(record.verticalOffset), "Vertical Offset " + getUnit()));
    return dataProvider;
}

export function EquipmentList(props: { imodel: IModelConnection }) {
    const [record, setRecord] = React.useState<any>();
    const [imgUrl, setImgUrl] = React.useState([]);
    const [manModDets, setManModDets] = React.useState<any>([]);
    const [manList, setManList] = React.useState<string[]>(["Select Manufacturer"]);
    const [modelList, setModelList] = React.useState<string[]>(["Select Model"]);
    const [showModal, setShowModal] = React.useState(false);
    const [enableSave, setEnableSave] = React.useState(false);
    const [modalFieldVals, setModalFieldVals] = React.useState<NVRowsForPropEditModal[]>([]);
    let updatedArray: NVRowsForPropEditModal[] = [];

    React.useEffect(() => {
        setRecord(undefined);
        getData().then((data) => {
            if (!data) {
                return;
            }
            // SampleToolWidget.selectedBoxInformation = data;
            setRecord(data);
            if (data && data.imageList !== "[]" && data.imageList !== "" && data.imageList !== null && data.imageList !== "['no_image']" && data.imageList !== "[\"no_image\"]") {
                const imgName = data.imageList;
                const imgNameReplace = imgName.replace(/[\[\]']+/g, "");
                const imageName = imgNameReplace.replaceAll('"', "").split(",");
                setImgUrl(imageName);
            }
        });

    }, [props.imodel]);

    // const colorChange = (evt: any) =>{
    //     SampleToolWidget.currEquipDecorator!.equipColors.antenna.faceCol=ColorDef.fromString(evt.currentTarget.value);
    // }

    const header = (
        <h1 className="header">
            Equipment Information
        </h1>
    );

    let content = <div className="noDataListContainer"><h1 className="noDataListText">No Equipment Data</h1></div>;

    React.useEffect(() => {
        if(record)loadManDets();
      }, [record]);
    
    React.useEffect(() => {
        if(manList?.length && modalFieldVals?.length){
            const temp = [...modalFieldVals];
            const manIndex = temp.findIndex(e=>e.cellTitle.name == "Manufacturer");
            const modelIndex = temp.findIndex(e=>e.cellTitle.name == "Model");
            temp[manIndex].nvCells[0].data = manList;
            temp[modelIndex].nvCells[0].data = modelList;
            setModalFieldVals([...temp]);
        }
      }, [manList]);
    
    
    const loadManDets = async () => {
        if(manList.length < 3 || modelList.length < 3){
            manList.unshift("Loading manufacturers...");
            modelList.unshift("Loading models...");
            setManList([...manList]);
            setModelList([...modelList]);
            if(modalFieldVals.length){
              const temp = [...modalFieldVals];
              const modelIndex = temp.findIndex(e=>e.cellTitle.name == "Model");
              temp[modelIndex].nvCells[0].data = modelList;
              setModalFieldVals([...temp]);
            }
            // if(!SampleToolWidget.equipManModelDetails.length){
            //   await SampleToolWidget.getManData().then((data) => {
            //     if (!data)return;
            //     if (data)SampleToolWidget.equipManModelDetails=data;
            //     setManModDets([...data]);
            //     const [manL, modL] = getManModForEquipType(data, record.manufacturer);
            //     setModelList(modL);
            //     setManList(manL);
            //   });
            // } else {
            //   setManModDets([...SampleToolWidget.equipManModelDetails]);
            //   const [manL, modL] = getManModForEquipType(SampleToolWidget.equipManModelDetails, record.manufacturer);
            //   setModelList(modL);
            //   setManList(manL);
            // }
        }
    }
    
    const getManEquipType = (et: string) => {
        let equipType: string='';
        switch(et) {
          case "Antenna": equipType = "PANEL";break;
          case "Micro_Wave": equipType = "DISH";break;
          case "RRU": equipType = "TME";break;
        }
        return equipType;
    }      
    
    const getManModForEquipType = (mmd, selectedMan) => {
        const mans:any = [], manL: string[]=[], modL: string[] = [];
        const equipType = getManEquipType(record.type);
        const temp = [...modalFieldVals];
        const manIndex = temp.findIndex(e=>e.cellTitle.name == "Manufacturer");
        mmd.forEach((e:any)=>{
            if(manL.indexOf(e.manufacturer?.toUpperCase()) === -1 && mans.indexOf(e.manufacturer?.toUpperCase()) === -1 && e.type == equipType && e.manufacturer.length)manL.push(e.manufacturer.toUpperCase());
            if(modL.indexOf(e.model?.toUpperCase()) === -1 && e.manufacturer?.toUpperCase() === selectedMan?.toUpperCase() && e.type == equipType && e.model.length)modL.push(e.model.toUpperCase());
        });
        //Following records in manufacturer & Model list are added to handle the model name mismatch in the equipment & catalog issue. Ideally it shount not be required.
        if(temp.length && temp[manIndex].nvCells[0].value == record.manufacturer && record?.manufacturer && record.manufacturer.length && !record.manufacturer.match(/UPT:NewEquip/i))manL.push(record.manufacturer);
        if(record.manufacturer && temp.length && temp[manIndex].nvCells[0].value == record.manufacturer  && record?.model && record.model.length && !record.model.match(/UPT:NewEquip-001/i))modL.push(record.model);
        return [["Select Manufacturer", ...[...new Set([...manL])].sort()], ["Select Model", ...[...new Set([...modL])].sort()]];
    }
      
    

    const saveProps = () => {
      updatedArray=JSON.parse(JSON.stringify(modalFieldVals));
      const newRec=JSON.parse(JSON.stringify(record));
      
      const unit = AppSettings.defaultDisplaySettings.unitSystem;

      const putData: any = {
        Azimuth: newRec.azimuth,
        Equipment_Name: record.name,
        Elevation_Height: unit === unitSystemType.METRIC ? record.elevationHeight : record.elevationHeight,
        Height: unit === unitSystemType.METRIC ? newRec.height : newRec.height,
        Thicness: unit === unitSystemType.METRIC ? newRec.depth : newRec.depth,
        Width: unit === unitSystemType.METRIC ? newRec.width : newRec.width,
        x_position: newRec.xPosition,
        y_position: newRec.yPosition,
        z_position: newRec.zPosition,
        Tilt: newRec.tilt,
        Roll: newRec.roll,
        Manufacturer: newRec.manufacturer,
        Model: newRec.model
      }
      
      const meterToFeetConvFactor = 1/3.281;
      let modified=false;
      const updatedProps: any[]=[];
      updatedArray.forEach((ua:NVRowsForPropEditModal, i:number)=>{
          const e = ua.nvCells[0];
          if(e.modified){
              modalFieldVals[i].nvCells[0].modified=false;
              modified=true;
              updatedProps.push(JSON.parse(JSON.stringify({...e, name: ua.cellTitle.name})));
              const formattedName = ua.cellTitle.origName[0].toUpperCase()+ua.cellTitle.origName.split('').slice(1).join('');
              if(e.type==="float" && ua.cellTitle.name !== "Elevation Height" && ua.cellTitle.name !== "Depth"){
                putData[formattedName]=unit === unitSystemType.METRIC ? parseFloat(e.value.toString()) : parseFloat(e.value.toString())*meterToFeetConvFactor;
                newRec[ua.cellTitle.origName]=unit === unitSystemType.METRIC ? parseFloat(e.value.toString()) : parseFloat(e.value.toString());
              } else if(e.type==="int" && ua.cellTitle.name !== "Elevation Height" && ua.cellTitle.name !== "Depth"){
                putData[formattedName]=parseInt(e.value.toString());
                newRec[ua.cellTitle.origName]=parseInt(e.value.toString());
              } else if(e.type==="degree" && ua.cellTitle.name !== "Elevation Height" && ua.cellTitle.name !== "Depth"){
                putData[formattedName]=parseInt(e.value.toString());
                newRec[ua.cellTitle.origName]=parseInt(e.value.toString());
              } else if(ua.cellTitle.name === "Elevation Height"){
                putData.z_position+=unit === unitSystemType.METRIC ? parseFloat(e.value.toString())-record.elevationHeight : (parseFloat(e.value.toString())*meterToFeetConvFactor-record.elevationHeight);
                putData.Elevation_Height=unit === unitSystemType.METRIC ? parseFloat(e.value.toString()) : parseFloat(e.value.toString())*meterToFeetConvFactor;
              } else if(ua.cellTitle.name === "Depth"){
                putData.Thicness=unit === unitSystemType.METRIC ? parseFloat(e.value.toString()) : parseFloat(e.value.toString())*meterToFeetConvFactor;
                newRec.depth=unit === unitSystemType.METRIC ? parseFloat(e.value.toString()) : parseFloat(e.value.toString())*meterToFeetConvFactor;
              } else if(formattedName.match(/Manufacturer|Model/i)) {
                putData[formattedName]=(e.value.toString() == "Select Manufacturer" || e.value.toString() == "Select Model") ? "UPT:NewEquip" : e.value.toString();
                newRec[ua.cellTitle.origName]=(e.value.toString() == "Select Manufacturer" || e.value.toString() == "Select Model") ? "UPT:NewEquip-001" : e.value.toString();
              } else {
                putData[formattedName]=e.value.toString();
                newRec[ua.cellTitle.origName]=e.value.toString();
              }
          }
      });

      if(modified){
        ModifyHandleDecoration.SaveData(putData, modified, newRec.name).then((res) => {
          if(res){
        //     const name = IndividualShapeDecorator.selectedEquipName;
        //     const enim = SampleToolWidget.equipNameInfoMap.get(name!);
        //     const enpm = SampleToolWidget.equipNamePositionMap.get(name!);
        //     updatedProps.forEach((up: any)=>{
        //       if(up.type === "float")enim[up.origName]=unit === unitSystemType.METRIC ? parseFloat(up.value): parseFloat(up.value);
        //       else if(up.type === "int")enim[up.origName]=parseInt(up.value);
        //       else if(up.type === "degree")enim[up.origName]=parseInt(up.value);
        //       else enim[up.origName]=up.value;

        //       if(up.name === "Manufacturer")enpm!.Manufacturer=up.value == "Select Manufacturer" ? "UPT:NewEquip" : up.value;
        //       if(up.name === "Model")enpm!.Model=up.value == "Select Model" ? "UPT:NewEquip-001" : up.value;
        //       if(up.name === "Azimuth")enpm!.Azimuth=parseFloat(up.value);
        //       if(up.name === "Roll")enpm!.Roll=parseFloat(up.value);
        //       if(up.name === "Height")enpm!.Height=unit === unitSystemType.METRIC ? parseFloat(up.value): parseFloat(up.value)*meterToFeetConvFactor;
        //       if(up.name === "Width")enpm!.Width=unit === unitSystemType.METRIC ? parseFloat(up.value): parseFloat(up.value)*meterToFeetConvFactor;
        //       if(up.name === "Thicness" || up.name === "Depth")enpm!.Thicness=unit === unitSystemType.METRIC ? parseFloat(up.value): parseFloat(up.value)*meterToFeetConvFactor;
        //       if(up.name === "Tilt")enpm!.Tilt=parseFloat(up.value);
        //       if(up.name === "Elevation Height"){
        //         enpm!.z_position=parseFloat(putData.z_position);
        //         enpm!.Elevation_Height=parseFloat(putData.Elevation_Height);
        //       }
        //     });

        //     const equipDec = SampleToolWidget.currEquipDecorator!;
        //     const equipType = SampleToolWidget.getEquipmentType();
        //     const jsonIndex = equipDec.currJson.findIndex((e) => e.Equipment_Name === name);
            
        //     equipDec.currJson[jsonIndex] = enpm!;
        //     if(equipType === EquipmentType.Antenna || equipType === EquipmentType.RRU){
        //       equipDec?.updateBoxPositionByName(name!, new Vector3d(0, 0, 0));
        //     } else if(equipType === EquipmentType.Microwave){
        //       equipDec?.updateCylinderPosition(name!, new Vector3d(0, 0, 0));
        //     }

          }
        });
      } else {
        IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Info,
          `Nothing to save.`, "", OutputMessageType.Toast));
      }
      setShowModal((current) => !current);
    }
    

    const onModalBtnClick = (evt: any) => {
        if(evt?.target.name == "Save")saveProps();
        else setShowModal(showModal=>!showModal)
    }

    const modalBtns = [
        {name: "Save", value: "Save", class: "dialogBox-save-btn", disable: !enableSave, onChange: onModalBtnClick},
        // {name: "Reset", class: "secondary", disable: false, onChange: onModalBtnClick},
        {name: "Cancel", value: "Cancel", class: "dialogBox-cancel-btn", disable: false, onChange: onModalBtnClick},
    ];
    

    const onPropertyModalChange = (evt: any) => {
        evt.persist();
        const {name: theName, value: theValue} = evt.target;
        const temp: NVRowsForPropEditModal[] = [...modalFieldVals];
        const unit = AppSettings.defaultDisplaySettings.unitSystem;
        const inchesToSelUnitConvFactor = unit == unitSystemType.METRIC ? 0.0254 : 1/12;
        const poundsToSelUnitConvFactor = unit == unitSystemType.METRIC ? 0.453592 : 1;
        // const towerData = SampleToolWidget.towerStructure;
        const towerData = store.getState().detectedData.towerStructureData as towerStructureData;
        switch(theName){
            case "Manufacturer": {
                const manIndex          = temp.findIndex(e=>e.cellTitle.name == "Manufacturer");
                const modelIndex        = temp.findIndex(e=>e.cellTitle.name == "Model");
                const heightIndex       = temp.findIndex(e=>e.cellTitle.name == "Height");
                const widthIndex        = temp.findIndex(e=>e.cellTitle.name == "Width");
                const depthIndex        = temp.findIndex(e=>e.cellTitle.name == "Depth");
                //const weightIndex       = temp.findIndex(e=>e.name == "Weight");
                temp[manIndex].nvCells[0].value    = manList[parseInt(theValue)];
                if(manList[theValue] == "Select Manufacturer"){
                    temp[manIndex].nvCells[0].value        = manList[theValue];
                    temp[modelIndex].nvCells[0].value      = modelList[0];
                    temp[modelIndex].nvCells[0].data       = ["Select Model"];
                    temp[manIndex].nvCells[0].modified     = true;
                    temp[modelIndex].nvCells[0].modified   = true;
                    break;
                }

                const [, modList] = getManModForEquipType(manModDets, temp[manIndex].nvCells[0].value);
                temp[modelIndex].nvCells[0].data   = modList;
                temp[modelIndex].nvCells[0].value  = modList[1];
                setModelList([...modList])

                const selMM = manModDets.filter(e=>e.manufacturer.toUpperCase()===temp[manIndex].nvCells[0].value && e.model.toUpperCase()===temp[modelIndex].nvCells[0].value.toString().toUpperCase() && e.type.toUpperCase()===getManEquipType(record.type).toUpperCase())[0];
                
                temp[heightIndex].nvCells[0].value = parseFloat((inchesToSelUnitConvFactor*selMM.height.toString()).toFixed(3));
                temp[widthIndex].nvCells[0].value  = parseFloat((inchesToSelUnitConvFactor*selMM.width.toString()).toFixed(3));
                temp[depthIndex].nvCells[0].value  = parseFloat((inchesToSelUnitConvFactor*selMM.depth.toString()).toFixed(3));
                //temp[weightIndex].value = parseFloat((poundsToSelUnitConvFactor*selMM.weight.toString()).toFixed(3));
                
                temp[manIndex].nvCells[0].modified     = true;
                temp[modelIndex].nvCells[0].modified   = true;
                temp[heightIndex].nvCells[0].modified  = true;
                temp[widthIndex].nvCells[0].modified   = true;
                temp[depthIndex].nvCells[0].modified   = true;
                //temp[weightIndex].modified  = true;
                break;
            }
            case "Model": {
                const manIndex      = temp.findIndex(e=>e.cellTitle.name == "Manufacturer");
                const modelIndex    = temp.findIndex(e=>e.cellTitle.name == "Model");
                const heightIndex   = temp.findIndex(e=>e.cellTitle.name == "Height");
                const widthIndex    = temp.findIndex(e=>e.cellTitle.name == "Width");
                const depthIndex    = temp.findIndex(e=>e.cellTitle.name == "Depth");
                //const weightIndex   = temp.findIndex(e=>e.name == "Weight");
                if(modelList[theValue] == "Select Model"){
                    temp[modelIndex].nvCells[0].value      = modelList[theValue];
                    temp[modelIndex].nvCells[0].modified   = true;
                    break;              
                }
                
                const selMM = manModDets.filter(e=>e.manufacturer.toUpperCase()===temp[manIndex].nvCells[0].value && e.model.toUpperCase()===modelList[theValue].toUpperCase() && e.type.toUpperCase()===getManEquipType(record.type).toUpperCase())[0];
                if(selMM !== undefined){
                    temp[modelIndex].nvCells[0].value  = modelList[parseInt(theValue)];
                    temp[heightIndex].nvCells[0].value = parseFloat((inchesToSelUnitConvFactor*selMM.height.toString()).toFixed(3));
                    temp[widthIndex].nvCells[0].value  = parseFloat((inchesToSelUnitConvFactor*selMM.width.toString()).toFixed(3));
                    temp[depthIndex].nvCells[0].value  = parseFloat((inchesToSelUnitConvFactor*selMM.depth.toString()).toFixed(3));
                    //temp[weightIndex].value = parseFloat((poundsToSelUnitConvFactor*selMM.weight.toString()).toFixed(3));

                    temp[modelIndex].nvCells[0].modified    = true;
                    temp[heightIndex].nvCells[0].modified   = true;
                    temp[widthIndex].nvCells[0].modified    = true;
                    temp[depthIndex].nvCells[0].modified    = true;
                    //temp[weightIndex].modified   = true;
                } else {
                    IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Warning, `Select Manufacturer & model is not valid for the equipment.`, "", OutputMessageType.Toast));
                }
                    break;
            }
            case "Equipment Library": {
                const dimIndex          = temp.findIndex(e=>e.cellTitle.name == "Equipment Library");
                const manIndex          = temp.findIndex(e=>e.cellTitle.name == "Manufacturer");
                const modelIndex        = temp.findIndex(e=>e.cellTitle.name == "Model");
                const heightIndex       = temp.findIndex(e=>e.cellTitle.name == "Height");
                const widthIndex        = temp.findIndex(e=>e.cellTitle.name == "Width");
                const depthIndex        = temp.findIndex(e=>e.cellTitle.name == "Depth");
                //const weightIndex       = temp.findIndex(e=>e.name == "Weight");
                const td = temp[dimIndex].nvCells[0].data;
                temp[dimIndex].nvCells[0].value    = td != undefined ? td[theValue]: temp[dimIndex].nvCells[0].value;
                
                if(temp[dimIndex].nvCells[0].value == "CUSTOM"){
                    temp[manIndex].nvCells[0].value        = "UPT:NewEquip";
                    temp[modelIndex].nvCells[0].value      = "UPT:NewEquip-001";
                    temp[manIndex].nvCells[0].disabled     = true;
                    temp[modelIndex].nvCells[0].disabled   = true;
                    temp[heightIndex].nvCells[0].disabled  = false;
                    temp[widthIndex].nvCells[0].disabled   = false;
                    temp[depthIndex].nvCells[0].disabled   = false;
                    //temp[weightIndex].disabled  = false;
                    temp[manIndex].nvCells[0].modified     = true;
                    temp[modelIndex].nvCells[0].modified   = true;
                } else if(temp[dimIndex].nvCells[0].value == "STANDARD"){
                    const [manL, modL] = getManModForEquipType(manModDets, record.manufacturer);
                    setModelList(modL);
                    setManList(manL);
                    temp[manIndex].nvCells[0].value        = record.manufacturer ? record.manufacturer.match(/UPT:NewEquip/i) ? "Select Manufacturer" : record.manufacturer : record.manufacturer ;
                    temp[modelIndex].nvCells[0].value      = record.model ? record.model.match(/UPT:NewEquip-001/i) ? "Select Model" : record.model : record.model;
                    temp[modelIndex].nvCells[0].data       = modL;
                    temp[manIndex].nvCells[0].disabled     = false;
                    temp[modelIndex].nvCells[0].disabled   = false;
                    temp[heightIndex].nvCells[0].disabled  = true;
                    temp[widthIndex].nvCells[0].disabled   = true;
                    temp[depthIndex].nvCells[0].disabled   = true;
                    //temp[weightIndex].disabled  = true;
                    if(record.manufacturer && !record.manufacturer.match(/UPT:NewEquip/i)){
                        const selMM = manModDets.filter(e=>e.manufacturer.toUpperCase()===record.manufacturer.toUpperCase() && e.model.toUpperCase()===record.model.toUpperCase() && e.type.toUpperCase()===getManEquipType(record.type).toUpperCase())[0];
                        temp[heightIndex].nvCells[0].value = parseFloat((inchesToSelUnitConvFactor*selMM?.height.toString()).toFixed(3));
                        temp[widthIndex].nvCells[0].value  = parseFloat((inchesToSelUnitConvFactor*selMM?.width.toString()).toFixed(3));
                        temp[depthIndex].nvCells[0].value  = parseFloat((inchesToSelUnitConvFactor*selMM?.depth.toString()).toFixed(3));
                        //temp[weightIndex].value = parseFloat((poundsToSelUnitConvFactor*selMM?.weight.toString()).toFixed(3));
                    }
                }
                break;
            }
            default: {
                // const unitConvFactor = unit == unitSystemType.IMPERIAL ? 3.28084 : 1;
                // const towerEl = towerData ? (towerData.top_altitude - towerData.base_altitude) * unitConvFactor : "Not Available" ;
                // const index = temp.findIndex(e=>e.cellTitle.name == theName);
                // let valid: boolean = true;

                // switch(theName){
                //     case "Elevation Height":{
                //         if(towerEl != "Not Available"){
                //             if(theValue > 0 && theValue > towerEl){
                //                 valid= false;
                //                 IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Warning, `Equipment elevation can not be greater than the tower height ${Math.floor(towerEl)} ${unit == unitSystemType.METRIC ? "Meters" : "Feet"}.`, "", OutputMessageType.Toast));
                //             } else if(theValue < 0){
                //                 valid= false;
                //                 IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Warning, `Equipment elevation can not be a negative value < 0.`, "", OutputMessageType.Toast));
                //             }
                //         } else if(theValue < 0){
                //             valid= false;
                //             IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Warning, `Equipment ${theName} can not less than -180 & greater than +180 degrees.`, "", OutputMessageType.Toast));
                //         }
                //         break;
                //     }
                //     case "Azimuth":
                //     case "Roll":
                //     case "Tilt":{
                //         if(theValue > 180 || theValue < -180){
                //             valid= false;
                //             IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Warning, `Equipment ${theName} can not less than -180 & greater than +180 degrees.`, "", OutputMessageType.Toast));
                //         }
                //         break;
                //     }
                //     case "Azimuth":
                //     case "Roll":
                //     case "Tilt":{
                //         if(theValue > 180 || theValue < -180){
                //             valid= false;
                //             IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Warning, `Equipment ${theName} can not less than -180 & greater than +180 degrees.`, "", OutputMessageType.Toast));
                //         }
                //         break;
                //     }
                //     case "Height":
                //     case "Width":
                //     case "Depth":{
                //         if(towerEl != "Not Available"){
                //             if(theValue > 0 && theValue > towerEl/2){
                //                 valid= false;
                //                 IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Warning, `Equipment ${theName} can not be too big.`, "", OutputMessageType.Toast));
                //             } else if(theValue <= 0) {
                //                 valid= false;
                //                 IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Warning, `Equipment ${theName} can not be 0 or a negative value.`, "", OutputMessageType.Toast));
                //             }
                //         } else if(theValue < 0){
                //             valid= false;
                //             IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Warning, `Equipment ${theName} can not be a negative value < 0.`, "", OutputMessageType.Toast));
                //         }
                //         break;
                //     }
                // }
                
                // if(valid){
                //     temp[index].nvCells[0].value       = theValue;
                //     temp[index].nvCells[0].modified    = true;
                //     // if(record.manufacturer || record.model){
                //     //     const manIndex              = temp.findIndex(e=>e.name == "Manufacturer");
                //     //     const modelIndex            = temp.findIndex(e=>e.name == "Model");
                //     //     temp[manIndex].value        = null;
                //     //     temp[modelIndex].value      = null;
                //     //     temp[manIndex].modified     = true;
                //     //     temp[modelIndex].modified   = true;
                //     // }
                // }
                break;
            }
        }
        setModalFieldVals([...temp]);
        let modified = false;
        temp.map(e=>{
            const recType = typeof(record[e.cellTitle.origName]);
            if(e.cellTitle.origName != "equipmentLibrary"){
                if(recType == "number" && !e.cellTitle.origName.match(/azimuth|roll|tilt/i)){
                    if(getValueInRequiredUnit(record[e.cellTitle.origName]) != (Math.round(e.nvCells[0].value as number*100)/100).toFixed(2))modified = true;
                } else if(record[e.cellTitle.origName] != e.nvCells[0].value){
                    modified = true;
                }
            } 
        });
        setEnableSave(modified);
    }

    const openModal = () => {
        if (record != undefined) {
            const dimTy = record.manufacturer && record.manufacturer.match(/UPT:NewEquip/i) ? "CUSTOM" : record.manufacturer && !record.manufacturer.match(/UPT:NewEquip/i) ? "STANDARD" : "CUSTOM";
            const meterToSelUnitConvFactor = AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.IMPERIAL ? 3.2808399 : 1;
            const poundsToSelUnitConvFactor = AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.IMPERIAL ? 1 : 0.453592;
            let manL:any[]=[], modL:any[]=[];
            if(dimTy == "STANDARD" && !record.manufacturer.match(/UPT:NewEquip/i)){
                manL=[...new Set(["Select Manufacturer", ...[record.manufacturer,...manList].sort()])];
                modL=[...new Set(["Select Model", ...[record.model, ...modelList].sort()])];
            } else if(dimTy == "CUSTOM"){
                manL=[...new Set(["Select Manufacturer",...manList.sort()])];
                modL=[...new Set(["Select Model", ...modelList.sort()])];
            }
            setManList([...manL]);
            setModelList([...modL]);
            setEnableSave(false);
            const tempRec = {...record, equipmentLibrary: dimTy}
            const recData = Object.entries(tempRec);
            const values: NVRowsForPropEditModal[] = [];

            [
                {keyName: "elevationHeight", label:"Elevation Height", disabled: false, type: "float", classes: "dialogBox-propEditModal-input", inputType: "input"},
                {keyName: "equipmentLibrary", label:"Equipment Library", disabled: false, type: "string", classes: "dialogBox-propEditModal-dropdown", inputType: "select", data: ["STANDARD", "CUSTOM"]},
                {keyName: "manufacturer", label:"Manufacturer", disabled: dimTy == "CUSTOM", type: "string", classes: "dialogBox-propEditModal-dropdown",  inputType: "select", data: (dimTy == "STANDARD" && manL.length) ? manL : ["Select Manufacturer", record.manufacturer]},
                {keyName: "model", label:"Model", disabled: dimTy == "CUSTOM", type: "string", classes: "dialogBox-propEditModal-dropdown", inputType: "select", data: (dimTy == "STANDARD" && modL.length) ? modL : ["Select Model", record.model]},
                {keyName: "height", label:"Height", disabled: dimTy == "STANDARD", type: "float", classes: "dialogBox-propEditModal-input", inputType: "input"},
                {keyName: "width", label:"Width", disabled: dimTy == "STANDARD", type: "float", classes: "dialogBox-propEditModal-input", inputType: "input"},
                {keyName: "depth", label:"Depth", disabled: dimTy == "STANDARD", type: "float", classes: "dialogBox-propEditModal-input", inputType: "input"},
                // {keyName: "weight", label:"Weight", disabled: dimTy == "STANDARD", type: "float", inputType: "input"},
                {keyName: "azimuth", label:"Azimuth", disabled: false, type: "degree", classes: "dialogBox-propEditModal-input", inputType: "input"},
                {keyName: "roll", label:"Roll", disabled: false, type: "degree", classes: "dialogBox-propEditModal-input", inputType: "input"},
                {keyName: "tilt", label:"Tilt", disabled: false, type: "degree", classes: "dialogBox-propEditModal-input", inputType: "input"},
                // {"pipeDist": "Pipe Distance", disabled: false, type: "float"},
            ].forEach((e: any) => {
                const {keyName, label, type, disabled, inputType, data} = e;
                let theVal = recData.filter((f) => f[0] === keyName)[0][1] as any;
                if(keyName.match(/height|width|depth/i)){
                    theVal*=meterToSelUnitConvFactor;
                    theVal=parseFloat(theVal.toFixed(3));
                }
                if(keyName.match(/weight/i)){
                    theVal*=poundsToSelUnitConvFactor;
                    theVal=parseFloat(theVal.toFixed(3));
                }
                const nvCells: nvCell[] = [{
                        value: theVal, 
                        type: type, 
                        disabled: disabled, 
                        inputType: inputType, 
                        modified: false, 
                        styles: {parentClass: 'col-6', elementClass: `${e.classes} col-12`},
                        data: data
                    }]
                // values.push({nvCells: row, name: `${mountName} - Elevation: ${elevation} ${getUnit()}`, origName: "elevation"});
                // values.push({name: label, value: theVal, origName: keyName, type: type, disabled: disabled, inputType: inputType, modified: false, data: data});
                values.push({nvCells: nvCells, cellTitle: {name: label, origName: keyName, styles: {parentClass: 'col-6', elementClass: 'col-12 pt-3'}}});
            });
            setModalFieldVals(values);
            setShowModal(current=>!current)
        }
        else {
            IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Error, "Error! ,Issue With Data", "", OutputMessageType.Toast));
        }
    };

    if (typeof record !== "undefined") {
        const dataProvider: PropertyRecord[] = createDataProvider(record);
        content = <PropertyList width={300} properties={dataProvider} orientation={Orientation.Vertical} />;
    }

    return (
        <div className="info-panel">
            {header}
            {imgUrl[0] === `"no_image"` ? null : <div className="info-panel-images"><ImageSlider slides={imgUrl} /></div>}
            <div style={{marginTop: 5, marginBottom: 5}} className="btn-wrapper">
                <button className="push-btn" onClick={openModal}>Edit Properties</button>
            </div>
            <hr className="img-border" />
            {/* {!store.getState().dtvState.applicationState.isEditModeActive && <OpacitySlider value={0.7}></OpacitySlider>} */}
            {/* {colorInputs} */}
            {content}
            {(modalFieldVals.length && showModal) ?  
                <PropertyEditModal 
                    cellHeadings={[]}
                    nameVals={modalFieldVals} 
                    openOClose={showModal} 
                    eventHandlers={{onChange: onPropertyModalChange, btnClickHandler: onModalBtnClick }} 
                    btns={modalBtns} 
                    title={`Edit Properties - ${record?.name.split('_').join(' ')}`}>
                </PropertyEditModal>
                : null
            }
        </div>
    );
}

export function ReduceDecimalToTwoPlaces(number: string) {
    let newNumber: number = 0.00;
    if (number !== "" || number) {
        const numToReduce = parseFloat(number);
        const reducedNum = numToReduce.toFixed(2);
        newNumber = Number(reducedNum);
    }
    return newNumber;
}

export function ReduceDecimalNumberToTwoPlaces(number: number) {
    if (number) {
        return Number(parseFloat(number!.toString()).toFixed(2));
    }
    return 0.00;
}

export function ReduceDecimalNumberToFourPlaces(number: number) {
    if (number) {
        return Number(parseFloat(number!.toString()).toFixed(4));
    }
    return 0.00;
}
