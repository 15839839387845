import { SyncUiEventDispatcher, UiFramework } from "@itwin/appui-react";
import { AppSettings } from "../../config/AppSettings";
import { setSelectedObjectStructure } from "../../store/detectedData/apiDataActionTypes";
import { ObjectCategoryTypes, objectProperties, clickType, selectedObjectInformation, initialPropertyListObjectState, init_DM_AntennaPropListState, init_DM_MountPropListState, init_DM_PanelPropListState, init_DM_TowerPropListState, init_DM_SesmicPropListState, NotApplicable, init_DM_WindPropListState, GeomObjectType, init_RM_EquipPropListState, init_RM_MountPropListState, MountSectionType, TowerSubSectionType, init_RM_TowerPropListState, init_RM_DefectPropListState, init_DM_AppurtenancePropListState, init_DM_AttachmentPropListState, init_DM_MountPipePropListState, init_DM_MountMemberPropListState, init_RM_FeederPropListState,init_RM_LadderPropListState, init_DM_FlatBarPropListState } from "../../store/detectedData/apiDataTypes";
import { selectionObjective, EquipmentType, DesignModelObjectType, SyncUiEventIds, unitSystemType } from "../../store/redux-types";
import { store } from "../../store/rootReducer";
import { towerColorConvFooter } from "../components/getTowerProperties";
import { getUnitsInLabel } from "./getPropertiesUnitLabelled";
import { editExecution } from "../components/HorizontalToolbarItems";
import { IModelApp } from "@itwin/core-frontend";
import { ModifyHandleDecoration } from "../components/tools/modification/ModifyHandleDecoration";
import { EquipmentsTable } from "../components/Tables/EquipmentsTable";

const listener = () => {
  const currentState = store.getState()
  return currentState;
}

store.subscribe(listener)

// export const ObjectIdLookUp = ({objectIds, objectId3DMaps, selCon}): any => {
export const ObjectIdLookUp = async ({instanceKeys}): Promise<any> => {
  if(instanceKeys.size > 1)return;

  const [objectType, objectIds] = [...instanceKeys][0];

  let objectProperties: objectProperties = {
    selectionObjective: selectionObjective.Info,
    objectIds: undefined,
    objectDetails: undefined,
    objectCategoryType: ObjectCategoryTypes.Tower_DM,
    selectedObjectNST: {name: "", subType: DesignModelObjectType.Tower},
    clicked: clickType.SINGLE
  }, elementsOnTop: any[] = [], elementsOnBottom: any[] = [], propListObj: selectedObjectInformation = initialPropertyListObjectState;
  const isEditActive = store.getState().dtvState.applicationState.isEditModeActive;
  // const sop: selectedObjectProperties = objectId3DMaps;
  if(objectType.match(/OpenTower/ig)){
    SyncUiEventDispatcher.dispatchImmediateSyncUiEvent(SyncUiEventIds.RM_Equipment_UnSelected);
    const selection = objectType.split(':')[1];
        switch (selection) {
      case "Antenna":
        propListObj = {...propListObj, heading: "Antenna Information", propertyList: getUnitsInLabel(init_DM_AntennaPropListState,"Antenna")  };
        // objectProperties={...objectProperties, objectIds, objectId3DMaps, objectCategoryType: ObjectCategoryTypes.Equipment_RM, selectedObject: objectDetails};
        objectProperties={...objectProperties, selectionObjective: isEditActive ? selectionObjective.Edit : selectionObjective.Info, objectCategoryType: ObjectCategoryTypes.Antenna_DM,  selectedObjectNST: {name: "", subType: DesignModelObjectType.Antenna}, objectIds};
        SyncUiEventDispatcher.dispatchImmediateSyncUiEvent(SyncUiEventIds.DM_Equipment_Selected);
        break;
      case "Mount":
        let obj =  [...[...instanceKeys][0][1]]
        elementsOnTop = [
          {label: "Edit Mount", type: "button"},
        ];
        propListObj = {...propListObj, heading: "Mount Information", propertyList: getUnitsInLabel(init_DM_MountPropListState,"Mount") , elementsOnTop };
        // objectProperties={...objectProperties, objectIds, objectId3DMaps, objectCategoryType: ObjectCategoryTypes.Equipment_RM, selectedObject: objectDetails};
        objectProperties={...objectProperties, selectionObjective: selectionObjective.Info, objectCategoryType: ObjectCategoryTypes.Mount_DM,  selectedObjectNST: {name: "", subType: DesignModelObjectType.Mount},objectIds:obj};
        SyncUiEventDispatcher.dispatchImmediateSyncUiEvent(SyncUiEventIds.DM_Mount_Selected);
        break;
      case "Panel":
        propListObj = {...propListObj, heading: "Panel Information", propertyList: getUnitsInLabel(init_DM_PanelPropListState,"Panel")  };
        // objectProperties={...objectProperties, objectIds, objectId3DMaps, objectCategoryType: ObjectCategoryTypes.Equipment_RM, selectedObject: objectDetails};
        objectProperties={...objectProperties, selectionObjective: selectionObjective.Info, objectCategoryType: ObjectCategoryTypes.Panel_DM,  selectedObjectNST: {name: "", subType: DesignModelObjectType.Panel}, objectIds};
        break;
      case "Pole":
        propListObj = {...propListObj, heading: "Pole Information", propertyList: getUnitsInLabel(init_DM_TowerPropListState,"Pole") };
        // objectProperties={...objectProperties, objectIds, objectId3DMaps, objectCategoryType: ObjectCategoryTypes.Equipment_RM, selectedObject: objectDetails};
        objectProperties={...objectProperties, selectionObjective: isEditActive ? selectionObjective.Edit : selectionObjective.Info, objectCategoryType: ObjectCategoryTypes.Pole_DM,  selectedObjectNST: {name: "", subType: DesignModelObjectType.Pole}, objectIds};
        break;
      case "Tower":
        propListObj = {...propListObj, heading: "Tower Information", propertyList: getUnitsInLabel(init_DM_TowerPropListState,"Tower") };
        // objectProperties={...objectProperties, objectIds, objectId3DMaps, objectCategoryType: ObjectCategoryTypes.Equipment_RM, selectedObject: objectDetails};
        objectProperties={...objectProperties, selectionObjective: isEditActive ? selectionObjective.Edit : selectionObjective.Info, objectCategoryType: ObjectCategoryTypes.Tower_DM,  selectedObjectNST: {name: "", subType: DesignModelObjectType.Tower}, objectIds};
        break;
        case "TowerInformation":
        propListObj = {...propListObj, heading: "Tower Information", propertyList: getUnitsInLabel(init_DM_TowerPropListState,"Tower") };
        objectProperties={...objectProperties, selectionObjective: isEditActive ? selectionObjective.Edit : selectionObjective.Info, objectCategoryType: ObjectCategoryTypes.Tower_DM,  selectedObjectNST: {name: "", subType: DesignModelObjectType.Tower}, objectIds};
        break;
      case "SeismicLoad":
          propListObj = {...propListObj, heading: "Sismic Load Information", propertyList: init_DM_SesmicPropListState };
          // objectProperties={...objectProperties, objectIds, objectId3DMaps, objectCategoryType: ObjectCategoryTypes.Equipment_RM, selectedObject: objectDetails};
          objectProperties={...objectProperties, selectionObjective: selectionObjective.Info, objectCategoryType: ObjectCategoryTypes.Seismic_DM,  selectedObjectNST: {name: "", subType: NotApplicable.NAL}, objectIds};
          break;
      case "WindLoad":
        const iModelConn = UiFramework.getIModelConnection();
        iModelConn?.selectionSet.add(iModelConn?.transientIds.getNext());
        // propListObj = {...propListObj, heading: "Wind Load Information", propertyList: init_DM_WindPropListState, elementsOnTop };
        propListObj = {...propListObj, heading: "Wind Load Information", propertyList: init_DM_WindPropListState };
        // objectProperties={...objectProperties, objectIds, objectId3DMaps, objectCategoryType: ObjectCategoryTypes.Equipment_RM, selectedObject: objectDetails};
        objectProperties={...objectProperties, selectionObjective: selectionObjective.Info, objectCategoryType: ObjectCategoryTypes.Wind_DM,  selectedObjectNST: {name: "", subType: NotApplicable.NAL}, objectIds};
        break; 
      case "Attachment": 
        propListObj = {...propListObj, heading: "Attachment Property", propertyList: init_DM_AttachmentPropListState };
        // objectProperties={...objectProperties, objectIds, objectId3DMaps, objectCategoryType: ObjectCategoryTypes.Equipment_RM, selectedObject: objectDetails};
        objectProperties={...objectProperties, selectionObjective: isEditActive ? selectionObjective.Edit : selectionObjective.Info, objectCategoryType: ObjectCategoryTypes.Attachment_DM,  selectedObjectNST: {name: "", subType: DesignModelObjectType.Attachment}, objectIds};
        break; 
    
      case "Appurtenance": 
        propListObj = {...propListObj, heading: "Appurtenance Property", propertyList: init_DM_AppurtenancePropListState };
        // objectProperties={...objectProperties, objectIds, objectId3DMaps, objectCategoryType: ObjectCategoryTypes.Equipment_RM, selectedObject: objectDetails};
        objectProperties={...objectProperties, selectionObjective: isEditActive ? selectionObjective.Edit : selectionObjective.Info, objectCategoryType: ObjectCategoryTypes.Appurtenance_DM,  selectedObjectNST: {name: "", subType: DesignModelObjectType.Appurtenance}, objectIds};
        break;    
      case "MountPipe": 
        propListObj = {...propListObj, heading: "Mount Pipe Property", propertyList:getUnitsInLabel( init_DM_MountPipePropListState ,"MountPipe")};
        // objectProperties={...objectProperties, objectIds, objectId3DMaps, objectCategoryType: ObjectCategoryTypes.Equipment_RM, selectedObject: objectDetails};
        objectProperties={...objectProperties, selectionObjective: selectionObjective.Info, objectCategoryType: ObjectCategoryTypes.MountPipe_DM,  selectedObjectNST: {name: "", subType: DesignModelObjectType.MountPipe}, objectIds};
        break;    
      case "MountMember": 
        propListObj = {...propListObj, heading: "Mount Member Property", propertyList:getUnitsInLabel(init_DM_MountMemberPropListState ,"MountMember")};
        // objectProperties={...objectProperties, objectIds, objectId3DMaps, objectCategoryType: ObjectCategoryTypes.Equipment_RM, selectedObject: objectDetails};
        objectProperties={...objectProperties, selectionObjective: selectionObjective.Info, objectCategoryType: ObjectCategoryTypes.MountMember_DM,  selectedObjectNST: {name: "", subType: DesignModelObjectType.MountMember}, objectIds};
        break;    
      case "FlatBar": 
        propListObj = {...propListObj, heading: "Flat Bar Information", propertyList:getUnitsInLabel(init_DM_FlatBarPropListState ,"FlatBar")};
        // objectProperties={...objectProperties, objectIds, objectId3DMaps, objectCategoryType: ObjectCategoryTypes.Equipment_RM, selectedObject: objectDetails};
        objectProperties={...objectProperties, selectionObjective: selectionObjective.Info, objectCategoryType: ObjectCategoryTypes.FlatBar_DM,  selectedObjectNST: {name: "", subType: DesignModelObjectType.FlatBar}, objectIds};
        break;  
      default:
        propListObj = {...propListObj, heading: "Object Information", propertyList: {} };
        elementsOnTop = [
          {label: "PropertyInFeet", type: "note"},
        ];
        propListObj.elementsOnTop = elementsOnTop;
        // objectProperties={...objectProperties, objectIds, objectId3DMaps, objectCategoryType: ObjectCategoryTypes.Equipment_RM, selectedObject: objectDetails};
        objectProperties={...objectProperties, selectionObjective: selectionObjective.Info, objectCategoryType: ObjectCategoryTypes.Miscellaneous_DM,  selectedObjectNST: {name: "", subType: DesignModelObjectType.Miscellaneous}, objectIds};
        break;
    }
  } else if(objectType.match(/TRANSIENT/)) {
    // if(isEditActive)UiFramework.dialogs.modeless.dialogs.forEach(e=>UiFramework.dialogs.modeless.close(e.id));

    // const {equipMaps, mountMaps, towerMaps, bracingMaps} = objectId3DMaps;
    const all3DObjectsMap = new Map(store.getState().detectedData.built3DObjectIdMaps.idValues);
    const all3DObjectsMapRev = new Map(store.getState().detectedData.built3DObjectIdMaps.valueIds);
    // const iModelConnection = UiFramework.getIModelConnection();
    const selectedObject: string = all3DObjectsMap.get([...objectIds][0]) as string;
        if(selectedObject){
        const [objectType, objectDetails] = selectedObject.split('#');
        let sType: EquipmentType;
        switch (objectType as GeomObjectType) {
          case GeomObjectType.EquipmentTypeGeom: {
              elementsOnTop = [
                {label: "Edit Properties", type: "button"},
                {label: "Object Transparency", type: "slider"},
                {label: "AGLNote", type: "note"},
              ];
              let {equipmentData}:any = store.getState().detectedData;
              let data = equipmentData.filter(equipments=>equipments.displayName == selectedObject.split('#')[1])[0]
              if (data && data.imageList !== "[]" && data.imageList !== "" && data.imageList !== null && data.imageList !== "['no_image']" && data.imageList !== "[\"no_image\"]" && !data.imageList.match(/no_image/ig))elementsOnTop.unshift({label: "Show Images", btnTooltip: "Show Images", disabled: false, type: "button"})
              else elementsOnTop.unshift({label: "Show Images", disabled: true, btnTooltip: "No Images Found", type: "button"})
              propListObj = {...propListObj, heading: "Equipment Information", propertyList: init_RM_EquipPropListState, elementsOnTop };
              let theSType: EquipmentType = EquipmentType.Antenna;
              switch (selectedObject.split('#')[1]) {
                case EquipmentType.Antenna:
                  theSType = EquipmentType.Antenna;
                  break;
                case EquipmentType.RRU:
                  theSType = EquipmentType.RRU;
                  break;
                case EquipmentType.Microwave:
                  theSType = EquipmentType.Microwave;
                  break;          
                case EquipmentType.SQUID:
                  theSType = EquipmentType.SQUID;
                  break;          
                default:
                  break;
              }
    
              sType = selectedObject.split('#')[1].match(/Antenna/ig) ? EquipmentType.Antenna : selectedObject.split('#')[1].match(/RRU/ig) ? EquipmentType.RRU : EquipmentType.Microwave;
              // objectProperties={...objectProperties, objectIds, objectId3DMaps, objectCategoryType: ObjectCategoryTypes.Equipment_RM, selectedObject: objectDetails};
              const equipName = selectedObject.split('#')[1];
              const equipInfo = await EquipmentsTable.getSelectedEquipmentInfoJson(equipName);
              // objectProperties={...objectProperties, selectionObjective: isEditActive ? selectionObjective.Edit : selectionObjective.Info, objectCategoryType: ObjectCategoryTypes.Equipment_RM,  selectedObjectNST: {name: equipName, displayName: equipInfo?.displayName, subType: sType}, objectIds};
              objectProperties={...objectProperties, selectionObjective: isEditActive ? selectionObjective.Edit : selectionObjective.Info, objectCategoryType: ObjectCategoryTypes.Equipment_RM,  selectedObjectNST: {name: equipName, displayName: equipName, subType: sType}, objectIds};
              SyncUiEventDispatcher.dispatchImmediateSyncUiEvent(SyncUiEventIds.RM_Equipment_Selected);
            }
            break;
          // case GeomObjectType.EquipmentFaceTypeGeom: {
          //     let name: string = "";
          //     if(objectDetails.includes("ANT")){
          //       name=`equipGeom#Antenna_${objectDetails.split('_')[1]}`;
          //       // SampleToolWidget.setEquipmentType(EquipmentType.Antenna);
          //     } else if(objectDetails.includes('MW')){
          //       name=`equipGeom#Micro_Wave_${objectDetails.split('_')[1]}`;
          //       // SampleToolWidget.setEquipmentType(EquipmentType.Microwave);
          //     } else if(objectDetails.includes('RRU')){
          //       name=`equipGeom#RRU_${objectDetails.split('_')[1]}`;
          //       // SampleToolWidget.setEquipmentType(EquipmentType.RRU);
          //     }
          //     // const revObjectId: string = all3DObjectsMapRev.get(name) as string;
          //     const equipName = name.split('#')[1];
          //     const equipInfo = await EquipmentsTable.getSelectedEquipmentInfoJson(equipName);
          //     sType = selectedObject.split('#')[1].match(/Ant/ig) ? EquipmentType.Antenna : selectedObject.split('#')[1].match(/RRU/ig) ? EquipmentType.RRU : EquipmentType.Microwave;
          //   // store.dispatch(DTVActions.setSelectedAsBuiltObjectDetails(selObjDet))
    
          //     // selCon.addToSelection([revObjectId]);
          //     // selCon.getSelection().add(revObjectId);
          //     elementsOnTop = [
          //       {label: "Edit Properties", type: "button"},
          //       {label: "Object Transparency", type: "slider"},
          //       {label: "AGLNote", type: "note"},
          //     ];
          //     let {equipmentData}:any = store.getState().detectedData;
          //     let data = equipmentData.filter(equipments=>equipments.displayName == equipName)[0]
          //     if (data && data.imageList !== "[]" && data.imageList !== "" && data.imageList !== null && data.imageList !== "['no_image']" && data.imageList !== "[\"no_image\"]" && !data.imageList.match(/no_image/ig))elementsOnTop.unshift({label: "Show Images", btnTooltip: "Show Images", disabled: false, type: "button"})
          //     else elementsOnTop.unshift({label: "Show Images", disabled: true, btnTooltip: "No Images Found", type: "button"})
          //     propListObj = {...propListObj, heading: "Equipment Information", propertyList:init_RM_EquipPropListState , elementsOnTop };
          //     // objectProperties={...objectProperties, ...selObjProps, objectCategoryType: ObjectCategoryTypes.Equipment_RM, selectedObject: i};
          //     // objectProperties={...objectProperties, objectIds, objectId3DMaps, objectCategoryType: ObjectCategoryTypes.Equipment_RM, selectedObject: name.split('#')[1]};
          //     objectProperties={...objectProperties, objectIds, selectionObjective: isEditActive ? selectionObjective.Edit : selectionObjective.Info, objectCategoryType: ObjectCategoryTypes.Equipment_RM, selectedObjectNST: {name: name.split('#')[1], displayName: equipInfo?.displayName, subType: sType}};
          //       // selCon.addToSelection(revObjectId);
          //     // selCon.selectionSet.add(revObjectId);
          //     SyncUiEventDispatcher.dispatchImmediateSyncUiEvent(SyncUiEventIds.RM_Equipment_Selected);
          //   }
          //   break;
          case GeomObjectType.MountTypeGeom:
            let mountName = selectedObject.split('#')[1].split("@")[3]
            elementsOnTop = [
              {label: "Object Transparency", type: "slider"},
            ];
              if(objectIds.size > 1 || objectDetails.match(/Standoff/)){
                // if(store.getState().detectedData.mountingsData.mounts[mountName].hasOwnProperty("scored") && store.getState().detectedData.mountingsData.mounts[mountName].scored){
                  // elementsOnTop.push({type: "btn-row", "buttons": [{label: "Set Model", type: "button"}, {label: "Detect Mount", type: "button"}]});
                  elementsOnTop.push({label: "Edit Properties", type: "button"});
                  elementsOnTop.push({label: "Identify Models", type: "button"} );
                // } else {
                //   elementsOnTop.push({label: "Set Mount Model", type: "button", disabled: true});
                //   elementsOnTop.push({label: "Identify Models", type: "button"});
                // }
                // if(store.getState().detectedData.mountingsData.mounts[mountName].hasOwnProperty("scored") && store.getState().detectedData.mountingsData.mounts[mountName].scored)elementsOnTop.push( {label: "Set Mount Model", type: "button"});
                // else elementsOnTop.push( {label: "Set Mount Model", type: "button", disabled: true});
                // elementsOnTop.push({label: "Add Supporting Member", type: "DropdownNButton"});
              }
              elementsOnTop.push({label: "AGLNote", type: "note"})
              propListObj = {...propListObj, heading: "Mount Information", propertyList: init_RM_MountPropListState};
              // if(objectIds.size > 1)
              propListObj.elementsOnTop = elementsOnTop;
              objectProperties={...objectProperties, selectionObjective: isEditActive ? selectionObjective.Edit : selectionObjective.Info, clicked: objectIds.size > 1 ? clickType.DOUBLE : clickType.SINGLE, objectCategoryType: ObjectCategoryTypes.Mount_RM, selectedObjectNST: {name: selectedObject.split('#')[1], subType: MountSectionType.Pipe}, objectIds};
              // objectProperties={...objectProperties, objectIds, objectCategoryType: ObjectCategoryTypes.Mount, selectedObject: i, clicked: objectIds.size > 1 ? click.DOUBLE : click.SINGLE};
              SyncUiEventDispatcher.dispatchImmediateSyncUiEvent(SyncUiEventIds.RM_Mount_Selected);
              break;
              
          case GeomObjectType.TowerTypeGeom:
            // elementsOnBottom = [
              //   {label: "Edit Properties", type: "DropdownNButton"},
              //   {label: "Object Transparency", type: "slider"},
              // ];
              elementsOnTop = [
                {label: "Object Transparency", type: "slider"},
                {label: "AGLNote", type: "note"},
                {label: "AMSLNote", type: "note"},
                {label: "ATBLNote", type: "note"},
              ];
              const marker = objectDetails.split('@')[0], type = "lattice";
              const header = [towerColorConvFooter(marker, type)];
              let Toss: TowerSubSectionType = TowerSubSectionType.TowerPole;
              switch (marker) {
                case TowerSubSectionType.GroundLevel:
                  Toss = TowerSubSectionType.GroundLevel;
                  break;              
                case TowerSubSectionType.HighestAppurtenance:
                  Toss = TowerSubSectionType.HighestAppurtenance;
                  break;              
                case TowerSubSectionType.LightningRod:
                  Toss = TowerSubSectionType.LightningRod;
                  break;              
                case TowerSubSectionType.TopOfSteel:
                  Toss = TowerSubSectionType.TopOfSteel;
                  break;              
                case TowerSubSectionType.TowerBase:
                  Toss = TowerSubSectionType.TowerBase;
                  break;              
                case TowerSubSectionType.TowerPole:
                  Toss = TowerSubSectionType.TowerPole;
                  break;              
              }
              propListObj = {...propListObj, heading: "Tower Information", header, propertyList: init_RM_TowerPropListState, elementsOnTop};
              if(objectIds.size > 1)propListObj.elementsOnBottom = elementsOnBottom;
              objectProperties={...objectProperties, selectionObjective: isEditActive ? selectionObjective.Edit : selectionObjective.Info, objectCategoryType: ObjectCategoryTypes.Tower_RM, selectedObjectNST: {name: marker, subType: Toss}, objectIds};
              SyncUiEventDispatcher.dispatchImmediateSyncUiEvent(SyncUiEventIds.RM_Tower_Selected);
              // objectProperties={...objectProperties, objectIds, objectCategoryType: ObjectCategoryTypes.Mount, selectedObject: i, clicked: objectIds.size > 1 ? click.DOUBLE : click.SINGLE};
              break;
        
          case GeomObjectType.BracingTypeGeom:
              // elementsOnBottom = [
              //   {label: "Edit Properties", type: "DropdownNButton"},
              //   {label: "Object Transparency", type: "slider"},
              // ];
              elementsOnTop = [
                {label: "AGLNote", type: "note"},
                {label: "Show In Image", type: "button"},
              ];
              // const marker = objectDetails.split('@')[0], type = "lattice";
              // const header = [towerColorConvFooter(marker, type)];
              propListObj = {...propListObj, heading: "Bracing Information", propertyList: init_RM_DefectPropListState, elementsOnTop};
              objectProperties={...objectProperties, selectionObjective: selectionObjective.Info, objectCategoryType: ObjectCategoryTypes.Bracings_RM, selectedObjectNST: {name: selectedObject.split('#')[1], subType: NotApplicable.NADEF}, objectIds};
              // objectProperties={...objectProperties, objectIds, objectCategoryType: ObjectCategoryTypes.Mount, selectedObject: i, clicked: objectIds.size > 1 ? click.DOUBLE : click.SINGLE};
              SyncUiEventDispatcher.dispatchImmediateSyncUiEvent(SyncUiEventIds.RM_Bracing_Selected);
              break;
        
          case GeomObjectType.GratingTypeGeom:
              // elementsOnBottom = [
              //   {label: "Edit Properties", type: "DropdownNButton"},
              //   {label: "Object Transparency", type: "slider"},
              // ];
              elementsOnTop = [
                {label: "AGLNote", type: "note"},
                {label: "Show In Image", type: "button"},
              ];
              // const marker = objectDetails.split('@')[0], type = "lattice";
              // const header = [towerColorConvFooter(marker, type)];
              propListObj = {...propListObj, heading: "Grating Information", propertyList: init_RM_DefectPropListState, elementsOnTop};
              objectProperties={...objectProperties, selectionObjective: selectionObjective.Info, objectCategoryType: ObjectCategoryTypes.Grating_RM, selectedObjectNST: {name: selectedObject.split('#')[1], subType: NotApplicable.NADEF}, objectIds};
              // objectProperties={...objectProperties, objectIds, objectCategoryType: ObjectCategoryTypes.Mount, selectedObject: i, clicked: objectIds.size > 1 ? click.DOUBLE : click.SINGLE};
              SyncUiEventDispatcher.dispatchImmediateSyncUiEvent(SyncUiEventIds.RM_Grating_Selected);
              break;
        
          case GeomObjectType.DefectTypeGeom:
              // elementsOnBottom = [
              //   {label: "Edit Properties", type: "DropdownNButton"},
              //   {label: "Object Transparency", type: "slider"},
              // ];
              elementsOnTop = [
                {label: "Show In Image", type: "button"},
              ];
              // const marker = objectDetails.split('@')[0], type = "lattice";
              // const header = [towerColorConvFooter(marker, type)];
              propListObj = {...propListObj, heading: "Defect Information", propertyList: init_RM_DefectPropListState, elementsOnTop};
              objectProperties={...objectProperties, selectionObjective: selectionObjective.Info, objectCategoryType: ObjectCategoryTypes.Defect_RM, selectedObjectNST: {name: selectedObject.split('#')[1], subType: NotApplicable.NADEF}, objectIds};
              // objectProperties={...objectProperties, objectIds, objectCategoryType: ObjectCategoryTypes.Mount, selectedObject: i, clicked: objectIds.size > 1 ? click.DOUBLE : click.SINGLE};
              break;

          case GeomObjectType.FeederTypeGeom:
            elementsOnTop = [
              {label: "Object Transparency", type: "slider"},
              {label: "AGLNote", type: "note"}
            ];
              propListObj = {...propListObj, heading: "FeedLine Information", propertyList: init_RM_FeederPropListState, elementsOnTop };
              objectProperties={...objectProperties, selectionObjective: selectionObjective.Info, objectCategoryType: ObjectCategoryTypes.FeedLine_RM,  selectedObjectNST: {name: selectedObject.split('#')[2], subType: NotApplicable.NA}, objectIds};
              SyncUiEventDispatcher.dispatchImmediateSyncUiEvent(SyncUiEventIds.RM_Feedline_Selected);
              break;
        
          case GeomObjectType.LadderTypeGeom:             
                elementsOnTop = [ 
                  {label: "Object Transparency", type: "slider"},
                  {label: "AGLNote", type: "note"}
                ];
                propListObj = {...propListObj, heading: "Ladder Information", propertyList: init_RM_LadderPropListState, elementsOnTop};
                objectProperties={...objectProperties, selectionObjective: selectionObjective.Info, objectCategoryType: ObjectCategoryTypes.Ladder_RM, selectedObjectNST: {name: selectedObject.split('#')[1], subType: NotApplicable.NA}, objectIds};
                SyncUiEventDispatcher.dispatchSyncUiEvent(SyncUiEventIds.RM_Ladder_Selected);
                break;
        
          default:
              break;
        }
      }
  }
  const temp = {...propListObj, objectProperties}
  store.dispatch(setSelectedObjectStructure(temp));
  // const mhd = IModelApp.viewManager.decorators.filter((e) => e.constructor.name.includes("ModifyHandleDecoration"))[0] as ModifyHandleDecoration;;

  // if(mhd)ModifyHandleDecoration.clear()
  if(isEditActive)editExecution(isEditActive ? selectionObjective.Edit : selectionObjective.Info, false)
  // if(equipMaps){
  //     equipMaps.objectIdMap.forEach((e: string, i: string): any=>{
  //       if(e==objectIds[0]){
  //         const elementsOnTop = [
  //           {label: "Show Images", type: "button"},
  //           {label: "Edit Properties", type: "button"},
  //           {label: "Object Transparency", type: "slider"},
  //         ];
  //         propListObj = {...propListObj, heading: "Equipment Information", propertyList: init_RM_EquipPropListState, elementsOnTop };
  //         // objectProperties={...objectProperties, ...selObjProps, objectCategoryType: ObjectCategoryTypes.Equipment_RM, selectedObject: i};
  //         objectProperties={...objectProperties, objectIds, objectId3DMaps, objectCategoryType: ObjectCategoryTypes.Equipment_RM, selectedObject: i};
  //       }
  //     })
  // }


  // if(towerMaps){
  //     towerMaps.objectIdMap.forEach((e: string, i: string): any=>{
  //       if(e==objectIds[0]){
  //         // const nvPair = getTowerKeyValue();
  //         // const footer = [towerColorConvFooter(i.split("@")[0], "lattice")];
  //         const elementsOnTop = [
  //           {label: "Object Transparency", type: "slider"},
  //         ];
  //         const marker = i.split('@')[0], type = "lattice";
  //         const header = [towerColorConvFooter(marker, type)];
  //         propListObj = {...propListObj, heading: "Tower Information", propertyList: init_RM_TowerPropListState, header, elementsOnTop };
  //         // objectProperties={...objectProperties, objectIds, objectId3DMaps, objectCategoryType: ObjectCategoryTypes.Tower, selectedObject: i};
  //         objectProperties={...objectProperties, objectIds, objectCategoryType: ObjectCategoryTypes.Tower, selectedObject: i};
  //       }
  //     })
  // }
  // if(mountMaps){
  //     mountMaps.objectIdMap.forEach((e: string, i: string): any=>{
  //       if(e==objectIds[0]){
  //         const elementsOnTop = [
  //           {label: "Object Transparency", type: "slider"},
  //         ];
  //         propListObj = {...propListObj, heading: "Mount Information", propertyList: initialMountPropListState, elementsOnTop };
  //         // objectProperties={...objectProperties, objectIds, objectId3DMaps, objectCategoryType: ObjectCategoryTypes.Mount, selectedObject: i, clicked: objectIds.size > 1 ? click.DOUBLE : click.SINGLE};
  //         objectProperties={...objectProperties, objectIds, objectCategoryType: ObjectCategoryTypes.Mount, selectedObject: i, clicked: objectIds.size > 1 ? click.DOUBLE : click.SINGLE};
  //       }
  //     })
  // }
  // if(bracingMaps){
  //     bracingMaps.objectIdMap.forEach((e: string, i: string): any=>{
  //       if(e==objectIds[0]){
  //         const elementsOnTop = [
  //           {label: "Object Transparency", type: "slider"},
  //         ];
  //         propListObj = {...propListObj, heading: "Bracings Information", propertyList: initialBracingsPropListState, elementsOnTop };
  //         // objectProperties={...objectProperties, objectIds, objectId3DMaps, objectCategoryType: ObjectCategoryTypes.Bracings, selectedObject: i};
  //         objectProperties={...objectProperties, objectIds, objectCategoryType: ObjectCategoryTypes.Bracings, selectedObject: i};
  //       }
  //     })
  // }

  // return {...propListObj, selectedObjectProperties: objectProperties};        
}
  
export const getFirstLetterUpperCase = (str) => {
  return `${str[0].toUpperCase()}${str.substring(1).toLowerCase()}`;
}