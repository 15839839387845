import EquipmentClient from "../api/equipment";
// import { FilterDataInt, SampleToolWidget } from "../app-ui/frontstages/SampleToolWidget";
// import { OutlineEquipmentTool } from "../components/tools/OutlineEquipmentTool";
import { EquipmentsTable } from "../components/Tables/EquipmentsTable";
import { ReduceDecimalNumberToTwoPlaces } from "../components/Lists/EquipmentList";
import { FRONTSTAGE_SETTINGS_NAMESPACE, SyncUiEventDispatcher, SyncUiEventId, UiFramework, UiItemsManager, WidgetState } from "@itwin/appui-react";
import { AccessToken, Guid } from "@itwin/core-bentley";
import { Logger } from "../api/logging";
import { AppSettings } from "../../config/AppSettings";
import { ConfigManager } from "../../config/ConfigManager";
import DatabaseApiClient from "../../services/graphql/database-api/database-api-client";
import { DTVActions } from "../../store/Actions";
import { RootState } from "../../store/States";
import { setSiteCoordinate, setMountingsData, setOperatorsData, setTowerSiteImagesData, setTowerData, setEquipmentData, setFeedLineData, setLadderData, setMountingSummaryData } from "../../store/detectedData/apiDataActionTypes";
import { SyncUiEventIds, projectInformationInterface, unitSystemType } from "../../store/redux-types";
import { store } from "../../store/rootReducer";
import FeederLineClient from "../api/feederLinesClient";
import { getLadderData } from "../api/ladderClient";
import mountClient from "../api/mountClient";

let _isInsiteSupported: boolean = true;
export interface FilterDataInt {mountName: string, highlighted: boolean, show: boolean};


const select = (state: RootState, dataKey: string) => {
  return state.detectedData[dataKey];
}

export const saveOperatorElevationMappingToStore = (operatorElevationMappingsData, mountingsData, siteCoords) => {
  let emList:any = [], convEMList: any = [];
  if(operatorElevationMappingsData.operators){
    const convFactor = AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.IMPERIAL ? 3.281 : 1;
    emList = Object.entries(mountingsData.mounts).map((e:any)=>{
      const elevation = ReduceDecimalNumberToTwoPlaces(e[1].utm_altitude - siteCoords.utm.z);
      const temp = operatorElevationMappingsData.operators.filter((o: { elevation: number; })=>{
        return o.elevation == elevation});
      const operator = temp.length ? temp[0].operator : "Select operator";
      return ({
        elevation: elevation,
        operator: operator,
        mountName: e[0]
      })
    })
    convEMList = emList.map((e: any)=>({...e, elevation: ReduceDecimalNumberToTwoPlaces(e.elevation*convFactor).toString()}));
    // SampleToolWidget.elOpMappings = JSON.parse(JSON.stringify(emList));
    if(emList)store.dispatch(DTVActions.setElevationOperatorMapping(JSON.parse(JSON.stringify(emList))));
  }
  return [emList, convEMList];
} 

const setElevationOperatorMapTool = async (tokenString: AccessToken) => {
    // const tokenString = store.getState().auth.accessTokenStatePrivateAPI.accessToken /*accessTokenPrivate is a string now*/;
    // const adminStatus = await EquipmentClient.getAdmin(tokenString);
    // if(adminStatus)setState({isUserAdmin: true});
    // const currState = store.getState();
    // let siteCoordinates = currState.detectedData.siteCoordinate;
    const {projectId} = select(store.getState(), "projectDetails");
    // store.getState().auth.accessTokenStatePrivateAPI.accessToken = store.getState().auth.accessTokenStatePrivateAPI.accessToken;

    try {
      const mountingsSummaryData = await mountClient.getMountingsSummaryDataJson(tokenString);
      store.dispatch(setMountingSummaryData(mountingsSummaryData));
    } catch (error) {
      console.log('Error occured while fetching mountingsSummaryData, ', error);
    }

    try {
     await Promise.all([
        EquipmentClient.setSiteCoordinates(tokenString),          // Storing site coordinate, for if equipments are edited & require update relative to site coordinates
        EquipmentClient.getMountingsDataJson(tokenString),      // Storing mountings data, for elevation operators mapping.
        EquipmentClient.getOperatorsJson(tokenString),           // Storing tower structure, for if equipments are edited they can be validated with the tower elevation values.
        DatabaseApiClient.retrieveElevationOperatorMapping(projectId!)
      ]).then(([siteCoords, mountingsData, operatorsData, operatorElevationMappingsData])=>{
        // SampleToolWidget.siteCoordinates  = siteCoords;
        // SampleToolWidget.mountsData       = mountingsData;
        // SampleToolWidget.operators        = operatorsData;
        store.dispatch(setSiteCoordinate(siteCoords));
        store.dispatch(setMountingsData(mountingsData));
        store.dispatch(setOperatorsData(operatorsData));
        if(siteCoords?.utm?.z){
          let ofd: FilterDataInt[] = [];
          saveOperatorElevationMappingToStore(operatorElevationMappingsData, mountingsData, siteCoords);
          ofd = Object.entries(mountingsData.mounts).map((e: any)=>({
              mountName: `${e[0][0].toUpperCase()+e[0].substr(1).replace(/[^a-z]/g, '')} ${parseInt(e[0].replace(/[^0-9]/g, ''))}`,
              highlighted: false,
              show: true
            })
          );
          // setState({operatorFilterData: {filterData: ofd, selectedMounts: [], selectedEquipment: []}});
          // setState({showEOMapModal:{...state.showEOMapModal, showActionButton: (mountingsData != null), existingMapping: convEMList, disabledToolTip: (mountingsData != null) ? null : "Mounts or Site details not found."}})
          UiFramework.frontstages.activeFrontstageDef?.findWidgetDef("OperatorFilterWidget")?.setWidgetState(WidgetState.Unloaded)
        } else {
          UiFramework.frontstages.activeFrontstageDef?.findWidgetDef("OperatorFilterWidget")?.setWidgetState(WidgetState.Hidden)
          // setState({showEOMapModal:{...state.showEOMapModal, showActionButton: false, existingMapping: [], disabledToolTip: "Mounts or Site details not found."}})
        }
      });

    } catch (err) {
      // setState({showEOMapModal:{...state.showEOMapModal, showActionButton: false, disabledToolTip: "Mounts or Site details not found."}})
      console.error('Error while fetching Equipment, Mount, Tower, Site or Operator related data: ', err);
    }
  }
 
  const loadInSiteUrl: any = () => {

    try {
      const url = (new URL(ConfigManager.INsiteImageUrl)).hostname+"/InSite";
      const anc = document.createElement("a");
      const iFr = document.createElement("iframe");
      iFr.id = "isIfr";
      iFr.name = "ifr";
      iFr.style.display = "none";
      anc.href = url;
      anc.target = "ifr";
      document.body.append(iFr);
      document.body.append(anc);
      anc.click();
    } catch (error) {
     Logger.error("Error when loadInSiteUrl in getDetectedObjectdata file",error);
    }
  }


  const getTowerImages = async() => {
    const towImg = await EquipmentClient.getTowerSiteImages(store.getState().auth.accessTokenStatePrivateAPI.accessToken /*accessTokenPrivate is a string now*/);
    if(towImg)store.dispatch(setTowerSiteImagesData(towImg))
    // if(towImg)this.setState({showPlanImgIcon: true});
    // this.setState({towerImage: towImg});
  }

  export const getEquipment: any = async (accessTokenPrivate: string): Promise<any> => {
    // const isInsiteSupported = store.getState().detectedData.insiteImageUrl.length ? true : false;
    const isInsiteSupported = ConfigManager.INsiteImageUrl.length ? true : false;
    
    try {
      await setElevationOperatorMapTool(accessTokenPrivate);
    } catch (error) {
    Logger.error('Error while fetching Elevation Operator Mapping: ', error);
    }

    try {
      let projectDetails: projectInformationInterface = store.getState().dtvState.applicationState.projectInformation!;
      if(projectDetails.instanceId == Guid.empty){projectDetails =  await DatabaseApiClient.getProjectName(ConfigManager.projectId!);}
  
      const manModelData = await EquipmentClient.getAllOrgManModels(accessTokenPrivate, projectDetails.properties.ultimateRefId);
      EquipmentsTable.equipManModelData = manModelData;
      EquipmentsTable.formattedEquipmentManModels = EquipmentsTable.getAntManModels(manModelData);
      const equipPolygons = manModelData.filter(e=>e.polygonPointId!=null);
      EquipmentsTable.equipPolygons = equipPolygons;
    } catch (error) {
      Logger.error('Error while fetching Equipment Manufacturer Model details.: ', error);
    }

    try {
      const allPolygonPoints = await EquipmentClient.getAllPolygons(accessTokenPrivate);
      if(allPolygonPoints.length)EquipmentsTable.allPolygonPoints = allPolygonPoints;
      // const theList = allPolygonPoints.map(app=>([`export const shapeId_${app.shapeId} = ${JSON.stringify(app.topProfile)}`]))
    } catch (error) {
      Logger.error('Error while fetching Elevation Operator Mapping: ', error);
    }

    try {
      const towerImages = await EquipmentClient.getTowerSiteImages(store.getState().auth.accessTokenStatePrivateAPI.accessToken /*accessTokenPrivate is a string now*/);
      if(towerImages)store.dispatch(setTowerSiteImagesData(towerImages))
    } catch (error) {
   Logger.error('Error while fetching Tower Images: ', error);
    }

    try {
      const towerStructure = await EquipmentClient.getTowerStructureJson(accessTokenPrivate);
      if(towerStructure)store.dispatch(setTowerData(towerStructure));
    } catch (error) {
     Logger.error('Error while fetching Tower Structure: ', error);
    }

    try {
      const feedLinesData = await FeederLineClient.getFeedLineData();
      if(feedLinesData) store.dispatch(setFeedLineData(feedLinesData));
    } catch (error) {
    }
    try {
      const ladder_data = await getLadderData();//Temporary example data.
      const ladder = ladder_data.data;
      if(ladder) store.dispatch(setLadderData(ladder));
    } catch (error) {
      Logger.error('Error while fetching FeedLine data: ', error);
    }
    if (isInsiteSupported) {
        // setState({showVizDefIcon: true});    // Task #767124
        fetchEquipmentData(accessTokenPrivate);
    } else {
      return false;
    }
  }

export const fetchEquipmentData = async(accessTokenPrivate: AccessToken): Promise<any> => {
      try {        
        const tempEquipTable: any = [];
        const validEquipData = await EquipmentClient.getAllEquipmentJson(accessTokenPrivate, "v1.1");
        // const validEquipData = equipData.filter(ed=>(ed.xPosition != null && ed.yPosition != null && ed.zPosition != null && ed.xPosition != null && ed.height != null && ed.width != null && ed.depth != null))
        if(validEquipData.length){
          // validEquipData.forEach((response) => {
            let temp: any = {};
            const xArr = ["XXXXX", "XXX", "xx", "xxx", "xxxxx"];
            const infoMap = new Map<string, any>(), posMap = new Map<string, any>();

            validEquipData.forEach((e: any) => {
              let correctedAzimuth: number = (Number(e.azimuth%360)+360)%360;
              if (e.type !== "Tower") {
                e.className = "Equipment";
                e.detailedEquipmentIdentificationId = "";
                e.operator = xArr.indexOf(e.operator) > -1 ? "" : e.operator;
                e.manufacturer = xArr.indexOf(e.manufacturer) > -1 ? "" : e.manufacturer;
                e.model = xArr.indexOf(e.model) > -1 ? "" : e.model;
                e.dimensions = `${e.height + "x" + e.width + "x" + e.depth}`;
                e.bandTechnology = xArr.indexOf(e.bandTechnology) > -1 ? "" : e.bandTechnology;
                e.faceName = e.face === null || e.face === "NA" || e.face === "" ? "" : `${e.face}`;

                // e.azimuth = Number(e.azimuth) > 180 ? Number(e.azimuth) - 360 : Number(e.azimuth);
                e.azimuth = correctedAzimuth;
                e.roll = Number(e.roll) > 180 ? Number(e.roll) - 360 : Number(e.roll);
                e.tilt = Number(e.tilt) > 180 ? Number(e.tilt) - 360 : Number(e.tilt);

                e.lateralOffset = e.lateralOffset === null || e.lateralOffset === "NA" ? 0.0 : e.lateralOffset;
                e.verticalOffset = e.verticalOffset === null || e.verticalOffset === "NA" ? 0.0 : e.verticalOffset;
                e.standoffDistance = e.standoffDistance === null || e.standoffDistance === "NA" ? 0.0 : e.standoffDistance;

                e.reference = xArr.indexOf(e.reference) > -1 ? "" : e.reference;

                e.id = UiFramework.getIModelConnection()?.transientIds.getNext();

                tempEquipTable.push(e);

                if (e.status === "Active" && (e.xPosition !== null || e.yPosition !== null && e.zPosition !== null)) {
                  const f = JSON.parse(JSON.stringify(e));
                  temp = {};

                  temp.detailedEquipmentIdentificationId = "";
                  temp.operator = f.operator;
                  temp.height = f.height;
                  temp.elevationHeight = f.elevationHeight;
                  temp.installedOn = f.installedOn == null ? "" : f.installedOn;
                  temp.type = f.type;
                  temp.brand = f.manufacturer;
                  temp.model = f.model;
                  temp.dimensions = `${f.height + "x" + f.width + "x" + f.depth}`;
                  temp.area = f.area === 0 || f.area == null ? "" : f.area;
                  // temp.azimuth = f.azimuth;
                  temp.azimuth = correctedAzimuth;
                  temp.position = f.position;
                  temp.downtilt = f.downtilt == null ? "" : f.downtilt;
                  temp.bandTechnology = f.bandTechnology;
                  temp.status = f.status;
                  temp.imageStatus = f.imageStatus;
                  // temp.yaw = f.azimuth;
                  temp.yaw = correctedAzimuth;
                  temp.pitch = f.pitch;
                  temp.imageList = f.imageList;
                  temp.name = f.name;
                  temp.reference = f.reference;
                  temp.equipmentId = f.equipmentId;
                  temp.vPipeId = f.vPipeId;
                  temp.lateralOffset = f.lateralOffset;
                  temp.standoffDistance = f.standoffDistance;
                  temp.verticalOffset = f.verticalOffset;
                  temp.displayName = f.displayName;
                  temp.width = f.width;
                  temp.depth = f.depth;
                  temp.className = f.className;
                  temp.id = f.id;

                  temp.roll = f.roll;

                  temp.faceName = f.face === "NA" || f.face === "" || f.face === null ? "Not Available" : `${f.face}`;

                  if (f.pitch !== undefined || f.pitch != null) {temp.pitch = Number(f.pitch) > 180 ? Number(f.pitch) - 360 : Number(f.pitch); } else {temp.pitch = 0; }

                  infoMap.set(f.name, temp);

                  temp = {};

                  // temp.Azimuth = e.azimuth;
                  temp.Azimuth = correctedAzimuth;
                  temp.Equipment_Name = e.name;
                  temp.Height = e.height;
                  temp.Elevation_Height = e.elevationHeight;
                  if (e.manufacturer !== "")temp.Manufacturer = e.manufacturer;
                  if (e.model !== "")temp.Model = e.model;

                  temp.Roll = e.roll;
                  temp.Thicness = e.depth;
                  temp.Tilt = e.tilt;
                  temp.Width = e.width;
                  temp.DisplayName = e.displayName;
                  temp.x_position = e.xPosition;
                  temp.y_position = e.yPosition;
                  temp.z_position = e.zPosition;
                  const equipPoly = EquipmentsTable.equipPolygons.filter(ep=>ep.manufacturer == e.manufacturer && ep.model == e.model);
                  if(equipPoly.length)temp.shapeId=equipPoly[0].polygonPointId;
                  posMap.set(e.name, temp);
                  // if (!state.loadedValidEquipData)setState({ loadedValidEquipData: true });
                }
              }

            });
            // SampleToolWidget.equipNameInfoMap = infoMap;
            // SampleToolWidget.equipNamePositionMap = posMap;
            EquipmentsTable.equipmentData = tempEquipTable;
            EquipmentsTable.equipNameInfoMap = infoMap;
            EquipmentsTable.equipNamePositionMap = posMap;
            // const existingValidEquipDataMaps = store.getState().detectedData.equipmentDataMaps;
            // const equipMaps: equipmentDataMaps = {...existingValidEquipDataMaps, equipNameInfoMap: new Map(infoMap), equipNamePositionMap: new Map(posMap), equipmentData: [...JSON.parse(JSON.stringify(tempEquipTable))]};

            store.dispatch(setEquipmentData(validEquipData));
            // store.dispatch(setEquipmentDataMaps(equipMaps));
            SyncUiEventDispatcher.dispatchSyncUiEvent(SyncUiEventIds.Implement_License);

            try {
              const newMountData = await EquipmentClient.getMountingsDataJson(accessTokenPrivate);
              store.dispatch(setMountingsData(JSON.parse(JSON.stringify(newMountData))));                
            } catch (error) {
              console.error("Error occurred while fetching mountings data: ", error)
            }
          // }).catch(() => {
          //   // tslint:disable-next-line:no-console
          //   SyncUiEventDispatcher.dispatchSyncUiEvent(SyncUiEventIds.Implement_License);
          // });
            // return equipMaps;
            return {equipNameInfoMap: new Map(infoMap), equipNamePositionMap: new Map(posMap), equipmentData: [...JSON.parse(JSON.stringify(tempEquipTable))], validEquipData}
        };
      } catch (error) {
      Logger.error('This is the error occured while fetching equipment, ', error);
        return false;
      }

  }


