import { Logger } from "../api/logging";
import { ConfigManager } from "../../config/ConfigManager";
import DatabaseApiClient from "../../services/graphql/database-api/database-api-client";
import { DTVActions } from "../../store/Actions";
import { DTVFeaturesInitialState } from "../../store/States";
import { LaunchDarklyFeatureFlags, SyncUiEventIds } from "../../store/redux-types";
import { store } from "../../store/rootReducer";
import { AccessToken } from "@itwin/core-bentley";
import FeatureFlagApiClient from "../api/featureFlag";
import { SyncUiEventDispatcher } from "@itwin/appui-react";

export const addFeatures = async (_accessTokenPrivate: AccessToken) => {
    let featureKeys = Object.keys(LaunchDarklyFeatureFlags);
    const flags: any = await FeatureFlagApiClient.featureFlags(Object.values(LaunchDarklyFeatureFlags), _accessTokenPrivate);

    let featureFlagsObj : any = DTVFeaturesInitialState;

    if(flags){
       
        flags.map((flag,index)=>{
            featureFlagsObj[featureKeys[index]] = flag
            
        })
        if(!featureFlagsObj["ShowEnableTierBasedLicensing"]){
    
            featureFlagsObj = {
                ...featureFlagsObj,
                ShowEnableTierBasedLicensing:true,
                ShowViewOnly:true,
                ShowRMMeasurementTools:true,
                ShowDMMeasurementTools:true,
                ShowIssuesTools:true,
                ShowSectionTools:true,
                ShowVersionCompare:true,
                ShowOrbitGT:true
            }
        }
    
        // const towerCapabilities = await DatabaseApiClient.getTowerCapabilities(ConfigManager.towerId!);
        // try {
        //     towerCapabilities.find((post: { code: string; }) => {
        //         switch (post.code) {
        //             case "ViewOnly":
        //                 featureFlagsObj["ShowViewOnly"] = true;
        //                 featureFlagsObj["ShowExplodeTool"] = true;        // NEEDSWORK: Move this tool to its own access code case
        //                 break;
        //             case "RMMeasurementTools":
        //                 featureFlagsObj["ShowRMMeasurementTools"] = true;
        //                 break;
        //             case "DMMeasurementTools":
        //                 featureFlagsObj["ShowDMMeasurementTools"] = true;
        //                 break;
        //             case "IssuesTools":
        //                 featureFlagsObj["ShowIssuesTools"] = true;
        //                 break;
        //             case "SectionTools":
        //                 featureFlagsObj["ShowSectionTools"] = true;
        //                 break;
        //             case "VersionCompare":
        //                 featureFlagsObj[ "ShowVersionCompare"] = true;
        //                 break;
        //             case "OrbitGT":
        //                 featureFlagsObj["ShowOrbitGT"] = true;
        //                 break;
        //         }
        //     });
        // } catch (e) {
        //     // tslint:disable-next-line:no-console
        //     Logger.error("Failed to initialize iModel", e);
        // }

    }
    
    // if(bracing) featureFlagsObj = {...featureFlagsObj, showBracings : bracing}
    // let bracing = await checkBracingData(accessTokenPrivate);
    store.dispatch(DTVActions.setUpDTVFeature (featureFlagsObj));
    SyncUiEventDispatcher.dispatchSyncUiEvent(SyncUiEventIds.Apply_Feature_Flag);
}
