import {useState} from "react";
import "./imageSlider360.scss";
import { Modal, ModalContent, ProgressRadial } from "@itwin/itwinui-react";
import React from "react";

const ImageSlider360 = (props:{title:any,height:any,width:any,insightImageUrl:any, showImageModal:any, toggleImageModal:any ,toggle:any,setToggle:any}) => {
    const [loading,setLoading]=useState(false)
    const [showModal, setShowModal]=useState(props.showImageModal)

    const nextIteration=()=>{
        setLoading(false)
        const  ifr=document.getElementById('inlineFrameExample')
        ifr?.setAttribute("style", "display: block;")
    }

    const hideSpinner=()=>{
        if(props.toggle){
            setTimeout(() => {
                nextIteration();
                props.setToggle(false);
            }, 10000);
        }
        else{
            setTimeout(()=>{
                nextIteration();
            },2000)
          
        }
    }

    return (
        <section>          
            {showModal && 


            <Modal
              isOpen={true}
              title={props.title}
              onClose={() => {setShowModal(false)}}
              closeOnEsc={true}
              style={{width: "1600px", height: "800px", maxWidth: "70%"}}
          >
              <ModalContent style={{height: "100%-50px"}}>
                <>
                    <div id="roundNround" style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '730px'}}>
                         {/* <Spinner size={SpinnerSize.XLarge} /> */}
                      {/* <LoadingSpinner style={{width: "100px"}} status="Loading..." title={"Loading panoramic view"} /> */}
                      <ProgressRadial size="large" style={{width: "160px", height: "200px"}} indeterminate value={50} >Loading...</ProgressRadial>
                    </div>
                    
                    <iframe id="inlineFrameExample"
                        title="Panoramic view"
                        width="100%"
                        height="730px"
                        src={props.insightImageUrl}
                        // src={"https://westeurope-dev.insite.bentley.com/insite_app_js/html/360_panoramic.html?site=Vantage_Segovia_Fine_Tunned&client=testOTIQ&pId=58862c13-ca4a-4020-801a-4b9aee1c22b7"}
                        style={{display: "none"}}
                        onLoadedData={()=>{

                        }}
                        onLoad={()=>{
                            setTimeout(() => {                                
                                const  ifr=document.getElementById('inlineFrameExample')
                                ifr?.setAttribute("style", "display: block;")
                                const  rnr=document.getElementById('roundNround')
                                rnr?.setAttribute("style", "display: none;")
                            }, 20000);
                            // setLoading(false)
                            }
                        }

                    />
                </>
                
              </ModalContent>


                {/* <div id="modal" className='imageViewModel'>
                    <ModalDialog title={props.title} Y={100} width={props.width} height={props.height} onClose={() => props.toggleImageModal()}>
                    <div className="imodel-viewport-dialog-container">
                        <div id='container'>
                        {loading ? (
                                        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '100%'}}>
                                             <Spinner size={SpinnerSize.XLarge} />
                                        </div>
                                        ) :null
                        }                      
                            <iframe id="inlineFrameExample"
                                title="Inline Frame"
                                width="100%"
                                height="100%"
                                src={props.insightImageUrl}
                                style={{display: "none"}}
                                onLoad={hideSpinner}
                                >
                            </iframe>
                        </div>
                    </div>    
                    </ModalDialog>
                 </div> */}
            </Modal>

            }
        </section>
    );
};


export default ImageSlider360;