import { BeButtonEvent, EventHandled, IModelApp, PrimitiveTool, Viewport } from "@itwin/core-frontend";
import { Point3d } from "@itwin/core-geometry";
import { TowerStructureDecorator } from "../../tools/decorators/TowerStructureDecorator";

export class PlaceTowerMarkerTool extends PrimitiveTool {
    public static override toolId = "PlaceTowerMarkerTool";
    public static override iconSpec = "icon-edit-all";
    thePoint: Point3d | undefined;
    decorator: any;
    public selection: string = "";
    public selPart: any;
    public towCyl: any;
    
    
    public static exitEquipDecorator: any | undefined;

    public onRestartTool(): Promise<void> {
        const tool = new PlaceTowerMarkerTool();
        if (!tool.run(this.selection))
            this.exitTool();
            return Promise.resolve();
    }

    public override isCompatibleViewport(_vp: Viewport | undefined, _isSelectedViewChange: boolean): boolean {
        return true;
    }

    public override run(selection: string): Promise<boolean> {
        super.run()
        this.selection = selection;
        const { toolAdmin, viewManager } = IModelApp;
        const towDec=IModelApp.viewManager.decorators.filter(e=>e.constructor.name.includes("TowerStructureDecorator"))[0] as TowerStructureDecorator;
        this.towCyl = towDec.hideMarkers ? towDec.backupCylinders: towDec.towerCylinders;
        this.selPart = this.towCyl.filter(e=>e.name===selection)[0];
        this.decorator = towDec;
        
        // if(!this.decorator){
            // this.decorator = new TowerStructureDecorator();
            // IModelApp.viewManager.addDecorator(this.decorator);
        // }

        if (!this.isCompatibleViewport(viewManager.selectedView, false) || !toolAdmin.onInstallTool(this)) {
            return Promise.resolve(false);
        }
        
        toolAdmin.startPrimitiveTool(this);
        toolAdmin.onPostInstallTool(this);
        return Promise.resolve(true);
    }

    public override async onDataButtonUp(_ev: BeButtonEvent): Promise<EventHandled> {
        this.thePoint=_ev.point;

        const towCyl = this.towCyl;
        const index = towCyl.findIndex(e=>e.name.includes(this.selPart.name));
        const stPt = towCyl[index].startPos;
        const endPt = towCyl[index].endPos;
        // endPt.z=endPtCart.height;
        endPt.z=this.thePoint.z-0.05;
        stPt.z=this.thePoint.z+0.05;
        towCyl[index].endPos = endPt;
        towCyl[index].startPos = stPt;
        this.decorator.towerCylinders=towCyl;
        const modVals = `${this.decorator.nameIdMap.get(this.selPart.name)}@${this.selPart.name}`;
        this.decorator.saveTower(modVals);
        this.decorator.hideMarkers=false;

        // this.decorator.backupCylinders[index]=this.decorator.towerCylinders[0];
        // this.decorator.towerCylinders=this.decorator.backupCylinders;

        // if (MainPage.workFlow !== "" && (IModelApp as any).refreshCallback) {
        //     (IModelApp as any).refreshCallback();
        // }
        this.exitTool();
        return EventHandled.Yes;
    }

    public override onPostInstall(): Promise<void> {
        super.onPostInstall();

        // Enable AccuSnap so that boxes can be created by snapping on existing model
        IModelApp.accuSnap.enableSnap(true);
        return Promise.resolve();
    }
}
