import { Arc3d, Box, CurveChainWithDistanceIndex, GeometryQuery, IndexedPolyface, IndexedPolyfaceVisitor, LineSegment3d, LineString3d, Loop, Matrix3d, Path, Point3d, PolyfaceBuilder, Transform, YawPitchRollAngles } from "@itwin/core-geometry";
import { ColorDef, LinePixels } from "@itwin/core-common";
import { DecorateContext, Decorator, GraphicBranch, GraphicBuilder, GraphicType, RenderGraphic } from "@itwin/core-frontend";
import { DecoratorHelper } from "./DecoratorHelper";
import { UiFramework } from "@itwin/appui-react";
import { EquipmentsTable } from "../../components/Tables/EquipmentsTable";

interface CustomGeometryQuery {
    geometry: GeometryQuery;
    color: ColorDef;
    fill: boolean;
    fillColor: ColorDef;
    lineThickness: number;
    edges: boolean;
    linePixels: LinePixels;
}
// tslint:disable:naming-convention
export class IndividualShapeDecorator implements Decorator {
    public useCachedDecorations?: true | undefined = true;

    private fill: boolean = true;
    private color: ColorDef = ColorDef.black;
    private lineThickness: number = 1;
    private edges: boolean = true;
    private linePixels = LinePixels.Solid;

    public static selectedEquipName: string | undefined;
    private loadedEquip: boolean = false;
    private currBox: Box | undefined;
    private currShape: CustomGeometryQuery | undefined;

    constructor (equipName: string) {
        IndividualShapeDecorator.selectedEquipName = equipName;
        this.loadedEquip = false;
        this.currBox = undefined;
    }

    public decorate(context: DecorateContext): void {
        if (IndividualShapeDecorator.selectedEquipName && !this.loadedEquip) {
            // load equipName
            this.loadEquipBox();
        }
        if (IndividualShapeDecorator.selectedEquipName && this.currBox) {
            const builder = PolyfaceBuilder.create();
            builder.addBox(this.currBox);
            const polyface = builder.claimPolyface(false);
            this.addGeometry(polyface, ColorDef.red);

            // const overrides = new ViewFlagOverrides();
            const overrides = {};
            // overrides.clipVolume = true;
            // overrides.setShowVisibleEdges(true);
            // overrides.setApplyLighting(true);
            // overrides.setRenderMode(RenderMode.SmoothShade);
            const branch = new GraphicBranch(false);
            branch.setViewFlagOverrides(overrides);

            branch.add(this.createGraphics(context)!);

            const graphic = context.createBranch(branch, Transform.identity);
            context.addDecoration(GraphicType.Scene, graphic);
        }
    }

    public async loadEquipBox() {
        if (!IndividualShapeDecorator.selectedEquipName) return;

        let name = IndividualShapeDecorator.selectedEquipName;

        name = name.replace(/-3SM/i, "");
        if (name === "Tower") return;

        // const json = SampleToolWidget.equipNamePositionMap.get(name);
        // const json = store.getState().detectedData.equipmentDataMaps.equipNamePositionMap.get(name);
        const json = EquipmentsTable.equipNamePositionMap.get(name);
        if (!json) return;

      //  json.Azimuth = -json.Azimuth; // TODO: negating Azimuth/Yaw value for now. Need to correct data in API.
        const pt = new Point3d(json.x_position, json.y_position, json.z_position);
        const box = this.constructBoxGeometry(json.Height, json.Width, json.Thicness, pt, json.Tilt, json.Azimuth, json.Roll);
        if (box) {
            this.currBox = box;
        }
        this.loadedEquip = true;
    }

    public addGeometry(geometry: GeometryQuery, fillColor: ColorDef) {
        const styledGeometry: CustomGeometryQuery = ({
            geometry,
            color: this.color,
            fill: this.fill,
            fillColor: ColorDef.fromTbgr(ColorDef.withTransparency(fillColor.tbgr, 127)),
            lineThickness: this.lineThickness,
            edges: this.edges,
            linePixels: this.linePixels,
        });
        this.currShape = styledGeometry;
    }

    public createGraphics(context: DecorateContext): RenderGraphic | undefined {
        const builder = context.createGraphicBuilder(GraphicType.Scene, undefined, context.viewport.iModel.transientIds.next);
        // builder.wantNormals = true;
        const styledGeometry = this.currShape;
        if (!styledGeometry) {
            return undefined;
        }
        const geometry = styledGeometry.geometry;
        builder.setSymbology(styledGeometry.color, styledGeometry.fillColor, styledGeometry.lineThickness, styledGeometry.linePixels);
        this.createGraphicsForGeometry(geometry, styledGeometry.edges, builder);

        const graphic = builder.finish();
        return graphic;
    }

    private constructBoxGeometry(height: number, width: number, thickness: number, pt: Point3d, _pitch: number = 360, yaw: number, _roll: number): Box | undefined {
        // pitch = tilt
        // yaw = azimuth
        const iModel = UiFramework.getIModelConnection()!;
        if (!iModel!.isBlank) {
            const o = iModel.spatialToCartographicFromEcef(iModel.projectExtents.high!);
            const ecef = DecoratorHelper.ExtractSpatialXYZ(o, pt.x, pt.y, pt.z, iModel);
            pt.x = ecef.x;
            pt.y = ecef.y;
            pt.z = ecef.z;
        }
        const ypr = YawPitchRollAngles.createDegrees(yaw, _pitch, _roll);
        const rightPoints = this.CreatePoint2BoxWithVector(pt, ypr.toMatrix3d(), height, width, thickness, iModel.isBlank);
        const matrix = ypr.toMatrix3d();
        matrix.transposeInPlace();
        const box = Box.createDgnBoxWithAxes(rightPoints[0], matrix, rightPoints[1],
            width, thickness, width, thickness, true);
        return box;
    }

    private createGraphicsForGeometry(geometry: GeometryQuery, wantEdges: boolean, builder: GraphicBuilder) {
        if (geometry instanceof LineString3d) {
            builder.addLineString(geometry.points);
        } else if (geometry instanceof Loop) {
            builder.addLoop(geometry);
            if (wantEdges) {
                // Since decorators don't natively support visual edges,
                // We draw them manually as lines along each loop edge/arc
                // builder.setSymbology(ColorDef.black, ColorDef.black, 2);
                const curves = geometry.children;
                curves.forEach((value) => {
                    if (value instanceof LineString3d) {
                        let edges = value.points;
                        const endPoint = value.pointAt(0);
                        if (endPoint) {
                            edges = edges.concat([endPoint]);
                        }
                        builder.addLineString(edges);
                    } else if (value instanceof Arc3d) {
                        builder.addArc(value, false, false);
                    }
                });
            }
        } else if (geometry instanceof Path) {
            builder.addPath(geometry);
        } else if (geometry instanceof IndexedPolyface) {
            builder.addPolyface(geometry, true);
            if (wantEdges) {
                // Since decorators don't natively support visual edges,
                // We draw them manually as lines along each facet edge
                builder.setSymbology(ColorDef.black, ColorDef.black, 2);
                const visitor = IndexedPolyfaceVisitor.create(geometry, 1);
                let flag = true;
                while (flag) {
                    const numIndices = visitor.pointCount;
                    for (let i = 0; i < numIndices - 1; i++) {
                        const point1 = visitor.getPoint(i);
                        const point2 = visitor.getPoint(i + 1);
                        if (point1 && point2) {
                            builder.addLineString([point1, point2]);
                        }
                    }
                    flag = visitor.moveToNextFacet();
                }
            }
        } else if (geometry instanceof LineSegment3d) {
            const pointA = geometry.point0Ref;
            const pointB = geometry.point1Ref;
            const lineString = [pointA, pointB];
            builder.addLineString(lineString);
        } else if (geometry instanceof Arc3d) {
            builder.addArc(geometry, false, false);
        } else if (geometry instanceof CurveChainWithDistanceIndex) {
            this.createGraphicsForGeometry(geometry.path, wantEdges, builder);
        }
    }

    /*
     Thicness -> Thicness (m) of the equipment
     Width -> Width (m) of the equipment
     xCenter -> coordinate X (m)  - center of the equipment
     yCenter -> coordinate Y (m)  - center of the equipment
     zCenter -> coordinate Z (m)  - center of the equipment
     height -> Height (m) of the equipemnt
     Yaw -> Yaw/Azimuth (degrees) of the Equipment
    */
     private CreatePoint2BoxWithVector(midPoint: Point3d, yprMatrix: Matrix3d, height: number, width: number, depth: number, htBuffer: boolean): Point3d[] {
        let heightBuffer: number;
        if (htBuffer) {
          heightBuffer = UiFramework.getIModelConnection()!.spatialToCartographicFromEcef(midPoint).height;
          midPoint.z = (midPoint.z - heightBuffer) + midPoint.z;
        } else {
          midPoint.z = midPoint.z;
        }
        const vecX = yprMatrix.rowX();
        vecX.normalizeInPlace();
        const vecY = yprMatrix.rowY();
        vecY.normalizeInPlace();
        const vecZ = yprMatrix.rowZ();
        vecZ.normalizeInPlace();

        let baseOrigin = midPoint.plusScaled(vecZ, -0.5 * height);
        let topOrigin = midPoint.plusScaled(vecZ, 0.5 * height);
        baseOrigin = baseOrigin.plusScaled(vecX, -0.5 * width);
        topOrigin = topOrigin.plusScaled(vecX, -0.5 * width);
        baseOrigin = baseOrigin.plusScaled(vecY, -0.5 * depth);
        topOrigin = topOrigin.plusScaled(vecY, -0.5 * depth);

        return [baseOrigin, topOrigin];
      }
}
