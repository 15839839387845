import { Guid } from "@itwin/core-bentley";
import { DigitalTwinViewerApp } from "../../api/DigitalTwinViewerApp";
import { ConfigManager } from "../../config/ConfigManager";
import DatabaseApiClient, { OrganizationAddOnProductProjectInputType } from "../../services/graphql/database-api/database-api-client";
import { DTVActions } from "../../store/Actions";
import { licenseProductIds, projectInformationInterface } from "../../store/redux-types";
import { store } from "../../store/rootReducer";

   //-----------------------------------
   export const implementLicenseWorkflow = async () => {
    let inspectionToolsFlag: boolean = false, theOverUsageLimit = 0, currentOverUseCount = 0,  licenseExpiryDate = new Date(), disableTT='';
    let projectDetails: projectInformationInterface = store.getState().dtvState.applicationState.projectInformation!;
    if(projectDetails.instanceId == Guid.empty){projectDetails =  await DatabaseApiClient.getProjectName(ConfigManager.projectId!);}

    let orgAddOndata      = await DatabaseApiClient.getOrganizationAddOnProducts(projectDetails.properties.ultimateRefId);
    let projectAddOndata  = await DatabaseApiClient.getProjectAddOnProducts(ConfigManager.projectId);
    let licenseComsumed   = projectAddOndata.filter((pad: any) => pad.addOnProductId == licenseProductIds.InspectionTools);
    

    if(licenseComsumed.length){
        inspectionToolsFlag = true;
        store.dispatch(DTVActions.setCheckForLincese(false));
        DigitalTwinViewerApp.checkForLicense = false;
        const newLicVal = {inspectionLicense: {
            ...DigitalTwinViewerApp.licenseStates.inspectionLicense,
            licenseEnabled: inspectionToolsFlag, 
            currentOverUseCount, 
            overUsageLimit: 0, 
            licenseExpiryDate, 
            disableToolTip: disableTT
        }};
        DigitalTwinViewerApp.licenseStates = newLicVal;
        store.dispatch(DTVActions.setLinceseStates(newLicVal));
        // SyncUiEventDispatcher.dispatchSyncUiEvent(SyncUiEventIds.Implement_License);
        // this.setState({licenseStates: {...this.state.licenseStates, inspectionLicense: {licenseEnabled: inspectionToolsFlag, currentOverUseCount, overUsageLimit: 0, licenseExpiryDate, disableToolTip: disableTT}}})
        return;
    } else {
        // store.dispatch(DTVActions.setCheckForLincese(true));
        DigitalTwinViewerApp.checkForLicense = true;
    }
    const inspAddOnLic = orgAddOndata.filter((oad: any)=>oad.addOnProductId == licenseProductIds.InspectionTools)[0];
    if(inspAddOnLic){
      const { licenseExpiresOn, overUsage: currentOverUsage, remainingLicense: remainingLicensesCount, overUsageLimit: orgOverUseLimit } = inspAddOnLic;
      
      theOverUsageLimit = orgOverUseLimit;
      currentOverUseCount = currentOverUsage;
      licenseExpiryDate = new Date(licenseExpiresOn);

      //The expiration date is not relevent at the moment as we are allowing the overuse of the tools.
      // const today: any = new Date();
      // const LicenseExpiryDate: any = new Date(licenseExpiresOn);
      // const remainingDays = Math.ceil((LicenseExpiryDate - today) / (1000 * 60 * 60 * 24));
      
      if(orgOverUseLimit!=0){        
        let globalAddOnData = await DatabaseApiClient.getGlobalAddOnProducts();
        const {overUsageLimit: globalOverUseLimit } = globalAddOnData.filter((gad: any)=>gad.addOnProductId==licenseProductIds.InspectionTools)[0];
        theOverUsageLimit = orgOverUseLimit ? orgOverUseLimit : globalOverUseLimit;
      }
      if(currentOverUsage < theOverUsageLimit || remainingLicensesCount > 0)inspectionToolsFlag = true;
      else disableTT = "Inspection Tool License overusage limit reached."
    } else {
      disableTT = "Inspection Tools License needed."
    }
    const newLicVal = {
        inspectionLicense: {
            ...DigitalTwinViewerApp.licenseStates.inspectionLicense, 
            licenseEnabled: inspectionToolsFlag, 
            currentOverUseCount, 
            overUsageLimit: theOverUsageLimit, 
            licenseExpiryDate, 
            disableToolTip: disableTT 
        }
    };
    DigitalTwinViewerApp.licenseStates = newLicVal;
    store.dispatch(DTVActions.setLinceseStates(newLicVal));
    // SyncUiEventDispatcher.dispatchSyncUiEvent(SyncUiEventIds.Implement_License);
    // callback();
}

  // const getUltimateIdOfUser = () => {
  //   // var Buffer = require('buffer/');
  //   let token = store.getState().auth.accessTokenStatePrivateAPI.accessToken!;
  //   let base64Payload = token.split('.')[1];
  //   let payload = require('buffer/').Buffer.from(base64Payload, 'base64');
  //   let tokenProperties =JSON.parse(payload.toString())
  //   return tokenProperties.ultimate_site
  // }

  export const deductLicense = async (productId: number) => {
    let params: OrganizationAddOnProductProjectInputType = {
      ultimateId: "",
      towerId: ConfigManager.towerId!,
      projectId: ConfigManager.projectId!,
      addOnProductId:productId
    }
    DTVActions.deductLicenseOnFeatureUse(params);
    let retVal: boolean = await DatabaseApiClient.handleProjectAddOnProductLicense(params);
    // if(retVal) store.dispatch(DTVActions.setCheckForLincese(false));
    if(retVal) DigitalTwinViewerApp.checkForLicense = false;
  }
