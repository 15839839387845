import React from "react";;
import { StagePanelLocation, UiFramework, WidgetState } from "@itwin/appui-react";
import { ConfigurableCreateInfo, SyncUiEventDispatcher, WidgetControl, WidgetDef } from "@itwin/appui-react";
import { AddEditPolygonShapeTool } from "../../components/tools/AddEditPolygonShapeTool";
import { PolygonShapeDecorator } from "../decorators/PolygonShapeDecorator";
import { HighlightGratingTool } from "../../components/tools/HighlightGratingTool";
import { store } from "../../../store/rootReducer";
import { PolygonShapeControl } from "../../components/controls/PolygonShapeControl";

export class PolygonTransformWidget extends WidgetControl {

    private currDef: WidgetDef | undefined;
    //------------------------------------------------------------------------------------
    constructor(info: ConfigurableCreateInfo, options: any) {
        super(info, options);
        this.reactNode = <PolygonShapeControl/>;

        this.currDef = UiFramework.frontstages.activeFrontstageDef?.getStagePanelDef(StagePanelLocation.Right)?.findWidgetDef("PoligonTransform");

        SyncUiEventDispatcher.onSyncUiEvent.addListener((args) => {
            if (args.eventIds.has("polygonSelected") && this.currDef?.state !== WidgetState.Open && store.getState().dtvState.applicationState.isEditModeActive) {
                this.setWidgetState(WidgetState.Open);
            }
            if (args.eventIds.has("polygonSelected") && this.currDef?.state !== WidgetState.Hidden) {
                this.setWidgetState(WidgetState.Hidden);
            }
        });
    }
    //------------------------------------------------------------------------------------
    // Static Mediator functions for called in  PolygonShapeControll class
    // see : PolygonShapeControl.tsx
    //------------------------------------------------------------------------------------
    public static async translatePolygon(dx: number, dy: number, dz: number): Promise<void> {
        if (PolygonShapeDecorator.lastSelectedPolygonShape != undefined) {
            AddEditPolygonShapeTool.poligonInfoArray;
            PolygonShapeDecorator.lastSelectedPolygonShape;//holds the refrence to the last selected Grating polygon that will be transformed.

            for (let n = 0; n < AddEditPolygonShapeTool.poligonInfoArray.length; n++) {
                if (AddEditPolygonShapeTool.poligonInfoArray[n]?.name == PolygonShapeDecorator.lastSelectedPolygonShape?.name) {
                    for (let m = 0; m < AddEditPolygonShapeTool.poligonInfoArray[n].poligonPoints.length; m++) {
                        AddEditPolygonShapeTool.poligonInfoArray[n].poligonPoints[m].x += dx;
                        AddEditPolygonShapeTool.poligonInfoArray[n].poligonPoints[m].y += dy;
                        AddEditPolygonShapeTool.poligonInfoArray[n].poligonPoints[m].z += dz;
                        }
                    }
                }
            AddEditPolygonShapeTool.createPoligonFromPolygonInfoArray(AddEditPolygonShapeTool.poligonInfoArray);
        }
    }
    //------------------------------------------------------------------------------------
    public static async updatePointCountForPolygonCreation(pointCount: number) {
        AddEditPolygonShapeTool.pointCountPerPolygon = pointCount;
    }
    //------------------------------------------------------------------------------------
    public static async snapToElevationPlane(mod: boolean) {
        if (PolygonShapeDecorator.lastSelectedPolygonShape != undefined) {
            AddEditPolygonShapeTool.snapGratingPointToNearestPlaneByName(PolygonShapeDecorator.lastSelectedPolygonShape.name, mod);
        }
    }
    //------------------------------------------------------------------------------------
    public static async createJointsAndSupportingMembers() {
        if (PolygonShapeDecorator.lastSelectedPolygonShape != undefined) {
            AddEditPolygonShapeTool.createJointsAndSupportingMembersByName(PolygonShapeDecorator.lastSelectedPolygonShape.name,false);
        }
    }
    //------------------------------------------------------------------------------------
    public static async levelAndCreateJointsAndSupportingMembers() {
        if (PolygonShapeDecorator.lastSelectedPolygonShape != undefined) {
            AddEditPolygonShapeTool.snapGratingPointToNearestPlaneByName(PolygonShapeDecorator.lastSelectedPolygonShape.name, true);
            AddEditPolygonShapeTool.createJointsAndSupportingMembersByName(PolygonShapeDecorator.lastSelectedPolygonShape.name,true);
        }
    }
    //------------------------------------------------------------------------------------
    public static async deleteSelectedPolygon() {
        if (PolygonShapeDecorator.lastSelectedPolygonShape != undefined) {
            AddEditPolygonShapeTool.deletePolygonByName(PolygonShapeDecorator.lastSelectedPolygonShape.name);
            if (HighlightGratingTool.isEnabled) {
                AddEditPolygonShapeTool.deleteSavedGratingByName(PolygonShapeDecorator.lastSelectedPolygonShape.name);
            }
        }
    }
    //------------------------------------------------------------------------------------
    public static async saveSelectedPolygonData() {
        if (PolygonShapeDecorator.lastSelectedPolygonShape != undefined) {
            AddEditPolygonShapeTool.saveExportGratingsData();
        }
    }
    //------------------------------------------------------------------------------------
}
