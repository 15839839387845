import { RenderMode } from "@itwin/core-common";
import { BeButtonEvent, EventHandled, IModelApp, NotifyMessageDetails, OutputMessagePriority, OutputMessageType, PrimitiveTool, Viewport } from "@itwin/core-frontend";
import EquipmentClient from "../../api/equipment";
import { SyncUiEventDispatcher, UiFramework } from "@itwin/appui-react";
import { StagePanelLocation, WidgetState } from "@itwin/appui-abstract";
import { EquipmentInformation, EquipmentsTable } from "../Tables/EquipmentsTable";
import { editExecution, resetMountingsData, resetObjectIds } from "../HorizontalToolbarItems";
import { RootState } from "../../../store/States";
import { setSiteCoordinate, updateBuilt3DObjectIdsMap, setSelectedObjectStructure, setMountingsData } from "../../../store/detectedData/apiDataActionTypes";
import { ObjectCategoryTypes, objectProperties, clickType, selectedObjectInformation, initialPropertyListObjectState, init_RM_EquipPropListState, mountingsData, NotApplicable, FeedlineSectionType } from "../../../store/detectedData/apiDataTypes";
import { EquipmentType, selectionObjective, SyncUiEventIds } from "../../../store/redux-types";
import { store } from "../../../store/rootReducer";
import { EquipmentPosition } from "../../tools/decorators/EditableShapeDecorator";
import { EquipmentShapeDecorator } from "../../tools/decorators/EquipmentShapeDecorator";
import { IndividualShapeDecorator } from "../../tools/decorators/IndividualShapeDecorator";
import { HighlightMountTool } from "./HighlightMountTool";
import { Point3d } from "@itwin/core-geometry";
import { CreateGeometryMode, CustomGeometryQuery, FeedlineData, FeedlineDecorator } from "../../tools/decorators/FeedlineDecorator";
import { DecoratorHelper } from "../../tools/decorators/DecoratorHelper";
import { Id64String } from "@itwin/core-bentley";

const select = (state: RootState, dataKey: string) => {
    return state.detectedData[dataKey];
}

export class AddFeedlineTool extends PrimitiveTool {
    public static override toolId = "addFeedlineTool";
    public static override iconSpec = "icon-edit-all";
    public static decorator: any | undefined;
    public static newClusterId: string = "";
    private feedlinePositions: Point3d[] = [];
    public equipmentType: EquipmentType = EquipmentType.Antenna;

    public onRestartTool(): Promise<void> {
        const tool = new AddFeedlineTool();
        if (!tool.run(this.equipmentType))
            this.exitTool();
        return Promise.resolve();
    }

    public override isCompatibleViewport(_vp: Viewport | undefined, _isSelectedViewChange: boolean): boolean {
        return true;
    }

    public override run(equipmentType: EquipmentType): Promise<boolean> {
        super.run();
        this.equipmentType = equipmentType;
        AddFeedlineTool.decorator = IModelApp.viewManager.decorators.filter((e) => e.constructor.name.includes("FeedlineDecorator"))[0] as FeedlineDecorator;
        const exisitngFLData = store.getState().detectedData.feedLinesData;
        AddFeedlineTool.newClusterId = `${(exisitngFLData[exisitngFLData.length-1].clusterId!+1)}_${AddFeedlineTool.decorator.shapes.length}`;
        const { toolAdmin, viewManager } = IModelApp;

        if (!this.isCompatibleViewport(viewManager.selectedView, false) || !toolAdmin.onInstallTool(this)) {
            return Promise.resolve(false);
        }

        toolAdmin.startPrimitiveTool(this);
        toolAdmin.onPostInstallTool(this);
        return Promise.resolve(true);
    }

    private drawNewFeedline = (start, end, finalized: boolean = false) => {
        let decorator = AddFeedlineTool.decorator;
        const newClusterId = AddFeedlineTool.newClusterId;
        const coordOrder = start.z < end.z ? {base: start, top: end} : {base: end, top: start}
        // let decorator = IModelApp.viewManager.decorators.filter((e) => e.constructor.name.includes("FeedlineDecorator"))[0] as FeedlineDecorator;
        const theFeedData: FeedlineData = {
            Azimuth: 0,
            Height: 0,
            Width: 0,
            Thickness: 0,
            Id: newClusterId,
            CenterPositions: {
                localBase: coordOrder.base,
                localTop: coordOrder.top
            },
            ActualPositions: {
                base: coordOrder.base,
                top: coordOrder.top
            },
            FeedlineType: {
                type: FeedlineSectionType.NonSegmented,
                segmentCount: 0,
                absoluteLength: coordOrder.base.distance(coordOrder.top)
            }
        };

        theFeedData.Height = 10;
        theFeedData.Width = .2;
        theFeedData.Thickness = .5;
        theFeedData.CenterPositions.localBase.x = coordOrder.base.x - 0.5 * theFeedData.Width;
        theFeedData.CenterPositions.localBase.y = coordOrder.base.y - 0.5 * theFeedData.Thickness;
        theFeedData.CenterPositions.localTop.x = coordOrder.top.x - 0.5 * theFeedData.Width;
        theFeedData.CenterPositions.localTop.y = coordOrder.top.y - 0.5 * theFeedData.Thickness;
        if(finalized) decorator.createGeometry(theFeedData, CreateGeometryMode.New);
        else decorator.createGeometry(theFeedData, CreateGeometryMode.Edit);



    }

    public override async onDataButtonUp(_ev: BeButtonEvent): Promise<EventHandled> {
        const iModel = UiFramework.getIModelConnection()!;
        const stPtCart = iModel!.spatialToCartographicFromEcef(_ev.point);
        const stPtUtm = DecoratorHelper.convertWGS2UTM(stPtCart);

        // this.feedlinePositions.push(stPtUtm);
        this.feedlinePositions.push(_ev.point);
        if(this.feedlinePositions.length == 2){
            let decorator = AddFeedlineTool.decorator;
            const shapeIndex = decorator.shapes.findIndex(e=>e.modelData.Id == AddFeedlineTool.newClusterId);
            if(shapeIndex!=-1)decorator.shapes.splice(shapeIndex, 1);
            const newShapes = decorator.shapes.filter(e=>!e.modelData.Id.match(/start|middle|end/));
            decorator.shapes = newShapes;

            const newClusterId = `${AddFeedlineTool.newClusterId}`;

            this.drawNewFeedline(this.feedlinePositions[0], this.feedlinePositions[1], true);

            IModelApp.viewManager.invalidateDecorationsAllViews();
            IModelApp.viewManager.selectedView?.invalidateCachedDecorations(decorator);
    
            
            resetObjectIds(decorator);

            // const id = decorator.shapes.find(e=>e.modelData?.Id === `CustomFeedline_${decorator.shapes.length-1}`)?.transientId;
            const id = decorator.shapes.find(e=>e.modelData?.Id === newClusterId)?.transientId;
            // const equipId = this.nameIdMap.get(name);
            // const equipId2 = this.objectIdMap.get(name);
            
            // const iModelConnection = UiFramework.getIModelConnection();
            // iModelConnection?.selectionSet.emptyAll();
            // iModelConnection?.selectionSet.add(id as Id64String);
      
            let objectProperties: objectProperties = {
                selectionObjective: selectionObjective.Info,
                objectIds: undefined,
                objectDetails: undefined,
                objectCategoryType: ObjectCategoryTypes.FeedLine_RM,
                selectedObjectNST: {name: "", subType: EquipmentType.None},
                clicked: clickType.SINGLE
            }, elementsOnTop: any[] = [], elementsOnBottom: any[] = [], propListObj: selectedObjectInformation = initialPropertyListObjectState;
            
            elementsOnTop = [
            //   {label: "Show Images", type: "button"},
            //   {label: "Edit Properties", type: "button"},
              {label: "Object Transparency", type: "slider"},
            ];
            propListObj = {...propListObj, heading: "Feedline Information", propertyList: init_RM_EquipPropListState, elementsOnTop };
            // objectProperties={...objectProperties, objectIds, objectId3DMaps, objectCategoryType: ObjectCategoryTypes.Equipment_RM, selectedObject: objectDetails};
            // objectProperties={...objectProperties, selectionObjective: selectionObjective.Add, objectCategoryType: ObjectCategoryTypes.FeedLine_RM,  selectedObjectNST: {name: `CustomFeedline_${decorator.shapes.length-1}`, subType: NotApplicable.NA}, objectIds: [id!]};
            objectProperties={...objectProperties, selectionObjective: selectionObjective.Add, objectCategoryType: ObjectCategoryTypes.FeedLine_RM,  selectedObjectNST: {name: newClusterId, subType: NotApplicable.NA}, objectIds: [id!]};
            SyncUiEventDispatcher.dispatchSyncUiEvent(SyncUiEventIds.RM_Feedline_Selected);
            const theShape = AddFeedlineTool.decorator.shapes.find(e=>e.modelData?.Id == AddFeedlineTool.newClusterId) as CustomGeometryQuery;
            AddFeedlineTool.decorator.selectedFeedline = {transId: theShape.transientId, modelId: theShape.modelData?.Id!};    

            const temp = {...propListObj, objectProperties}
            store.dispatch(setSelectedObjectStructure(temp));
          
            editExecution(selectionObjective.Add)

            this.exitTool();
            return EventHandled.Yes;
        } else return EventHandled.No;
    }

    public override async onMouseMotion(_ev: BeButtonEvent): Promise<void> {
        if(this.feedlinePositions.length == 1 && this.feedlinePositions[0].distance(_ev.point) >= 0.2){
            // this.feedlinePositions[1] = _ev.point;
            const decorator = AddFeedlineTool.decorator;
            // const shapeIndex = decorator.shapes.findIndex(e=>e.modelData.Id == AddFeedlineTool.newClusterId);
            // if(shapeIndex!=-1)decorator.shapes.splice(shapeIndex, 1);
            const newShapes = decorator.shapes.filter(e=>!e.modelData.Id.match(/start|middle|end/));
            decorator.shapes = newShapes;
            IModelApp.viewManager.invalidateDecorationsAllViews();
            IModelApp.viewManager.selectedView?.invalidateCachedDecorations(decorator);
            this.drawNewFeedline(this.feedlinePositions[0].clone(), _ev.point.clone());
        }
    }

    public override async onResetButtonUp(_ev: BeButtonEvent): Promise<EventHandled> {
        this.exitTool();

        return EventHandled.No;
    }

    public override async onDataButtonDown(_ev: BeButtonEvent): Promise<EventHandled> {
        // this.feedlinePositions.push(_ev.point);
        // let decorator = IModelApp.viewManager.decorators.filter((e) => e.constructor.name.includes("EquipmentShapeDecorator"))[0] as EquipmentShapeDecorator;

        // // let decorator = SampleToolWidget.currEquipDecorator;
        // let newBoxData: EquipmentPosition | undefined;
        // const equipmentType = this.equipmentType;
        // let siteCoordinates = select(store.getState(), "siteCoordinate");
        
        // if (!siteCoordinates) {
        //     siteCoordinates = await EquipmentClient.setSiteCoordinates(store.getState().auth.accessTokenStatePrivateAPI.accessToken /*accessTokenPrivate is a string now*/);     // Storing site coordinate, for if equipments are edited & require update relative to site <coordinates className="" />
        //     store.dispatch(setSiteCoordinate(siteCoordinates));
        // }
    // if (decorator != undefined) {
    //         newBoxData = await decorator.addNewBox(_ev.point, this.equipmentType);
    //     } else {
    //         const vp = IModelApp.viewManager.selectedView;
    //         if (vp) vp.viewFlags = vp.viewFlags.copy({renderMode: RenderMode.SmoothShade});
    //         decorator = new EquipmentShapeDecorator();
    //         // SampleToolWidget.currEquipDecorator = decorator;
    //         newBoxData = await decorator.addNewBox(_ev.point, this.equipmentType);
    //         AddFeedlineTool.exitEquipDecorator = IModelApp.viewManager.addDecorator(decorator);
    //     }
    //     if (newBoxData) {
    //         // SampleToolWidget.equipNamePositionMap.set(newBoxData!.Equipment_Name, newBoxData);
    //         // save height as such that when Box is drawn later on GET,
    //         // applying height buffer gives the current height
    //         // Do this by applying an "opposite" height buffer for saved height value
    //         const id = decorator.nameIdMap.get(newBoxData.Equipment_Name);

    //         const infoData: EquipmentInformation = {
    //             name: newBoxData.Equipment_Name,
    //             displayName: newBoxData.Equipment_Name,
    //             manufacturer: newBoxData.Manufacturer,
    //             model: newBoxData.Model,
    //             type: equipmentType,
    //             operator: "",
    //             height: newBoxData.Height,
    //             elevationHeight: newBoxData.z_position-siteCoordinates.utm.z,
    //             width: newBoxData.Width,
    //             depth: newBoxData.Thicness,
    //             weight: 0,
    //             azimuth: newBoxData.Azimuth,
    //             yaw: newBoxData.Azimuth,
    //             pitch: newBoxData.Azimuth,
    //             tilt: newBoxData.Tilt,
    //             xPosition: newBoxData.x_position,
    //             yPosition: newBoxData.y_position,
    //             zPosition: newBoxData.z_position,
    //             roll: newBoxData.Roll,
    //             area: 0,
    //             bandTechnology: "",
    //             reference: "",
    //             className: "Equipment",
    //             id: id!,
    //             imageList: "",
    //         };

    //         // SampleToolWidget.selectedBoxInformation = infoData;
    //         // SampleToolWidget.selectedBoxName = newBoxData!.Equipment_Name;
    //         // SampleToolWidget.equipNameInfoMap.set(newBoxData!.Equipment_Name, infoData);

    //         IndividualShapeDecorator.selectedEquipName = infoData.name;
    //         // Make API call with box information
    //         const token = store.getState().auth.accessTokenStatePrivateAPI.accessToken;
    //         const infoRes = await EquipmentClient.postEquipmentJson(token, infoData, "v1.1");
            
    //         // const selectionSet = UiFramework.getIModelConnection()?.selectionSet?.elements;
    //         const currentState = store.getState();

    //         const nameIndex = infoData.name.split('_');
    //         let name: string = "", type: EquipmentType | null = null;
    //         if(infoData.name.includes("Antenna")){
    //           name=`ANT_${nameIndex[1]}`;
    //           type = EquipmentType.Antenna;
    //         } else if(infoData.name.includes('Micro_Wave')){
    //           name=`MW_{${nameIndex[1]}`;
    //           type = EquipmentType.Microwave;
    //         } else if(infoData.name.includes('RRU')){
    //           name=`RRU_{${nameIndex[1]}`;
    //           type = EquipmentType.RRU;
    //         }
        
    //         const objIdVals = [...new Set(decorator.objectIdMap.values())];
    //         const objIdIndexes = [...new Set(decorator.objectIdMap.keys())];
    //         const faceIndex = objIdVals.findIndex(e=>e==`equipFaceGeom#${name}_face`);
    //         const built3ObjectMaps = currentState.detectedData.built3DObjectIdMaps;
    //         const newIdVals: any[] = [...built3ObjectMaps.idValues]
    //         newIdVals.push([id, `equipGeom#${infoData.name}`]);
    //         newIdVals.push([objIdIndexes[faceIndex], `equipFaceGeom#${name}_face`]);
    //         const newValIds: any[] = newIdVals.map((e: any[])=>[...e].reverse())
    //         store.dispatch(updateBuilt3DObjectIdsMap({idValues: newIdVals, valueIds: newValIds}));


    //         // const existingMaps = store.getState().detectedData.equipmentDataMaps;
    //         // const newEquipInfoMap = new Map(existingMaps.equipNameInfoMap);
    //         // newEquipInfoMap.set(newBoxData!.Equipment_Name, infoData);
    //         EquipmentsTable.equipNameInfoMap.set(newBoxData!.Equipment_Name, infoData);
    //         // const newEquipInfoMaps: equipmentDataMaps = {...existingMaps, equipNameInfoMap: newEquipInfoMap, tempEquipMap: [...decorator.currJson]};
    //         // const newEquipInfoMaps: equipmentDataMaps = {...existingMaps, equipNameInfoMap: newEquipInfoMap};
    //         // store.dispatch(setEquipmentDataMaps(newEquipInfoMaps));
    
    //         // store.dispatch(setSelectedObjectStructure(selObj))

    //         // store.dispatch(DTVActions.setSelectedAsBuiltObjectDetails(selObj))

    //         if (!infoRes) {
    //             IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Error,
    //                 `Error occured: ${equipmentType} not saved.`, "", OutputMessageType.Toast));
    //         // } else {
    //         //     const mountsData: mountingsData = await EquipmentClient.getMountingsDataJson(store.getState().auth.accessTokenStatePrivateAPI.accessToken!)
    //         //     let equipFaceReturned = "";
    //         //     if(mountsData){
    //         //         equipFaceReturned = mountsData.equipment[infoData.name].Face;
    //         //         store.dispatch(setMountingsData(mountsData));

    //         //         if(store.getState().dtvState.applicationState.highlightStates.mount){
    //         //             await IModelApp.tools.run(HighlightMountTool.toolId, {}, false, ["None"]);
    //         //             await resetMountingsData(mountsData);
    //         //             SyncUiEventDispatcher.dispatchSyncUiEvent(SyncUiEventIds.Enable_Highlight_Mount);
    //         //         }
    //         //     }

    //         //     // const equipData: any[] = [...store.getState().detectedData.equipmentDataMaps.equipmentData!];
    //         //     const equipData: any[] = EquipmentsTable.equipmentData;
    //         //     const equipTableData = {
    //         //         name:infoData.name,
    //         //         displayName:infoData.displayName,
    //         //         manufacturer:infoData.manufacturer,
    //         //         model:infoData.model,
    //         //         type:infoData.type,
    //         //         operator:infoData.operator,
    //         //         height:infoData.height,
    //         //         elevationHeight:infoData.elevationHeight,
    //         //         width:infoData.width,
    //         //         depth:infoData.depth,
    //         //         weight:infoData.weight,
    //         //         azimuth:infoData.azimuth,
    //         //         yaw:infoData.azimuth,
    //         //         pitch:infoData.roll,
    //         //         tilt:infoData.tilt,
    //         //         xPosition:infoData.xPosition,
    //         //         yPosition:infoData.yPosition,
    //         //         zPosition:infoData.zPosition,
    //         //         roll:infoData.roll,
    //         //         faceName:equipFaceReturned,
    //         //         lateralOffset:infoData.lateralOffset,
    //         //         standoffDistance:infoData.standoffDistance,
    //         //         verticalOffset:infoData.verticalOffset,
    //         //         area:infoData.area,
    //         //         bandTechnology:infoData.bandTechnology,
    //         //         reference:infoData.reference,
    //         //         imageList:infoData.imageList,
    //         //         className:"Equipment",
    //         //         dimensions:infoData.dimensions,
    //         //         id:infoData.id
    //         //     };
    //         //     equipData.push(equipTableData);
    //         //     EquipmentsTable.equipmentData.push(equipTableData);



    //         //     let objectProperties: objectProperties = {
    //         //         selectionObjective: selectionObjective.Info,
    //         //         objectIds: undefined,
    //         //         objectDetails: undefined,
    //         //         objectCategoryType: ObjectCategoryTypes.Equipment_RM,
    //         //         selectedObjectNST: {name: "", subType: EquipmentType.Antenna},
    //         //         clicked: clickType.SINGLE
    //         //     }, elementsOnTop: any[] = [], elementsOnBottom: any[] = [], propListObj: selectedObjectInformation = initialPropertyListObjectState;
                
    //         //     elementsOnTop = [
    //         //       {label: "Show Images", type: "button"},
    //         //       {label: "Edit Properties", type: "button"},
    //         //       {label: "Object Transparency", type: "slider"},
    //         //     ];
    //         //     propListObj = {...propListObj, heading: "Equipment Information", propertyList: init_RM_EquipPropListState, elementsOnTop };
    //         //     // objectProperties={...objectProperties, objectIds, objectId3DMaps, objectCategoryType: ObjectCategoryTypes.Equipment_RM, selectedObject: objectDetails};
    //         //     objectProperties={...objectProperties, selectionObjective: selectionObjective.Add, objectCategoryType: ObjectCategoryTypes.Equipment_RM,  selectedObjectNST: {name: infoData.name, subType: EquipmentType.Antenna}, objectIds: [id!]};
    //         //     SyncUiEventDispatcher.dispatchSyncUiEvent(SyncUiEventIds.RM_Equipment_Selected);
      
    //         //     const temp = {...propListObj, objectProperties}
    //         //     store.dispatch(setSelectedObjectStructure(temp));
              
    //         //     // const selObj: selectedObjectInformation = {
    //         //     //     objectProperties: {
    //         //     //         selectionObjective: selectionObjective.Add,
    //         //     //         objectIds: [new Set(selectionSet)],
    //         //     //         objectDetails: undefined,
    //         //     //         objectCategoryType: ObjectCategoryTypes.Equipment_RM,
    //         //     //         selectedObjectNST: {
    //         //     //             name: newBoxData.Equipment_Name,
    //         //     //             subType: EquipmentType.Antenna
    //         //     //         },
    //         //     //         clicked: clickType.SINGLE
    //         //     //     },
    //         //     //     heading: "",
    //         //     //     propertyList: {},
    //         //     //     header: [],
    //         //     //     footer: [],
    //         //     //     elementsOnTop: [],
    //         //     //     elementsOnBottom: []
    //         //     // }
    
    //         //         // SampleToolWidget.selectedList=ListEnum.Equipment;   //Required to set the selectedList to idenitify which 3D object is currently being edited.

    //         //     // if (MainPage.workFlow !== "" && (IModelApp as any).refreshCallback) {
    //         //     //     (IModelApp as any).refreshCallback();
    //         //     // }
    //         //     // FrontstageManager.activeFrontstageDef?.getStagePanelDef(StagePanelLocation.Right)?.
    //         //     //     findWidgetDef("PropertyListWidget")?.setWidgetState(WidgetState.Open);
    //         //     SyncUiEventDispatcher.dispatchSyncUiEvent("equipmentselected");
    //         //     IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Info,
    //         //       `Success: ${equipmentType} saved.`, "", OutputMessageType.Toast));

    //         //   //  IModelApp.tools.run(HighlightMountTool.toolId, [], false);
    //         // //   setTimeout(async () => {
    //         // //     const result = await EquipmentClient.getEquipmentOffsetJson(token);
    //         // //     //  IModelApp.tools.run(HighlightMountTool.toolId, result, true);
    //         // //     for (const dec of IModelApp.viewManager.decorators) {
    //         // //       const n = dec.constructor.name;
    //         // //       if (n.includes("MountDecorator")) {
    //         // //         (dec as MountDecorator).clearAndReload(result);
    //         // //       }
    //         // //     }
    //         // //   }, 10000);
    //         }
    //     }
        // setTimeout(() => {
            // editExecution(selectionObjective.Add)
        // }, 0);
        // this.exitTool();
        return EventHandled.No;
    }

    public override onPostInstall(): Promise<void> {
        super.onPostInstall();

        // Enable AccuSnap so that boxes can be created by snapping on existing model
        IModelApp.accuSnap.enableSnap(true);
        return Promise.resolve();
    }
}
