import { BeButtonEvent, EventHandled, IModelApp, PrimitiveTool, Viewport } from "@itwin/core-frontend";
import { WidgetState, UiFramework } from "@itwin/appui-react";
import { ModifyHandleDecoration } from "./modification/ModifyHandleDecoration";
import { setEquipmentData3DMaps, addToBuilt3DObjectIdsMap, setEquipmentData } from "../../../store/detectedData/apiDataActionTypes";
import { store } from "../../../store/rootReducer";
import { ShapeDecorator } from "../../tools/decorators/ShapeDecorator";
import { EquipmentDataStructure } from "../../../store/detectedData/apiDataTypes";
import EquipmentClient from "../../api/equipment";
import { DTVActions } from "../../../store/Actions";

export class OutlineShapeTool extends PrimitiveTool {
    public static override toolId = "outlineShapes";
    public static override iconSpec = "icon-3d";

    public static dropDecorator: any | undefined;

    public override isCompatibleViewport(_vp: Viewport | undefined, _isSelectedViewChange: boolean): boolean {
        return true;
    }

    public override async run(selectedEquipment: string[] = ["All"], filtering: boolean = false): Promise<boolean> {
        super.run()
        const { toolAdmin, viewManager } = IModelApp;
        if (!this.isCompatibleViewport(viewManager.selectedView, false) || !toolAdmin.onInstallTool(this)) {
            return Promise.reject(false);
        }
        const currentState = store.getState();
        toolAdmin.startPrimitiveTool(this);

        if (OutlineShapeTool.dropDecorator && (selectedEquipment[0] == "All" || selectedEquipment[0] == "None") && !filtering) {
            const widget = UiFramework.frontstages.activeFrontstageDef?.findWidgetDef("EquipmentEditWidget");
            if(widget?.state == WidgetState.Open)widget?.setWidgetState(WidgetState.Hidden);
            if(ModifyHandleDecoration._decorator)ModifyHandleDecoration.clear();
            const highlightStates = currentState.dtvState.applicationState.highlightStates;
            if(currentState.dtvState.applicationState.projectStatus.isInsightAvailable)store.dispatch(DTVActions.updateHighlightState({...highlightStates, equipment: false}))
        
            OutlineShapeTool.dropDecorator();
            OutlineShapeTool.dropDecorator = undefined;
            ModifyHandleDecoration.clear();
            this.exitTool();
            return Promise.resolve(false);
        } else {
            let equipDec: any = IModelApp.viewManager.decorators.filter((e) => e.constructor.name.includes("ShapeDecorator"));
            let storedED = currentState.detectedData.equipmentData;
            let data: EquipmentDataStructure[];
            if(storedED.length === 0){
              data = await EquipmentClient.getAllEquipmentJson(store.getState().auth.accessTokenStatePrivateAPI.accessToken, "v1.1");
              if(data) store.dispatch(setEquipmentData(data));
              else return Promise.reject(false);
            }
       
            if (!equipDec.length) {
                const decorator = new ShapeDecorator();
                decorator.loadShapes(storedED, selectedEquipment);

                store.dispatch(addToBuilt3DObjectIdsMap(new Map(decorator.objectIdMap)));
                OutlineShapeTool.dropDecorator = IModelApp.viewManager.addDecorator(decorator);
            } else if(equipDec.length && (selectedEquipment[0] != "All" && selectedEquipment[0] != "None" || filtering)) {
                equipDec = equipDec[0] as ShapeDecorator
                equipDec.loadShapes(storedED, selectedEquipment);
            } else {// Add box tool already started equip decorator
                equipDec = equipDec[0] as ShapeDecorator
                IModelApp.viewManager.dropDecorator(equipDec);
                // OutlineShapeTool.dropDecorator = AddBoundingBoxTool.exitEquipDecorator;
                equipDec.loadShapes(storedED, selectedEquipment);
                store.dispatch(setEquipmentData3DMaps(new Map(equipDec.nameIdMap)));
                store.dispatch(addToBuilt3DObjectIdsMap(new Map(equipDec.objectIdMap)));
            }
        }
        IModelApp.viewManager.selectedView?.invalidateDecorations();
        this.exitTool();

        return Promise.resolve(true);
    }
    public override async onResetButtonUp(_ev: BeButtonEvent): Promise<EventHandled> {
        this.exitTool();

        return EventHandled.No;
    }

    public onRestartTool(): Promise<void> {
        return Promise.resolve();
    }
}