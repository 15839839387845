import { pecColumn, pecInputTagTypes, pecDataValueTypes, pecRows, pecGridStructure, pecComponentProps, pecStrucType, pecTableButton, PropertyEditorComponent } from "../components/PropertyEditorComponent"

import React, { ReactNode, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { UiFramework } from "@itwin/appui-react"
import { IModelApp } from "@itwin/core-frontend"
import { Vector3d } from "@itwin/core-geometry"
import { DTVActions } from "../../store/Actions"
import { RootState } from "../../store/States"
import { objectProperties, TowerSubSectionType } from "../../store/detectedData/apiDataTypes"
import { store } from "../../store/rootReducer"
import { TowerStructureDecorator } from "./decorators/TowerStructureDecorator"
import { resetObjectIds } from "../components/HorizontalToolbarItems"

export const enum RadioSection {
    Start = "Start",
    Whole = "Whole",
    End = "End",
  }
let runLoop:any;
const DetectedTowerEditHandler = (props: {onClose: any, editSection: RadioSection, detTowerMenuStruc: pecGridStructure, selObjProps: string}) => {
  const [towerEditData, setTowerEditData] = useState<pecGridStructure>(props.detTowerMenuStruc);
  const [selection, setSelection] = useState(props.editSection);
  const [dataUpdated, setDataUpdated] = useState<boolean>(false);
  const move = 0.01;
  const radiusMove = 0.02;

  useEffect(() => {
    setTowerEditData(getTowerStructure(props.selObjProps));
  }, [props.selObjProps])
  
  useEffect(() => {
  
    // return () => {}
  }, [])
  
  const onModalBtnClick = (event) => {
    const id = event.target.id;
    switch (id) {
      case "Save#1":
        // saveMount();
        const decorator = IModelApp.viewManager.decorators.filter(e=>e.constructor.name.includes("TowerStructureDecorator"))[0] as TowerStructureDecorator;
        decorator.saveTower(props.selObjProps);
        UiFramework.dialogs.modeless.close("Edit Dialog");
        break;
      case "Cancel#2":
        UiFramework.dialogs.modeless.close("Edit Dialog");
        props.onClose("TowerStructureDecorator", true);
        store.dispatch(DTVActions.setEditModeFlag(false));
        break;
    }
  }
  
  const onFieldUpdate = (event) => {
    let currentEvent= event;
  }
  
  const onFieldChange = (event) => {
    const temp: pecGridStructure = {...towerEditData};    
    const eventType = event.constructor.name;
    
    if((eventType == "SyntheticBaseEvent") || (typeof event === 'object' && event !== null)){
      const {id: theId, name: theName, value: theValue} = event.target;
      const [inputType, rowIndex, columnIndex] = theId.split("@")[0].split('-');

      
      switch (inputType) {
        case pecInputTagTypes.SELECT:
          temp.rows[rowIndex].columns[columnIndex].value = temp.rows[rowIndex].columns[columnIndex].data![theValue];
          break;
        case pecInputTagTypes.CHECKBOX:
          temp.rows[rowIndex].columns[columnIndex].value = !temp.rows[rowIndex].columns[columnIndex].value;
          break;
        case pecInputTagTypes.SWITCH:
          temp.rows[rowIndex].columns[columnIndex].value = !temp.rows[rowIndex].columns[columnIndex].value;
          updateTowerGeom(props.selObjProps, temp.rows[rowIndex].columns[columnIndex].value as boolean);
          break;
      
        default:
          break;
      }
      
      
    } else {    
      switch (eventType) {
      case "Number":
        
      break;
      
      default:
        break;
      }

    }


    setTowerEditData(temp);
  }

  const updateTowerGeom = async (currentObjName: string, showHide: boolean = true) => {
    const towDec=IModelApp.viewManager.decorators.filter(e=>e.constructor.name.includes("TowerStructureDecorator"))[0] as TowerStructureDecorator;
    towDec.hideMarkers = showHide;
    towDec.selectedPart = currentObjName.split("-")[0];
    let towData = towDec.getTowerGeometry;      
    if(showHide){
      towDec.towerCylinders = towDec.backupCylinders;
    } else {
      // setTowMarkCheck(evt.target.checked);
      towDec.backupCylinders = towDec.towerCylinders;
    }
    towDec.terminate()
    towDec.loadShapes(towData);
    IModelApp.viewManager.invalidateDecorationsAllViews();
    IModelApp.viewManager.selectedView?.invalidateCachedDecorations(towDec);
    resetObjectIds(towDec);
  }

  
  const onFieldClick = (event) => {
    let currentEvent= event;
  }
  
  
  const onMouseDown = (event) => {
    const target = event.target.tagName != "SPAN" ? event.target : event.target.parentElement;;
    enum editMode {
      Position = "Positional Changes",
      Length = "Cylinder Height Changes",
      Radius = "Cylinder Radius Changes",
    }
    const [elementDetail, name] = target.id.split('@');
    const shapeName = props.selObjProps != "Tower Pole" ? `${props.selObjProps}@Marker` : props.selObjProps;
    const decorator = IModelApp.viewManager.decorators.filter(e=>e.constructor.name.includes("TowerStructureDecorator"))[0] as TowerStructureDecorator;
    let objectEditMode:editMode=editMode.Position, movementVector: Vector3d = new Vector3d(), strucMultFactor = 1;
    switch (name) {
      case "moveLeft":
        movementVector = new Vector3d(move, 0, 0);        
        break;
      case "moveRight":
        movementVector = new Vector3d(-move, 0, 0);        
        break;
      case "moveFront":
        movementVector = new Vector3d(0, move, 0);        
        break;
      case "moveBack":
        movementVector = new Vector3d(0, -move, 0);        
        break;
      case "moveUp":
        movementVector = new Vector3d(0, 0, move);        
        break;
      case "moveDown":
        movementVector = new Vector3d(0, 0, -move);        
        break;    
      case "increaseLength":
        objectEditMode = editMode.Length;
        break;    
      case "decreaseLength":
        objectEditMode = editMode.Length;
        strucMultFactor = -1;
        break;    
      case "increaseRadius":
        objectEditMode = editMode.Radius;
        break;    
      case "decreaseRadius":
        objectEditMode = editMode.Radius;
        strucMultFactor = -1;
        break;    
    }

    if (decorator && shapeName && objectEditMode == editMode.Position){
      setDataUpdated(true);
      runLoop=setInterval(()=>decorator.updateCylinderPosition(shapeName, movementVector, selection), 50);
      setTimeout(() => clearInterval(runLoop), 10000);
    } else if( decorator && shapeName && objectEditMode == editMode.Radius){
      setDataUpdated(true);
      runLoop=setInterval(()=>decorator.updateCylinderRadius(shapeName, move*strucMultFactor, selection), 50);
      setTimeout(() => clearInterval(runLoop), 10000);
    } else if( decorator && shapeName && objectEditMode == editMode.Length){
      setDataUpdated(true);
      runLoop=setInterval(()=>decorator.updateCylinderHeight(shapeName, move*strucMultFactor), 50);
      setTimeout(() => clearInterval(runLoop), 10000);
    }

  }
  
  const onMouseUp = () => {
    clearInterval(runLoop);        
  }
  
  const modalBtns: pecTableButton[] = [
    {name: "Save", value: "Save", class: "dialogBox-save-btn", disable: false, onInteract: onModalBtnClick},
    {name: "Cancel", value: "Cancel", class: "dialogBox-cancel-btn", disable: false, onInteract: onModalBtnClick},
  ];
  
  
  let towerEditorStructure: pecComponentProps = {
    structureType: pecStrucType.STRUCTURE,
    title: {
      label: "Tower Edit",
      // styles: undefined
    },
    grid: props.detTowerMenuStruc,
    gridButtons: modalBtns,
    eventHandlers: {
      onChange: onFieldChange,
      onUpdate: onFieldUpdate,
      onClick: onFieldClick,
      onMouseDown: onMouseDown,
      onMouseUp: onMouseUp,
    }
  }

  return (
    <PropertyEditorComponent {...towerEditorStructure} />
    // <PropertyEditorComponent structureType={pecStrucType.STRUCTURE} title={{label: "Tower Edit"}} gridButtons={modalBtns} eventHandlers={{onChange: onFieldChange, onUpdate: onFieldChange, onClick: onFieldClick}} grid={towerEditData} />
  )
}

const mapStateToProps = (state: RootState) => {
  const td = IModelApp.viewManager.decorators.filter(e=>e.constructor.name.includes("TowerStructureDecorator"))[0] as TowerStructureDecorator;
  const marker = td.selectedPart? td.selectedPart : td.previousSelection?.name
  const tempTitle = marker? marker.split("@")[0] : "";
  const editSection = RadioSection.Whole;

  const stateData = state;
  const towerStructure = getTowerStructure(tempTitle);
  const retVal = {
    detTowerMenuStruc: towerStructure,
    editSection,
    selObjProps: tempTitle
  };
  return retVal;
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(DetectedTowerEditHandler)

const getTowerStructure = (title: string) => {
  const editSection = RadioSection.Whole;
    const rows: pecRows[] = [
      {
        columns: [
          {
            size: 8,
            type: pecInputTagTypes.LABEL,
            customClass: "",
            value: "Move Left or Right",
            disabled: false,
            name: "mvLorR",
            fieldType: pecDataValueTypes.INTEGER,
            modified: false,
            styles: {parentClass: "mb-2", childClass: ""}
          },
          {
            size: 2,
            type: pecInputTagTypes.BUTTON,
            customClass: "",
            value: "L",
            disabled: false,
            name: "moveLeft",
            fieldType: pecDataValueTypes.INTEGER,
            modified: false,
            styles: {parentClass: "mb-2", childClass: "number-input-class"}
          },      
          {
            size: 2,
            type: pecInputTagTypes.BUTTON,
            customClass: "",
            value: "R",
            disabled: false,
            name: "moveRight",
            fieldType: pecDataValueTypes.INTEGER,
            modified: false,
            styles: {parentClass: "mb-2", childClass: "number-input-class"}
          },      
        ]
        
      },
      {
          columns: [
            {
              size: 8,
              type: pecInputTagTypes.LABEL,
              customClass: "",
              value: "Move Front or Back",
              disabled: false,
              name: "mvForB",
              fieldType: pecDataValueTypes.INTEGER,
              modified: false,
              styles: {parentClass: "mb-2", childClass: ""}
            },
            {
              size: 2,
              type: pecInputTagTypes.BUTTON,
              customClass: "",
              value: "F",
              disabled: false,
              name: "moveFront",
              fieldType: pecDataValueTypes.INTEGER,
              modified: false,
              styles: {parentClass: "mb-2", childClass: "number-input-class"}
            },      
            {
              size: 2,
              type: pecInputTagTypes.BUTTON,
              customClass: "",
              value: "B",
              disabled: false,
              name: "moveBack",
              fieldType: pecDataValueTypes.INTEGER,
              modified: false,
              styles: {parentClass: "mb-2", childClass: "number-input-class"}
            },      
          ]
          
      },
  ] 
 
  if(title.includes("Pole") || title.includes("lattice")){
      rows.unshift(
          {
              columns: [
                {
                  size: 12,
                  type: pecInputTagTypes.RADIO_GROUP,
                  customClass: "",
                  value: "Section to edit",
                  disabled: false,
                  name: "partSelection-1-1",
                  fieldType: pecDataValueTypes.INTEGER,
                  modified: false,
                  styles: {parentClass: "", childClass: ""},
                  data: {matchValue: editSection, otherRadios: [
                    {label: RadioSection.Start},
                    {label: RadioSection.Whole},
                    {label: RadioSection.End},
                  ]}
                },
                
              ]
              
              },
              );
      rows.push(
          {
              columns: [
              {
                  size: 8,
                  type: pecInputTagTypes.LABEL,
                  customClass: "",
                  value: "Length",
                  disabled: false,
                  name: "Length",
                  fieldType: pecDataValueTypes.INTEGER,
                  modified: false,
                  styles: {parentClass: "mb-2", childClass: ""}
              },
              {
                  size: 2,
                  type: pecInputTagTypes.BUTTON,
                  customClass: "",
                  value: "+",
                  disabled: false,
                  name: "increaseLength",
                  fieldType: pecDataValueTypes.INTEGER,
                  modified: false,
                  styles: {parentClass: "mb-2", childClass: "number-input-class"}
              },      
              {
                  size: 2,
                  type: pecInputTagTypes.BUTTON,
                  customClass: "",
                  value: "-",
                  disabled: false,
                  name: "decreaseLength",
                  fieldType: pecDataValueTypes.INTEGER,
                  modified: false,
                  styles: {parentClass: "mb-2", childClass: "number-input-class"}
              },      
              ]
              
          },
          {
              columns: [
              {
                  size: 8,
                  type: pecInputTagTypes.LABEL,
                  customClass: "",
                  value: "Diameter",
                  disabled: false,
                  name: "Diameter",
                  fieldType: pecDataValueTypes.INTEGER,
                  modified: false,
                  styles: {parentClass: "mb-2", childClass: ""}
              },
              {
                  size: 2,
                  type: pecInputTagTypes.BUTTON,
                  customClass: "",
                  value: "+",
                  disabled: false,
                  name: "increaseRadius",
                  fieldType: pecDataValueTypes.INTEGER,
                  modified: false,
                  styles: {parentClass: "mb-2", childClass: "number-input-class"}
              },      
              {
                  size: 2,
                  type: pecInputTagTypes.BUTTON,
                  customClass: "",
                  value: "-",
                  disabled: false,
                  name: "decreaseRadius",
                  fieldType: pecDataValueTypes.INTEGER,
                  modified: false,
                  styles: {parentClass: "mb-2", childClass: "number-input-class"}
              },      
              ]
          },

      )
  } else {
      rows.push(
          {
              columns: [
                {
                  size: 8,
                  type: pecInputTagTypes.LABEL,
                  customClass: "",
                  value: "Move Up or Down",
                  disabled: false,
                  name: "mvUorD",
                  fieldType: pecDataValueTypes.INTEGER,
                  modified: false,
                  styles: {parentClass: "mb-2", childClass: ""}
                },
                {
                  size: 2,
                  type: pecInputTagTypes.BUTTON,
                  customClass: "",
                  value: "U",
                  disabled: false,
                  name: "moveUp",
                  fieldType: pecDataValueTypes.INTEGER,
                  modified: false,
                  styles: {parentClass: "mb-2", childClass: "number-input-class"}
                },      
                {
                  size: 2,
                  type: pecInputTagTypes.BUTTON,
                  customClass: "",
                  value: "D",
                  disabled: false,
                  name: "moveDown",
                  fieldType: pecDataValueTypes.INTEGER,
                  modified: false,
                  styles: {parentClass: "mb-2", childClass: "number-input-class"}
                },      
              ]
              
          },        
      )        
  }
  rows.push(
      {
          columns: [
            {
              size: 8,
              type: pecInputTagTypes.LABEL,
              customClass: "",
              value: "Hide Other Objects",
              disabled: false,
              name: "HOO",
              fieldType: pecDataValueTypes.INTEGER,
              modified: false,
              styles: {parentClass: "mb-2", childClass: ""}
            },
            {
              size: 4,
              type: pecInputTagTypes.SWITCH,
              customClass: "",
              value: false,
              disabled: false,
              name: "Hide Other Object Switch",
              fieldType: pecDataValueTypes.BOOLEAN,
              modified: false,
              styles: {parentClass: "mb-2", childClass: "number-input-class"}
            },      
          ]
          
      },

  )
  const detTowerMenuStruc: pecGridStructure = {
    rows: rows
  }
  return detTowerMenuStruc;
}
