import { UiFramework } from "@itwin/appui-react";
import { Logger } from "../api/logging";
import { store } from "../../store/rootReducer";
import { getUnit, getValueInRequiredUnit } from "./Tables/BaseTable";


export const getAppurtanceproperties = async () => {
    const records = await getAppurtanceData();
    // if(records && records.length){
        let selectedMount =records && records.length? [...store.getState().detectedData.selectedObjectInformation.objectProperties.objectIds][0]:"";
        let appurtanceBase:any =records && records.length? records.filter(record=>record[1]==selectedMount )[0]:"";
        let nameValuePair: {name: string, value: string}[] | any  = [];
        nameValuePair.push({name: "Start Elevation of Appurtenance "+ getUnit(), value:appurtanceBase.length!=0? getValueInRequiredUnit(appurtanceBase[4],true):""});
        nameValuePair.push({name: "End Elevation of Appurtenance "+ getUnit(), value:appurtanceBase.length!=0?getValueInRequiredUnit( appurtanceBase[5],true):""});
        nameValuePair.push({name: "Name", value:appurtanceBase.length!=0? appurtanceBase[12]:""});     
        nameValuePair.push({name: "Manufacturer of Appurtenance ", value:appurtanceBase.length!=0? appurtanceBase[2]:""});    
        nameValuePair.push({name: "Model name of Appurtenance ", value:appurtanceBase.length!=0? appurtanceBase[3]:""});
        nameValuePair.push({name: "Depth of Appurtenance "+ getUnit(false, true), value:appurtanceBase.length!=0?getValueInRequiredUnit (appurtanceBase[8],true,true):""});
        nameValuePair.push({name: "Width of Appurtenance "+ getUnit(false, true), value:appurtanceBase.length!=0?getValueInRequiredUnit (appurtanceBase[9],true):""});
        nameValuePair.push({name: "Force coefficient of Appurtenance", value:appurtanceBase.length!=0? appurtanceBase[10]:""});
        nameValuePair.push({name: "Weight of Appurtenance "+ getUnit(true), value:appurtanceBase.length!=0? getValueInRequiredUnit(appurtanceBase[11],true,false,true):""});
        return nameValuePair
    // }return;
}

export const  getAttachmentProperties = async() => {
    const record = await getAttachmentData();
    // if(record && record.length){
        let attachmentBase:any =record && record.length? record[0]:[];
        let nameValuePair: {name: string, value: string}[] | any  = [];
    
        nameValuePair.push({name: "Name", value: attachmentBase[2]});    
        nameValuePair.push({name: "Top Elevation of Attachment "+ getUnit(), value:attachmentBase.length!=0? getValueInRequiredUnit(attachmentBase[3],true):""});     
        nameValuePair.push({name: "Bottom Elevation of Attachment "+getUnit(), value:attachmentBase.length!=0? getValueInRequiredUnit(attachmentBase[4],true):""});      
        nameValuePair.push({name: "Name of Attachment", value:attachmentBase.length!=0? attachmentBase[13]:""});     
        nameValuePair.push({name: "Type of Attachment", value:attachmentBase.length!=0? attachmentBase[5]:""});
        nameValuePair.push({name: "Location of Attachment", value:attachmentBase.length!=0? attachmentBase[6]:""});
        nameValuePair.push({name: "Azimuth of Attachment (°)", value:attachmentBase.length!=0? attachmentBase[7]:""});
        nameValuePair.push({name: "Width of Attachment "+getUnit(false, true), value:attachmentBase.length!=0? getValueInRequiredUnit(attachmentBase[8],true,true):""});
        nameValuePair.push({name: "Depth of Attachment "+getUnit(false, true), value:attachmentBase.length!=0? getValueInRequiredUnit(attachmentBase[9],true,true):""});
        nameValuePair.push({name: "Weight of Attachment "+getUnit(true), value:attachmentBase.length!=0? getValueInRequiredUnit(attachmentBase[10],true,false,true):""});
        nameValuePair.push({name: "Classification of Attachment", value:attachmentBase.length!=0? attachmentBase[12]:""});
        return nameValuePair
    // }return;
}

const getAppurtanceData = async() =>{
    try {
        const imodel : any= UiFramework.getIModelConnection();

        if (imodel.isBlank) {
            return undefined;
        }
        let records: any = []
        for await (const record of imodel.query("select ECClassId, ECInstanceId, Manufacturer, ModelName, StartElevation, EndElevation, Classification, Location, Depth, Width, ForceCoefficient, Weight,Name from OpenTower.Appurtenance")) {
             records.push(record);
        }
        return records
    } catch (e) {
      Logger.error("Error while getting SeismicLoadParameter data",e);
    }

    return undefined;
}

const getAttachmentData = async() =>{
    try {
        const imodel : any= UiFramework.getIModelConnection();

        if (imodel.isBlank) {
            return undefined;
        }
        let records: any = []
        for await (const record of imodel.query("select ECClassId, ECInstanceId, Name, TopElevation, BottomElevation, AttachmentType,Location, Azimuth, Width, Depth, Weight,UserLabel,Classification,AttachmentName from OpenTower.Attachment")) {
             records.push(record);
        }
        return records
    } catch (e) {
      Logger.error("Error while getting SeismicLoadParameter data",e);
    }

    return undefined;
}