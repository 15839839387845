import React from "react";
import "./RotationStyles.scss";
import { Button, Text } from "@itwin/itwinui-react";
import { AddEditPolygonShapeTool } from "../tools/AddEditPolygonShapeTool";
import { PolygonTransformWidget } from "../../tools/widgets/PolygonTranformWidget";

/*
 * Displays the UI buttons that pop out from the side.
 * Has UI functonality to Edit and manupulate the Polygon/Grating that is created in scene. 
 * */
export const PolygonShapeControl: React.FunctionComponent = () => {
    const [pontCount, setPointCount] = React.useState<string>(AddEditPolygonShapeTool.pointCountPerPolygon.toString());
    //-------------------------------------------------------------------------------------------
    //Events 
    //Invoked in the HTML below.
    const leftOrRightOnClick = async (evt: any) => {
        const classes = evt.currentTarget.className;
        if (classes.includes("left")) PolygonTransformWidget.translatePolygon(0.01, 0, 0);
        else if (classes.includes("right")) PolygonTransformWidget.translatePolygon(-0.01, 0, 0);
    }
    //------------
    const leftOrRightMouseDown = async (evt: any) => {
        const classes = evt.currentTarget.className;
        if (classes.includes("left")) PolygonTransformWidget.translatePolygon(0.01, 0, 0);
        else if (classes.includes("right")) PolygonTransformWidget.translatePolygon(-0.01, 0, 0);
    }
    //------------
    const frontOrBackOnClick = async (evt: any) => {
        const classes = evt.currentTarget.className;
        if (classes.includes("front")) PolygonTransformWidget.translatePolygon(0, 0.01, 0);
        else if (classes.includes("back")) PolygonTransformWidget.translatePolygon(0, -0.01, 0);
    }   
    //------------
    const frontOrBackMouseDown = async (evt: any) => {
        const classes = evt.currentTarget.className;
        if (classes.includes("front")) PolygonTransformWidget.translatePolygon(0, 0.01, 0);
        else if (classes.includes("back")) PolygonTransformWidget.translatePolygon(0, -0.01, 0);
    }
    //------------
    const upOrDownOnClick = async (evt: any) => {
        const classes = evt.currentTarget.className;

        if (classes.includes("up")) PolygonTransformWidget.translatePolygon(0, 0, 0.001);
        else if (classes.includes("down")) PolygonTransformWidget.translatePolygon(0, 0, -0.001);
    }
    //------------
    const upOrDownMouseDown = async (evt: any) => {
        const classes = evt.currentTarget.className;
        if (classes.includes("up")) PolygonTransformWidget.translatePolygon(0, 0, 0.01);
        else if (classes.includes("down")) PolygonTransformWidget.translatePolygon(0, 0, -0.01);
    }
    //------------
    const handleChange = async(event) => {
        //AddEditPolygonShapeTool._pointCountPerPolygon = event.target.value;
        PolygonTransformWidget.updatePointCountForPolygonCreation(parseInt(event.target.value));
        setPointCount(AddEditPolygonShapeTool.pointCountPerPolygon.toString());
    }
    //------------
    const handleSubmit = async (event) => {
        PolygonTransformWidget.updatePointCountForPolygonCreation(parseInt(pontCount));
        event.preventDefault();
    }
    //------------
    const snappAproxElivationOnClick = async (event) =>{
        PolygonTransformWidget.snapToElevationPlane(true);
        event.preventDefault();
    }
    //------------
    const snappRawElivationOnClick = async (event) => {
        PolygonTransformWidget.snapToElevationPlane(false);
        event.preventDefault();
    }
    //------------
    const createJointsAndSupportingMembers = async (event) => {
        PolygonTransformWidget.createJointsAndSupportingMembers();

        event.preventDefault();
    }
    const levelAndCreateSupportingMemebers = async (event) => {
        PolygonTransformWidget.levelAndCreateJointsAndSupportingMembers();
        event.preventDefault();
    }
    //------------
    const deleteSelectedGrating = async (event) => {
        PolygonTransformWidget.deleteSelectedPolygon();
        event.preventDefault();
    }
    //------------
    const saveSelectedPolygn = async (event) => {
        PolygonTransformWidget.saveSelectedPolygonData();
        event.preventDefault();
    }
    //-------------------------------------------------------------------------------------------
    //The React HTML output for the Widget.
    return (
        <>
        <div className="parent-options">
            <div className="sample-options">
                <div style={{ paddingBottom: "5px", paddingTop: "5px" }}>
                        <span><Text variant="subheading">Grating Editor</Text></span>
                </div>
            </div>
            <div style={{ padding: "10px"}}>
                    <form onSubmit={handleSubmit}>
                        <label title="Count for the vertices that made or make a grating polygon,Define this number before creation to detrmine the vertext count for the polygon to be created" style={{ whiteSpace: "pre-wrap" }}>
                            Point count  <input type="text" value={pontCount} onChange={handleChange} />
                        </label>
                    </form>
            </div>

            <div className="position">

                <div className="sample-options-3col" style={{ gridTemplateColumns: "4fr 0fr 0fr" }}>
                    <span>Move Up or Down</span>
                    <Button className="yrp-button-height tb-symbol-size up" onClick={upOrDownOnClick} onMouseUp={() => { clearInterval(1) }} onMouseDown={upOrDownMouseDown}>
                        &#8896;
                    </Button>
                        <Button className="yrp-button-height tb-symbol-size down" onClick={upOrDownOnClick} onMouseUp={() => { clearInterval(1) }} onMouseDown={upOrDownMouseDown} style={{
                            position: "relative",
                            bottom: 0,
                            right: 3
                        }}>
                        &#8897;
                    </Button>
                </div>

                    <div className="sample-options-3col" style={{ gridTemplateColumns: "3fr 0fr 0fr" }}>
                        <span>Snap To Nearest Plane</span>
                        <label title="Snap to Average Aproximate Normals Facing directly upwards" style={{ whiteSpace: "pre-wrap" }}>
                        <Button className="yrp-button-height tb-symbol-size down" onClick={snappAproxElivationOnClick} onMouseUp={() => { clearInterval(1) }}>                      
                            &#8884;
                        </Button>
                        </label>
                        <label title="Snap to Raw Normals" style={{ whiteSpace: "pre-wrap" }}>
                            <Button className="yrp-button-height tb-symbol-size down" onClick={snappRawElivationOnClick} onMouseUp={() => { clearInterval(1) }} style={{
                                position: "relative",
                                bottom: 0,
                                right: 3
                            }}>
                            &#8885;
                        </Button>
                        </label>
                    </div>

                    <div className="sample-options-3col" style={{ gridTemplateColumns: "4fr 0fr 0fr" }}>
                        <span>Auto Create Supporting Members</span>
                        <label title="Auto Create Supporting Members" style={{ whiteSpace: "pre-wrap" }}>
                            <Button className="yrp-button-height tb-symbol-size down" onClick={createJointsAndSupportingMembers} onMouseUp={() => { clearInterval(1) }}>
                                &#8762;
                            </Button>
                        </label>

                        <label title=" Level the Grating , Auto Create Supporting Members and Snape the Grating to Pipes" style={{ whiteSpace: "pre-wrap" }}>
                        <Button className="yrp-button-height tb-symbol-size down" onClick={levelAndCreateSupportingMemebers} onMouseUp={() => {clearInterval(1)}} style={{
                            position: "relative",
                            bottom: 0,
                                right: 3,
                                height: 50,
                                width: 40}}>
                               &#8785;
                            </Button>
                        </label>                 
                    </div>
                    <div className="sample-options-3col" style={{ gridTemplateColumns: "4fr 0fr 0fr" }}>
                        <span>Save | Delete</span>
                        <label title="Save the selected Grating Object" style={{ whiteSpace: "pre-wrap" }}>
                            <Button className="yrp-button-height tb-symbol-size down" onClick={saveSelectedPolygn} onMouseUp={() => { clearInterval(1) }} style={{
                                position: "relative",
                                bottom: 0,
                                right: 0
                            }}> Save
                            </Button>
                        </label>
                        <label title="Delete the selected Grating Object" style={{ whiteSpace: "pre-wrap" }}>
                            <Button className="yrp-button-height tb-symbol-size down" onClick={deleteSelectedGrating} onMouseUp={() => { clearInterval(1) }} style={{
                                position: "relative",
                                bottom: 0,
                                right: 2
                            }}>Del
                            </Button>
                        </label>               
                    </div>
            </div>
        </div>
        </>
    );
};
