import { BeButtonEvent, EventHandled, IModelApp, NotifyMessageDetails, OutputMessagePriority, OutputMessageType, PrimitiveTool, Viewport } from "@itwin/core-frontend";
import GratingsClient from "../../api/gratingsClient";
import { Point3d } from "@itwin/core-geometry";
import { ColorDef } from "@itwin/core-common";
import { addToBuilt3DObjectIdsMap } from "../../../store/detectedData/apiDataActionTypes";
import { store } from "../../../store/rootReducer";
import { PolygonShapeDecorator } from "../../tools/decorators/PolygonShapeDecorator";

class Pipe {

    constructor() {
        this.pointA = new Point3d(0);
        this.pointB = new Point3d(0);
    }
    pointA: Point3d;
    pointB: Point3d;
}
class PolygonInfo {

    constructor() {
        this.name = "";
        this.poligonPoints = [];

        this.jointPipes = [];
        this.endToendJointPipes = []
        this.edgePipes = [];
        //this.area = 0;
    }
    name: string;                                               // The name of the polygon
    poligonPoints: Point3d[];                                   // Points that make a polygon/grating shape.
    faceLoacation: any;                                         // The face btween wich the polygon exists, ie : FaceA, FaceB, Mountname | format : {first, second ,mount}
    //Supporting Members
    endToendJointPipes: Pipe[];                                 // The joint pipes that create a stringth end to end joints for the grating that connect to the relevant mount pipe members.
    jointPipes: Pipe[];                                         // The point data for pipes that will make pipes visualising joints.
    edgePipes: Pipe[];                                          // The points data for pipes that will make grating edges.
}
/*
 * Tool that highlights and visualises the Gratings fixtures and supporting pipes saved via creating/editing/saving features in AddEditPolygonShapeTool.
 */ 
export class HighlightGratingTool extends PrimitiveTool {
    public static override toolId = "HilightGratingTool";

    private static _mountingsData: any | undefined;              // Fetched data for mountPipes and Equipment.
    private static polygonDecorator: PolygonShapeDecorator;      // This is the polygon Decorator that will be added to viewManager and should hold the current Decorator context.
    private static _poligonInfoArray: PolygonInfo[] = [];
    private static toggle: boolean = false;                     //Holds the state if this tool is enabled or disabled.

    /*
     * Events that run when the Tool is to be exited.
     * Clean and clear all relevant arrays and decorators.
     */ 
    public static terminateTool() {
        //clear local containers.
        HighlightGratingTool._poligonInfoArray = [];
        this.polygonDecorator?.clearGeometry();

        for (const dec of IModelApp.viewManager.decorators) {
            if (dec.constructor.name.includes("PolygonShapeDecorator")) {
                IModelApp.viewManager.dropDecorator(dec);
            }
        }
    }
    //------------------------------------------------------------------------------------
    /*
     * set the initalised PolygonDecorator isEditable state externally.
     * this is disabled on init/run() of this Primitive tool.
     * Will be used in Smapletoolwidget-> EditEquipment button click.
     */
    public static setEditableStateOfDecorator(state: boolean) {
        if (HighlightGratingTool.polygonDecorator != undefined) {
            HighlightGratingTool.polygonDecorator.isEditable = state;
        }
    }
    //------------------------------------------------------------------------------------
    public static get poligonInfoArray(): PolygonInfo[] {
        return HighlightGratingTool._poligonInfoArray;
    }
    /*
     */ 
    public static get mountingsData(): any {
        return HighlightGratingTool._mountingsData;
    }
    /*
     * 
     */ 
    public static get isEnabled(): boolean {
        return HighlightGratingTool.toggle;
    }
    public static set isEnabled( set: boolean) {
        HighlightGratingTool.toggle = set;
    }
    //------------------------------------------------------------------------------------
    /* Import the mounts data , for our use case we need the horizontal pipe information
     * for the use cases of Snapping and disjointing avoidance.
     */
    private async importAndShowGratingData() {
        const token = store.getState().auth.accessTokenStatePrivateAPI.accessToken!;
        const a = await GratingsClient.getMountingsDataJason(token);
        HighlightGratingTool._mountingsData = a;

        if (HighlightGratingTool._mountingsData.mounts) {
            HighlightGratingTool.useDataAndShowGratingInformation(HighlightGratingTool._mountingsData.mounts);
        }
    }
    /*
     * Takes the mountData fetched from API and use it to visualise the Grating associated items.
     * Includes Grating polygon structure and the supporting pipes.
     * 
     * Note: this PolygonInfo[] array created here is also the one that will be handed to AddEditPolygonShapeTool for edit and saving
     * during the run of EditEquipment feature. All the parameters are identical.
     * this PolygonInfo[] will be handed over on SampleToolWidget -> EditEquipment button press.
     */ 
    private static useDataAndShowGratingInformation(mountData: any)
    {
        try{
        HighlightGratingTool._poligonInfoArray = [];

        let gratingInfoFound: number = 0;
        for (let mnt in mountData) {//Iterate over the mountings data and store the grating information in polygonInfo for use.
            for (let grate in mountData[mnt]) {
                if (grate == "grating") {
                    for (let poly in mountData[mnt][grate]) {
                        gratingInfoFound++
                        let polyinfo: PolygonInfo = new PolygonInfo();
                        polyinfo.name = poly;
                        let f = { first:mountData[mnt][grate][poly].FaceLocation.split(',')[0], second:mountData[mnt][grate][poly].FaceLocation.split(',').pop(), mount:mnt}
                        polyinfo.faceLoacation = f;
                        for (let u = 0; u < mountData[mnt][grate][poly].utm.length; u++) {
                            let point: Point3d = new Point3d();
                            point.x = mountData[mnt][grate][poly].utm[u][0];
                            point.y = mountData[mnt][grate][poly].utm[u][1];
                            point.z = mountData[mnt][grate][poly].utm[u][2];
                            polyinfo.poligonPoints.push(point);
                        }
                        //Check for supporting pipes if generated and saved , if yes then load then up and visualise them.
                        if (mountData[mnt][grate][poly].SupportingPipes != undefined) {
                            for (let sp in mountData[mnt][grate][poly].SupportingPipes) {
                                if (sp.slice(0, 4) == "edge") {//edge pipe
                                    let pip: Pipe = new Pipe();
                                    if (mountData[mnt][grate][poly].SupportingPipes[sp].startPoint != undefined) {
                                        pip.pointA.x = mountData[mnt][grate][poly].SupportingPipes[sp].startPoint[0];
                                        pip.pointA.y = mountData[mnt][grate][poly].SupportingPipes[sp].startPoint[1];
                                        pip.pointA.z = mountData[mnt][grate][poly].SupportingPipes[sp].startPoint[2];
                                        pip.pointB.x = mountData[mnt][grate][poly].SupportingPipes[sp].endPoint[0];
                                        pip.pointB.y = mountData[mnt][grate][poly].SupportingPipes[sp].endPoint[1];
                                        pip.pointB.z = mountData[mnt][grate][poly].SupportingPipes[sp].endPoint[2];
                                        polyinfo.edgePipes.push(pip);
                                    }
                                }
                                if (sp.slice(0, 4) == "endt") {//end to end pipe
                                    let pip: Pipe = new Pipe();
                                    if (mountData[mnt][grate][poly].SupportingPipes[sp].startPoint != undefined) {
                                        pip.pointA.x = mountData[mnt][grate][poly].SupportingPipes[sp].startPoint[0];
                                        pip.pointA.y = mountData[mnt][grate][poly].SupportingPipes[sp].startPoint[1];
                                        pip.pointA.z = mountData[mnt][grate][poly].SupportingPipes[sp].startPoint[2];
                                        pip.pointB.x = mountData[mnt][grate][poly].SupportingPipes[sp].endPoint[0];
                                        pip.pointB.y = mountData[mnt][grate][poly].SupportingPipes[sp].endPoint[1];
                                        pip.pointB.z = mountData[mnt][grate][poly].SupportingPipes[sp].endPoint[2];
                                        polyinfo.endToendJointPipes.push(pip);
                                    }
                                }
                                if (sp.slice(0, 4) == "join") {//joint pipe
                                    let pip: Pipe = new Pipe();
                                    if (mountData[mnt][grate][poly].SupportingPipes[sp].startPoint != undefined) {
                                        pip.pointA.x = mountData[mnt][grate][poly].SupportingPipes[sp].startPoint[0];
                                        pip.pointA.y = mountData[mnt][grate][poly].SupportingPipes[sp].startPoint[1];
                                        pip.pointA.z = mountData[mnt][grate][poly].SupportingPipes[sp].startPoint[2];
                                        pip.pointB.x = mountData[mnt][grate][poly].SupportingPipes[sp].endPoint[0];
                                        pip.pointB.y = mountData[mnt][grate][poly].SupportingPipes[sp].endPoint[1];
                                        pip.pointB.z = mountData[mnt][grate][poly].SupportingPipes[sp].endPoint[2];
                                        polyinfo.jointPipes.push(pip);
                                    }
                                }
                            }
                            //----
                            for (let sp = 0; sp < mountData[mnt][grate][poly].SupportingPipes.length; sp++) {
                                if (mountData[mnt][grate][poly].SupportingPipes[sp].SupportingPipesID.slice(0, 4) == "edge") {//edge pipe
                                    let pip: Pipe = new Pipe();
                                    if (mountData[mnt][grate][poly].SupportingPipes[sp].startPoint != undefined) {
                                        pip.pointA.x = mountData[mnt][grate][poly].SupportingPipes[sp].startPoint[0];
                                        pip.pointA.y = mountData[mnt][grate][poly].SupportingPipes[sp].startPoint[1];
                                        pip.pointA.z = mountData[mnt][grate][poly].SupportingPipes[sp].startPoint[2];
                                        pip.pointB.x = mountData[mnt][grate][poly].SupportingPipes[sp].endPoint[0];
                                        pip.pointB.y = mountData[mnt][grate][poly].SupportingPipes[sp].endPoint[1];
                                        pip.pointB.z = mountData[mnt][grate][poly].SupportingPipes[sp].endPoint[2];
                                        polyinfo.edgePipes.push(pip);
                                    }
                                }
                                if (mountData[mnt][grate][poly].SupportingPipes[sp].SupportingPipesID.slice(0, 4) == "endt") {//edge pipe
                                    let pip: Pipe = new Pipe();
                                    if (mountData[mnt][grate][poly].SupportingPipes[sp].startPoint != undefined) {
                                        pip.pointA.x = mountData[mnt][grate][poly].SupportingPipes[sp].startPoint[0];
                                        pip.pointA.y = mountData[mnt][grate][poly].SupportingPipes[sp].startPoint[1];
                                        pip.pointA.z = mountData[mnt][grate][poly].SupportingPipes[sp].startPoint[2];
                                        pip.pointB.x = mountData[mnt][grate][poly].SupportingPipes[sp].endPoint[0];
                                        pip.pointB.y = mountData[mnt][grate][poly].SupportingPipes[sp].endPoint[1];
                                        pip.pointB.z = mountData[mnt][grate][poly].SupportingPipes[sp].endPoint[2];
                                        polyinfo.endToendJointPipes.push(pip);
                                    }
                                }
                                if (mountData[mnt][grate][poly].SupportingPipes[sp].SupportingPipesID.slice(0, 4) == "join") {//edge pipe
                                    let pip: Pipe = new Pipe();
                                    if (mountData[mnt][grate][poly].SupportingPipes[sp].startPoint != undefined) {
                                        pip.pointA.x = mountData[mnt][grate][poly].SupportingPipes[sp].startPoint[0];
                                        pip.pointA.y = mountData[mnt][grate][poly].SupportingPipes[sp].startPoint[1];
                                        pip.pointA.z = mountData[mnt][grate][poly].SupportingPipes[sp].startPoint[2];
                                        pip.pointB.x = mountData[mnt][grate][poly].SupportingPipes[sp].endPoint[0];
                                        pip.pointB.y = mountData[mnt][grate][poly].SupportingPipes[sp].endPoint[1];
                                        pip.pointB.z = mountData[mnt][grate][poly].SupportingPipes[sp].endPoint[2];
                                        polyinfo.jointPipes.push(pip);
                                    }
                                }
                            }
                        }

                        HighlightGratingTool._poligonInfoArray.push(polyinfo);
                    }                    
                }
            }
        }

        //check if grating data is presenet if yes then display status popup messages.
        if (gratingInfoFound == 0 && !store.getState().dtvState.applicationState.highlightStates.highlightAll) {
            IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Warning, "Gratings Data Not Found","", OutputMessageType.Toast));
        }
        if (gratingInfoFound > 0 && !store.getState().dtvState.applicationState.highlightStates.highlightAll) {
            if(!store.getState().dtvState.applicationState.highlightStates.highlightAll) IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Info, "Data for " + " Gratings found", "", OutputMessageType.Toast));
        }


        //Create Polygon shape and Joint pipes.
        HighlightGratingTool.createPoligonFromPolygonInfoArray(HighlightGratingTool._poligonInfoArray);//relatively effecent.
        for (let p = 0; p < HighlightGratingTool._poligonInfoArray.length; p++) {//Less efficent , !refactor!.
            HighlightGratingTool.createPipesFromPipeData(HighlightGratingTool._poligonInfoArray[p].endToendJointPipes, ColorDef.from(224, 224, 0));//create Full length pipes for grating joints.
            HighlightGratingTool.createPipesFromPipeData(HighlightGratingTool._poligonInfoArray[p].jointPipes, ColorDef.from(224, 0, 224));//create pipes for grating joints.
            HighlightGratingTool.createPipesFromPipeData(HighlightGratingTool._poligonInfoArray[p].edgePipes, ColorDef.from(0, 224, 0));//create pipes for supporting edge members.
        }
        }catch(e){
            
        }
    }
    //------------------------------------------------------------------------------------
    /*
     * Uses the populated Polygon info array to populate the scene with Polygon/Grid objects.
     * Note: since this will recreate only the polygon will be visible all helpers and markers will disappear.
     * Check function createPolygonFromPoints() and the needUpdate flag in PolygonShapeDecorator.
     * 
     * Note : Always keep this first as it clears all poly before regen of geometries.See function.
     */
    private static createPoligonFromPolygonInfoArray(polygonInfoArray: PolygonInfo[]) {

        //check if the same deocrator exist in ViewManager
        let isDecoratorExists: boolean = false;
        for (const dec of IModelApp.viewManager.decorators) {
            if (dec.constructor.name.includes("PolygonShapeDecorator")) {
                isDecoratorExists = true;
                HighlightGratingTool.polygonDecorator = (dec as PolygonShapeDecorator);//copy the existing reference to the local variable So that the orgnal is reused.
            }
        }

        if (polygonInfoArray.length > 0) {
            HighlightGratingTool.polygonDecorator.clearGeometry();//clear geometry before recreating,updates the dimension based on changes in data.and avoid overlapping geometry.

            for (let i = 0; i < polygonInfoArray.length; i++) {
                HighlightGratingTool.polygonDecorator.createPolygonFromPoints(polygonInfoArray[i].poligonPoints, ColorDef.from(214, 125, 62), polygonInfoArray[i].name, "");
            }
            //if decorator exits then dont add it to the ViewManger just invalidate cache and request for a re-draw for the decorator.else it throws error.
            store.dispatch(addToBuilt3DObjectIdsMap(new Map(HighlightGratingTool.polygonDecorator.objectIdMap)));
            isDecoratorExists == false ?
                IModelApp.viewManager.addDecorator(HighlightGratingTool.polygonDecorator) ://else
                IModelApp.viewManager.invalidateCachedDecorationsAllViews(HighlightGratingTool.polygonDecorator);
        }
    }
    //----------------------------------------------------------------------------------------------------------
    /* Create pipe Decorators using the Pipe class array .
     */
    private static createPipesFromPipeData(pipes: Pipe[], color: ColorDef) {

        //check if decorator exists , needs to be done every time adding a new geometry. //**
        let isDecoratorExists: boolean = false;
        for (const dec of IModelApp.viewManager.decorators) {
            if (dec.constructor.name.includes("PolygonShapeDecorator")) {
                isDecoratorExists = true;
                HighlightGratingTool.polygonDecorator = (dec as PolygonShapeDecorator);
            }
        }

        for (let i = 0; i < pipes.length; i++) {
            if (pipes[i].pointA != undefined && pipes[i].pointB != undefined) {
                HighlightGratingTool.polygonDecorator.createPipeBetweenPoints(pipes[i].pointA, pipes[i].pointB, color, "jointline" + i.toString(), "");
            }
        }
        store.dispatch(addToBuilt3DObjectIdsMap(new Map(HighlightGratingTool.polygonDecorator.objectIdMap)));

        //if decorator exits then dont add it to the ViewManger just invalidate cache and request for a re-draw/needsUpdate for the decorator.//**
        isDecoratorExists == false ? IModelApp.viewManager.addDecorator(HighlightGratingTool.polygonDecorator) ://else
            IModelApp.viewManager.invalidateCachedDecorationsAllViews(HighlightGratingTool.polygonDecorator);
    }

    //------------------------------------------------------------------------------------
    //  ╔═╗┌─┐┬  ┬    ┌┐ ┌─┐┌─┐┬┌─┌─┐
    //  ║  ├─┤│  │    ├┴┐├─┤│  ├┴┐└─┐
    //  ╚═╝┴ ┴┴─┘┴─┘  └─┘┴ ┴└─┘┴ ┴└─┘
    //------------------------------------------------------------------------------------
    /*
     * this runs on Init or on IModelApp.tools.run();
     */ 
    public override run(): Promise<boolean> {
        const { toolAdmin, viewManager } = IModelApp;

        if (!this.isCompatibleViewport(viewManager.selectedView, false) || !toolAdmin.onInstallTool(this)) {
            return Promise.resolve(false);
        }

        let isDecoratorExists: boolean = false;
        for (const dec of IModelApp.viewManager.decorators) {
            if (dec.constructor.name.includes("PolygonShapeDecorator")) {
                isDecoratorExists = true;
            }
        }

        HighlightGratingTool.toggle = !HighlightGratingTool.toggle;
        if (HighlightGratingTool.toggle == true) {
            HighlightGratingTool.polygonDecorator = new PolygonShapeDecorator();
            HighlightGratingTool.polygonDecorator.isEditable = false;
            this.importAndShowGratingData();
            // FrontstageManager.activeFrontstageDef?.getStagePanelDef(StagePanelLocation.Right)?.findWidgetDef("PropertyListWidget")?.setWidgetState(WidgetState.Hidden);
        }
        else if (isDecoratorExists == false && HighlightGratingTool.toggle == false) {
            HighlightGratingTool.polygonDecorator = new PolygonShapeDecorator();
            HighlightGratingTool.polygonDecorator.isEditable = false;
            this.importAndShowGratingData();
            HighlightGratingTool.toggle = true;
            // FrontstageManager.activeFrontstageDef?.getStagePanelDef(StagePanelLocation.Right)?.findWidgetDef("PropertyListWidget")?.setWidgetState(WidgetState.Hidden);
        }
        else {
            HighlightGratingTool.terminateTool();
            // FrontstageManager.activeFrontstageDef?.getStagePanelDef(StagePanelLocation.Right)?.findWidgetDef("PropertyListWidget")?.setWidgetState(WidgetState.Hidden);
        }
        return Promise.resolve(true);
    }
    //------------------------------------------------------------------------------------
    /* Runs when the tool is rerun after being instanciated once.
     */
    public onRestartTool(): Promise<void> {
        const tool = new HighlightGratingTool();
        if (!tool.run()) { 
            this.exitTool();
        }
        return Promise.resolve();
    }
    //------------------------------------------------------------------------------------
    /* Override Gets called when the Tool is run() from SampleToolsWidget.tsx.
     * does object click +  surface ray intersection test
     * _ev return data about the intersecting object and point of intersection.
     */
    public override async onDataButtonDown(_ev: BeButtonEvent): Promise<EventHandled> {
        return EventHandled.No;
    }
    //------------------------------------------------------------------------------------
    public override async onMouseMotion(_ev: BeButtonEvent): Promise<void> {
        //empty
    }
    //------------------------------------------------------------------------------------
    public override async onMouseStartDrag(_ev: BeButtonEvent): Promise<EventHandled> {
        return EventHandled.No;
    }
    //------------------------------------------------------------------------------------
    public override async onMouseEndDrag(_ev: BeButtonEvent): Promise<EventHandled> {
        this.exitTool();
        return EventHandled.Yes;
    }
    //------------------------------------------------------------------------------------
    public override async onCleanup() {
    }
    //------------------------------------------------------------------------------------
    public override onPostInstall(): Promise<void> {
        super.onPostInstall();
        return Promise.resolve()
    }
    //------------------------------------------------------------------------------------
    public override isCompatibleViewport(_vp: Viewport | undefined, _isSelectedViewChange: boolean): boolean {
        return true;
    }
    //------------------------------------------------------------------------------------

}
