import { PropertyRecord } from "@itwin/appui-abstract";
import { UiFramework } from "@itwin/appui-react";
import { Logger } from "../api/logging";
import { ReduceDecimalNumberToTwoPlaces } from "./Lists/EquipmentList";
import { AppSettings } from "../../config/AppSettings";
import { store } from "../../store/rootReducer";
import { getValueInRequiredUnit } from "./Tables/BaseTable";
import { unitSystemType } from "../../store/redux-types";


export const getWindParamProperties = async()=>{
    const dataProvider: PropertyRecord[] = [];
    const record = await getWindParamData();
    // if(record && record.length){

        let windBase:any =  record && record.length!=0? record[0]:[];
       
        let nameValuePair: {name: string, value: string}[] | any  = [];
    
        nameValuePair.push({name: "Design Standard", value:windBase.length!=0? windBase[0]:""});
       
        nameValuePair.push({name: "Risk Category", value:windBase.length!=0? windBase[8]:""});
        
        nameValuePair.push({name: "Exposure Category", value:windBase.length!=0? windBase[9]:""});
        
        nameValuePair.push({name: "Topographic Category", value:windBase.length!=0? windBase[10]:""});
      
        nameValuePair.push({name: `Ultimate Wind Speed ${AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.IMPERIAL?"(mph)":"(m/s)"}`, value: windBase. length!=0?convertValueIntoUnits(windBase[1],"speed").toString():""});
    
        nameValuePair.push({name: `Basic Wind Speed ${AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.IMPERIAL?"(mph)":"(m/s)"}`, value:windBase. length!=0? convertValueIntoUnits(windBase[2],"speed").toString():""});
        
        nameValuePair.push({name: `Ice WindSpeed ${AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.IMPERIAL?"(mph)":"(m/s)"}`, value:windBase. length!=0? convertValueIntoUnits(windBase[3],"speed").toString():""});
    
        nameValuePair.push({name: `Ice Thickness  ${AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.IMPERIAL?"(in)":"(mm)"}`, value:windBase. length!=0? getValueInRequiredUnit(windBase[4],false,true).toString():""});
         
        nameValuePair.push({name: `Ground Elevation ${getUnit()}`, value:windBase.length!=0? getValueInRequiredUnit(windBase[5],true).toString():""});
        
        nameValuePair.push({name: `Base ELevation ${getUnit()}`, value:windBase. length!=0? getValueInRequiredUnit(windBase[6],true).toString():""});
        
        nameValuePair.push({name: "Wind Direction From True North", value:windBase. length!=0? windBase[7]:""});
    
        
    
        return nameValuePair
    // }return;
}

const convertValueIntoUnits = (value,type) => {
    if(type == "speed"){
        if(AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.IMPERIAL)return ReduceDecimalNumberToTwoPlaces( value).toString() 
        return ReduceDecimalNumberToTwoPlaces(value* 0.44704).toString();
    }else{        
        if(AppSettings.defaultDisplaySettings.unitSystem == unitSystemType.IMPERIAL)return ReduceDecimalNumberToTwoPlaces( value).toString()  
        return ReduceDecimalNumberToTwoPlaces(value*25.4).toString();
    }
}

const getWindParamData = async() =>{
    try {
        const imodel : any= UiFramework.getIModelConnection();

        if (imodel.isBlank) {
            return undefined;
        }
        let records: any = []
        for await (const record of imodel.query("select DesignStandard, UltimateWindSpeed,BasicWindSpeed, IceWindSpeed, IceThickness, GroundElevation, BaseELevation, WindDirTrueNorth, RiskCategory,  ExposureCategory, TopographicCategory from OpenTower.WindLoadParameter")) {
             records.push(record);
        }
        return records
    } catch (e) {
        Logger.error("Error while getting WindLoadParameter data",e);
    }

    return undefined;
}

export const getSesmicLoadProperties = async()=>{
    const dataProvider: PropertyRecord[] = [];
    const record = await getSesmicData();
    // if(record && record.length){

    
        let sesmicLoad:any = record && record.length!=0? record[0]:[];
       
        let nameValuePair: {name: string, value: string}[] | any  = [];
    
        nameValuePair.push({name: "Latitude", value: sesmicLoad.length!=0?sesmicLoad[0]:""});
       
        nameValuePair.push({name: "Longitude", value:sesmicLoad.length!=0? sesmicLoad[1]:""});

        nameValuePair.push({name: "Risk Category", value:sesmicLoad.length!=0? sesmicLoad[2] : ""});
        
        nameValuePair.push({name: "Impfactor", value:sesmicLoad.length!=0? sesmicLoad[3]:""});
      
        nameValuePair.push({name: "Ss", value: sesmicLoad[4]? ReduceDecimalNumberToTwoPlaces(sesmicLoad[4]).toString():""});
    
        nameValuePair.push({name: "S1", value:sesmicLoad[5]? ReduceDecimalNumberToTwoPlaces(sesmicLoad[5]).toString():""});
        
        nameValuePair.push({name: "T1", value:sesmicLoad[6]? ReduceDecimalNumberToTwoPlaces(sesmicLoad[6]).toString():""});
        
        return nameValuePair
    // }return;
}

const getSesmicData = async() =>{
    try {
        const imodel : any= UiFramework.getIModelConnection();

        if (imodel.isBlank) {
            return undefined;
        }
        let records: any = []
        for await (const record of imodel.query("select Latitude, Longitude, RiskCategory, Impfactor, ResponseFactor, SiteClass, Ss, S1, Tl from OpenTower.SeismicLoadParameter")) {
             records.push(record);
        }
        return records
    } catch (e) {
      Logger.error("Error while getting SeismicLoadParameter data",e);
    }

    return undefined;
}
const getUnit = (isWeight: boolean = false, isInches: boolean= false) => {
    const defaultSetting = store.getState().dtvState.applicationState.projectUnitSystem
    if (defaultSetting == 2) {
        return isWeight ? "(kg)" : isInches ? "(mm)" : "(m)";
    } else {
        return isWeight ? "(lb)" : isInches ? "(in)" : "(ft)";
    }
};