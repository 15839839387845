import { Cartographic } from "@itwin/core-common";
import { IModelConnection } from "@itwin/core-frontend";
import proj4 from "proj4";
import { store } from "../../../store/rootReducer";
import { towerStructureData } from "../../../store/detectedData/apiDataTypes";
import { Point3d } from "@itwin/core-geometry";
import { UiFramework } from "@itwin/appui-react";
// import * as egm96 from "egm96-universal";
// import { ConfigManager } from "../../api/config/ConfigManager";

export class DecoratorHelper {
    //-------------------------------------------------------------------------------------------------------------------------------
    /*
        Returns Spatial coordinate
    */
    // tslint:disable-next-line:naming-convention
    public static ExtractSpatialXYZ(o: Cartographic, xCenter: number, yCenter: number, zCenter: number, iModel: IModelConnection) {
        const epsgCode = DecoratorHelper.GetEPSGCode(o);
        const r = DecoratorHelper.getLongAndLat(epsgCode, xCenter, yCenter, zCenter);
        let m: Cartographic = Cartographic.fromDegrees({longitude: r.x, latitude: r.y, height: zCenter});
        const ecef = iModel.cartographicToSpatialFromEcef(m!);
        return ecef;
    }
    //-------------------------------------------------------------------------------------------------------------------------------//
    // convert the UTM coordinates to the local coordinates using the ESPG code
    //-------------------------------------------------------------------------------------------------------------------------------//
    public static utm2localCoords(o: Cartographic, xCenter: number, yCenter: number, zCenter: number, iModel: IModelConnection, epsgCode?:any) {
        epsgCode = (epsgCode == undefined) ? DecoratorHelper.GetEPSGCode(o) : epsgCode.toString();
        const r = DecoratorHelper.getLongAndLat(epsgCode, xCenter, yCenter, zCenter);
        let m: Cartographic = Cartographic.fromDegrees({longitude: r.x, latitude: r.y, height: zCenter});
        const spatialCoords = iModel.cartographicToSpatialFromEcef(m!);
        return spatialCoords;
    }
    //-------------------------------------------------------------------------------------------------------------------------------//
    public static getLongAndLat(epsgCode: string, xCenter: number, yCenter: number, zCenter: number) {
        const zone = DecoratorHelper.GetZone(epsgCode);
        let project: any;
        if (epsgCode.includes("327")) {
            project = "+proj=utm +zone=" + zone + " +south";
        } else {
         project = "+proj=utm +zone=" + zone;
        }
        proj4.defs(epsgCode, project);
        const sourceProj = proj4.Proj(epsgCode);
        const destProj = proj4.Proj("WGS84");
        const p = proj4.toPoint([xCenter, yCenter, zCenter]);
        const r = proj4.transform(sourceProj, destProj, p);
        return r;
    }
    //-------------------------------------------------------------------------------------------------------------------------------
    public static convertWGS2UTM(o: Cartographic) {
        const epsgCode = DecoratorHelper.GetEPSGCode(o);
        const zone = DecoratorHelper.GetZone(epsgCode);
        let project: any;
        if (epsgCode.includes("327")) {
            project = "+proj=utm +zone=" + zone + " +south";
        } else {
         project = "+proj=utm +zone=" + zone;
        }
        proj4.defs(epsgCode, project);
        const proj: any = proj4;
        const sourceProj = new proj.Proj(epsgCode);
        const destProj = new proj.Proj("WGS84");
        const proj4Arr = [o.longitudeDegrees, o.latitudeDegrees];
        const r = proj(destProj, sourceProj, proj4Arr);
        return r;
    }

    public static extractPoint3D(pt: Point3d) {
        // const iModel = UiFramework.getIModelConnection()!;
        // const cart = iModel.spatialToCartographicFromEcef(iModel.projectExtents.high!);
        // pt = DecoratorHelper.ExtractSpatialXYZ(cart, pt.x, pt.y, pt.z, iModel);
        const iModel = UiFramework.getIModelConnection();
        const cart = iModel!.spatialToCartographicFromEcef(pt);
        const m = DecoratorHelper.convertWGS2UTM(cart);
        return new Point3d(m[0], m[1], cart.height);
      }
  
    public static extract3DPoint(pt: Point3d) {
        const iModel = UiFramework.getIModelConnection()!;
        const cart = iModel.spatialToCartographicFromEcef(iModel.projectExtents.high!);
        pt = DecoratorHelper.ExtractSpatialXYZ(cart, pt.x, pt.y, pt.z, iModel);
        return pt;
      }
  
    //-------------------------------------------------------------------------------------------------------------------------------
    /*
        Return Zone
    */
    private static GetZone(epsgCode: string) {
        return epsgCode.substring(epsgCode.length - 2, epsgCode.length);
    }
    //-------------------------------------------------------------------------------------------------------------------------------
    /*
        Returns EPSG code
    */
    // tslint:disable-next-line:naming-convention
    private static GetEPSGCode(o: Cartographic) {
        let utmBand = ((Math.floor((o.longitudeDegrees + 180) / 6) % 60) + 1).toString();

        let epsgCode = "";
        if (utmBand.length === 1) {
            utmBand = "0" + utmBand;
        }
        if (o.latitudeDegrees >= 0) {
            epsgCode = "326" + utmBand;
        } else {
            epsgCode = "327" + utmBand;
        }
        const tsd = store.getState().detectedData.towerStructureData as towerStructureData;
        if(tsd.epsg_code)epsgCode = tsd.epsg_code.toString();
        return epsgCode;
    }
}
