import { UiFramework } from "@itwin/appui-react";
import { ProgressRadial } from "@itwin/itwinui-react";
import axios from "axios";
import * as React from "react";
import { Logger } from "../api/logging";
import { ConfigManager } from "../../config/ConfigManager";
import { RootState } from "../../store/States";
import { store } from "../../store/rootReducer";
import ModalDialog from "./TreeVisualizer/dialog/dialog";
import { BuddiHelper } from "../api/BuddiHelper";

export const PDFManager = () => {
    const [fileId, setfileId] = React.useState("");
    const [pdfMarkupUrl, setPDFMarkupUrl] = React.useState("");
    const [isLoading, setIsLoading] = React.useState(true);
    const [fileLength, setFileLength] = React.useState(0);
    React.useEffect(() => {
       fetchFileData();
    }, [])


    const fetchFileData = async () => {
      let  project = await getProjectName()
      let storageData = await  getParentFolderData(ConfigManager.projectId!)
     if(storageData){
        let projectName = project.data.projectById[0].properties.name
        storageData.items.map(async(data)=> {
            if(data.id && data.type == "folder"){

                await getFilesInParticularFolder(data.id,projectName)
            } 
        })
     }
    }

    const getEnvironmentPrefix = () => {
        let prefix = ""
        if(ConfigManager.regionCode == 103)prefix="dev-"
        else if(ConfigManager.regionCode == 102)prefix="qa-"
        return prefix
    }
    const getAccessToken = (state: RootState) => {
               
        return state.auth.accessTokenState.accessToken;
      }
      const getAccessTokenPrivate = (state: RootState) => {
               
        return state.auth.accessTokenStatePrivateAPI.accessToken;
      }

    const getParentFolderData = async (projectId:string) => {
        try{
            let token :any=  getAccessToken(store.getState())?.toString() ;
            
            let baseUrl = new URL(`https://${getEnvironmentPrefix()}api.bentley.com/storage/?projectId=${projectId}`);

            const subFoldersFo: any = await axios.get(baseUrl.href,{
                headers:{
                    Authorization:token,
                    Accept:" application/vnd.bentley.itwin-platform.v1+json  "     
                         } 

            });
        if(subFoldersFo.status == 200) return (subFoldersFo.data)
        }catch(e){
          Logger.error(`Error while calling https://${getEnvironmentPrefix()}api.bentley.com/storage/?projectId=${projectId}`,e)
        }
    }

    const getFilesInParticularFolder = async (id,projectName:string) => {
        try{
            let token :any=  getAccessToken(store.getState())?.toString() ;

            let baseUrl = new URL(`https://${getEnvironmentPrefix()}api.bentley.com/storage/folders/${id}/search?name=${projectName}.pdf`);

            const file: any =  await axios.get(baseUrl.href,{
                headers:{
                    Authorization:token,
                    Accept:" application/vnd.bentley.itwin-platform.v1+json  "     
                         } 
        
            });
          
     if(file && file.status == 200){
        if(file.data.items.length!=0){
            setFileLength(1)
            await downloadFileInformation(file.data.items[0].id)
        }
     }
     setIsLoading(false)
        }catch(e){
           Logger.error(`Error while calling https://${getEnvironmentPrefix()}api.bentley.com/storage/folders/${id}/search?name=${projectName}.pdf`,e)
        }
    }
   

   const  downloadFileInformation = async (wsgId : string) => {
        try{

            let token :any = store.getState().auth.accessTokenState.accessToken;//Project assess token
            var myHeaders = new Headers();
            myHeaders.append("Authorization",token);
            
            var requestOptions = {
              method: 'GET',
              headers: myHeaders,
              redirect: undefined
            };
            let img;
           let data = await fetch(`https://${getEnvironmentPrefix()}api.bentley.com/storage/files/${wsgId}/download`, requestOptions).then(res=>{
               img = res.url
            //    res.url
        })
        const PDFMarkupUrl = await BuddiHelper.getConnectPDFMarkupUrl();
        setPDFMarkupUrl(PDFMarkupUrl);
           
        if(img) {
            let fileId = img.split(["/"])[6].split(["?"])[0]
            const url = `${PDFMarkupUrl}/${ConfigManager.projectId}/Share/${fileId}?embedded=true`;
            setfileId(url)
            setIsLoading(true)
        }
   // setPDFMarkupUrl(img)
           
            return img;
        }catch(e){
          Logger.error(`Error while calling https://${getEnvironmentPrefix()}api.bentley.com/storage/files/${wsgId}/download`,e)
        }
    }

    const getProjectName = async () => {
        try{

            const data = JSON.stringify( {
                "operationName": "projectById",
                "variables": {
                    "instanceId": ConfigManager.projectId
                },
                "query": "query projectById($instanceId: String) {\n  projectById(instanceId: $instanceId) {\n    instanceId\n     properties {\n      assetId\n        name\n      number\n      ultimateRefId\n       unitSystemId\n      __typename\n    }\n    __typename\n  }\n}\n"});
            let token :any = store.getState().auth.accessTokenStatePrivateAPI.accessToken;//Project assess token
    
            let  res = await axios.post(`https://${getEnvironmentPrefix()}towersightbff.bentley.com/graphql`,data,{
                headers: {
                    Authorization:token,
                    "Content-Type": "application/json",
                },
            })
            if(res && res.status == 200){
                return res.data
            }
            return []
        }catch(e){
          Logger.error(`Error while calling https://${getEnvironmentPrefix()}towersightbff.bentley.com/graphql`,e)
        }
    }

    const closeModal = ()=>{
        UiFramework.content.dialogs.close(
            "saReport"

        )
    }
    return (
        <>
        <ModalDialog
       height={900} width={1500}  X={100} Y={30}
                // closeHandle={() => closeModal()}
                // primaryButtonLabel={"Yes"}
                title={"SA Report"}
                // primaryButtonHandle={() => handleSave()}
                // isPrimaryDisabled={containsAntenna}
                onClose={() => closeModal()}
                // secondaryButtonLabel="No"
            >

        
        {isLoading  ?
        <div style={{ width: "100%", height: "100%", gridRow: 1, gridColumn: 2, zIndex: 6001 }} className="loding-container facilities-loading">
           <div id="roundNround" style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '730px'}}>
                         {/* <Spinner size={SpinnerSize.XLarge} /> */}
                      {/* <LoadingSpinner style={{width: "100px"}} status="Loading..." title={"Loading panoramic view"} /> */}
                      <ProgressRadial size="large" style={{width: "160px", height: "200px"}} indeterminate value={50} >Loading...</ProgressRadial>
                    </div>
        </div> : fileLength === 0 ?
            <>
                <div style={{ height: "100%", display:'flex',justifyContent:'center',alignItems:'center' }}  className="no-Pdf-File">
                    <div>

                    <div>File is not present in Documents Management</div>
                    <div>**File should present under Analysis Folder and name must be same as Project Name</div>
                    </div>
                </div>
            </> :
            <div style={{ width: "100%", height: "100%", gridRow: 1, gridColumn: 2, zIndex: 6001 }} className="facilities-loading">
                <embed type="application/pdf"  src={fileId}  style={{ height: "100%", width: "100%" }} background-color="0xFF525659" top-toolbar-height="56" full-frame="" />
                </div>
        }
            </ModalDialog>

        </>
            );
}