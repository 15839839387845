import { BeButtonEvent, EventHandled, IModelApp, NotifyMessageDetails, OutputMessagePriority, OutputMessageType, PrimitiveTool, ScreenViewport, Viewport } from "@itwin/core-frontend";
import { StagePanelLocation, UiFramework, WidgetState } from "@itwin/appui-react";
import { Logger} from "@itwin/core-bentley";
import { LadderDecorator } from "../../tools/decorators/LadderDecorator";
import { store } from "../../../store/rootReducer";

// tslint:disable:naming-convention
export class HighlightLadderTool extends PrimitiveTool {
    public static override toolId = "HighlightLadderTool";
    public static override iconSpec = "icon-spatial-view-apply";

    public override isCompatibleViewport(_vp: Viewport | undefined, _isSelectedViewChange: boolean): boolean {
        return true;
    }

    public override run(ladderData: any, isLoaded: boolean, selectedElevations: string[] = ["All"]): Promise<boolean> {
        super.run()
        const { toolAdmin, viewManager } = IModelApp;
        if (!this.isCompatibleViewport(viewManager.selectedView, false) || !toolAdmin.onInstallTool(this)) {
            return Promise.resolve(false);
        }
        toolAdmin.startPrimitiveTool(this);
        // const vp = IModelApp.viewManager.selectedView as ScreenViewport;
        // SampleToolWidget.setModelIndexValues(vp);
        if (isLoaded) {
            if (ladderData === undefined) {
                Logger.logWarning("Warning", "HiglightLadderTool Data not avilable");
                if(!store.getState().dtvState.applicationState.highlightStates.highlightAll) IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Error, "Information missing, cannot highlight ladder.", "", OutputMessageType.Toast));
                return Promise.resolve(false);
            } else {
                if(!store.getState().dtvState.applicationState.highlightStates.highlightAll) Logger.logWarning("Message", "HiglightLadderTool data is avilable");
            }
            let found = true;
            let md = IModelApp.viewManager.decorators.filter(e=>e.constructor.name.includes("LadderDecorator"))[0] as LadderDecorator;
            if(md === undefined){
                found = false;
                md = new LadderDecorator();
                IModelApp.viewManager.addDecorator(md);
            }
            // if(selectedElevations != "All")md.clearGeometry(mountData, selectedElevations);
            const retVal: any = md.useDataAndLoadPipes(ladderData, selectedElevations);
                    // IModelApp.viewManager.invalidateDecorationsAllViews();
                    // IModelApp.viewManager.selectedView?.invalidateCachedDecorations(md);
            if (retVal === true) {
                if(found){
                    const allPipes = md.pipes;
                    const tori = md.tori;
                    md.terminate();
                    md.pipes=allPipes;
                    md.tori=tori;
                    IModelApp.viewManager.invalidateDecorationsAllViews();
                    IModelApp.viewManager.selectedView?.invalidateCachedDecorations(md);
                }
            } else if(retVal === false && !store.getState().dtvState.applicationState.highlightStates.highlightAll){
                IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Info, "Error Loading Ladders", "", OutputMessageType.Toast));
            }
        } else {
            for (const dec of IModelApp.viewManager.decorators) {
                const n = dec.constructor.name;
                if (n.includes("LadderDecorator")) {
                (dec as LadderDecorator).terminate();
                IModelApp.viewManager.dropDecorator(dec);
                // IModelApp.viewManager.decorators.splice(i, 1);
                }
            }
            const isBlankConnection: boolean | undefined = UiFramework.getIModelConnection()?.isBlank;
            if (isBlankConnection) {
                UiFramework.frontstages.activeFrontstageDef?.getStagePanelDef(StagePanelLocation.Right)?.
                findWidgetDef("PropertyListWidget")?.setWidgetState(WidgetState.Hidden);
            } else if ((IModelApp as any).listCallback) {
                // (IModelApp as any).listCallback(SampleToolWidget.currentList);
            }
        }
        // IModelApp.viewManager.selectedView?.invalidateDecorations();
            this.exitTool();
        return Promise.resolve(true);
    }

    public onResetButtonUp(_ev: BeButtonEvent): Promise<EventHandled> {
        this.exitTool();
        return Promise.resolve(EventHandled.Yes);
    }

    public onRestartTool(): Promise<void> {
        return Promise.resolve();
    }
}
