import axios from "axios";
import { Logger } from "./logging";

export default class FeatureFlagApiClient {
  constructor() {}

    public static async featureFlag(featureName: string, token: string) {
    const path = decodeURIComponent(window.location.origin);
    const featureFlagUrl = path + "/FeatureToggling/IsFeatureEnabled?featureName=" + featureName;
    try {
          const value = axios(featureFlagUrl, {
              method: "get",
              headers: {
                  Accept: "application/json",
                  Authorization: token,
              },
          });
          return (await value).data;
      } catch (e) {
          // tslint:disable-next-line:no-console
          Logger.error("Error while fetching feature flag",e)
          return;
      }
  }

  public static async featureFlags(featureNames: string[], token: string) {
    const path = decodeURIComponent(window.location.origin);
    const featureFlagUrl = path + "/FeatureToggling/IsFeaturesEnabled";
    // const featureFlagUrl = `https://qa-towersightitwin.bentley.com/FeatureToggling/IsFeaturesEnabled`;
    // return true;
    try {
      const value = await axios(featureFlagUrl, {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: token,
        },
        data: featureNames,
      });
      return value.data;
    } catch (e) {
      // tslint:disable-next-line:no-console
      Logger.error("Error while fetching feature flag",e)
      return;
    }
  }
}
