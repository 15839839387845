import * as React from "react";
import { Dialog, DialogAlignment } from "@itwin/core-react";
import { IModelApp } from "@itwin/core-frontend";

interface modalProps{
    X?:number;      // Dialog appearance x location of screen
    Y?:number;      // Dialog appearance y location of screen
    width?:number;
    title?: string;
    height?: number;
    children: boolean | React.ReactNode | React.ReactFragment | React.ReactPortal | null | undefined;
    customClasses?: string;
    identifier?: string;
    onClose: () => void;
    contentClassName?:string;
}

const ModalDialog = (props: modalProps) => {
    const [opened, setOpened] = React.useState(true);
    const getLocationOfPopup = (proposedWidth: number, proposedHeight: number) => {
        let returnValue = {
            xLocation: window.innerWidth - proposedWidth - 90,
            yLocation: window.innerHeight - proposedHeight - 90,
            alignment: DialogAlignment.Center,
        };
        const translatedTitle = "VisualizerApp:buttons.viewportDialog";
        const x: NodeListOf<Element> = document.querySelectorAll(
            '[title="' + translatedTitle + '"][class="nz-toolbar-item-item"]',
        );
        if (x.length > 0) {
            returnValue = {
                xLocation: x[0].getBoundingClientRect().left,
                yLocation: x[0].getBoundingClientRect().top + x[0].getBoundingClientRect().height + 10,
                alignment: DialogAlignment.TopLeft,
            };
        } else {
            let nineZoneDiv: Element | null = document.getElementById("uifw-ninezone-area");
            if (null == nineZoneDiv)
                nineZoneDiv =
                    document.getElementsByClassName("collapseButton").length > 0
                        ? document.getElementsByClassName("collapseButton")[0]
                        : null;
            if (nineZoneDiv !== null)
                returnValue = {
                    xLocation: nineZoneDiv.getBoundingClientRect().left + 90,
                    yLocation: returnValue.yLocation,
                    alignment: DialogAlignment.BottomLeft,
                };
        }
        return returnValue;
        // var toolbarButtons = document.getElementsByClassName("nz-toolbar-item-item")
    };

    const _handleClose = () => {
        _closeDialog();
    };

    const _closeDialog = () => {
        props.onClose();
    };

    const modalWidth = props.width? props.width : 900;
    const modalHeight = props.height ? props.height : 600;
    const dialogTitle = props.title != undefined ? props.title : IModelApp.localization.getLocalizedString("NineZoneSample:tools.3dview");
    const xLocationAndAlignemntComputed = getLocationOfPopup(
        modalWidth,
        modalHeight,
    );

    return (
            <>
                <div>
                    <Dialog
                        className={props.customClasses}
                        contentClassName = {props.contentClassName}
                        id={`imodel-viewport-dialog-${props.identifier}`}
                        title={dialogTitle}
                        opened={opened}
                        resizable={true}
                        movable={true}
                        modal={false}
                        width={modalWidth}
                        height={modalHeight}
                        x={props.X ?? 400}
                        y={props.Y ?? 350}
                        minWidth={modalWidth ? modalWidth :400}
                        minHeight={modalHeight ? modalHeight : 150}
                        onClose={() => _handleClose()}
                        onEscape={() => _handleClose()}
                        alignment={xLocationAndAlignemntComputed.alignment}
                >
                    {props.children}
                    </Dialog>
                </div>
            </>
    );
};

export default ModalDialog;
