import { useEffect, useRef, useState } from "react";
import * as React from "react";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import "./imageCarousel.css";
import { connect } from 'react-redux'
import { Modal, ModalContent } from "@itwin/itwinui-react";
import { Accordion, Button, Carousel, Collapse } from "react-bootstrap";
import { RootState } from "../../../store/States";
import { ConfigManager } from "../../../config/ConfigManager";

export type ImageType = { id: number; url: string };


interface imgCarInterface { 
    images?: ImageType[];
    showCarousel: any;
    toggleCarousel: any;
    title: any;
    height:any;
    width:any;
    setImageData?:any;
    listOptions: any[];
    insiteImageUrl?: string;
    imageData: any;
}
const ImageCarousel: React.FC<imgCarInterface> = (props) => {
    // let start=0, end=0, imagesBkp = JSON.parse(JSON.stringify(props.images));
    // const [images, setImages] = useState<ImageType[]>(props.images!);
    const { images, showCarousel, toggleCarousel,title,height,width, setImageData, listOptions} = props;
    const [modalTitle, setModalTitle] = React.useState(title);
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);
    const [selectedImage, setSelectedImage] = useState<ImageType>({id: 0, url: "data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22800%22%20height%3D%22400%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20800%20400%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_1885f0d6ee3%20text%20%7B%20fill%3A%23a3a3a3%3Bfont-weight%3Anormal%3Bfont-family%3Avar(--bs-font-sans-serif)%2C%20monospace%3Bfont-size%3A40pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_1885f0d6ee3%22%3E%3Crect%20width%3D%22800%22%20height%3D%22400%22%20fill%3D%22%23e5e5e5%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22278.3203125%22%20y%3D%22221.3%22%3EThird%20slide%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E"});
    const [theImages, setTheImages] = useState(images);
    const [mainImageLoaded, setMainImageLoaded] = useState(false);
    const carouselItemsRef = useRef<HTMLDivElement[] | null[]>([]);
    const [selectedOption, setSelectedOption] = useState<any>(listOptions[0]);
    const [open, setOpen] = useState(false);
    const [theSrcs, setTheSrcs] = useState<string[]>([]);
    const [accordianImages, setAccordianImages] = useState<string[]>([]);
    const theRef = useRef();
    useEffect(()=>{
        // setImageData(selectedOption.parent, selectedOption.value);
        getImageData(props.imageData, selectedOption.parent, selectedOption.value);
    },[])
    
    useEffect(()=>{
        setImageData(selectedOption.parent, selectedOption.value);
        getImageData(props.imageData, selectedOption.parent, selectedOption.value);
        },[selectedOption])
    
    useEffect(() => {
        // if(selectedOption){
            // const retS = getImageData(props.towerImages, "structural", "structural");
        // }
        let interval;
        if (theImages && theImages[0]) {
            carouselItemsRef.current = carouselItemsRef.current.slice(
                0,
                theImages.length
            );
            // if(imagesBkp.length > 3){
            //     interval = setInterval(() => {
            //         end=start+3;
            //         if(start+3 > imagesBkp.length){
            //             end=imagesBkp.length;
            //             // setImages(imagesBkp);
            //         }
            //         const tempImages = imagesBkp.slice(0, end);
            //         start+=3;
            //         setImages(tempImages);
            //         // if(end == imagesBkp.length)clearInterval(interval);
            //     }, 3000);
            // }

            setSelectedImageIndex(0);
            setSelectedImage(theImages[0]);
            const srcs = [
                theImages[0].url,
                // theImages[1].url,
                // theImages[2].url,
            ]

            setTheSrcs(srcs);
        }
        //  return () => {if(interval && end == imagesBkp.length)clearInterval(interval)};
        
    }, [theImages]);

    

    const getImageData = async (data,parentField, field) => {
        // data = props.towerImages;
        let siteImage: any = [];
        let defectType;
        let insiteUrl = props.insiteImageUrl;
          if (field == "") {
            //--------//
            //Updated : 01-04-2023.
            // The correct spec should not have any rigid sub category as above under structural data.
            // It can be named to any sub memeber name.
            //--------
              siteImage = data[parentField];
              let tempa: any[] = [];
              for (var key in siteImage) {
                  if (siteImage.hasOwnProperty(key)) {
                      if (siteImage[key].Filename != undefined) {
                          tempa.push(siteImage[key].Filename);
                      }
                      for (var kkey in siteImage[key]) {
                        if (siteImage[key].hasOwnProperty(kkey) && siteImage[key][kkey].Filename != undefined) {
                            tempa.push(siteImage[key][kkey].Filename);
                        }
                      }      
                  }
              }
              siteImage = tempa;
            //--------
          } 
          else if(parentField === "Equipment Images") {
            const imgName = data.imageList;
            const imgNameReplace = imgName.replace(/[\[\]']+/g, "");
            siteImage = imgNameReplace.replaceAll('"', "").split(",");
          } 
          else if(parentField === "Defect Image") {
            if (data && data.fileName) {
                const {criticality, type, defect} = data
                let titl = titleCase(`${criticality} ${type} - ${defect}`)
                setModalTitle(titl);
                const imgName = data.fileName;
                defectType = data.type.toLowerCase() == "screw"?"screws":data.type.toLowerCase()
                siteImage = [imgName];
                if(insiteUrl){
                    insiteUrl = insiteUrl.replace("api/img?", "api/aiml/img?");
                }
            }
          }
           else {
              siteImage = Object.keys(data[parentField][field]);
          }
    
          let s: any[] = siteImage.map((image, index) => ({
              id: index,
              url: `${insiteUrl}&img=${image.trimStart()}${parentField === "Defect Image" ? `&type=${defectType}` : ""}`
          }));
        //   setSelectedOption(s);
          setTheImages(s);
          setAccordianImages(s.slice(0, 8));
          return s;
        //   this.setState({ showCarousel: true, images: s });
          const time = 3000;
    }
    

    const handleSelectedImageChange = (newIdx: number) => {
        if (theImages && theImages.length > 0) {
            setSelectedImage(theImages[newIdx]);
            setSelectedImageIndex(newIdx);

            const tempSrc: string[] = [];
            const imagesLength = theImages!.length;
            const tempIdx: number[] = [];
            if(newIdx === 0)tempIdx.push(theImages.length-2, 0, 1);
            else if(newIdx === theImages.length-1)tempIdx.push(theImages.length-2, theImages.length-1, 0);
            else tempIdx.push(newIdx-1, newIdx, newIdx+1);

            if(imagesLength >= 1 && imagesLength < 2)tempSrc.push(theImages![tempIdx[0]].url);
            else if(imagesLength >= 2 && imagesLength < 3)tempSrc.push(theImages![tempIdx[1]].url);
            else if(imagesLength >= 3 && imagesLength < 4)tempSrc.push(theImages![tempIdx[2]].url);
            else if(imagesLength > 3)tempSrc.push(theImages![tempIdx[0]].url, theImages![tempIdx[1]].url, theImages![tempIdx[2]].url)
            setTheSrcs(tempSrc);

            if (carouselItemsRef?.current[newIdx]) {
                carouselItemsRef?.current[newIdx]?.scrollIntoView({
                    inline: "center",
                    behavior: "smooth"
                });
            }
        }
    };

    const handleRightClick = () => {
        if (theImages && theImages.length > 0) {
            let newIdx = selectedImageIndex + 1;
            if (newIdx >= theImages.length) {
                newIdx = 0;
            }
            handleSelectedImageChange(newIdx);
        }
    };

    const handleLeftClick = () => {
        if (theImages && theImages.length > 0) {
            let newIdx = selectedImageIndex - 1;
            if (newIdx < 0) {
                newIdx = theImages.length - 1;
            }
            handleSelectedImageChange(newIdx);
        }
    };

    const getOptionName = (e) => {
        setSelectedOption({value: e.currentTarget.dataset.id, parent: e.currentTarget.dataset.parent});
    }

    const getSelectedName = () => {
        let name = "";
        const selectedName = listOptions.filter(item=>{
            if (selectedOption.value == item.value) name = item.name;
        });
        return name;
    }

    const onSlide = (event: any, direction: any) => {
        if(direction && direction.target){
            let eventValue = event;
            let temp = selectedImageIndex;
            if(direction.target.classList.contains("carousel-control-next-icon") || direction.target.classList.contains("carousel-control-next"))temp++;
            else temp--;

            if(temp > theImages!?.length-1){
                temp = 0;
            }
            if(temp < 0){
                temp = theImages!?.length-2-temp;
            }
            const tempIdx = [temp, temp+1 > theImages!?.length-1 ? 1 : temp+1, temp+2 > theImages!?.length-1 ? 2: temp+2];

            const tempSrc: string[] = [];
            const imagesLength = theImages!.length;
            if(imagesLength >= 1 && imagesLength < 2)tempSrc.push(theImages![tempIdx[0]].url);
            else if(imagesLength >= 2 && imagesLength < 3)tempSrc.push(theImages![tempIdx[1]].url);
            else if(imagesLength >= 3 && imagesLength < 4)tempSrc.push(theImages![tempIdx[2]].url);
            else if(imagesLength > 3)tempSrc.push(theImages![tempIdx[0]].url, theImages![tempIdx[1]].url, theImages![tempIdx[2]].url)

            setTheSrcs(tempSrc);
            setSelectedImageIndex(temp);
            setSelectedImage(theImages![temp]);
            setSelectedImageIndex(temp);
            setSelectedImage(theImages![temp]);
        }
    }

    const onSelectSlide = (event: any, direction: any) => {
        if(direction && direction.target){
            let temp = selectedImageIndex;
            let eventValue = event;
            if(direction.target.classList.contains("carousel-control-next-icon") || direction.target.classList.contains("carousel-control-next"))temp++;
            else temp--;
            if(temp > theImages!?.length-1){
                temp = 0;
            }
            if(temp < 0){
                temp = theImages!?.length-2-temp;
            }
            const tempIdx = [temp, temp+1 > theImages!?.length-1 ? 1 : temp+1, temp+2 > theImages!?.length-1 ? 2: temp+2];

            const tempSrc: string[] = [];
            const imagesLength = theImages!.length;
            if(imagesLength >= 1 && imagesLength < 2)tempSrc.push(theImages![tempIdx[0]].url);
            else if(imagesLength >= 2 && imagesLength < 3)tempSrc.push(theImages![tempIdx[1]].url);
            else if(imagesLength >= 3 && imagesLength < 4)tempSrc.push(theImages![tempIdx[2]].url);
            else if(imagesLength > 3)tempSrc.push(theImages![tempIdx[0]].url, theImages![tempIdx[1]].url, theImages![tempIdx[2]].url);

            setTheSrcs(tempSrc);
            setSelectedImageIndex(temp);
            setSelectedImage(theImages![temp]);
        }
    }
    const titleCase = (str) => {
        return str.split(' ').map(item => 
               item.charAt(0).toUpperCase() + item.slice(1).toLowerCase()).join(' ');
      }

    return (
    <section>
            {showCarousel &&
        <div className="carousel-container imageViewModel">
        <Modal
              isOpen={showCarousel}
              title={modalTitle}
              onClose={() => toggleCarousel()}
              closeOnEsc={true}
              style={{width: "1600px", maxWidth: "70%"}}
          >
              <ModalContent>

              <nav className="navigation">
              {!selectedOption.parent.match(/Equipment|Defect/i)?<ul>
                        {
                            listOptions.map((item)=><li className={selectedOption.value == item.value ? "active-class" : ""} data-id={item.value} data-parent={item.parent} onClick={getOptionName}>{item.name}</li>)
                        }
                    </ul> :null}
                </nav>
    <Carousel variant="dark" indicators={false} slide={true} controls={accordianImages.length>1?true:false}  onSlid={onSlide} onSlide={onSlide} onSelect={onSelectSlide} style={{width: "auto"}}>
      {/* <Carousel.Item>
        <img
          className="d-block w-100"
        //   src="data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22800%22%20height%3D%22400%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20800%20400%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_1885f0d6ee2%20text%20%7B%20fill%3A%23afafaf%3Bfont-weight%3Anormal%3Bfont-family%3Avar(--bs-font-sans-serif)%2C%20monospace%3Bfont-size%3A40pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_1885f0d6ee2%22%3E%3Crect%20width%3D%22800%22%20height%3D%22400%22%20fill%3D%22%23f5f5f5%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22289.71875%22%20y%3D%22221.3%22%3EFirst%20slide%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E"
        src={new URL(theImages![selectedImageIndex].url).href}
        alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
        //   src="data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22800%22%20height%3D%22400%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20800%20400%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_1885f0d6ee3%20text%20%7B%20fill%3A%23aaaaaa%3Bfont-weight%3Anormal%3Bfont-family%3Avar(--bs-font-sans-serif)%2C%20monospace%3Bfont-size%3A40pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_1885f0d6ee3%22%3E%3Crect%20width%3D%22800%22%20height%3D%22400%22%20fill%3D%22%23eee%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22251.96875%22%20y%3D%22221.3%22%3ESecond%20slide%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E"
        src={new URL(theImages![selectedImageIndex+1].url).href}
        alt="Second slide"
        />
      </Carousel.Item> */}
      <Carousel.Item>
      <TransformWrapper >
                    <TransformComponent>
                        
                        <img
                        className="d-block w-100"
                        key={selectedImageIndex}
                        //   src="data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22800%22%20height%3D%22400%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20800%20400%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_1885f0d6ee3%20text%20%7B%20fill%3A%23a3a3a3%3Bfont-weight%3Anormal%3Bfont-family%3Avar(--bs-font-sans-serif)%2C%20monospace%3Bfont-size%3A40pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_1885f0d6ee3%22%3E%3Crect%20width%3D%22800%22%20height%3D%22400%22%20fill%3D%22%23e5e5e5%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22278.3203125%22%20y%3D%22221.3%22%3EThird%20slide%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E"
                        //   src={new URL(selectedImage!.url).href}
                            // src={new URL(theImages![selectedImageIndex].url).href}
                            src={theSrcs[0]}
                        alt="Image"
                        width={900}
                        height={600}
                        loading="lazy"
                        />
                    </TransformComponent>
                </TransformWrapper >
      </Carousel.Item>
      {theSrcs.length == 2 && <Carousel.Item>
      <TransformWrapper >
                    <TransformComponent>
                        
                        <img
                        className="d-block w-100"
                        key={selectedImageIndex}
                        //   src="data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22800%22%20height%3D%22400%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20800%20400%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_1885f0d6ee3%20text%20%7B%20fill%3A%23a3a3a3%3Bfont-weight%3Anormal%3Bfont-family%3Avar(--bs-font-sans-serif)%2C%20monospace%3Bfont-size%3A40pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_1885f0d6ee3%22%3E%3Crect%20width%3D%22800%22%20height%3D%22400%22%20fill%3D%22%23e5e5e5%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22278.3203125%22%20y%3D%22221.3%22%3EThird%20slide%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E"
                        //   src={new URL(selectedImage!.url).href}
                            // src={new URL(theImages![selectedImageIndex+1].url).href}
                            src={theSrcs[1]}
                        alt="Image"
                        width={800}
                        height={600}
                        loading="lazy"
                        />
                    </TransformComponent>
                </TransformWrapper >
      </Carousel.Item>}
      {theSrcs.length == 3 && <Carousel.Item>
      <TransformWrapper >
                    <TransformComponent>
                        
                        <img
                        className="d-block w-100"
                        key={selectedImageIndex}
                        //   src="data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22800%22%20height%3D%22400%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20800%20400%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_1885f0d6ee3%20text%20%7B%20fill%3A%23a3a3a3%3Bfont-weight%3Anormal%3Bfont-family%3Avar(--bs-font-sans-serif)%2C%20monospace%3Bfont-size%3A40pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_1885f0d6ee3%22%3E%3Crect%20width%3D%22800%22%20height%3D%22400%22%20fill%3D%22%23e5e5e5%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22278.3203125%22%20y%3D%22221.3%22%3EThird%20slide%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E"
                        //   src={new URL(selectedImage!.url).href}
                            // src={new URL(theImages![selectedImageIndex+2].url).href}
                            src={theSrcs[2]}
                        alt="Image"
                        width={700}
                        height={600}
                        loading="lazy"
                        />
                    </TransformComponent>
                </TransformWrapper >
      </Carousel.Item>}
    </Carousel>
    {/* <Button
        style={{borderRadius: 0}}
        onClick={() => setOpen(!open)}
        aria-controls="example-collapse-text"
        aria-expanded={open}
        variant="outline-dark"
      >
        { open ? <SvgChevronUp/> : <SvgChevronDown/> }
      </Button>
      <Collapse in={open}> */}

    <Accordion defaultActiveKey="none" flush>
      <Accordion.Item eventKey="0">
        <Accordion.Header>Show All Images</Accordion.Header>
        <Accordion.Body style={{paddingTop: 0, paddingBottom: 0}}>      
                    <div id="example-collapse-text" className="carousel__images">
                        {theImages &&
                            theImages.map((image: any, idx) => (                        

                                <img
                                    onClick={() => handleSelectedImageChange(idx)}
                                    // style={{ backgroundImage: `url(${image.url})` }}
                                    // src={new URL(selectedImage.url).href}
                                    // src={selectedImage.url}
                                    src={theImages[selectedImageIndex].url}
                                    key={image.id}
                                    className={`carousel__image ${
                                        selectedImageIndex === idx && "carousel__image-selected"
                                    }`}
                                    ref={(el) => (carouselItemsRef.current[idx] = el)}
                                    height={60}
                                    loading="lazy"
                                />
                            ))
                        }
                    </div>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>

                {/* </Collapse> */}

                {/* <ModalDialog Y={150} height={height} width={width} onClose={() => toggleCarousel()} title={title}> */}
            {/* <div style={{  overflowY: "hidden" }}>
                <nav className="navigation">
                    <ul>
                        {
                            listOptions.map((item)=><li className={selectedOption.value == item.value ? "active-class" : ""} data-id={item.value} data-parent={item.parent} onClick={getOptionName}>{item.name}</li>)
                        }
                    </ul> 
                </nav>

                <TransformWrapper >
                    <TransformComponent>
                        <div
                            className="selected-image"
                            style={{ backgroundImage: `url(${selectedImage?.url})`, overflowY: "hidden" }}
                        />
                    </TransformComponent>
                </TransformWrapper >
                <div className="carousel">
                    <div className="carousel__images">
                        {theImages &&
                            theImages.map((image, idx) => (
                                <div
                                    onClick={() => handleSelectedImageChange(idx)}
                                    style={{ backgroundImage: `url(${image.url})` }}
                                    key={image.id}
                                    className={`carousel__image ${
                                        selectedImageIndex === idx && "carousel__image-selected"
                                        }`}
                                    ref={(el) => (carouselItemsRef.current[idx] = el)}
                                />
                            ))}
                    </div>
                    {theImages!.length > 5 && <button
                        className="carousel__button carousel__button-left"
                        onClick={handleLeftClick}
                    >
                        Prev
                    </button>}
                    {theImages!.length > 5 &&<button
                        className="carousel__button carousel__button-right"
                        onClick={handleRightClick}
                    >
                        Next
                    </button>}
                </div>
            </div> */}
        {/* </ModalDialog > */}
        </ModalContent>
        </Modal>
        </div>
            }
       </section>
    );
};

// export default ImageCarousel;
const mapStateToProps = (state: RootState) => {
    const imgs = [];

    
    return {
        insiteImageUrl: ConfigManager.INsiteImageUrl,
        towerImages: state.detectedData.towerSiteImagesData,
    }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ImageCarousel)
