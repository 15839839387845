import { IModelApp, NotifyMessageDetails, OutputMessagePriority, OutputMessageType } from "@itwin/core-frontend"
import { pecColumn, pecInputTagTypes, pecDataValueTypes, pecRows, pecGridStructure, pecComponentProps, pecStrucType, pecTableButton, PropertyEditorComponent } from "../components/PropertyEditorComponent"

import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Vector3d } from "@itwin/core-geometry"
import { UiFramework } from "@itwin/appui-react"
import React from "react"
import { DTVActions } from "../../store/Actions"
import { RootState } from "../../store/States"
import { store } from "../../store/rootReducer"
import { AddLadderTool } from "../components/tools/AddLadderTool"
import { LadderDecorator } from "./decorators/LadderDecorator"
import { setLadderData } from "../../store/detectedData/apiDataActionTypes"
import { getLadderData } from "../api/ladderClient"
import { resetObjectIds } from "../components/HorizontalToolbarItems"
export const enum RadioSection {
    Start = "Start",
    Whole = "Whole",
    End = "End",
  }
  let runLoop:any;
  
const DetectedLadderEditHandler = (props) => {
  const [ladderEditData, setLadderEditData] = useState(props.detMountMenuStruc);
  const [selection, setSelection] = useState(props.editSection);
  const [dataUpdated, setDataUpdated] = useState<boolean>(false);
  
  const move = 0.01;
  const radiusMove = 0.02;

  useEffect(() => {
    // return () => {}
  }, [])
  

const saveLadder = async() => {
   let res =  await AddLadderTool.saveLadder()
  if(res && res.status == 200) IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Success, `Ladder ${res.data}`, "", OutputMessageType.Toast));
  let ladder_data = await getLadderData();
  if(ladder_data && ladder_data.status == 200) {
    store.dispatch(setLadderData(ladder_data.data));
    LadderDecorator.ladderData =  []
    const ld = IModelApp.viewManager.decorators.filter(e=>e.constructor.name.includes("LadderDecorator"))[0] as LadderDecorator;
    
    ld. clearAndReload(ladder_data.data)
    resetObjectIds(ld);
  }
}

const deleteLadder = async () => {
    let res = await AddLadderTool.deletePreview();
    IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Success, `Ladder successfully deleted.`, "", OutputMessageType.Toast));
    let ladder_data = await getLadderData();
    if(ladder_data && ladder_data.status == 200) store.dispatch(setLadderData(ladder_data.data));
  
LadderDecorator.editing = false;
    props.onClose("LadderDecorator", true)
  
}

  const onModalBtnClick = (event) => {
    const id = event.target.id;
    switch (id) {
      case "Save#1":
        saveLadder();
        props.onClose("LadderDecorator", false);
        store.dispatch(DTVActions.setEditModeFlag(false));
        UiFramework.dialogs.modeless.close("Edit Dialog")
        break;
      case "Cancel#3":
        props.onClose("LadderDecorator", true);
        store.dispatch(DTVActions.setEditModeFlag(false));
        UiFramework.dialogs.modeless.close("Edit Dialog")
        break;
    case "Delete#2":
        deleteLadder()
        store.dispatch(DTVActions.setEditModeFlag(false));
        UiFramework.dialogs.modeless.close("Edit Dialog")
        break;
    case "Cancel#2":
      props.onClose("LadderDecorator", true);
      store.dispatch(DTVActions.setEditModeFlag(false));
      UiFramework.dialogs.modeless.close("Edit Dialog")
      break;
    }
  }
  
  const onFieldUpdate = (event) => {
    let currentEvent= event;
  }
  
  const onFieldChange = (event, value: string) => {
    const temp: pecGridStructure = {...ladderEditData};
    const eventType = event.constructor.name;


    if(event.target.name == "radio-buttons-group"){
      setSelection(value == RadioSection.Start ? RadioSection.Start : value == RadioSection.Whole ? RadioSection.Whole : RadioSection.End)
      return;
    }

    if((eventType == "SyntheticBaseEvent") || (typeof event === 'object' && event !== null)){
      const {id: theId, name: theName, value: theValue} = event.target;
      const [inputType, rowIndex, columnIndex] = theId.split("@")[0].split('-');

      
      switch (inputType) {
        case pecInputTagTypes.SELECT:
          temp.rows[rowIndex].columns[columnIndex].value = temp.rows[rowIndex].columns[columnIndex].data![theValue];
          break;
        case pecInputTagTypes.CHECKBOX:
          temp.rows[rowIndex].columns[columnIndex].value = !temp.rows[rowIndex].columns[columnIndex].value;
          break;
        case pecInputTagTypes.RADIO_GROUP:
          break;
        case pecInputTagTypes.RADIO_BUTTON:
          const otherRadiosIndex = temp.rows[rowIndex].columns[columnIndex].data.allOtherRadiosIndex;
          otherRadiosIndex.forEach((e:string)=>{
            const rowCol = e.split('-');
            temp.rows[rowCol[0]].columns[rowCol[1]].data.matchValue = temp.rows[rowIndex].columns[columnIndex].value;
          })
          break;
      
        default:
          break;
      }
      
      
    } else {    
      switch (eventType) {
      case "Number":
        
      break;
      
      default:
        break;
      }

    }

  }
  
  const onMouseDown = (event) => {
    const target = event.target.tagName != "SPAN" ? event.target : event.target.parentElement;
    enum editMode {
      Position = "Positional Changes",
      Structure = "Structural Changes",
    }
    const [elementDetail, name] = target.id.split('@');
    const decorator = IModelApp.viewManager.decorators.filter(e=>e.constructor.name.includes("LadderDecorator"))[0] as LadderDecorator;
    let objectEditMode:editMode=editMode.Position, movementVector: Vector3d = new Vector3d(), strucMultFactor = 1;
    switch (name) {
      case "moveLeft":
        movementVector = new Vector3d(move, 0, 0);        
        break;
      case "moveRight":
        movementVector = new Vector3d(-move, 0, 0);        
        break;
      case "moveFront":
        movementVector = new Vector3d(0, move, 0);        
        break;
      case "moveBack":
        movementVector = new Vector3d(0, -move, 0);        
        break;
      case "moveUp":
        movementVector = new Vector3d(0, 0, move);        
        break;
      case "moveDown":
        movementVector = new Vector3d(0, 0, -move);        
        break;    
      case "increaseLength":
        objectEditMode = editMode.Structure;
        break;    
      case "decreaseLength":
        objectEditMode = editMode.Structure;
        strucMultFactor = -1;
        break;    
    }

    if ( decorator && decorator.selectedLadder && objectEditMode == editMode.Position ){
            setDataUpdated(true);
      runLoop=setInterval(()=>decorator.updateCylinderPosition(decorator.selectedLadder.uid.split("_edit")[0], movementVector, selection), 50);
      setTimeout(() => clearInterval(runLoop), 10000);
          } 
          else if(objectEditMode == editMode.Structure){
            setDataUpdated(true);
            runLoop=setInterval(()=>{
              decorator.updateCylinderThickness(decorator.selectedLadder.uid.split("_edit")[0], move*strucMultFactor)
             if( decorator.otherSelectedLadder!=null){
               decorator.updateCylinderThicknessOfAnotherLadderPipe(decorator.otherSelectedLadder.uid, move*strucMultFactor)             
               resetObjectIds(decorator)
              } 
            }
            , 50);
            setTimeout(() => clearInterval(runLoop), 10000);

          }

  }
  
  const onMouseUp = () => {
    clearInterval(runLoop);        
  }
  
  const onFieldClick = (event) => {
    const target = event.target.tagName != "SPAN" ? event.target : event.target.parentElement;
    const [elementDetail, name] = target.id.split('@');
    const decorator = IModelApp.viewManager.decorators.filter(e=>e.constructor.name.includes("LadderDecorator"))[0] as LadderDecorator;
    let movementVector: Vector3d = new Vector3d();
    switch (name) {
      case "moveLeft":
        movementVector = new Vector3d(move, 0, 0);        
        break;
      case "moveRight":
        movementVector = new Vector3d(-move, 0, 0);        
        break;
      case "moveFront":
        movementVector = new Vector3d(0, move, 0);        
        break;
      case "moveBack":
        movementVector = new Vector3d(0, -move, 0);        
        break;
      case "moveUp":
        movementVector = new Vector3d(0, 0, move);        
        break;
      case "moveDown":
        movementVector = new Vector3d(0, 0, -move);        
        break;
      }

      if ( decorator && decorator.selectedLadder) {
          setDataUpdated(true);
          decorator.updateCylinderPosition(decorator.selectedLadder.uid.split("_edit")[0], movementVector, "Whole");
      }

  }
  
  
  let modalBtns: pecTableButton[] = [
    {name: "Save", value: "Save", class: "dialogBox-save-btn", disable: false, onInteract: onModalBtnClick},
    {name: "Delete", value: "Delete", class: "dialogBox-cancel-btn", disable: false, onInteract: onModalBtnClick},
    {name: "Cancel", value: "Cancel", class: "dialogBox-cancel-btn", disable: false, onInteract: onModalBtnClick},

  ];
  let modalBtnsWithoutDelete : pecTableButton[] = [
    {name: "Save", value: "Save", class: "dialogBox-save-btn", disable: false, onInteract: onModalBtnClick},
    {name: "Cancel", value: "Cancel", class: "dialogBox-cancel-btn", disable: false, onInteract: onModalBtnClick},

  ];
  
  
  const checkForNewladder = () => {
    let ladderName :any= store.getState().detectedData.selectedObjectInformation.objectProperties.selectedObjectNST.name;
    let ladderTypes = ladderName.split(["@"]);
    if(ladderTypes.length>3){
     return ladderTypes[4]=="NEW" ?true:false
    }
    return false
  }
  let ladderEditorStructure: pecComponentProps = {
    structureType: pecStrucType.STRUCTURE,
    title: {
      label: "Ladder Edit",
      // styles: undefined
    },
    grid: ladderEditData,
    gridButtons:checkForNewladder()? modalBtns:modalBtnsWithoutDelete,
    eventHandlers: {
      onChange: onFieldChange,
      onUpdate: onFieldUpdate,
      onClick: onFieldClick,
      onMouseDown: onMouseDown,
      onMouseUp: onMouseUp,
    }
  }


  return (
    <PropertyEditorComponent {...ladderEditorStructure} />
  )
}

const mapStateToProps = (_state: RootState) => {
  const editSection = RadioSection.Whole;
  const detMountMenuStruc: pecGridStructure ={
    rows: [
      
        {
            columns: [
              {
                size: 12,
                type: pecInputTagTypes.RADIO_GROUP,
                customClass: "",
                value: "Section to edit",
                disabled: false,
                name: "partSelection-1-1",
                fieldType: pecDataValueTypes.INTEGER,
                modified: false,
                styles: {parentClass: "", childClass: ""},
                data: {matchValue: editSection, otherRadios: [
                  {label: RadioSection.Start},
                  {label: RadioSection.Whole},
                  {label: RadioSection.End},
                ]}
              },
            ]
            
            },
        {
          columns: [
            {
              size: 8,
              type: pecInputTagTypes.LABEL,
              customClass: "",
              value: "Move Left or Right",
              disabled: false,
              name: "mvLorR",
              fieldType: pecDataValueTypes.INTEGER,
              modified: false,
              styles: {parentClass: "mb-2", childClass: ""}
            },
            {
              size: 2,
              type: pecInputTagTypes.BUTTON,
              customClass: "",
              value: "L",
              disabled: false,
              name: "moveLeft",
              fieldType: pecDataValueTypes.INTEGER,
              modified: false,
              styles: {parentClass: "mb-2", childClass: "number-input-class"}
            },      
            {
              size: 2,
              type: pecInputTagTypes.BUTTON,
              customClass: "",
              value: "R",
              disabled: false,
              name: "moveRight",
              fieldType: pecDataValueTypes.INTEGER,
              modified: false,
              styles: {parentClass: "mb-2", childClass: "number-input-class"}
            },      
          ]
          
        },
        {
            columns: [
              {
                size: 8,
                type: pecInputTagTypes.LABEL,
                customClass: "",
                value: "Move Front or Back",
                disabled: false,
                name: "mvForB",
                fieldType: pecDataValueTypes.INTEGER,
                modified: false,
                styles: {parentClass: "mb-2", childClass: ""}
              },
              {
                size: 2,
                type: pecInputTagTypes.BUTTON,
                customClass: "",
                value: "F",
                disabled: false,
                name: "moveFront",
                fieldType: pecDataValueTypes.INTEGER,
                modified: false,
                styles: {parentClass: "mb-2", childClass: "number-input-class"}
              },      
              {
                size: 2,
                type: pecInputTagTypes.BUTTON,
                customClass: "",
                value: "B",
                disabled: false,
                name: "moveBack",
                fieldType: pecDataValueTypes.INTEGER,
                modified: false,
                styles: {parentClass: "mb-2", childClass: "number-input-class"}
              },      
            ]
            
        },
        {
            columns: [
              {
                size: 8,
                type: pecInputTagTypes.LABEL,
                customClass: "",
                value: "Move Up or Down",
                disabled: false,
                name: "mvUorD",
                fieldType: pecDataValueTypes.INTEGER,
                modified: false,
                styles: {parentClass: "mb-2", childClass: ""}
              },
              {
                size: 2,
                type: pecInputTagTypes.BUTTON,
                customClass: "",
                value: "U",
                disabled: false,
                name: "moveUp",
                fieldType: pecDataValueTypes.INTEGER,
                modified: false,
                styles: {parentClass: "mb-2", childClass: "number-input-class"}
              },      
              {
                size: 2,
                type: pecInputTagTypes.BUTTON,
                customClass: "",
                value: "D",
                disabled: false,
                name: "moveDown",
                fieldType: pecDataValueTypes.INTEGER,
                modified: false,
                styles: {parentClass: "mb-2", childClass: "number-input-class"}
              },      
            ]
            
        },
        {
            columns: [
              {
                size: 8,
                type: pecInputTagTypes.LABEL,
                customClass: "",
                value: "Length",
                disabled: false,
                name: "Length",
                fieldType: pecDataValueTypes.INTEGER,
                modified: false,
                styles: {parentClass: "mb-2", childClass: ""}
              },
              {
                size: 2,
                type: pecInputTagTypes.BUTTON,
                customClass: "",
                value: "+",
                disabled: false,
                name: "increaseLength",
                fieldType: pecDataValueTypes.INTEGER,
                modified: false,
                styles: {parentClass: "mb-2", childClass: "number-input-class"}
              },      
              {
                size: 2,
                type: pecInputTagTypes.BUTTON,
                customClass: "",
                value: "-",
                disabled: false,
                name: "decreaseLength",
                fieldType: pecDataValueTypes.INTEGER,
                modified: false,
                styles: {parentClass: "mb-2", childClass: "number-input-class"}
              },      
            ]
            
        },
    ]
  }

  const retVal = {
    detMountMenuStruc,
    editSection,
  };
  return retVal;
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(DetectedLadderEditHandler);