import { Cartographic } from "@itwin/core-common";
import { BeButton, BeButtonEvent, DecorateContext, Decorator, IModelApp, Marker } from "@itwin/core-frontend";
import { Point2d, Point3d } from "@itwin/core-geometry";
// import App from "../../components/App";
import { StagePanelLocation, SyncUiEventDispatcher, UiFramework } from "@itwin/appui-react";
import { WidgetState } from "@itwin/appui-react";
import { Id64String } from "@itwin/core-bentley";

export class MLDefectDecorator implements Decorator {
  private _defectData: any;
  constructor(defectsData: any) {
    this._defectData = defectsData;
  }
  public useCachedDecorations: true | undefined = true;
  private static _markers: Marker[] = [];
  public objectIdMap: Map<Id64String, string> = new Map<string, Id64String>();
  // private static _context: DecorateContext | undefined;
  public static selectedMarkerJson: any | undefined;
  public publicData: any[] = [];
  // public static selectedMarker: Marker | undefined;
  // public static latitudeToDefectJsonMap = new Map<string, any>();

  public decorate(context: DecorateContext): void {
    // MLDefectDecorator._context = context;
    if (MLDefectDecorator._markers.length === 0) {
      this.loadMarkers(context);
    }

    MLDefectDecorator._markers?.forEach((marker) => {
      marker.addDecoration(context);
    });
  }

  public async loadMarkers(context: DecorateContext): Promise<void> {
    const json = this._defectData;
    if (!json) {
      return;
    }
    const mlPoints = json;
    if (context && mlPoints) {
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < mlPoints.length; i++) {
        let cart: Cartographic = Cartographic.fromDegrees({longitude: mlPoints[i].longitude, latitude: mlPoints[i].latitude, height: mlPoints[i].altitude});
        // let utmXY = this.LatLong2UTM(mlPoints[i].latitude, mlPoints[i].longitude, 10);
        const iModel = UiFramework.getIModelConnection()!;
        const utmXY = await iModel.cartographicToSpatial(cart!);
        const heightBuffer = iModel.spatialToCartographicFromEcef(new Point3d(utmXY.x, utmXY.y, mlPoints[i].altitude)).height;
        const spatialPoint = new Point3d(utmXY.x, utmXY.y, (mlPoints[i].altitude - heightBuffer) + mlPoints[i].altitude);

        const marker = new Marker(spatialPoint, new Point2d(38, 38));
        marker.visible = true;
        if (mlPoints[i].userData === undefined) {mlPoints[i].userData = {marker}; }

        marker.setImageUrl("image/defect-camera-icon2.png");
        marker.onMouseLeave = () => {
          marker.label = "";
          marker.imageSize = {x: 38, y: 38};
        };
        marker.onMouseEnter = (_ev: BeButtonEvent) => {
          marker.label = mlPoints[i].criticality + ": " + mlPoints[i].defect;
          marker.labelBaseline = "bottom";
          marker.labelOffset = {x: 0, y: 20};
          marker.imageSize = {x: 48, y: 48};
          return true;
        };
        marker.onMouseButton = (_ev: BeButtonEvent) => {
          if (_ev.button === BeButton.Data) {

            MLDefectDecorator._markers.forEach((e) => {
              e.setImageUrl("image/defect-camera-icon2.png");
            });

            marker.setImageUrl("image/defect-camera-icon-select.png");
            MLDefectDecorator.selectedMarkerJson = mlPoints[i];
            // show defect widget
            // FrontstageManager.activeFrontstageDef?.getStagePanelDef(StagePanelLocation.Right)?.
            //   findWidgetDef("PropertyListWidget")?.setWidgetState(WidgetState.Open);
            SyncUiEventDispatcher.dispatchSyncUiEvent("defectselected");
          } else if (_ev.button === BeButton.Reset) {
            MLDefectDecorator.selectedMarkerJson = undefined;

            // FrontstageManager.activeFrontstageDef?.getStagePanelDef(StagePanelLocation.Right)?.
            //   findWidgetDef("PropertyListWidget")?.setWidgetState(WidgetState.Hidden);
            SyncUiEventDispatcher.dispatchSyncUiEvent("defectunselected");
          } else {
            return false;
          }

          return true;
        };

        // if (!MLDefectDecorator._markers) {
        //   MLDefectDecorator._markers = [];
        // }
        MLDefectDecorator._markers.push(marker);
        const vp = IModelApp.viewManager.selectedView;
        if (vp === undefined) return;
        const nextId = vp.iModel.transientIds.getNext();
        this.objectIdMap.set(nextId, `defectMarker#${i}`);
        this.publicData.push(mlPoints[i]);
      }
    }
        // this.publicData=MLDefectDecorator._markers;
  }
}
