// -------------------------------------------------------
import { DecoratorHelper } from "./DecoratorHelper";
import { StagePanelLocation, WidgetState, SyncUiEventDispatcher, UiFramework } from "@itwin/appui-react";
import { Id64String } from "@itwin/core-bentley";
import { ColorByName, ColorDef, LinePixels } from "@itwin/core-common";
import { Decorator, RenderGraphic, IModelApp, NotifyMessageDetails, OutputMessageType, SelectionTool, HitDetail, BeButtonEvent, EventHandled, OutputMessagePriority, DecorateContext, GraphicType, GraphicBranch, GraphicBuilder } from "@itwin/core-frontend";
import { GeometryQuery, Point3d, PolyfaceBuilder, Box, YawPitchRollAngles, Matrix3d, LineString3d, Loop, Arc3d, Path, IndexedPolyface, IndexedPolyfaceVisitor, LineSegment3d, CurveChainWithDistanceIndex, Transform, Vector3d, Angle } from "@itwin/core-geometry";
import { towerStructureData } from "../../../store/detectedData/apiDataTypes";
import { store } from "../../../store/rootReducer";
// -------------------------------------------------------
// Custom geometry container to help on construction of the bracings (pipe)
// -------------------------------------------------------
export interface CustomGeometry {
    geometry: GeometryQuery;
    fill: boolean;
    fillColor: ColorDef;
    lineThickness: number;
    edges: boolean;
    linePixels: LinePixels;
    transientId: Id64String;
    name: string;
    uid: string;
    start: Point3d;
    end: Point3d;
    modelData: any;
    thickness: number;
    length: number;
    width: number;
}

export class FeederLineDecorator implements Decorator {
    // ---------------------------------------------
    public pipes: CustomGeometry[];
    public boxes: CustomGeometry[];
    public geometryGraphics: RenderGraphic[];
    public nameIdMap: Map<string, Id64String> = new Map<string, Id64String>();
    public static sourceId: any;
    public selectedBracings: any;
    public pipesMapping: any;
    public static selectedBracing: any;
    //-----------------------------------------------
    private static elevationToWidthMapping: any[] = [];
    private static topandBottomPoints: any[] = [];
    private static isShiftPressed: boolean = false;
/*    private static multiselectBracingSet: any[] =[];*/
    // ---------------------------------------------
    // ---------------------------------------------
    // this is very important for high density decorators , improves performance
    // apparently the parent uses this public variable to know how to
    // draw the primitives wrt this decorator child.
    // In the default secanrio the decorator validates and rebinds primitive information , which is inefficent,
    // Use this to optimise ,where info is not rebound but buffer handle/ref is reused.
    // https://www.itwinjs.org/learning/frontend/viewdecorations/#cached-decorations. for more info
    public readonly useCachedDecorations = true;
    public objectIdMap: Map<Id64String, string> = new Map<string, Id64String>();
    // ---------------------------------------------
    // CONSTRUCTOR
    // ---------------------------------------------
    constructor() {
        // array to save all the pipes to show
        this.pipes = [];
        this.boxes = [];
        this.geometryGraphics = [];
        this.pipesMapping = {};

        //create the element contenxt on left button down.
        let div = document.getElementsByClassName("imodeljs-vp")[0];
        div?.addEventListener('mouseup', this.onMouseClickUp);
    }
    //-------------------------------------------------------------------//
    // Help function to log information to the user
    //-------------------------------------------------------------------//
    private logToScreen(TYPE: any, TITLE: string, CONTENT: string = "") {
        IModelApp.notifications.outputMessage(new NotifyMessageDetails(TYPE, TITLE, CONTENT, OutputMessageType.Toast));
    }
    // ---------------------------------------------
    /**
     * @param bracingToHighgligh : the bracing info that is to be highlighted
     */
    public async highlightBracingFromStore(bracingToHighgligh: any) {
        SyncUiEventDispatcher.dispatchSyncUiEvent("bracings-selected");
        // FrontstageManager.activeFrontstageDef?.getStagePanelDef(StagePanelLocation.Right)?.findWidgetDef("PropertyListWidget")?.setWidgetState(WidgetState.Open);
        IModelApp.tools.run(SelectionTool.toolId);
        // SampleToolWidget.selectedList = ListEnum.Bracings;

        if (bracingToHighgligh && bracingToHighgligh != undefined && bracingToHighgligh.modelData != undefined) {
            // get the iModel
            const iModel = UiFramework.getIModelConnection()!;
            iModel!.selectionSet.emptyAll();
            // get the selected data from the selected pipe
            const selectedData = bracingToHighgligh.modelData;
            if (selectedData && selectedData.parentBracing in this.pipesMapping && selectedData.parentFace) {
                // lets highlight/select all the child pipes of the bracing
                for (let face in this.pipesMapping[selectedData.parentBracing]) {
                    for (let pipeID in this.pipesMapping[selectedData.parentBracing][face]) {
                        iModel!.selectionSet.add(this.pipesMapping[selectedData.parentBracing][face][pipeID]); //All added transitent ids will be highlighted with this method.
                    }
                }
            }
        }
    }
    // ---------------------------------------------
    // Return tooltip message for the decoration
    // ---------------------------------------------
    public async getDecorationToolTip(_hit: HitDetail): Promise<HTMLElement | string> {
        const sourceId = _hit.sourceId;
        const name = Array.from(this.nameIdMap.keys()).find((key) => this.nameIdMap.get(key) === sourceId);
        const parentBracing = "Bracing " + (this.selectedBracings.modelData.parentBracing).toString();
        if (!parentBracing) {
            return "Unknown Bracing";
        }
        return parentBracing;
    }
    //------------------------------------------------------------------------------------
    /* Callback event from dom. See: OnDataButtonDown()
     * The event is loaded in the onDataButtonDown() for this tool,
     * So event will remain isolated to this context only.
     */
    private onMouseClickUp = (event) => {
        event.preventDefault();
        //event.button == 2 signifies the right click on mouse. So this runs on mouseup for right click
        if (event.button == 2) {
            IModelApp.tools.run(SelectionTool.toolId);//run the default tool and stop this Primitive tool.
        }

        if (event.shiftKey) {
            FeederLineDecorator.isShiftPressed = true;
        } else {
            FeederLineDecorator.isShiftPressed = false;
        }
    }
    // ---------------------------------------------
    // funtion to organize the clicks (single and double)
    // ---------------------------------------------
    public async onDecorationButtonEvent(_hit: HitDetail, _ev: BeButtonEvent): Promise<EventHandled> {
        let sourceId = _hit.sourceId;
        //const pipeData = this.pipes.filter(e=>e.transientId==sourceId);
        if (_ev.isDoubleClick) {
            //create the double click event
        }
        else {
            //create the single click event
        }

        return EventHandled.No;
    }
    // ---------------------------------------------
    // function that will assign the data to the selected object
    // and handle the clicks (single and double)
    // ---------------------------------------------
    public testDecorationHit(_id: string): boolean {

        // Setting the selectedBracings property
        let isbracingSlected: boolean = false;
        for (const pipe of this.pipes) {
            if (pipe.transientId == _id) {
                // static member used in BracingsPropertylist.tsx
                this.selectedBracings = pipe;
                FeederLineDecorator.selectedBracing = this.selectedBracings;
                isbracingSlected = true;
                break;
            }
        }
        if (isbracingSlected && _id.length !== 0) {
            return true;
        }
        return false;
    }
    // ---------------------------------------------
    // CLEAN THE GEOMETRY
    // empty the pipe and geometry graphics array
    // create a new instance of the nameIdMap
    // ---------------------------------------------
    private clearGeometry() {
        this.pipes = [];
        this.geometryGraphics = [];
        this.nameIdMap = new Map<string, Id64String>();
        this.objectIdMap = new Map<string, Id64String>();
        /* 
        This will invalidate all view decorations in all views in the current iModel, 
        forcing them to be redrawn the next time the view is displayed.
        */
        IModelApp.viewManager.invalidateDecorationsAllViews();
    }
    // ---------------------------------------------
    // Run, outside, when finishing and exiting this tool.
    // --------------------------------------------- 
    public terminate() {
        this.clearGeometry();
        /* 
        This will invalidate all view decorations in all views in the current iModel, 
        forcing them to be redrawn the next time the view is displayed.
        */
        IModelApp.viewManager.invalidateDecorationsAllViews();
        /* 
        When you create a view decoration, it is often more efficient to cache the 
        graphical representation of the decoration rather than recreating it from 
        scratch every time the view is rendered. However, if you change the 
        properties of the decoration, you need to invalidate the cached version 
        to force it to be recreated with the new properties.
        */
        IModelApp.viewManager.selectedView?.invalidateCachedDecorations(this);
    }
    /**
     * 
     * @param finfo use custom geometry info to create the Boxes
     * and relevant polygons, Unsing the 
     */
    private addBoxWithInfo(objinfo: any) {
        const st: Point3d = objinfo.start;
        const en: Point3d = objinfo.end;
        if (objinfo && objinfo.start && st.distance(en) != 0) {

            let box = this.createBoxFromStartandEndPoints(objinfo.start, objinfo.end, objinfo.width, objinfo.thickness, objinfo.orientation);

            // create cyliner using Cone geometry generated via api.
            const uniqName = `${objinfo.name}`;
            //const pipe = Cone.createAxisPoints(objInfo.pointA, objInfo.pointB, objInfo.thickness, objInfo.thickness, true);
            // create a cone using a Cone object in Polyface Builder.
            const poly = PolyfaceBuilder.create();
            if (box !== undefined) {
                poly.addBox(box!);
                const polyface = poly.claimPolyface(false);
                const transientId: string = IModelApp.viewManager.selectedView!.iModel.transientIds.next
                // create the geometry data
                const geometryData: CustomGeometry = this.addGeometry(polyface, objinfo, transientId, uniqName);
                if(this.objectIdMap && this.objectIdMap.keys.length){
                    const nameIndex = parseFloat(this.objectIdMap[this.objectIdMap.keys.length -1].Value) + 1;
                    this.nameIdMap.set(`${nameIndex}`, geometryData.transientId);
                    this.objectIdMap.set(geometryData.transientId, `feederGeom#FeedLine_${nameIndex}`);
                }
                else{
                    this.nameIdMap.set(`${geometryData.name}`, geometryData.transientId);
                    this.objectIdMap.set(geometryData.transientId, `feederGeom#FeedLine_${geometryData.name}`);
                }
                this.boxes.push(geometryData);
            }

        }
    }
    /**
     * 
     * @param jasonInfo Data for feedline coming in from backend.
     * Use the data and create Boxes for visualisations
     */
    public async addBoxsForFeederLineInfo(jasonInfo): Promise<boolean> {
        //create boxes
        let towerStructureData = store.getState().detectedData.towerStructureData as towerStructureData;

        if (towerStructureData && jasonInfo) {
            for (let j = 0; j < jasonInfo.length; j++) {

                let convertedPoints = this.convertPoints(jasonInfo[j].startCenter, jasonInfo[j].endCenter, jasonInfo[j].segmentPoints, towerStructureData.epsg_code);
                if(convertedPoints?.segmentPoints.length){
                    if(convertedPoints.segmentPoints.length > 1)debugger;
                    let start: Point3d = convertedPoints.ecefA, end: Point3d;
                    for(let i=0; i < convertedPoints.segmentPoints.length; i++){
                        if(i == 0)end=convertedPoints.segmentPoints[i];
                        else if(i == convertedPoints.segmentPoints.length-2){
                            start=convertedPoints.segmentPoints[i+1];
                            end = jasonInfo[j].ecefB;
                        } else {
                            start=convertedPoints.segmentPoints[i];
                            end=convertedPoints.segmentPoints[i+1];
                        }
                        const objinfo: any = {//Set this custom data container with the relevant data , this will be shared with the side pannel on Click events.
                            name: j.toString(),
                            startUTMPoint: jasonInfo[j].startCenter,
                            endUTMpoint: jasonInfo[j].endCenter,
                            start: start,
                            end,
                            width: jasonInfo[j].width,
                            thickness: jasonInfo[j].height,
                            orientation: jasonInfo[j].orientation,
                            color: ColorDef.from(100, 0, 255),
                            transparency: 100,// transparency => 0% = 255 || 100% = 0
                        };
        
                        this.addBoxWithInfo(objinfo);
                    }
                    const objinfo: any = {//Set this custom data container with the relevant data , this will be shared with the side pannel on Click events.
                        name: j.toString(),
                        startUTMPoint: jasonInfo[j].startCenter,
                        endUTMpoint: jasonInfo[j].endCenter,
                        start: convertedPoints.segmentPoints[convertedPoints.segmentPoints.length-1],
                        end: convertedPoints.ecefB,
                        width: jasonInfo[j].width,
                        thickness: jasonInfo[j].height,
                        orientation: jasonInfo[j].orientation,
                        color: ColorDef.from(100, 0, 255),
                        transparency: 100,// transparency => 0% = 255 || 100% = 0
                    };
    
                    this.addBoxWithInfo(objinfo);

                } else {

                    const objinfo: any = {//Set this custom data container with the relevant data , this will be shared with the side pannel on Click events.
                        name: j.toString(),
                        startUTMPoint: jasonInfo[j].startCenter,
                        endUTMpoint: jasonInfo[j].endCenter,
                        start: convertedPoints?.ecefA,
                        end: convertedPoints?.ecefB,
                        width: jasonInfo[j].width,
                        thickness: jasonInfo[j].height,
                        orientation: jasonInfo[j].orientation,
                        color: ColorDef.from(100, 0, 255),
                        transparency: 100,// transparency => 0% = 255 || 100% = 0
                    };
    
                    this.addBoxWithInfo(objinfo);
                }
            }
            return true;
        } else {
            return false;
            // this.logToScreen(OutputMessagePriority.Error, "FeedLine Data Not Found.")
        }
    }
    // ---------------------------------------------
    /**
    * Build the box in local coordinate system
    * @localCentroid local centroid
    * @param height equipment height
    * @param width equipment width
    * @param thickness equipment thickness
    * @param centroidPt centroid point
    * @returns create a box
    * 
    * Not in use
    */
    private createBoxInLocalCoordinate(localCentroid: Point3d, height: number, width: number, thickness: number) {
        const localBaseCenter = new Point3d(localCentroid.x, localCentroid.y, -0.5 * height);
        const localBaseOrigin = localBaseCenter.clone();
        localBaseOrigin.x = localBaseOrigin.x - 0.5 * width;
        localBaseOrigin.y = localBaseOrigin.y - 0.5 * thickness;

        const localTopOrigin = localBaseOrigin.clone();
        localTopOrigin.z = localTopOrigin.z + height;

        const box = Box.createDgnBoxWithAxes(localBaseOrigin, Transform.identity.matrix, localTopOrigin, width, thickness, width, thickness, true);
        return box;
    }
    /**
     * In Use
    */
    private createBoxFromStartandEndPoints(start: Point3d, end: Point3d, width: number, thickness: number , yaw: number) {
        const localBaseOrigin = new Point3d(start.x, start.y, start.z);
        localBaseOrigin.x = localBaseOrigin.x - 0.5 * width;
        localBaseOrigin.y = localBaseOrigin.y - 0.5 * thickness;
        
        const localTopOrigin = new Point3d(end.x, end.y, end.z);;
        localTopOrigin.x = localTopOrigin.x - 0.5 * width;
        localTopOrigin.y = localTopOrigin.y - 0.5 * thickness;

        const box = Box.createDgnBoxWithAxes(localBaseOrigin, Transform.identity.matrix, localTopOrigin, width, thickness, width, thickness, true)!;

        let x=0, y=0, z=0;
        box.getCorners().forEach(e=>{x+=e.x;y+=e.y;z+=e.z;});
        const centroidPtInLocal = new Point3d(x/8, y/8, z/8);
        const azimuthMat = Matrix3d.createRotationAroundVector(box.getVectorZ(), Angle.createDegrees(yaw))!;
        const trans = Transform.createFixedPointAndMatrix(centroidPtInLocal, azimuthMat);
        let newCentroidPtInGlobal = trans.origin;
        newCentroidPtInGlobal = trans.multiplyPoint3d(newCentroidPtInGlobal);
        box?.tryTransformInPlace(trans);
        return box;
    }
    /**
     * Not in use
     */ 
    private constructBoxGeometry(height: number, width: number, thickness: number, pt: Point3d, _pitch: number = 360, yaw: number, _roll: number): Box | undefined {
        // pitch = tilt
        // yaw = azimuth
        const iModel = UiFramework.getIModelConnection()!;
        if (!iModel!.isBlank) {
            const o = iModel.spatialToCartographicFromEcef(iModel.projectExtents.high!);
            const ecef = DecoratorHelper.ExtractSpatialXYZ(o, pt.x, pt.y, pt.z, iModel);
            pt.x = ecef.x;
            pt.y = ecef.y;
            pt.z = ecef.z;
        }
        const ypr = YawPitchRollAngles.createDegrees(yaw, _pitch, _roll);
        const rightPoints = this.createPoint2BoxWithVector(pt, ypr.toMatrix3d(), height, width, thickness, iModel.isBlank);
        const matrix = ypr.toMatrix3d();
        matrix.transposeInPlace();
        const box = Box.createDgnBoxWithAxes(rightPoints[0], matrix, rightPoints[1], width, thickness, width, thickness, true);
        return box;
    }
    /*
    Thicness -> Thicness (m) of the equipment
    Width -> Width (m) of the equipment
    xCenter -> coordinate X (m)  - center of the equipment
    yCenter -> coordinate Y (m)  - center of the equipment
    zCenter -> coordinate Z (m)  - center of the equipment
    height -> Height (m) of the equipemnt
    Yaw -> Yaw/Azimuth (degrees) of the Equipment

    Not in Use
    */
    private createPoint2BoxWithVector(midPoint: Point3d, yprMatrix: Matrix3d, height: number, width: number, depth: number, htBuffer: boolean): Point3d[] {
        let heightBuffer: number;
        if (htBuffer) {
            heightBuffer = UiFramework.getIModelConnection()!.spatialToCartographicFromEcef(midPoint).height;
            midPoint.z = (midPoint.z - heightBuffer) + midPoint.z;
        } else {
            midPoint.z = midPoint.z;
        }
        const vecX = yprMatrix.rowX();
        vecX.normalizeInPlace();
        const vecY = yprMatrix.rowY();
        vecY.normalizeInPlace();
        const vecZ = yprMatrix.rowZ();
        vecZ.normalizeInPlace();

        let baseOrigin = midPoint.plusScaled(vecZ, -0.5 * height);
        let topOrigin = midPoint.plusScaled(vecZ, 0.5 * height);
        baseOrigin = baseOrigin.plusScaled(vecX, -0.5 * width);
        topOrigin = topOrigin.plusScaled(vecX, -0.5 * width);
        baseOrigin = baseOrigin.plusScaled(vecY, -0.5 * depth);
        topOrigin = topOrigin.plusScaled(vecY, -0.5 * depth);

        return [baseOrigin, topOrigin];
    }
    // ---------------------------------------------
    // CREATE THE GEOMETRY OBJECT
    // input: 'objInfo' => (object) realityData object
    // ---------------------------------------------
    public addGeometry(geometry: GeometryQuery, objInfo: any, transientId: string, uniqName: string) {
        const redColor = objInfo.color;
        // transparency => 0% = 255 || 100% = 0
        const semiTransparentRed = redColor.withTransparency(objInfo.transparency);

        const geometryObject: CustomGeometry = ({
            geometry: geometry,
            fill: true,
            fillColor: ColorDef.fromTbgr(ColorDef.withTransparency(ColorDef.create(ColorByName.aqua).tbgr,  255-Math.round(255*store.getState().detectedData.objectOpacityState.feedline.value))),
            lineThickness: 0.5,
            edges: true,
            linePixels: LinePixels.Solid,
            transientId: transientId,
            name: objInfo.name,
            uid: uniqName,
            modelData: objInfo,
            start: objInfo.start,
            end: objInfo.end,
            thickness: objInfo.thickness,
            length: objInfo.length,
            width: objInfo.width,
        });
        return geometryObject;
    }
    // ---------------------------------------------
    // convert the UTM coordinates to the 'local' coordinates sytem
    // The points sent in need to be in an array format and not xyz
    // ---------------------------------------------
    public convertPoints(point1: any, point2: any, sps: any, epsg_code: any) {
        try {
            const iModel = UiFramework.getIModelConnection()!;
            const points = [point1, point2] // [ [x,y,z] , [x,y,z] , [x,y,z] ]
            const tsd = store.getState().detectedData.towerStructureData as towerStructureData;
            let cA : any;
            let cB: any;

            if (point1.length > 0) {
                cA = new Point3d(points[0][0], points[0][1], points[0][2]);
                cB = new Point3d(points[1][0], points[1][1], points[1][2]);
            } else {
                cA = point1;
                cB = point2;
            }
     
            const o = iModel.spatialToCartographicFromEcef(iModel.projectExtents.high!);
            const segmentPoints = sps != null ? sps.map(e=>DecoratorHelper.ExtractSpatialXYZ(o, e.x, e.y, e.z, iModel)) : [];
            const ecefA = DecoratorHelper.utm2localCoords(o, cA.x, cA.y, cA.z, iModel, epsg_code);
            const ecefB = DecoratorHelper.utm2localCoords(o, cB.x, cB.y, cB.z, iModel, epsg_code);
            const dot = ecefA.dotVectorsToTargets(new Point3d(ecefB.x, ecefB.y, 0), ecefB);
            const lengthA = ecefA.distance(ecefB);
            const lengthB = ecefA.distance(new Point3d(ecefB.x, ecefB.y, 0));
            const theta = Math.acos(dot / (lengthA * lengthB));
            const deg = theta * 180 / Math.PI; // 1Rad × 180/π
            const utms = points.map((e: any) => e[2]);
            const altitude = (utms.reduce((sum: number, e: number) => sum + e, 0) / utms.length);
            const elevation = altitude - tsd.base_altitude;
            return { ecefA: ecefA, ecefB: ecefB, altitude: altitude, elevation: elevation, deg: deg, segmentPoints };
        } catch (error) {
            return null;
        }
    }
    // ---------------------------------------------
    // [same from mountDecorator]
    // Load up the geometry info for pipes into a builder object and
    // return a graphic object and store it in the geometryGraphics[].
    // These grapic objects will be added to the the context via context.addDecoration()
    // Utilised in deocrate overload See: in decorate();
    // ---------------------------------------------
    private createGraphics(context: DecorateContext) {
        // Check if Geometry Graphic objects are created and if not then load up in array.
        if (this.geometryGraphics.length === 0) {
            // Iterate over each object loaded with the imported jason params and build the graphics for it.
            //For pipes
            for (const pipe of this.pipes) {
                let transientId = context.viewport.iModel.transientIds.next;
                if (pipe.transientId === "") {
                    pipe.transientId = transientId;
                    if(this.objectIdMap && this.objectIdMap.keys.length){
                        const nameIndex = parseFloat(this.objectIdMap[this.objectIdMap.keys.length -1].Value) + 1;
                        this.nameIdMap.set(`${nameIndex}`, pipe.transientId);
                        this.objectIdMap.set(pipe.transientId, `feederGeom#FeedLine_${nameIndex}`);
                    }
                    else{
                        this.nameIdMap.set(pipe.name, pipe.transientId);
                        this.objectIdMap.set(pipe.transientId, `feederGeom#FeedLine_${pipe.name}`);
                    }
                } else {
                    transientId = pipe.transientId;
                }
                // Speicify unique transientId so that geometry are considered as sperate entites.
                const builder = context.createGraphicBuilder(GraphicType.Scene, undefined, transientId);
                // builder.wantNormals = true;
                const geometry = pipe.geometry;
                builder.setBlankingFill(pipe.fillColor);
                this.createGraphicsForGeometry(geometry, false, builder);
                const graphic = builder.finish();
                // create graphic obj and push to array to be used in a GraphicBranch
                this.geometryGraphics.push(graphic);
            }
            //For Boxes 
            for (const box of this.boxes) {
                let transientId = context.viewport.iModel.transientIds.next;
                if (box.transientId === "") {
                    box.transientId = transientId;
                    if(this.objectIdMap && this.objectIdMap.keys.length){
                        const nameIndex = parseFloat(this.objectIdMap[this.objectIdMap.keys.length -1].Value) + 1;
                        this.nameIdMap.set(`${nameIndex}`, box.transientId);
                        this.objectIdMap.set(box.transientId, `feederGeom#FeedLine_${nameIndex}`);
                    }
                    else{
                        this.nameIdMap.set(box.name, box.transientId);
                        this.objectIdMap.set(box.transientId, `feederGeom#FeedLine_${box.name}`);
                    }
                } else {
                    transientId = box.transientId;
                }
                // Speicify unique transientId so that geometry are considered as sperate entites.
                const builder = context.createGraphicBuilder(GraphicType.Scene, Transform.identity, transientId);
                // builder.wantNormals = true;
                const geometry = box.geometry;
                builder.setBlankingFill(box.fillColor);
                this.createGraphicsForGeometry(geometry, true, builder);
                const graphic = builder.finish();
                // create graphic obj and push to array to be used in a GraphicBranch
                this.geometryGraphics.push(graphic);
            }
        }
        // If the Graphics are already created  and geometryGraphics array length is greater than 0 then
        // add all the grapics objects as decorations to the scene. This helps avoid having to bind primitive data into
        // buffers each time decorate is called which is costly.
        // Also see : useCachedDecorations that implicityl re-uses buffers , rather than having to mange them here like so.
        for (const geo of this.geometryGraphics) {
            // Some branch object presets
            const overrides = {};//new ViewFlagOverrides();
            // overrides.setShowVisibleEdges(false);
            // overrides.setApplyLighting(true);
            // Create new SceneGraph //dont know why we do this every time seems inefficient, but it is as per docs.
            const branch = new GraphicBranch(false);
            branch.setViewFlagOverrides(overrides);
            // add to SceneGraph graphic branch Note : this is a workaround to improve not having to rebind geometry info.
            branch.add(geo);
            const graphic = context.createBranch(branch, Transform.identity);
            context.addDecoration(GraphicType.Scene, graphic);
        }
    }
    // ---------------------------------------------
    // [same from mountDecorator]
    // This is a boilerplate for loading up all types of geometry queries
    // into the builder object . This function is a as per the IModel doc samples.
    // Keep as is.
    // ---------------------------------------------
    private createGraphicsForGeometry(geometry: GeometryQuery, wantEdges: boolean, builder: GraphicBuilder) {
        if (geometry instanceof LineString3d) {
            builder.addLineString(geometry.points);
        } else if (geometry instanceof Loop) {
            builder.addLoop(geometry);
            if (wantEdges) {
                // Since decorators don't natively support visual edges,
                // We draw them manually as lines along each loop edge/arc
                builder.setSymbology(ColorDef.black, ColorDef.black, 2);
                const curves = geometry.children;
                curves.forEach((value) => {
                    if (value instanceof LineString3d) {
                        let edges = value.points;
                        const endPoint = value.pointAt(0);
                        if (endPoint) {
                            edges = edges.concat([endPoint]);
                        }
                        builder.addLineString(edges);
                    } else if (value instanceof Arc3d) {
                        builder.addArc(value, false, false);
                    }
                });
            }
        } else if (geometry instanceof Path) {
            builder.addPath(geometry);
        } else if (geometry instanceof IndexedPolyface) {
            builder.addPolyface(geometry, true); // this is what will be used to generate the pipes.
            if (wantEdges) {
                // Since decorators don't natively support visual edges,
                // We draw them manually as lines along each facet edge
                builder.setSymbology(ColorDef.black, ColorDef.black, 2);
                const visitor = IndexedPolyfaceVisitor.create(geometry, 1);
                let flag = true;
                while (flag) {
                    const numIndices = visitor.pointCount;
                    for (let i = 0; i < numIndices - 1; i++) {
                        const point1 = visitor.getPoint(i);
                        const point2 = visitor.getPoint(i + 1);
                        if (point1 && point2) {
                            builder.addLineString([point1, point2]);
                        }
                    }
                    flag = visitor.moveToNextFacet();
                }
            }
        } else if (geometry instanceof LineSegment3d) {
            const pointA = geometry.point0Ref;
            const pointB = geometry.point1Ref;
            const lineString = [pointA, pointB];
            builder.addLineString(lineString);
        } else if (geometry instanceof Arc3d) {
            builder.addArc(geometry, false, false);
        } else if (geometry instanceof CurveChainWithDistanceIndex) {
            this.createGraphicsForGeometry(geometry.path, wantEdges, builder);
        }
    }
    // ---------------------------------------------
    // Ovveride from Decorator parent.
    // Is called to validate Decoratror objects to draw in the current view context.
    // Note :
    // Not a real render callback/loop not usable for Animations,
    // as the loop is not syncronus and runs intermittently, or when the current view comes into focus 'ie on mousclick or mouse move'.
    // ---------------------------------------------
    public decorate(context: DecorateContext): void {
        // Create and add the Geometry to Context
        this.createGraphics(context);
    }
}
