import { saveAs } from "file-saver";
import { getUnit, getValueInRequiredUnit } from "../../components/Tables/BaseTable";
import { ReduceDecimalToTwoPlaces } from "../../components/Lists/EquipmentList";
import { ConfigManager } from "../../../config/ConfigManager";
import ExcelJS from 'exceljs';
import DatabaseApiClient from "../../../services/graphql/database-api/database-api-client";
import { projectInformationInterface } from "../../../store/redux-types";
import { store } from "../../../store/rootReducer";
import { Guid } from "@itwin/core-bentley";
export const ExcelComponent = async (props: { tableName: any; info: any; }) => {
    return convertIntoExcel(props.tableName, props.info);
};
const convertIntoExcel = async (tableName: string, data: any[]) => {
    const arr: any = [];
    const numericArray = ["Height", "Width", "Depth", "lateralOffset", "standoffDistance", "verticalOffset"];
    const defectFixedArray = ["altitude", "pitch", "yaw"]
    try {
        if (data) {

            let project: projectInformationInterface = store.getState().dtvState.applicationState.projectInformation!;
            if(project.instanceId == Guid.empty){project =  await DatabaseApiClient.getProjectName(ConfigManager.projectId!);}
            let obj: any = getColumnsNeeded(tableName);
            const newData = data.map(info=>{
                const newInfo = {...info};
                if(tableName == "Equipment"){
                    numericArray.forEach(key=>newInfo[key] = getValueInRequiredUnit(info[key]));
                } else {
                    defectFixedArray.forEach(key=>newInfo[key] = ReduceDecimalToTwoPlaces(info[key]));
                }
                return newInfo;
            })

            let workbook = new ExcelJS.Workbook();
            let Worksheet = workbook.addWorksheet(tableName, { pageSetup: { errors: 'blank' } });
    
            Worksheet.columns = obj;

            newData.forEach(newData=>{
                Worksheet.addRow(newData)
            });

            workbook.xlsx.writeBuffer().then(data=>{
                let blob = new Blob([data], {type: "binary"});
                saveAs(blob, project?.properties.name + "_" + tableName + ".xlsx")
            })

            // data.forEach((info: { [x: string]: any; }) => {
            //     const temp: any = {};
            //     Object.keys(obj).map((key) => {
            //         if (tableName === "Equipment") {
            //             const index = numericArray.findIndex((i) => i === key);
            //             if (index !== -1) {
            //                 temp[obj[key]] = getValueInRequiredUnit(info[key]);
            //             }
            //             else {
            //                 temp[obj[key]] = info[key];
            //             }
            //         }
            //         else {
            //             const index = defectFixedArray.findIndex((i) => i === key);
            //             if (index !== -1) {
            //                 temp[obj[key]] = ReduceDecimalToTwoPlaces(info[key]);
            //             }
            //             else {
            //                 temp[obj[key]] = info[key];
            //             }
            //         }
            //     });
            //     arr.push(temp);
            // });


            // const wb = XLSX.utils.book_new();
            // const id = ConfigManager.projectId;
            // // const requestContext = await AuthorizedFrontendRequestContext.create();
            // let project: any;

            // wb.SheetNames.push(tableName);
            // const exportedTowers = XLSX.utils.json_to_sheet(arr);

            // const dataHeader: string[] = ["name", "type", "brand", "model", "Height", "Width", "Depth", "yaw", "pitch", "lateralOffset", "standoffDistance", "verticalOffset", "bandTechnology"];
            // const header = dataHeader; // columns name

            // const wscols: any = [];
            // // tslint:disable-next-line:prefer-for-of
            // for (let i = 0; i < header.length; i++) {  // columns length added
            //     wscols.push({ wch: header[i].length + 5 });
            // }

            // exportedTowers["!cols"] = wscols;
            // wb.Sheets[tableName] = exportedTowers;

            // const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary", cellStyles: true });
            // const date = new Date();
            // const dateString = `${date.getFullYear().toString()}${(date.getMonth() + 1)}${date.getDate()}${date.getHours()}${date.getMinutes()}`;
            // saveAs(new Blob([s2ab(wbout)], { type: "application/octet-stream" }), project.name + "_" + tableName + ".xlsx");

        }
    } catch (e) {
        // tslint:disable-next-line:no-console
        console.error(e);
    }
};
// const s2ab = (s: string) => {
//     const buf = new ArrayBuffer(s.length);
//     const view = new Uint8Array(buf);

//     for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
//     return buf;
// };



export const getColumnsNeeded = (tableName: string) => {
    let obj = {};
    if (tableName === "Equipment") {
        obj = [
            { key: "name", header: "Antenna ID"},
            { key: "type", header: "Equipment Type"},
            { key: "brand", header: "Manufacturer"},
            { key: "model", header: "Model"},
            { key: "Height", header: "Height " + getUnit()},
            { key: "Width", header: "Width " + getUnit()},
            { key: "Depth", header: "Depth " + getUnit()},
            { key: "yaw", header: "Absolute Azimuth"},
            { key: "pitch", header: "Vertical Tilt"},
            { key: "lateralOffset", header: "Lateral Offset" + getUnit()},
            { key: "standoffDistance", header: "Standoff Distanc" + getUnit()},
            { key: "verticalOffset", header: "Vertical Offset" + getUnit()},
            { key: "bandTechnology", header: "Operation Frequency Band"},
        ];
    } else {
        obj = [
            { key: "type", header: "Equipment Description"},
            { key: "altitude", header: "Altitude"},
            { key: "defect", header: "Defect"},
            { key: "defectiveComponent", header: "Defective Component"},
            { key: "defectiveStructure", header: "Defective Structure"},
            { key: "criticality", header: "Criticality"},
            { key: "pitch", header: "Tilt"},
            { key: "yaw", header: "Azimuth"},
            { key: "intervation", header: "Intervention Priority"},
            { key: "inspectionDate", header: "Date_Time"},
        ];
    }
    return obj;
};
