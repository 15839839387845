import { ConfigManager } from "../../config/ConfigManager";

export default class BracingsClient {
    constructor() { }
    /*
     * Get Bracings Data from Api, 
     * Holds the start and end elevation 
     * and the type of shape the bracing is made of.
     */ 
    public static async getBracingsData(token: string): Promise<any> {
        const url: string = `${ConfigManager.openToweriQUrl}/v1.0/${ConfigManager.projectId}/Site/Bracings`;
        return fetch(url, {
            method: "get",
            headers: {
                Authorization: token,
            },
        }).then(async (response: Response) => {
            if (!response.ok) {
                return null;
            }
            return response.json();
        }).catch((data: any) => {
            return data;
        });
    }
    //------------------------------------------------------------------
    // Get tower Structure data from database
    //------------------------------------------------------------------
    public static async getTowerStructureJson(token: string): Promise<any> {
        const url = `${ConfigManager.openToweriQUrl}/v1.0/${ConfigManager.projectId}/Site/Structure`;
        return fetch(url, {
            method: "get",
            headers: {
                Authorization: token,
            },
        }).then(async (response: Response) => {
            if (!response.ok) {
                return null;
            }
            return response.json();
        }).then((data: any) => {
            return data;
        });
    }
    //------------------------------------------------------------------//
    /*
     * Save the bracings data in one Go , as per api requirement
     * Make sure the Bracing bracing_ids are sequenced and sorted for them to appear in correct order.
     * See: BracingAddEditWidget -> saveNewBracing()
     */
    public static async postBracingData(data:any , token: string): Promise<any> {
        if (!data) {
            return "Data no correct , check";
        }
        const url = `${ConfigManager.openToweriQUrl}/v1.0/${ConfigManager.projectId}/Site/Bracings`;
        return fetch(url, {
            method: "post",
            headers: {
                Authorization: token,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        }).then(async (res: Response) => {
            if (!res.ok) {
                
                return "Error";
            }
            return "Success";
        }).then((data: any) => {
            return data;
        });
    }
    //------------------------------------------------------------------//
    public static async postBracingDetectorGeneratorData(token: string, data: any): Promise<any> {
        const url = `${decodeURIComponent(window.location.origin)}/api/Detectors/BracingDataGenerator`;
        //const url = `http://localhost:3001/api/Detectors/BracingDataGenerator`;
        //const url = `${ConfigManager.openToweriQUrl}/api/Detectors/BracingDataGenerator`;
        return fetch(url, {
            method: "post",
            headers: {
                Authorization: token,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        }).then(async (res: Response) => {
            if (!res.ok) {
                return "Error";
            }
            return res.json();
        }).catch(e=>{
            console.log(e)
        });
    }
}
