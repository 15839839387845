import { IModelApp, Tool, StartOrResume, ScreenViewport, NotifyMessageDetails, OutputMessagePriority, OutputMessageType } from "@itwin/core-frontend";
import { SvgArrowUp, SvgArrowLeft, SvgArrowRight, SvgArrowDown, SvgCaretUp, SvgCaretDown, SvgMove } from "@itwin/itwinui-icons-react";
import React, { useEffect, useRef, useState } from "react";
import { EquipmentsTable } from "../components/Tables/EquipmentsTable";
import { Alert, Badge, Button, LabeledInput, LabeledSelect, Radio, Select, Slider, Text, ToggleSwitch } from "@itwin/itwinui-react";
import { RootState } from "../../store/States";
import { connect } from "react-redux";
import { Point3d, Vector3d } from "@itwin/core-geometry";
import { EquipmentRotationWidget } from "./widgets/EquipmentRotationWidget";
import { EquipmentInformation, EquipmentType } from "../../store/redux-types";
import { EquipmentRotationType } from "./DetectedEquipmentEditHandler";
import { UiFramework, WidgetState } from "@itwin/appui-react";
import { Id64String } from "@itwin/core-bentley";
import { store } from "../../store/rootReducer";
import { DTVActions } from "../../store/Actions";
import { resetObjectIds } from "../components/HorizontalToolbarItems";
import { ModifyHandleDecoration } from "../components/tools/modification/ModifyHandleDecoration";
import EquipmentClient, { createEquipment, saveEquipment } from "../api/equipment";
import { fetchEquipmentData } from "./GetDetectedObjectsData";
import Place3DObjectApi from "../components/tools/Place3DObjectApi";
import { DetectedGeometryType, PlacementMode, PlacementTool } from "../components/tools/PlacementTool";
import { TowerGeometry, TowerModelData, TowerStructureDecorator, TowerStructurePostData } from "./decorators/TowerStructureDecorator";
import { setSiteCoordinate, setTowerData } from "../../store/detectedData/apiDataActionTypes";
import { ConfigManager } from "../../config/ConfigManager";
import { DecoratorHelper } from "./decorators/DecoratorHelper";
import { ObjectCategoryTypes, towerStructureData } from "../../store/detectedData/apiDataTypes";

export interface position3D {x: number, y: number, z: number}
export enum CylinderSection {Top, Whole, Bottom}
export interface EquipmentEditProperties {
  DisplayName: string;
  Manufacturer: string;
  Model: string;
  Height: number,
  Width: number,
  Depth: number,
  Azimuth: number,
  DownTilt: number,
  Roll: number,
  ShapeId: number|undefined,
  Position: position3D
  Modified: boolean;
}

const equipDefProps: EquipmentEditProperties = {
  DisplayName: "",
  Manufacturer: "",
  Model: "",
  Height: 0,
  Width: 0,
  Depth: 0,
  Azimuth: 0,
  DownTilt: 0,
  Roll: 0,
  ShapeId: undefined,
  Position: { x: 0, y: 0, z: 0 },
  Modified: false
}

export interface buttonState {label: string, disabled: boolean};
const CustomTowerEditHandler: React.FunctionComponent = (cethProps: any) => {
    let timerRef = useRef<NodeJS.Timer>();
    const towerSectionList = [
      {label: "Lightning Rod", value: 0},
      {label: "Highest Appurtenance", value: 1},
      {label: "Top of Tower", value: 2},
      {label: `Tower ${cethProps?.towerProperties?.towerType == "monopole" ? "Pole" : "Leg"}`, value: 3},
      {label: "Base of Tower", value: 4},
      {label: "Ground Level", value: 5},
    ];
    const disable = (IModelApp.toolAdmin.activeTool as PlacementTool)?.toolId === "PlacementTool";
    
    const [dataUpdated, setDataUpdated] = useState<boolean>(false);
    const [equipmentProps, setEquipmentProps] = useState<EquipmentEditProperties>(cethProps?.towerProperties);
    const [selPoleSection, setSelPoleSection] = useState<CylinderSection>(CylinderSection.Whole);
    const [selSectionId, setSelSectionId] = useState<string>("3");
    const [selSegmentId, setSelSegmentId] = useState<string>("0");
    const [selMissSectionId, setSelMissSectionId] = useState<number>(0);
    const [towerSegmentWidth, setTowerSegmentWidth] = useState<number>(0);
    const [monopoleWidth, setMonopoleWidth] = useState<number>(0);
    // const [monopoleWidthBottom, setMonopoleWidthBottom] = useState<number>(0);
    const [monopoleHeight, setMonopoleHeight] = useState<number>(0);
    const [towerTiltX, setTowerTiltX] = useState<number>(cethProps?.towerProperties?.TiltX);
    const [towerTiltY, setTowerTiltY] = useState<number>(cethProps?.towerProperties?.TiltY);
    const [towerBearing, setTowerBearing] = useState<number>(cethProps?.towerProperties?.Bearing);
    const [equipTiltSlider, setEquipTiltSlider] = useState<number>(0);
    const [selMan, setSelMan] = useState<number>(0);
    const [selModel, setSelModel] = useState<number>(0);
    const [library, setLibrary] = useState<number>(0);
    const [isDisabled, setIsDisabled] = useState(!disable);
    const [isModified, setIsModified] = useState(false);
    const [disableMovement, setDisableMovement] = useState(false);
    const [hideSymbolicTower, setHideSymbolicTower] = useState<boolean>(false);
    const [hideOtherObjects, setHideOtherObjects] = useState<boolean>(false);
    const [resetLabel, setResetLabel] = useState<string>("Reset");
    const [saveLabel, setSaveLabel] = useState<string>("Save");
    const [saveButton, setSaveButton] = useState<buttonState>({label: "Save", disabled: true});
    const [resetButton, setResetButton] = useState<buttonState>({label: "Reset", disabled: true});
    const move = 0.01;
  
    useEffect(()=>{
      if(cethProps.open){
        const towDec=IModelApp.viewManager.decorators.filter(e=>e.constructor.name.includes("TowerStructureDecorator"))[0] as TowerStructureDecorator;
        if(towDec.getTowerGeometry.type!="monopole" && !hideSymbolicTower)towDec.drawTiltPoleSegmentForLattice();      
      }
    }, [cethProps.open]);
  
    useEffect(()=>{
      if(selSectionId == "3"){
        if(cethProps?.towerProperties?.towerType == "monopole"){
          const theSection = TowerStructureDecorator.selectedSection.theSection!;
          switch (selPoleSection) {
            case CylinderSection.Top:              
              setMonopoleWidth(theSection.modelData?.actWidthBot!);
              break;
            case CylinderSection.Bottom:              
              setMonopoleWidth(theSection.modelData?.actWidthTop!);
              break;
            default:
              setMonopoleWidth(Math.round(((theSection.modelData?.actWidthTop!+theSection.modelData?.actWidthBot!)/2)*1000)/1000);
              break;
          }
          setMonopoleHeight(theSection.modelData?.actHeight!)

        } else {
          const towSelSec = TowerStructureDecorator.selectedSection;
          const towDec=IModelApp.viewManager.decorators.filter(e=>e.constructor.name.includes("TowerStructureDecorator"))[0] as TowerStructureDecorator;
          if(!towSelSec.theSection)TowerStructureDecorator.selectedSection.theSection = towDec.towerCylinders.find(e=>e.name.match(towSelSec.sectionName))!;
          setTowerSegmentWidth(TowerStructureDecorator.selectedSection.theSection?.modelData?.actWidthBot!)
          if(parseInt(selSegmentId) == 0 || parseInt(selSegmentId) == TowerStructureDecorator.towerGeometryInfo.tower_vertical_segments.length)setDisableMovement(true);
          else setDisableMovement(false);
        }
      } else setDisableMovement(false);
    }, [selSegmentId, selSectionId, selPoleSection]);
  
    useEffect(()=>{
      // setResetButton({...resetButton, disabled: !cethProps.towerProperties?.isModified});
      let sectionId: string = "5";
      const towSelSec = TowerStructureDecorator.selectedSection;
      switch (towSelSec.sectionName) {
        case "Lightning Rod@Marker":
        sectionId = "0"
        break;
        case "Top end of equipment@Marker":
        sectionId = "1"
        break;
        case "Tower top@Marker":
        sectionId = "2"
        break;
        case "Tower leg@Marker":
        case "Tower Pole":
        sectionId = "3"
        setHideSymbolicTower(false);
        setDisableMovement(true);
        break;
        case "Concrete level@Marker":
        sectionId = "4"
        break;
        case "Ground level@Marker":
        sectionId = "5"
        break;
            
        default:{
          if(towSelSec.sectionName.match(/segment/)){
            sectionId = "3";
            const selSeg = towSelSec.sectionName.split('-')[2];
            setSelSegmentId((parseInt(selSeg)-1).toString());
            // setTowerSegmentWidth(towSelSec.theSection?.modelData?.actWidthBot!)
          }
        }
        break;
      }
      setSelSectionId(sectionId);
    }, [cethProps.towerProperties])

    /** Disable input box (radius) if PlacementTool is not active  */
    const toolChangeListener = (tool: Tool, _start: StartOrResume): void => {
      if (tool.toolId === "PlacementTool") {
        setIsDisabled(false);
        return;
      }
      setIsDisabled(true);
    };
  
    const updateEquipmentIn3D = async () => {
    };
  
  
    const onSegmentSelect = async (_nameIndex: number) => {
      const towDec=IModelApp.viewManager.decorators.filter(e=>e.constructor.name.includes("TowerStructureDecorator"))[0] as TowerStructureDecorator;
      const imc = UiFramework.getIModelConnection();
      const towerInfo = TowerStructureDecorator.towerGeometryInfo;
      const theIndex = towerInfo.tower_vertical_segments.length-_nameIndex;
      // setSelSegmentId(_nameIndex.toString());
      setSelSegmentId(_nameIndex.toString());
      if(_nameIndex == 0 || _nameIndex == towerInfo.tower_vertical_segments.length)setDisableMovement(true);
      else setDisableMovement(false);

      towDec.selectedPart = `segment-joint-${_nameIndex+1}`;
      const theSection = towDec.towerCylinders.find(e=>e.name==towDec.selectedPart)!;
      TowerStructureDecorator.selectedSection.sectionName = towDec.selectedPart!;
      TowerStructureDecorator.selectedSection.theSection = theSection!;
      if(theSection){
        imc?.selectionSet.emptyAll();
        imc?.selectionSet.add(theSection?.transientId!);
        setTowerSegmentWidth(theSection?.modelData?.actWidthBot!);
      }
    };
  
    const onSectionSelect = async (_nameIndex: number) => {
      const towSection = towerSectionList[_nameIndex];
      const towDec=IModelApp.viewManager.decorators.filter(e=>e.constructor.name.includes("TowerStructureDecorator"))[0] as TowerStructureDecorator;
      const imc = UiFramework.getIModelConnection();
      // towDec.hideMarkers = !hideOtherObjects;
      switch (towSection.label) {
        case "Lightning Rod": 
        towDec.selectedPart = "Lightning Rod@Marker";
        
        break;
        case "Highest Appurtenance": 
        towDec.selectedPart = "Top end of equipment@Marker";
        
        break;
        case "Top of Tower": 
        towDec.selectedPart = "Tower top@Marker";
        
        break;
        case "Tower Leg": 
        case "Tower Pole": 
        if(TowerStructureDecorator.towerGeometryInfo.type != "monopole"){
          setDisableMovement(true);
          towDec.selectedPart = "segment-joint-1";
        } else {
          towDec.selectedPart = "Tower Pole";

        }
        break;
        case "Base of Tower": 
        towDec.selectedPart = "Concrete level@Marker";
        
        break;
        case "Ground Level": 
        towDec.selectedPart = "Ground level@Marker";
        
        break;
            
        default:
          break;
      }
      if(hideSymbolicTower)towDec.drawTiltPoleSegmentForLattice();
      const theSection = towDec.towerCylinders.find(e=>e.name==towDec.selectedPart)!;
      TowerStructureDecorator.selectedSection.sectionName = towDec.selectedPart!;
      TowerStructureDecorator.selectedSection.theSection = theSection!;
      if(theSection){
        
        imc?.selectionSet.emptyAll();
        imc?.selectionSet.add(theSection?.transientId!);
        setTowerSegmentWidth(theSection?.modelData?.actWidthBot!);
        setHideSymbolicTower(false);
        setSelSegmentId("0");
        setSelSectionId("3")
      }
    };
  
    const poleSelectionHandler = (e)=>{
      setSelPoleSection(e);
    }
  
    const onFieldUpdate = async (target, field: string) => {
      let theValue: number;

      const towPrevInfo: towerStructureData = JSON.parse(JSON.stringify(TowerStructureDecorator.towerGeometryInfo));
      let towPrevInfoCopy: towerStructureData=JSON.parse(JSON.stringify(towPrevInfo));
      const towDec = IModelApp.viewManager.decorators.filter(e=>e.constructor.name.includes("TowerStructureDecorator"))[0] as TowerStructureDecorator;

      if(target.constructor.name == "Array"){
        theValue = target[0];
      } else {
        theValue = Number(target.target.value);
      }

      let towData: TowerStructurePostData = {};
      switch (field) {
        case "Width": {
      
          setTowerSegmentWidth(theValue);
          // const equipType = cethProps.selectedObjectInfo.objectProperties.selectedObjectNST.subType;
          let theSelIndex = (towPrevInfo.tower_vertical_segments.length-parseInt(selSegmentId)).toString();
          towData = towDec.convertGetToPostTowerData(TowerStructureDecorator.towerGeometryInfo, ["baseWidth", "towerVerticalSegments"]);
          switch (theSelIndex) {
            case "0": {
              towData.topWidth = theValue;
              towData.towerVerticalSegments = towDec.convertGetToPostTowerVSData(towPrevInfo.tower_vertical_segments);
              towData.towerVerticalSegments![0].topWidth = theValue;
              
              towPrevInfoCopy.top_width = towData.topWidth!;
              towPrevInfoCopy.tower_vertical_segments[0].top_width = theValue;
            
              break;
            }
            case (towPrevInfo.tower_vertical_segments.length).toString(): {
              towData.baseWidth = theValue;
              towData.towerVerticalSegments = towDec.convertGetToPostTowerVSData(towPrevInfo.tower_vertical_segments);
              towData.towerVerticalSegments![towPrevInfo.tower_vertical_segments.length-1].widthBottom = theValue;
              
              towPrevInfoCopy.base_width = towData.baseWidth;
              towPrevInfoCopy.tower_vertical_segments[towPrevInfo.tower_vertical_segments.length-1].width_bottom = theValue;
              
              break;
            }
            default: {
              const theIndex = parseInt(theSelIndex)-1;

              towData.towerVerticalSegments = towDec.convertGetToPostTowerVSData(towPrevInfo.tower_vertical_segments);
              towData.towerVerticalSegments![theIndex].widthBottom = theValue;
              towData.towerVerticalSegments![theIndex+1].topWidth = theValue;

              towPrevInfoCopy.tower_vertical_segments[theIndex].width_bottom = theValue;
              towPrevInfoCopy.tower_vertical_segments[theIndex+1].top_width = theValue;

              // towData.towerVerticalSegments = towDec.convertGetToPostTowerVSData(towPrevInfo.tower_vertical_segments);
              // towData.towerVerticalSegments![selSegmentId].widthBottom = theValue;
              // towData.towerVerticalSegments![parseInt(selSegmentId)+1].topWidth = theValue;
              
              // towPrevInfoCopy.tower_vertical_segments[selSegmentId].width_bottom = theValue;
              // towPrevInfoCopy.tower_vertical_segments[parseInt(selSegmentId)+1].top_width = theValue;
              break;
            }
          }




          const newCyls: TowerGeometry[] = towDec.towerCylinders.filter(tc=>!tc.name.match(/latticeSection|segment-pole|segment-joint/));
          towDec.towerCylinders = newCyls;
          towDec.backupCylinders = towDec.towerCylinders;
          if(towDec.hideMarkers)towDec.hideMarkers=false;
          TowerStructureDecorator.towerGeometryInfo=towPrevInfoCopy;
          // towDec.loadShapes(towPrevInfoCopy);
          towDec.drawLatticeLegs(towPrevInfoCopy);
          break;
        }
        case "TiltX": {
          towData.towerTiltX = theValue;
          towPrevInfoCopy.tower_tilt_x = theValue;
          setTowerTiltX(theValue);
          towDec.terminate();
          towDec.loadShapes(towPrevInfoCopy);
          // if(selSectionId == "3")towDec.drawTiltPoleSegmentForLattice();
          break;
        }
        case "TiltY": {
          towData.towerTiltY = theValue;
          towPrevInfoCopy.tower_tilt_y = theValue;
          setTowerTiltY(theValue);
          towDec.terminate();
          towDec.loadShapes(towPrevInfoCopy);
          // if(selSectionId == "3")towDec.drawTiltPoleSegmentForLattice();
          break;
        }
        case "Bearing": {
          towData.bearing = theValue;
          towPrevInfoCopy.bearing = theValue;
          setTowerBearing(theValue);
          // towDec.terminate();
          // towDec.loadShapes(towPrevInfoCopy);
          const newCyls: TowerGeometry[] = towDec.towerCylinders.filter(tc=>!tc.name.match(/latticeSection|segment-pole|segment-joint/));
          towDec.towerCylinders = newCyls;
          towDec.backupCylinders = towDec.towerCylinders;
          if(towDec.hideMarkers)towDec.hideMarkers=false;
          TowerStructureDecorator.towerGeometryInfo=towPrevInfoCopy;
          // towDec.loadShapes(towPrevInfoCopy);
          towDec.drawLatticeLegs(towPrevInfoCopy);

          // if(selSectionId == "3")towDec.drawTiltPoleSegmentForLattice();
          break;
        }
        case "Height": {
          towData.topSteelElevation = theValue;
          towData.topAltitude = towPrevInfo.base_altitude + theValue;
          towPrevInfoCopy.top_steel_elevation = towData.topSteelElevation;
          towPrevInfoCopy.top_altitude = towData.topAltitude;
          
          // const avgPt = new Point3d((selTowPart.startPos.x+selTowPart.endPos.x)*0.5, (selTowPart.startPos.y+selTowPart.endPos.y)*0.5, (selTowPart.startPos.z+selTowPart.endPos.z)*0.5);
          
          
          towDec.towerCylinders = towDec.towerCylinders.filter(tc=>!tc.name.match(/Tower Pole|Tower top/));
          towDec.backupCylinders = towDec.towerCylinders;
          if(towDec.hideMarkers)towDec.hideMarkers=false;
          TowerStructureDecorator.towerGeometryInfo=towPrevInfoCopy;
          
          towDec.drawMonoPole(towPrevInfoCopy);
          towDec.drawTowerTopLevel(towPrevInfoCopy);
          
          const towerTop = towDec.towerCylinders.find(e=>e.name.match(/Tower Pole/))!;
          let tempPart = towDec.towerCylinders.find(e=>e.name.match(/Lightning Rod/))!;
          if(tempPart){
            const LRHPos = tempPart.startPos.interpolate(0.5, tempPart.endPos);//.x)*0.5, (tempPart.startPos.y+tempPart.endPos.y)*0.5, (tempPart.startPos.z+tempPart.endPos.z)*0.5);
            towData.lightningRodHeight = LRHPos.z-towerTop.startPos.z;
            towPrevInfoCopy.lightning_rod_height = towData.lightningRodHeight;
          }
          
          TowerStructureDecorator.towerGeometryInfo=towPrevInfoCopy;
          towDec.towerCylinders = towDec.towerCylinders.filter(tc=>!tc.name.match(/Lightning Rod/));
          towDec.drawLightningRodLevel(towPrevInfoCopy);

          tempPart = towDec.towerCylinders.find(e=>e.name.match(/Top end of equipment/))!;
          const TAPos = tempPart.startPos.interpolate(0.5, tempPart.endPos);//.x)*0.5, (tempPart.startPos.y+tempPart.endPos.y)*0.5, (tempPart.startPos.z+tempPart.endPos.z)*0.5);
          towData.topAppurtenanceHeight = TAPos.z-towerTop.startPos.z;
          towPrevInfoCopy.top_appurtenance_height = towData.topAppurtenanceHeight;
          // towPrevInfoCopy.top_appurtenance_height = towPrevInfoCopy.top_appurtenance_height - towData.topAltitude;
          
          TowerStructureDecorator.towerGeometryInfo=towPrevInfoCopy;
          towDec.towerCylinders = towDec.towerCylinders.filter(tc=>!tc.name.match(/Top end of equipment/));
          towDec.drawHighestAppurtenanceLevel(towPrevInfoCopy);
          setMonopoleHeight(theValue);
          break;
        }
        case "Diameter": {
          switch (selPoleSection) {
            case CylinderSection.Whole:
            case CylinderSection.Top: {
              const newCyls: TowerGeometry[] = towDec.towerCylinders.filter(tc=>!tc.name.match(/Tower Pole/));
              towDec.towerCylinders = newCyls;
              towDec.backupCylinders = towDec.towerCylinders;
              if(towDec.hideMarkers)towDec.hideMarkers=false;
              // towDec.loadShapes(towPrevInfoCopy);
              towData.topWidth = theValue;
              
              towPrevInfoCopy.top_width = theValue;
              towPrevInfoCopy.tower_vertical_segments[0].top_width = theValue;
              TowerStructureDecorator.towerGeometryInfo=towPrevInfoCopy;
              if(selPoleSection != CylinderSection.Whole)break;
            }
            case CylinderSection.Whole: 
            case CylinderSection.Bottom: {
              const newCyls: TowerGeometry[] = towDec.towerCylinders.filter(tc=>!tc.name.match(/Tower Pole/));
              towDec.towerCylinders = newCyls;
              towDec.backupCylinders = towDec.towerCylinders;
              if(towDec.hideMarkers)towDec.hideMarkers=false;
              // towDec.loadShapes(towPrevInfoCopy);
              towData.baseWidth = theValue;
              
              towPrevInfoCopy.base_width = theValue;
              towPrevInfoCopy.tower_vertical_segments[0].width_bottom = theValue;
              TowerStructureDecorator.towerGeometryInfo=towPrevInfoCopy;
              // towDec.drawMonoPole(towPrevInfoCopy)
              // setMonopoleWidth(theValue);              
              break;
            }
            default:
              break;
          }
          towDec.drawMonoPole(towPrevInfoCopy)
          setMonopoleWidth(theValue);
          break
        }
        default:
          break;
      }
      if(!hideSymbolicTower && cethProps?.towerProperties?.towerType != "monopole")towDec.drawTiltPoleSegmentForLattice();
      setResetButton({...resetButton, disabled: false});
      setSaveButton({...saveButton, disabled: false});
      TowerStructureDecorator.selectedSection.isModified = true;
      resetObjectIds(towDec);
      IModelApp.viewManager.invalidateDecorationsAllViews();
      IModelApp.viewManager.selectedView?.invalidateCachedDecorations(towDec);

      // setEquipmentProps(temp);
      //   if (equipType == EquipmentType.Microwave) { // cylinder
      //       await EquipmentRotationWidget.sliderChange(rotationVal == EquipmentRotationType.Tilt ? -1*theValue : theValue, rotationVal, ShapeType.Cylinder);
      //   } else {
      //       await EquipmentRotationWidget.sliderChange(rotationVal == EquipmentRotationType.Tilt ? -1*theValue : theValue, rotationVal, ShapeType.Box);
      //   }
      //   // const decorator = IModelApp.viewManager.decorators.filter((e) => e.constructor.name.includes("TowerStructureDecorator"))[0] as TowerStructureDecorator;
      //   TowerStructureDecorator.selectedEquipment.isModified = true;      
      //   setIsModified(TowerStructureDecorator.selectedEquipment.isModified);
      //   setSaveButton({...saveButton, disabled: !TowerStructureDecorator.selectedEquipment.isModified});
      //   // if(!TowerStructureDecorator.selectedEquipment.created)setHideOtherObjects(false);
      //   if(!TowerStructureDecorator.selectedEquipment.created)setResetButton({...resetButton, disabled: false});
    };
  
    const onMouseUp = () => {
      clearInterval(timerRef.current!);
    }
    
  

    const onMouseDown = (target) => {
      let towDec = IModelApp.viewManager.decorators.filter((e) => e.constructor.name.includes("TowerStructureDecorator"))[0] as TowerStructureDecorator;
      const towCyl = towDec.towerCylinders;
      const selTowSec = TowerStructureDecorator.selectedSection;
      const selTowPart = towDec.towerCylinders.find(e=>e.name==selTowSec.sectionName)!;
      const towPrevInfo = towDec.getTowerGeometry;
      const towerType = towPrevInfo.type;
      let towPrevInfoCopy=JSON.parse(JSON.stringify(towPrevInfo));
      const towData:TowerStructurePostData = {};

      let movementFactor: number = 0;
      switch (target) {
        case "Move Up":
          movementFactor = 0.1
          break;
        case "Move Down":
          movementFactor = -0.1;
          
          break;
      
        default:
          break;
      }

      switch (selTowSec.sectionName) {
        case "Ground level@Marker": {
          let tempIndex = towCyl.findIndex(e=>e.name.includes("Concrete level"));
          let tempPart = towCyl[tempIndex];

          const CLPosAvg = tempPart.startPos.interpolate(0.5, tempPart.endPos);//.x)*0.5, (tempPart.startPos.y+tempPart.endPos.y)*0.5, (tempPart.startPos.z+tempPart.endPos.z)*0.5)
          const GLPosAvg = selTowPart.startPos.interpolate(0.5, selTowPart.endPos);//.x)*0.5, (selTowPart.startPos.y+selTowPart.endPos.y)*0.5, (selTowPart.startPos.z+selTowPart.endPos.z)*0.5)
          
          timerRef.current = setInterval(()=>{
            GLPosAvg.z+=movementFactor;
            towData.concreteHeight = CLPosAvg.z-GLPosAvg.z;
            // if(towData.concreteHeight > 0){
              towPrevInfoCopy.concrete_height = towData.concreteHeight;
              TowerStructureDecorator.towerGeometryInfo=towPrevInfoCopy;
              towDec.towerCylinders = towDec.towerCylinders.filter(e=>!e.name.match(/Ground level@Marker/));
              towDec.drawGroundLevel(towPrevInfoCopy);
            // }
          }, 50)
          setTimeout(() => {clearInterval(timerRef.current);}, 3000);
          break;
        }
        case "Lightning Rod@Marker": {

          let tempIndex = towCyl.findIndex(e=>e.name.includes("Lightning Rod"));
          let tempPart = towCyl[tempIndex], dist: number = 0;
          const LRPos = tempPart.startPos.interpolate(0.5, tempPart.endPos);//.x)*0.5, (tempPart.startPos.y+tempPart.endPos.y)*0.5, (tempPart.startPos.z+tempPart.endPos.z)*0.5)
          
          if(towerType == "monopole"){
            tempIndex = towCyl.findIndex(e=>e.name.includes("Tower Pole"));
            let towPole = towCyl[tempIndex];
            dist = LRPos.z-towPole.startPos.z;
          } else {
            tempPart = towCyl.find(e=>e.name.match(/Tower top/))!;
            let topPt = tempPart.startPos.interpolate(0.5, tempPart.endPos);
            dist = LRPos.z-topPt.z;
          }

          
          towData.lightningRodHeight = dist;
          timerRef.current = setInterval(()=>{
            towData.lightningRodHeight!+=movementFactor;
            towPrevInfoCopy.lightning_rod_height = towData.lightningRodHeight;
            TowerStructureDecorator.towerGeometryInfo=towPrevInfoCopy;

            towDec.towerCylinders = towDec.towerCylinders.filter(e=>!e.name.match(/Lightning Rod/));
            towDec.drawLightningRodLevel(towPrevInfoCopy);
          }, 50)
          setTimeout(() => {clearInterval(timerRef.current);}, 3000);

          break;
        }
        case "Tower top@Marker": {
          const index = towCyl.findIndex(e=>e.name.includes(selTowSec.sectionName));

          let towLen: number = 0;
          if(towerType == "monopole"){
            const poleIindex = towCyl.findIndex(e=>e.name.includes("Tower Pole"));
            towLen=towCyl[poleIindex].endPos.distance(towCyl[index].startPos);
          } else {
            let tempIndex = towCyl.findIndex(e=>e.name.includes("Concrete level"));
            let tempPart = towCyl[tempIndex];
            // const GLPosAvg = new Point3d((tempPart.startPos.x+tempPart.endPos.x)*0.5, (tempPart.startPos.y+tempPart.endPos.y)*0.5, (tempPart.startPos.z+tempPart.endPos.z)*0.5)
            // const GLPosAvg = tempPart.startPos.interpolate(0.5, tempPart.endPos);//.x)*0.5, (tempPart.startPos.y+tempPart.endPos.y)*0.5, (tempPart.startPos.z+tempPart.endPos.z)*0.5)
            const CLPosAvg = tempPart.startPos.interpolate(0.5, tempPart.endPos);//.x)*0.5, (selTowPart.startPos.y+selTowPart.endPos.y)*0.5, (selTowPart.startPos.z+selTowPart.endPos.z)*0.5)

            const basePt = new Point3d(towPrevInfo.base_center[0], towPrevInfo.base_center[1], towPrevInfo.base_altitude);
            let topPt = basePt.clone();
            topPt.z = towPrevInfo.top_altitude;
            // topPt = DecoratorHelper.extract3DPoint(topPt);
            topPt = selTowPart.startPos.interpolate(0.5, selTowPart.endPos);


            const bottomPt = DecoratorHelper.extract3DPoint(basePt.clone());
            const height = topPt.z - bottomPt.z;
            topPt.x = topPt.x + (height) * Math.tan(towDec.radians(towPrevInfo.tower_tilt_x));
            topPt.y = topPt.y + (height) * Math.tan(towDec.radians(towPrevInfo.tower_tilt_y));
            const extraR: number = 0.05; // to display monopole tower outside pole diameter
            towLen = topPt.distance(CLPosAvg);
          }

          timerRef.current = setInterval(()=>{
            towLen+=movementFactor;

            towData.topSteelElevation = towLen;
            towData.topAltitude = towPrevInfo.base_altitude + towLen;
            towPrevInfoCopy.top_steel_elevation = towData.topSteelElevation;
            towPrevInfoCopy.top_altitude = towData.topAltitude;

            towDec.towerCylinders = towDec.towerCylinders.filter(e=>!e.name.match(/Tower top@Marker/));
            towDec.drawTowerTopLevel(towPrevInfoCopy)
            
            let tempIndex = towCyl.findIndex(e=>e.name.includes("Lightning Rod"));
            if(tempIndex !== -1){
              let tempPart = towCyl[tempIndex];
              const tt = towDec.towerCylinders.find(e=>e.name.match(/Tower top@Marker/))!;
              const ttPos = tt.startPos.interpolate(0.5, tt.endPos);
              
              const LRHPos = tempPart.startPos.interpolate(0.5, tempPart.endPos);//.x)*0.5, (tempPart.startPos.y+tempPart.endPos.y)*0.5, (tempPart.startPos.z+tempPart.endPos.z)*0.5);
              towData.lightningRodHeight = LRHPos.z-ttPos.z;
              towPrevInfoCopy.lightning_rod_height = towData.lightningRodHeight;

              towDec.towerCylinders = towDec.towerCylinders.filter(e=>!e.name.match(/Lightning Rod@Marker/));
              towDec.drawLightningRodLevel(towPrevInfoCopy)
            }

            tempIndex = towCyl.findIndex(e=>e.name.includes("Top end of equipment"));
            if(tempIndex !== -1){
              let tempPart = towCyl[tempIndex];
              const TAPos = tempPart.startPos.interpolate(0.5, tempPart.endPos);//.x)*0.5, (tempPart.startPos.y+tempPart.endPos.y)*0.5, (tempPart.startPos.z+tempPart.endPos.z)*0.5);
              const tt = towDec.towerCylinders.find(e=>e.name.match(/Tower top@Marker/))!;
              const ttPos = tt.startPos.interpolate(0.5, tt.endPos);
              // if(ttPos.z > TAPos.z){
                towData.topAppurtenanceHeight = TAPos.z-ttPos.z;
                towPrevInfoCopy.top_appurtenance_height = towData.topAppurtenanceHeight;
                TowerStructureDecorator.towerGeometryInfo=towPrevInfoCopy;

                towDec.towerCylinders = towDec.towerCylinders.filter(e=>!e.name.match(/Top end of equipment@Marker/));
                towDec.drawHighestAppurtenanceLevel(towPrevInfoCopy)
              // }
            }


          }, 50)
          setTimeout(() => {clearInterval(timerRef.current);}, 3000);


          break;
        }
        case "Top end of equipment@Marker": {
          let tempIndex = towCyl.findIndex(e=>e.name.includes("Top end of equipment"));
          let tempPart = towCyl[tempIndex];
          if(towerType == "monopole"){
            tempIndex = towCyl.findIndex(e=>e.name.includes("Tower Pole"));
            if(tempIndex > -1){
                let towPole = towCyl[tempIndex];
                // const TAPos = new Point3d((tempPart.startPos.x+tempPart.endPos.x)*0.5, (tempPart.startPos.y+tempPart.endPos.y)*0.5, (tempPart.startPos.z+tempPart.endPos.z)*0.5)
                const TAPos = tempPart.startPos.interpolate(0.5, tempPart.endPos);//.x)*0.5, (tempPart.startPos.y+tempPart.endPos.y)*0.5, (tempPart.startPos.z+tempPart.endPos.z)*0.5)
            
                towData.topAppurtenanceHeight = TAPos.z-towPole.startPos.z;
                towPrevInfoCopy.top_appurtenance_height = towData.topAppurtenanceHeight;
            }
          } else {
            const TAPos = tempPart.startPos.interpolate(0.5, tempPart.endPos);//.x)*0.5, (tempPart.startPos.y+tempPart.endPos.y)*0.5, (tempPart.startPos.z+tempPart.endPos.z)*0.5)
            let ttIndex = towCyl.findIndex(e=>e.name.includes("Tower top"));
            let ttPart = towCyl[ttIndex];

            const basePt = new Point3d(towPrevInfo.base_center[0], towPrevInfo.base_center[1], towPrevInfo.base_altitude);
            let topPt = basePt.clone();
            topPt.z = towPrevInfo.top_altitude;
            topPt = DecoratorHelper.extract3DPoint(topPt);

            // towData.topAppurtenanceHeight = TAPos.z-basePt.z;
            towData.topAppurtenanceHeight = TAPos.z-ttPart.startPos.interpolate(0.5, ttPart.endPos).z;
            towPrevInfoCopy.top_appurtenance_height = towData.topAppurtenanceHeight;
          }

    
          timerRef.current = setInterval(()=>{
            towData.topAppurtenanceHeight! += movementFactor!;
            towPrevInfoCopy.top_appurtenance_height = towData.topAppurtenanceHeight;
            TowerStructureDecorator.towerGeometryInfo=towPrevInfoCopy;

            towDec.towerCylinders = towDec.towerCylinders.filter(e=>!e.name.match(/Top end of equipment@Marker/));
            towDec.drawHighestAppurtenanceLevel(towPrevInfoCopy);
          }, 50)
          setTimeout(() => {clearInterval(timerRef.current);}, 3000);


          break;
        }
        case "Concrete level@Marker": {
          let tempIndex = towCyl.findIndex(e=>e.name.includes("Ground level"));
          let tempPart = towCyl[tempIndex];
          // const GLPosAvg = new Point3d((tempPart.startPos.x+tempPart.endPos.x)*0.5, (tempPart.startPos.y+tempPart.endPos.y)*0.5, (tempPart.startPos.z+tempPart.endPos.z)*0.5)
          const GLPosAvg = tempPart.startPos.interpolate(0.5, tempPart.endPos);//.x)*0.5, (tempPart.startPos.y+tempPart.endPos.y)*0.5, (tempPart.startPos.z+tempPart.endPos.z)*0.5)
          const CLPosAvg = selTowPart.startPos.interpolate(0.5, selTowPart.endPos);//.x)*0.5, (selTowPart.startPos.y+selTowPart.endPos.y)*0.5, (selTowPart.startPos.z+selTowPart.endPos.z)*0.5)
          let topPt: Point3d = new Point3d();
          let towLen: number = 0;
          if(towerType == "monopole"){
            const poleIndex = towCyl.findIndex(e=>e.name.includes("Tower Pole"));
            topPt = towCyl[poleIndex].startPos.clone()!;
          } else {

            const basePt = new Point3d(towPrevInfo.base_center[0], towPrevInfo.base_center[1], towPrevInfo.base_altitude);
            const centerPt = basePt.clone();
            const bottomPt = DecoratorHelper.extract3DPoint(basePt.clone());
            topPt = basePt.clone();
            topPt.z = towPrevInfo.top_altitude;
            topPt = DecoratorHelper.extract3DPoint(topPt);

            const height = topPt.z - bottomPt.z;
            topPt.x = topPt.x + (height) * Math.tan(towDec.radians(towPrevInfo.tower_tilt_x));
            topPt.y = topPt.y + (height) * Math.tan(towDec.radians(towPrevInfo.tower_tilt_y));
            // const extraR: number = 0.05; // to display monopole tower outside pole diameter
          }

    
          timerRef.current = setInterval(()=>{
            CLPosAvg.z+=movementFactor;
            towLen=topPt.distance(CLPosAvg);
            towData.concreteHeight = CLPosAvg.distance(GLPosAvg);
            towData.baseAltitude=towPrevInfo.top_altitude-towLen;
            towData.topSteelElevation = towLen;
  
            towPrevInfoCopy.concrete_height = towData.concreteHeight;
            towPrevInfoCopy.base_altitude = towData.baseAltitude;
            towPrevInfoCopy.top_steel_elevation = towData.topSteelElevation;
            if(towData.concreteHeight > 0){
              TowerStructureDecorator.towerGeometryInfo=towPrevInfoCopy;
              towDec.towerCylinders = towDec.towerCylinders.filter(e=>!e.name.match(/Concrete level@Marker|latticeSection_/));
              towDec.drawConcreteLevel(towPrevInfoCopy);
              towDec.drawLatticeLegs(towPrevInfoCopy);
            }
          }, 50)
          setTimeout(() => {clearInterval(timerRef.current);}, 3000);
        }
        break;

        default:
          if(selTowSec.sectionName.match(/segment-joint/)){
            let towData: TowerStructurePostData = {};
            let theSelIndex = (towPrevInfo.tower_vertical_segments.length-parseInt(selSegmentId)).toString();
            towData = towDec.convertGetToPostTowerData(TowerStructureDecorator.towerGeometryInfo, ["towerVerticalSegments"]);
  
            const theIndex = parseInt(theSelIndex)-1;

            towData.towerVerticalSegments = towDec.convertGetToPostTowerVSData(towPrevInfo.tower_vertical_segments);
            timerRef.current = setInterval(()=>{

              towData.towerVerticalSegments![theIndex].altitudeBottom += movementFactor;
              towData.towerVerticalSegments![theIndex+1].altitudeTop += movementFactor;
              towData.towerVerticalSegments![theIndex].height = Math.abs(towData.towerVerticalSegments![theIndex].altitudeBottom-towData.towerVerticalSegments![theIndex].altitudeTop);
              towData.towerVerticalSegments![theIndex+1].height = Math.abs(towData.towerVerticalSegments![theIndex+1].altitudeBottom-towData.towerVerticalSegments![theIndex+1].altitudeTop);

              towPrevInfoCopy.tower_vertical_segments[theIndex].altitude_bottom += movementFactor;
              towPrevInfoCopy.tower_vertical_segments[theIndex+1].altitude_top += movementFactor;
              towPrevInfoCopy.tower_vertical_segments[theIndex].height = Math.abs(towData.towerVerticalSegments![theIndex].altitudeBottom-towData.towerVerticalSegments![theIndex].altitudeTop);
              towPrevInfoCopy.tower_vertical_segments[theIndex+1].height = Math.abs(towData.towerVerticalSegments![theIndex+1].altitudeBottom-towData.towerVerticalSegments![theIndex+1].altitudeTop);

              const newCyls: TowerGeometry[] = towDec.towerCylinders.filter(tc=>!tc.name.match(/latticeSection|segment-pole|segment-joint/));
              towDec.towerCylinders = newCyls;
              towDec.backupCylinders = towDec.towerCylinders;
              // if(towDec.hideMarkers)towDec.hideMarkers=false;
              TowerStructureDecorator.towerGeometryInfo=towPrevInfoCopy;
              // towDec.loadShapes(towPrevInfoCopy);
              towDec.drawLatticeLegs(towPrevInfoCopy);
              towDec.drawTiltPoleSegmentForLattice();
              IModelApp.viewManager.selectedView?.invalidateCachedDecorations(towDec);
            }, 50)
            setTimeout(() => {clearInterval(timerRef.current);}, 3000);
         }
          break;
      }
      setResetButton({...resetButton, disabled: false});
      setSaveButton({...saveButton, disabled: false});
      TowerStructureDecorator.selectedSection.isModified = true;
    };
  
    const onClose = ()=>{
      UiFramework.frontstages.activeFrontstageDef?.findWidgetDef("TowerEditWidget")?.setWidgetState(WidgetState.Hidden);
      store.dispatch(DTVActions.setEditModeFlag(!store.getState().dtvState.applicationState.isEditModeActive));

      let towDec = IModelApp.viewManager.decorators.filter((e) => e.constructor.name.includes("TowerStructureDecorator"))[0] as TowerStructureDecorator;
      let towData: towerStructureData = store.getState().detectedData.towerStructureData as towerStructureData;      
      towDec.selectedPart = "";
      towDec.terminate()
      towDec.hideMarkers = false;
      towDec.loadShapes(towData);
      if(cethProps?.towerProperties?.towerType != "monopole")towDec.clearTiltPoleSegmentForLattice();
      IModelApp.viewManager.invalidateDecorationsAllViews();
      IModelApp.viewManager.selectedView?.invalidateCachedDecorations(towDec);
      resetObjectIds(towDec);
      const imc = UiFramework.getIModelConnection();
      imc?.selectionSet.emptyAll();
      let selPart = towDec.towerCylinders.find(e=>e.name.match(TowerStructureDecorator.selectedSection.sectionName));
      if(!selPart)selPart = towDec.towerCylinders.find(e=>e.name.match("Ground level"));
      imc?.selectionSet.add(selPart?.transientId!);
    }
  
    const onReset = ()=>{
      let towDec = IModelApp.viewManager.decorators.filter((e) => e.constructor.name.includes("TowerStructureDecorator"))[0] as TowerStructureDecorator;
      if(resetButton.label == "Cancel"){
      //   decorator.deleteCurrentShape(cethProps.towerProperties?.DisplayName);
      //   decorator.resetSelectedEquipment();
      //   if(ModifyHandleDecoration._decorator)ModifyHandleDecoration.clear();
      //   setResetButton({label: "Reset", disabled: true});
      //   setSaveButton({label: "Save", disabled: true});
      //   setSelSectionId("0");
      } else {
        // towDec.hideMarkers = !hideOtherObjects;
        towDec.selectedPart = TowerStructureDecorator.selectedSection.sectionName.split("-")[0];
        // let towData = towDec.getTowerGeometry();      
        let towData: towerStructureData = store.getState().detectedData.towerStructureData as towerStructureData;      
        // if(hideOtherObjects){
        towDec.towerCylinders = towDec.backupCylinders;
        setTowerBearing(towData.bearing);
        setTowerTiltX(towData.tower_tilt_x);
        setTowerTiltY(towData.tower_tilt_y);
        setTowerSegmentWidth(TowerStructureDecorator.selectedSection.theSection?.modelData?.actWidthBot!)
        //   setHideOtherObjects(!hideOtherObjects);
        // } else {
        //   // setTowMarkCheck(evt.target.checked);
        //   setHideOtherObjects(!hideOtherObjects);
        //   towDec.backupCylinders = towDec.towerCylinders;
        // }
        towDec.terminate()
        towDec.hideMarkers = false;
        towDec.loadShapes(towData);
        if(!hideSymbolicTower && cethProps?.towerProperties?.towerType != "monopole")towDec.drawTiltPoleSegmentForLattice();
        IModelApp.viewManager.invalidateDecorationsAllViews();
        IModelApp.viewManager.selectedView?.invalidateCachedDecorations(towDec);
        resetObjectIds(towDec);
        const imc = UiFramework.getIModelConnection();
        imc?.selectionSet.emptyAll();
        imc?.selectionSet.add(towDec.towerCylinders.find(e=>e.name.match(TowerStructureDecorator.selectedSection.sectionName))?.transientId!);
  
      //   const equipDisplayName = cethProps.towerProperties?.DisplayName;
      //   const theEquip = store.getState().detectedData.equipmentData.find(e=>e.displayName==equipDisplayName);
      //   const decEquipData: EquipmentData = decorator.convertDetectionDataToDecoratorData(theEquip);
      //   updateManModel(decEquipData.Manufacturer, decEquipData.Model);
      //   let shapeIndex = decorator.shapes.findIndex((shape) => shape.modelData?.DisplayName === equipDisplayName);
      //   TowerStructureDecorator.selectedEquipment.isModified = false;      
      //   setIsModified(TowerStructureDecorator.selectedEquipment.isModified);
      //   setSaveButton({...saveButton, disabled: !TowerStructureDecorator.selectedEquipment.isModified});
        
      //   let shapeDN = decEquipData!.DisplayName;
      //   decorator.deleteCurrentShape(shapeDN);
      //   // decorator.resetSelectedEquipment();
  
      //   decorator.createGeometry(decEquipData!, CreateGeometryMode.New);
      //   shapeIndex = decorator.shapes.findIndex((shape) => shape.modelData?.DisplayName === equipDisplayName);
      //   if(!ModifyHandleDecoration._decorator && shapeIndex !== -1){
      //     ModifyHandleDecoration.clear();
      //     ModifyHandleDecoration.create(IModelApp.viewManager.selectedView as ScreenViewport, decorator.shapes[shapeIndex].transientId, shapeDN!, shapeIndex, decorator);
      //   }      
      //   if (ModifyHandleDecoration._decorator && decorator.shapes[shapeIndex].modelData?.shapeId == null) {
      //     ModifyHandleDecoration._decorator._shape = decorator.shapes[shapeIndex].modelData?.geometryEntry?.geometry;
      //     ModifyHandleDecoration._decorator._shapeIndex = shapeIndex;
      //     ModifyHandleDecoration._decorator._shapeName = equipDisplayName;
      //     ModifyHandleDecoration._decorator.createClipShapeControls();
      //   } else {
      //     // ModifyHandleDecoration.clear();
        }
        // IModelApp.viewManager.selectedView?.invalidateDecorations();
        // IModelApp.viewManager.selectedView?.invalidateCachedDecorations(towDec);
        TowerStructureDecorator.selectedSection.isModified = false;
        resetObjectIds(towDec);
        setSelSectionId("5");
        setHideOtherObjects(false);
        setResetButton({...resetButton, disabled: true});
        setSaveButton({...saveButton, disabled: true});
      // }
    }
  
    const onSave = async ()=>{
      const decorator = IModelApp.viewManager.decorators.filter((e) => e.constructor.name.includes("TowerStructureDecorator"))[0] as TowerStructureDecorator;
      const selSection = TowerStructureDecorator.selectedSection;
      const selPartName = TowerStructureDecorator.selectedSection.sectionName.split('@')[0];

      const editSelection = "up@Full".split("@");
      // const selPartName = editPartName.split("@")[1];
      const towPrevInfo=JSON.parse(JSON.stringify(TowerStructureDecorator.towerGeometryInfo));
      const towerType = towPrevInfo.type;
      let towPrevInfoCopy=JSON.parse(JSON.stringify(towPrevInfo));
      const towDec = IModelApp.viewManager.decorators.filter(e=>e.constructor.name.includes("TowerStructureDecorator"))[0] as TowerStructureDecorator;
      const towCyl = towDec.towerCylinders;
      const towData:any = {};
      const index = towCyl.findIndex(e=>e.name.includes(selPartName));
      const selTowPart = towCyl[index];
      const towLen=towCyl[index].endPos.distance(towCyl[index].startPos);
      const stPt = towCyl[index].startPos;
      const endPt = towCyl[index].endPos;
      
      const toBeSavedData = towDec.convertGetToPostTowerData(towPrevInfoCopy);


      setSaveButton({...saveButton, disabled: true, label: "Saving..."});
      TowerStructureDecorator.selectedSection.isModified=false;
      const projectId = ConfigManager.projectId;
      const url = `${ConfigManager.openToweriQUrl}/v1.0/${projectId}/Site/Structure/`;
      fetch(url, {
      method: "put",
      headers: {
          Authorization: store.getState().auth.accessTokenStatePrivateAPI.accessToken!,
          "Content-Type": "application/json",
      },
      body: JSON.stringify(toBeSavedData),
      }).then(async (res: Response) => {
      if (!res.ok) {
          return null;
      }
      return "success";
      }).then((data: any) => {
          if(data==="success"){
            setSaveButton({...saveButton, label: "Saved"});
            setTimeout(() => setSaveButton({...saveButton, label: "Save"}), 3000);
            towDec.terminate();
            if(towDec.hideMarkers)towDec.hideMarkers=false;
            TowerStructureDecorator.towerGeometryInfo=towPrevInfoCopy;
            towDec.loadShapes(towPrevInfoCopy);
            store.dispatch(setTowerData(towPrevInfoCopy));
            if(!hideSymbolicTower && towPrevInfoCopy.type!="monopole")towDec.drawTiltPoleSegmentForLattice();
            resetObjectIds(towDec);
            IModelApp.viewManager.invalidateDecorationsAllViews();
            IModelApp.viewManager.selectedView?.invalidateCachedDecorations(towDec);
            IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Info, "Tower edits saved successfully.", "", OutputMessageType.Toast));                
          } else {
              IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Error, `Error occured: Tower edits not saved.`, "", OutputMessageType.Toast)); 
          }
      });
      
    }
  
  
    const hideSymbolicTowerHandler = () => {
      const towDec=IModelApp.viewManager.decorators.filter(e=>e.constructor.name.includes("TowerStructureDecorator"))[0] as TowerStructureDecorator;
      if(!hideSymbolicTower){
        towDec.clearTiltPoleSegmentForLattice();
        towDec.selectedPart = "Ground level@Marker";
        const theSection = towDec.towerCylinders.find(e=>e.name==towDec.selectedPart)!;
        TowerStructureDecorator.selectedSection.sectionName = towDec.selectedPart!;
        TowerStructureDecorator.selectedSection.theSection = theSection!;
        setSelSectionId("5");
      }
      else towDec.drawTiltPoleSegmentForLattice();
      IModelApp.viewManager.selectedView?.invalidateCachedDecorations(towDec);
      setHideSymbolicTower(!hideSymbolicTower);
    }
  
  
    const hideOtherSectionHandler = () => {
      const towDec=IModelApp.viewManager.decorators.filter(e=>e.constructor.name.includes("TowerStructureDecorator"))[0] as TowerStructureDecorator;
      towDec.hideMarkers = !hideOtherObjects;
      towDec.selectedPart = TowerStructureDecorator.selectedSection.sectionName.split("-")[0];
      let towData = towDec.getTowerGeometry;      
      if(hideOtherObjects){
        towDec.towerCylinders = towDec.backupCylinders;
        setHideOtherObjects(!hideOtherObjects);
      } else {
        // setTowMarkCheck(evt.target.checked);
        setHideOtherObjects(!hideOtherObjects);
        towDec.backupCylinders = towDec.towerCylinders;
      }
      towDec.terminate()
      towDec.loadShapes(towData);
      if(towData.type!="monopole" && !hideSymbolicTower)towDec.drawTiltPoleSegmentForLattice();
      IModelApp.viewManager.invalidateDecorationsAllViews();
      IModelApp.viewManager.selectedView?.invalidateCachedDecorations(towDec);
      resetObjectIds(towDec);
      const imc = UiFramework.getIModelConnection();
      imc?.selectionSet.emptyAll();
      imc?.selectionSet.add(towDec.towerCylinders.find(e=>e.name.match(TowerStructureDecorator.selectedSection.sectionName))?.transientId!);
    }
  
  
    const moveTowerMarker = (mode: PlacementMode = PlacementMode.Update) => {
      const decorator = IModelApp.viewManager.decorators.filter((e) => e.constructor.name.includes("TowerStructureDecorator"))[0] as TowerStructureDecorator;
      // const equipJson = decorator.shapes.find(e=>e.modelData?.DisplayName==cethProps.selectedObjectInfo.objectProperties.selectedObjectNST.name)?.modelData!;
      let towProps = TowerStructureDecorator.selectedSection.theSection!;
      if(mode == PlacementMode.Create){
        switch (selMissSectionId) {
          case 0: {
            const towPrevInfo=JSON.parse(JSON.stringify(TowerStructureDecorator.towerGeometryInfo));
            const towerType = towPrevInfo.type;
            let towPrevInfoCopy=JSON.parse(JSON.stringify(towPrevInfo));
            const towCyl = decorator.towerCylinders;
            const LRPos = new Point3d();
            let dist: number = 1;
            const towData: TowerStructurePostData = {}
            if(towerType == "monopole"){
              const tempIndex = towCyl.findIndex(e=>e.name.includes("Tower Pole"));
              let towPole = towCyl[tempIndex];
              dist = LRPos.z-towPole.startPos.z;
            } else {
              const tempPart = towCyl.find(e=>e.name.match(/Tower top/))!;
              let topPt = tempPart.startPos.interpolate(0.5, tempPart.endPos);
              dist = LRPos.z-topPt.z;
            }

            towData.lightningRodHeight = dist;

            // towData.lightningRodHeight!+=movementFactor;
              towPrevInfoCopy.lightning_rod_height = towData.lightningRodHeight;
              TowerStructureDecorator.towerGeometryInfo=towPrevInfoCopy;

              decorator.towerCylinders = decorator.towerCylinders.filter(e=>!e.name.match(/Lightning Rod/));
              TowerStructureDecorator.towerGeometryInfo = towPrevInfoCopy;
              decorator.drawLightningRodLevel(towPrevInfoCopy);
              const LRL = decorator.towerCylinders.find(tc=>tc.name.match(/Lightning Rod/))!;
              if(LRL){
                TowerStructureDecorator.selectedSection.theSection = LRL;
                TowerStructureDecorator.selectedSection.sectionName = LRL.name;
                TowerStructureDecorator.selectedSection.transId = LRL.transientId!;
              }
            break;
          }
          default:
            break;
        }
      }
      if(!hideOtherObjects){
        setHideOtherObjects(true);
        hideOtherSectionHandler();
      }
      Place3DObjectApi.objectProperties = {mode, properties: towProps};
      void IModelApp.tools.run(PlacementTool.toolId, PlacementMode.Update, DetectedGeometryType.Tower, (e: string)=>{
        let towData = decorator.getTowerGeometry;
        switch (e) {
          case "Reset":
            setResetButton({...resetButton, disabled: true});
            setSaveButton({...saveButton, disabled: true});
            towData = store.getState().detectedData.towerStructureData as towerStructureData;            
            break;
          case "Success":
            setResetButton({...resetButton, disabled: false});
            setSaveButton({...saveButton, disabled: false});
            break;
        
          default:
            break;
        }
        setHideOtherObjects(false);
        decorator.hideMarkers = false;
        decorator.terminate()
        decorator.loadShapes(towData);
        if(towData.type!="monopole" && !hideSymbolicTower)decorator.drawTiltPoleSegmentForLattice();
        IModelApp.viewManager.invalidateDecorationsAllViews();
        IModelApp.viewManager.selectedView?.invalidateCachedDecorations(decorator);
        resetObjectIds(decorator);
        const imc = UiFramework.getIModelConnection();
        imc?.selectionSet.emptyAll();
        imc?.selectionSet.add(decorator.towerCylinders.find(e=>e.name.match(TowerStructureDecorator.selectedSection.sectionName))?.transientId!);
      })
      // if(!TowerStructureDecorator.selectedEquipment.created){
      //   setHideOtherObjects(false);
      //   setResetButton({...resetButton, disabled: false});
      // }        
    }
    
    IModelApp.toolAdmin.activeToolChanged.addListener(toolChangeListener);
  
    return <>{cethProps.open && 
      <>
        <div className="tower-placement-options">
          <div className="tower-placement-grid pl-0">
            <div className="controls">
            <fieldset>
                <legend style={{fontWeight: "bold"}}>Section & Segment Select</legend>
                <div className="tower-placement-control row pt-1 pb-1">
                  <Text className="col-4">Section: </Text>
                  <Select
                    className="col-8"
                    size="small"
                    value={parseInt(selSectionId)}
                    options={towerSectionList}
                    onChange={(e)=>{onSectionSelect(e)}} />
                </div>
                <div className="component-container mt-1">
                  <div className="component-group row">
                    <div className="col-8" style={{fontWeight: "bold"}}>
                      Hide other sections
                    </div>
                    <div className="col-4">
                      <ToggleSwitch
                        // labelPosition={"left"}
                        // label="Hide other sections"
                        onChange={hideOtherSectionHandler}
                        checked={hideOtherObjects}
                      />
                    </div>
                  </div>
                </div>
                {(TowerStructureDecorator.towerGeometryInfo.type != "monopole" && selSectionId == "3") && 
                <>
                  <div className="tower-placement-control row pt-1 pb-1">
                    <Text className="col-7">Segment junction: </Text>
                    <Select
                      className="col-5"
                      size="small"
                      value={parseInt(selSegmentId)}
                      options={cethProps.legSegmentOptions}
                      onChange={(e)=>{onSegmentSelect(e)}} />
                  </div>
                  <div className="tower-placement-control row pt-1 pb-1">
                    <LabeledInput
                      label="Width (m):"
                      size="small"
                      type="number"
                      step={0.005}
                      min={1}
                      max={50}
                      value={Math.round(towerSegmentWidth*1000)/1000}
                      displayStyle="inline"
                      onChange={(e)=>onFieldUpdate(e, "Width")}
                    />
                    <Slider values={[Math.round(towerSegmentWidth*1000)/1000]} min={1} max={50} onUpdate={(e)=>onFieldUpdate(e, "Width")} step={0.005} className="mt-1" />
                  </div>

                </>}
                {(TowerStructureDecorator.towerGeometryInfo.type == "monopole" && selSectionId == "3") && 
                <>
                  {(TowerStructureDecorator.towerGeometryInfo.type == "monopole" && selSectionId == "3") &&
                  <div className="component-container mt-3 mb-1">
                    <div className="component-group row">
                      <Radio checked={selPoleSection==CylinderSection.Bottom} onChange={()=>poleSelectionHandler(CylinderSection.Bottom)} name="section" className="col" status="positive" label="Start" disabled={false} />
                      <Radio checked={selPoleSection==CylinderSection.Whole} onChange={()=>poleSelectionHandler(CylinderSection.Whole)} name="section" className="col" status="warning" label="Whole" disabled={false} />
                      <Radio checked={selPoleSection==CylinderSection.Top} onChange={()=>poleSelectionHandler(CylinderSection.Top)} name="section" className="col" status="negative" label="End" disabled={false} />
                    </div>
                  </div>}
                  <div className="tower-placement-control row pt-1 pb-1">
                    <LabeledInput
                      label="Diameter (m):"
                      size="small"
                      type="number"
                      step={0.005}
                      min={0}
                      max={10}
                      value={Math.round(monopoleWidth*1000)/1000}
                      displayStyle="inline"
                      onChange={(e)=>onFieldUpdate(e, "Diameter")}
                    />
                    <Slider values={[Math.round(monopoleWidth*1000)/1000]} min={0} max={10} onUpdate={(e)=>onFieldUpdate(e, "Diameter")} step={0.005} className="mt-1" />
                  </div>
                  <div className="tower-placement-control row mt-2 pt-1 pb-1">
                    <LabeledInput
                      label="Height (m):"
                      size="small"
                      type="number"
                      step={0.005}
                      min={1}
                      max={100}
                      value={Math.round(monopoleHeight*1000)/1000}
                      displayStyle="inline"
                      onChange={(e)=>onFieldUpdate(e, "Height")}
                    />
                    <Slider values={[Math.round(monopoleHeight*1000)/1000]} min={1} max={100} onUpdate={(e)=>onFieldUpdate(e, "Height")} step={0.005} className="mt-1" />
                  </div>

                </>}
                {TowerStructureDecorator.towerGeometryInfo.type != "monopole" && 
                <div className="component-container mt-1">
                  <div className="component-group row">
                    <div className="col-8" style={{fontWeight: "bold"}}>
                      Symbolic lattice tower
                    </div>
                    <div className="col-4">
                      <ToggleSwitch
                        disabled={hideOtherObjects}
                        // labelPosition={"left"}
                        // label="Hide other sections"
                        onChange={hideSymbolicTowerHandler}
                        checked={(!hideSymbolicTower && !hideOtherObjects) || selSectionId == "3"}
                      />
                    </div>
                  </div>
                </div>}

                {/* <Alert type="informational" style={{marginTop: "10px"}}>
                  Select a section to update.
                </Alert> */}

              </fieldset>
              <fieldset>
                <legend style={{fontWeight: "bold"}}>Tower properties</legend>
                  <div className="tower-placement-control row pt-1 pb-1">
                    <LabeledInput
                      label="Tilt X:"
                      size="small"
                      type="number"
                      step={0.005}
                      value={Math.round(towerTiltX*1000)/1000}
                      displayStyle="inline"
                      onChange={(e)=>onFieldUpdate(e, "TiltX")}
                    />
                    <Slider values={[Math.round(towerTiltX*1000)/1000]} min={-10} max={10} onUpdate={(e)=>onFieldUpdate(e, "TiltX")} step={0.005} className="mt-1" />
                  </div>
                  <div className="tower-placement-control row pt-1 pb-1">
                    <LabeledInput
                      label="Tilt Y:"
                      size="small"
                      type="number"
                      step={0.005}
                      value={Math.round(towerTiltY*1000)/1000}
                      displayStyle="inline"
                      onChange={(e)=>onFieldUpdate(e, "TiltY")}
                    />
                    <Slider values={[Math.round(towerTiltY*1000)/1000]} min={-10} max={10} onUpdate={(e)=>onFieldUpdate(e, "TiltY")} step={0.005} className="mt-1" />
                  </div>
                  {cethProps?.towerProperties?.towerType != "monopole" &&
                    <div className="tower-placement-control row pt-1 pb-1">
                      <LabeledInput
                        label="Bearing angle:"
                        size="small"
                        type="number"
                        step={0.005}
                        min={0}
                        max={360}
                        value={Math.round(towerBearing*1000)/1000}
                        displayStyle="inline"
                        onChange={(e)=>onFieldUpdate(e, "Bearing")}
                      />
                      <Slider values={[Math.round(towerBearing*1000)/1000]} min={0} max={360} onUpdate={(e)=>onFieldUpdate(e, "Bearing")} step={0.005} className="mt-1" />
                    </div>
                  }
              </fieldset>
              <fieldset>
                <legend style={{fontWeight: "bold"}}>Position</legend>
                <div className="tower-placement-grid">
                  <div className="controls">
                    <Button disabled={disableMovement} title="Move Up" size="large" startIcon={<SvgArrowUp/>} onMouseUp={onMouseUp} onMouseDown={()=>onMouseDown("Move Up")} />
                    <Button disabled={disableMovement} title="Scale & Move" size="large" startIcon={<SvgMove />} onClick={()=>moveTowerMarker()} />
                    <Button disabled={disableMovement} title="Move Down" size="large" startIcon={<SvgArrowDown />} onMouseUp={onMouseUp} onMouseDown={()=>onMouseDown("Move Down")} />
                  </div>
                </div>
              </fieldset>
              {cethProps.allMissingMarkers.length > 0 && 
                <fieldset>
                  <legend style={{fontWeight: "bold"}}>Add section</legend>
                  <div className="tower-placement-control row pt-1 pb-1">
                    <Select
                      className="col-8"
                      size="small"
                      value={selMissSectionId}
                      options={cethProps.allMissingMarkers}
                      onChange={(e)=>{onSectionSelect(e)}} />
                    <div className="col-4">

                        <Button title="Scale & Move" size="large"  style={{width: "100%"}} startIcon={<SvgMove />} onClick={()=>moveTowerMarker(PlacementMode.Create)} />
                    </div>
                  </div>
                </fieldset>
              }
              <fieldset>
                <legend style={{fontWeight: "bold"}}>Action</legend>
                <div className="tower-placement-grid">
                  <Button style={{border: "none", padding: "0", backgroundColor: "transparent"}} className="col text-center" disabled={resetButton.disabled} onClick={onReset}><Badge style={{width: "100%", border: `1px solid ${resetButton.disabled ? "black":  "blue"}`}} backgroundColor={resetButton.disabled ? "ash" : "primary"}>{resetButton.label}</Badge></Button>
                  <Button style={{border: "none", padding: "0", backgroundColor: "transparent"}} className="col text-center" disabled={saveButton.disabled} onClick={onSave}><Badge style={{width: "100%", border: `1px solid ${saveButton.disabled ? "black" : "green"}`}} backgroundColor={saveButton.disabled ? "ash" : "positive"}>{saveButton.label}</Badge></Button>
                  <Button style={{border: "none", padding: "0", backgroundColor: "transparent"}} className="col text-center" onClick={onClose}><Badge style={{width: "100%"}} backgroundColor="negative">Close</Badge></Button>
                </div>
              </fieldset>
              {/* <hr/> */}
            </div>
          </div>
        </div>
      </>
    }</>;
  };
  

  const mapStateToProps = (state: RootState) => {
    const decorator = IModelApp.viewManager.decorators.filter((e) => e.constructor.name.includes("TowerStructureDecorator"))[0] as TowerStructureDecorator;
    const widget = UiFramework.frontstages.activeFrontstageDef?.findWidgetDef("TowerEditWidget");
    // if(widget?.state == WidgetState.Hidden && state.dtvState.applicationState.isEditModeActive && equipDec && state.detectedData.selectedObjectInformation.objectProperties.objectCategoryType == ObjectCategoryTypes.Equipment_RM)widget?.setWidgetState(WidgetState.Open);
    if(widget?.state == WidgetState.Hidden && state.dtvState.applicationState.isEditModeActive && state.detectedData.selectedObjectInformation.objectProperties.objectCategoryType == ObjectCategoryTypes.Tower_RM && decorator)widget?.setWidgetState(WidgetState.Open);

    const allValidMarkers = ["Concrete level", "Ground level", "Tower top", "Top end of equipment", "Lightning Rod"];
    const allMissingMarkers: {label: string, value: number}[] = [];
    let legSegmentOptions: {label: any, value: any}[] = [];
    const towerInfo = TowerStructureDecorator.towerGeometryInfo;
    if(decorator){
      // const segCount = Array.from({ length: TowerStructureDecorator.towerGeometryInfo.tower_vertical_segments.length }, (_value, index) => index+1);
      legSegmentOptions = [...Array(TowerStructureDecorator.towerGeometryInfo.tower_vertical_segments.length+1).keys()].map((_e, i)=>({label: `${_e}-${_e+1}`, value: i}))
      legSegmentOptions[0].label = "Base";
      legSegmentOptions[legSegmentOptions.length-1].label = `Top`;
      legSegmentOptions.reverse();

      allValidMarkers.forEach(amm=>{
        const index = decorator.towerCylinders.findIndex(tc=>tc.name.match(amm));
        if(index == -1)allMissingMarkers.push({label: amm, value: allMissingMarkers.length});
      });
    }

    const retVal = {
      state,
      legSegmentOptions,
      allMissingMarkers,
      open: true,
      towerProperties: {Bearing: towerInfo.bearing, TiltX: towerInfo.tower_tilt_x, TiltY: towerInfo.tower_tilt_y, towerType: towerInfo.type, selectedSection: TowerStructureDecorator.selectedSection, isModified: TowerStructureDecorator.selectedSection.isModified}
    };
    return widget?.state == WidgetState.Open ? retVal : {open: false};
  }
  const mapDispatchToProps = {}
  
  export default connect(mapStateToProps, mapDispatchToProps)(CustomTowerEditHandler)