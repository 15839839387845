import React from "react";
import { FormControl, FormControlLabel, FormGroup, FormLabel, Radio, RadioGroup, Switch } from "@material-ui/core";
import "./propertyEditorComponent.scss";
import { Badge, Button, IconButton, Slider, ToggleSwitch } from "@itwin/itwinui-react";
import { NumberInput } from "@itwin/core-react";
import ModalDialog from "./TreeVisualizer/dialog/dialog";
import { SvgCheckmark, SvgInfoCircular, SvgInfoCircularHollow, SvgRefresh } from "@itwin/itwinui-icons-react";
import { ConfigManager } from "../../config/ConfigManager";

export  interface nvCell {
  value: string|boolean|number;
  type: string;
  disabled: boolean;
  inputType: string;
  modified: boolean;
  styles: {parentClass: string, elementClass: string};
  data?: string[];
}

export interface NVRowsForPropEditModal {
  cellTitle: {name: string, origName: string, styles: {parentClass: string, elementClass: string}};
  nvCells: nvCell[]
}

interface PEMProps {
  nameVals: NVRowsForPropEditModal[];
  cellHeadings?: {name: string, styles: pecStylesInterface}[];
  title: string;
  openOClose: boolean;
  eventHandlers: any;
  btns: any[];
  screenLoc?: {x: number, y: number};
  width?: number;
  children: React.ReactNode;
}

export interface radioGroupLabel {label: string, disabled?: boolean};
export interface radioGroupInterface {matchValue: string, otherRadios: radioGroupLabel[]}


export enum pecDataValueTypes {
  FLOAT = "float",
  INTEGER = "integer",
  BOOLEAN = "boolean",
  DEGREE = "degree",
  STRING = "string"
};

// export enum pecDataValueTypes {
//   FLOAT = "float",
//   INTEGER = "integer",
//   DEGREE = "degree"
// };

export enum pecInputTagTypes {
  HEADING = "heading",
  LABEL = "label",
  NOTE = "note",
  INPUT = "input",
  NUMERIC_INPUT = "numericInput",
  SELECT = "select",
  SLIDER = "slider",
  SLIDER_INPUT = "SliderWithInput",
  BUTTON = "button",
  INFOBADGE = "info-badge",
  INFOICON = "info-icon",
  REFRESHICON = "refresh-icon",
  SWITCH = "switch",
  TOGGLE = "toggle",
  SWITCH_GROUP = "switchGroup",
  CHECKBOX = "checkbox",
  RADIO_BUTTON = "radioButton",
  RADIO_GROUP = "radioGroup",
};


export interface numericValue {
  currentValue: number; 
  min: number; 
  max: number; 
  precision: number; 
  step: number
};

export interface pecOptionData {label: string, value: string, disabled?: boolean}

export interface pecColumn {
  name: string;
  size: number;
  type: pecInputTagTypes;
  customClass: string;
  value: string | number | boolean | numericValue;
  disabled: boolean;
  // inputType: "input";
  fieldType: pecDataValueTypes;
  modified: boolean;
  styles?: pecStylesInterface;
  data?: any | pecOptionData[];
}

export interface pecRows {
  header?: pecColumn;
  columns: pecColumn[]
  footer?: pecColumn;
}

export interface pecGridStructure {
  header?: pecRows;
  rows: pecRows[];
  footer?: pecRows;
}

export interface pecTableButton {
  name: string;
  value: string;
  class: string;
  disable: boolean
  onInteract: any;
}

export interface pecStylesInterface {parentClass: string, childClass: string}

export enum pecStrucType {
  MODAL = "modal", // Use this if you want to include the editor with a modal.
  DIALOG = "dialog",   // Use this if you do not want the modal but a dialog box.
  STRUCTURE = "structureOnly"  // Just the internal structure
}

export interface pecEventHandlers {onChange: any, onUpdate: any, onClick: any, onMouseUp?: any,onMouseDown?: any}

export interface pecComponentProps {
  structureType: pecStrucType;
  title: {label: string; styles?: pecStylesInterface};
  grid: pecGridStructure
  gridButtons: pecTableButton[];
  gridStickyHeading?:any;
  eventHandlers: pecEventHandlers;
  openOClose?: boolean;
  screenLoc?: {x: number, y: number};
  width?: number;
}




export const PropertyEditorComponent: React.FC<pecComponentProps> = (props) => {
  let {grid, openOClose, eventHandlers, gridButtons,gridStickyHeading, title, screenLoc={x: 920, y: 100}, width: winWid = 500} = props;
  winWid;
  let height = (grid.rows.length*47.4)+70;
  height+=gridButtons.length ? 70 : 0;
  // cellHeadings.length > 0 ? height+=40: null;
  height = height > 600 ? 600 : height;
  const [openModal, setOpenModal] = React.useState<boolean>(openOClose!);
  const editedVals: any = [];  


  const getModalBtns = () => {
    const mbs: any[] = [];
    gridButtons.forEach((b: pecTableButton, i: number)=>{
      // mbs.push(<button key={i} style={{width:"100px", marginRight: "20px"}} disabled={b.disable} className={`${b.class}`} name={b.name} onClick={eventHandlers.onClick} >{b.name}</button>)
      mbs.push(<button id={`${b.name}#${i+1}`} key={i} style={{width:"100px", marginLeft:"20px",marginRight:"0px"}} disabled={b.disable} className={`${b.class}`} name={b.name} onClick={b.onInteract} >{b.name}</button>)
    })
    return mbs;
  }

  const getStickyHeading = () => {
    const headings: any[] = [];
    gridStickyHeading.forEach((h: any)=>{
      headings.push(<div className={`text-center font-weight-bold ${h.classParent}`}><div className={`${h.classChild}`}>{h.name}</div></div>)
    })
    return headings;
  }

  // grid.rows.forEach((e: pecRows, i:number)=>{
  //   const eps: EditProps[]=[];

  //   // e.columns.forEach((theRow: pecColumn)=>{
  //     // theRow.forEach((f: pecColumn, index: number) => {        
  //     //   eps.push({
  //     //     value: f.value,
  //     //     // size: f.size,
  //     //     disabled: f.disabled,
  //     //     index: index,
  //     //     inputType: f.inputType,
  //     //     fieldType: f.fieldType,
  //     //     styles: f.styles!,
  //     //     eventHandlers: eventHandlers,
  //     //     data: f.data ? f.data: []
  //     //   });
  //     // });
  //   // });

  //   // editedVals.push(<PropertyEditor key={i} fieldTitle={{name:e.cellTitle.name, nameUnit: e.cellTitle.origName, styles: e.cellTitle.styles}} fields={[...eps]}></PropertyEditor>);
  // });

  const temppecColumns: pecColumn[] = [
    {
      size: 3,
      type: pecInputTagTypes.INPUT,
      customClass: "",
      value: "col-1-value",
      disabled: false,
      name: "input-3",
      fieldType: pecDataValueTypes.INTEGER,
      modified: false,
      styles: undefined
    }
  ]

const getGrid = () => {
  const gridJsx: any = [], tempRet: any = [];
  grid.rows.forEach((rows: pecRows, rowIndex: number) =>  {
      const rowJsx: any = [];
      const tempRow = <div className="row" key={rowIndex} style={{zIndex: 101, fontWeight: 600, display:"flex",justifyContent:"space-between"}}>

        {getColumns(rows.columns, rowIndex).map((e: any)=>{tempRet.push(e);return e})}

      </div>;
      gridJsx.push(tempRow);
        {/* <div className={`${props.fieldTitle.styles.parentClass}`}>
          {<p className={`${props.fieldTitle.styles.childClass}`}>{name}</p>}
        </div> */}
       {/* </div> */}
  })
  // return gridJsx;
  // return tempRet;
  return gridJsx;
}

  const getColumns = (columns: pecColumn[], rowIndex: number) => {
    const columnJsx : any = [];
    columns.forEach((column: pecColumn, cellIndex: number)=>{
      columnJsx.push(getCells(column, rowIndex, cellIndex));
      // columnJsx.push(getCells(column).map(e=>e));

    })
    return columnJsx;
  }

  const getCells = (column: pecColumn, rowIndex: number, columnIndex: number) => {
    const colunJsx = [];
    const {  name, size, type, customClass, value, disabled, fieldType, modified, styles, data } = column;
    let field: any, numVal: numericValue;
    switch (type){
      case pecInputTagTypes.HEADING: 
        // field = <div key={columnIndex} className={`${styles.parentClass}`}>
          // field = <div key={columnIndex} className={`col-${size} ${styles!.parentClass}`}><input id={`input-${rowIndex}-${columnIndex}@${name.split(' ').join('')}`} className={`col-12 ${styles!.childClass}`} type={fieldType.match(/float|integer|degree/i) ? "number" : "text"} name={name} key={columnIndex} style={{}} placeholder={value as string} onChange={eventHandlers.onChange} disabled={disabled} data-name={name} value={value == null ? "N/A" : value as string}></input></div>;
          field = <div key={columnIndex} className={`col-${size} ${styles!.parentClass} text-center font-weight-bold border`}>
          <p className={`${styles?.childClass}`}>{name}</p>
          </div>;
            // field = <input id={`input-${columnIndex}-${name.split(' ').join('')}`} className={`col-${size} ${styles!!.childClass}`} type={"text"} name={name} key={columnIndex} style={{}} placeholder={value as string} onChange={eventHandlers.onChange} disabled={disabled} data-name={name} value={value == null ? "N/A" : value as string}></input>
        // </div>;
        break;
      case pecInputTagTypes.INPUT: 
        // field = <div key={columnIndex} className={`${styles.parentClass}`}>
          field = <div key={columnIndex} className={`col-${size} ${styles!.parentClass}`}><input id={`${pecInputTagTypes.INPUT}-${rowIndex}-${columnIndex}@${name.split(' ').join('')}`} className={`col-12 ${styles!.childClass}`} type={fieldType.match(/float|integer|degree/i) ? "number" : "text"} name={name} key={columnIndex} style={{}} placeholder={value as string} onChange={eventHandlers.onChange} disabled={disabled} data-name={name} value={value == null ? "N/A" : value as string}></input></div>;
            // field = <input id={`input-${columnIndex}-${name.split(' ').join('')}`} className={`col-${size} ${styles!!.childClass}`} type={"text"} name={name} key={columnIndex} style={{}} placeholder={value as string} onChange={eventHandlers.onChange} disabled={disabled} data-name={name} value={value == null ? "N/A" : value as string}></input>
        // </div>;
        break;
      case pecInputTagTypes.NUMERIC_INPUT: 
        // field = <div key={columnIndex} className={`${styles.parentClass}`}>
          numVal = value as numericValue;  
          field = <div id={`${pecInputTagTypes.NUMERIC_INPUT}-${rowIndex}-${columnIndex}@${name.split(' ').join('')}`} onChange={eventHandlers.onChange} key={columnIndex} className={`sample-options-3col col-${size} ${styles!.parentClass}`} style={{ gridTemplateColumns: "1fr" }}>
                <NumberInput id={`${pecInputTagTypes.NUMERIC_INPUT}-${rowIndex}-${columnIndex}@${name.split(' ').join('')}`} width={20} onChange={(val)=>eventHandlers.onChange(val, `ni-${rowIndex}-${columnIndex}@${name}`)} className="" value={numVal.currentValue} min={numVal.min} max={numVal.max} precision={numVal.precision} step={numVal.step} />
          </div>;
        break;
      case pecInputTagTypes.SELECT: 
        const selVal = data!.findIndex(f=>f.value==value);
        // field = <div key={columnIndex} className={`col-${size} ${styles!.parentClass}`}><select id={`select-${rowIndex}-${columnIndex}@${name.split(' ').join('')}`} className={`col-${size} ${styles!.childClass}`} name={name} key={columnIndex} style={{}} value={ selVal } onChange={eventHandlers.onChange} disabled={disabled}>{data!.map((f:any, i:number)=><option key={i} value={i}>{f}</option>)}</select></div>;
        field = <div key={columnIndex} className={`col-${size} ${styles!.parentClass}`}><select id={`${pecInputTagTypes.SELECT}-${rowIndex}-${columnIndex}@${name.split(' ').join('')}`} className={`${styles!.childClass} dialogBox-propEditorComp-dropdown`} name={name} key={columnIndex} style={{}} value={ value as string } onChange={eventHandlers.onChange} disabled={disabled}>{data!.map((f:any, i:number)=><option key={i} disabled={f.disabled} value={f.value}>{f.label}</option>)}</select></div>;
        // field = <select id={`select-${columnIndex}-${name.split(' ').join('')}`} className={`col-${size} ${styles!.childClass}`} name={name} key={columnIndex} style={{}} value={ selVal } onChange={eventHandlers.onChange} disabled={disabled}>{data!.map((f:any, columnIndex:number)=><option key={columnIndex} value={columnIndex}>{f}</option>)}</select>;
        break;
      case pecInputTagTypes.CHECKBOX: 
        // field = <div key={columnIndex} className={`${styles!.parentClass}`} style={{paddingLeft: 0}}><input id={`checkbox-${columnIndex}-${name.split(' ').join('')}`} checked={e[1].value as boolean} className={``} type="checkbox" name={name} key={columnIndex} style={{marginTop: 10, marginBottom: 4, width: "100%", float:"left", height:  "20px"}} onChange={eventHandlers.onChange} disabled={disabled} data-name={name} value={value == null ? "N/A" : value as string}></input></div>;
        // field = <input id={`checkbox-${columnIndex}-${name.split(' ').join('')}`} checked={value as boolean} className={`col-${size} `} type="checkbox" name={name} key={columnIndex} style={{marginTop: 10, marginBottom: 4, width: "100%", float:"left", height:  "20px"}} onChange={eventHandlers.onChange} disabled={disabled} data-name={name} value={value == null ? "N/A" : value as string}></input>;
        field = <input id={`${pecInputTagTypes.CHECKBOX}-${rowIndex}-${columnIndex}@${name.split(' ').join('')}`} checked={value as boolean} className={`col-${size} `} type="checkbox" name={name} key={columnIndex} style={{marginTop: "5px", height: "20px", float:"left"}} onChange={eventHandlers.onChange} disabled={disabled} data-name={name}></input>;
        break;
        case pecInputTagTypes.SWITCH: 
        field = <div key={columnIndex} className={`col-${size} ${styles!.parentClass}`}>
          <FormGroup>
            <FormControlLabel control={<Switch id={`${pecInputTagTypes.SWITCH}-${rowIndex}-${columnIndex}@${name.split(' ').join('')}`} onChange={eventHandlers.onChange} checked={value as boolean} disabled = {disabled}/>} label={value ? "True" : "False"} />
          </FormGroup>            
            </div>;
        break;
        case pecInputTagTypes.SWITCH_GROUP: 
        field = <div key={columnIndex} className={`col-${size} ${styles!.parentClass}`}>
          <FormGroup>
            <FormControlLabel control={<Switch defaultChecked />} label="Label" />
            <FormControlLabel control={<Switch />} label="Required" />
            <FormControlLabel disabled control={<Switch />} label="Disabled" />
          </FormGroup>            
            </div>;
        break;
        case pecInputTagTypes.RADIO_BUTTON: 
        field = 
                <div className={`input-group-prepend mb-3 col-${size} ${styles!.parentClass}`}>
                    <div className="input-group-text">
                        <input type="radio" id={`${pecInputTagTypes.RADIO_BUTTON}-${rowIndex}-${columnIndex}@${name.split(' ').join('')}`} name={name} onChange={eventHandlers.onChange} checked={value == data.matchValue} aria-label="Radio button for following text input"></input>
                        <span className="RTC-pipe-type-radio-text">{value}</span>
                    </div>
                </div>
        // <div key={columnIndex} className={`col-${size} ${styles!.parentClass}`}>
        //                 <input type="radio" name="Selection" onChange={()=>{}} checked={false} aria-label="Radio button for following text input"></input>
        //                 <span className="pipe-type-radio-text">Starting Point</span>
        //     </div>;
        break;
        case pecInputTagTypes.RADIO_GROUP: 
        const radioGroupData: radioGroupInterface = data;
        field = <div key={columnIndex} className={`col-${size} ${styles!.parentClass}`}>
              <FormControl id={`${pecInputTagTypes.RADIO_BUTTON}-${rowIndex}-${columnIndex}@${name.split(' ').join('')}`}>
                <FormLabel>{value}</FormLabel>
                <RadioGroup id={`${pecInputTagTypes.RADIO_GROUP}-${rowIndex}-${columnIndex}@${name.split(' ').join('')}`} onChange={eventHandlers.onChange} row
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue={radioGroupData.matchValue}
                  name="radio-buttons-group"
                >
                  {radioGroupData.otherRadios.map((e: radioGroupLabel)=>(
                    <FormControlLabel disabled={e.disabled} id={`${pecInputTagTypes.RADIO_BUTTON}-${rowIndex}-${columnIndex}@${name.split(' ').join('')}`} value={e.label} control={<Radio />} label={e.label} />
                  ))}
                  {/* <FormControlLabel value="start" control={<Radio />} label="Start" />
                  <FormControlLabel value="wholeSection" control={<Radio />} label="Whole Section" />
                  <FormControlLabel value="end" control={<Radio />} label="End" /> */}
                </RadioGroup>
              </FormControl>
            </div>;
        break;
        case pecInputTagTypes.SLIDER: 
        field = <div key={columnIndex} className={`col-${size} ${styles!.parentClass}`}>
              {/* <Form.Label>Range</Form.Label>
              <Form.Range /> */}
            {/* <Slider defaultValue={50} aria-label="Default" valueLabelDisplay="auto" /> */}
            {/* <Slider
              getAriaLabel={() => 'Minimum distance shift'}
              value={1}
              onChange={()=>{}}
              valueLabelDisplay="auto"
              getAriaValueText={(1, 3)=>{}}
              disableSwap
            /> */}
            {/* <p id={`label-${columnIndex}-${name.split(' ').join('')}`} className={`${styles!.childClass}`} key={columnIndex} style={{marginTop: 6, marginBottom: 4, float:"left", height:  "20px"}} data-name={name}>{value}</p> */}
          </div>;
        break;
        case pecInputTagTypes.SLIDER_INPUT: 
        // const Input = styled(MuiInput)`width: 42px;`; 
        numVal = value as numericValue;  
        const childClassObject = JSON.parse(styles?.childClass as string);
        field = <div key={columnIndex } id={`si-${rowIndex}-${columnIndex}@${name.split(' ').join('')}`} className={`sample-options-3col mr-2 mb-2 col-${size} ${styles!.parentClass}`} style={{ fontWeight: 200, fontSize: 14, gridTemplateColumns: "0.8fr 0.2fr" }}>
                  <Slider disabled={disabled} className={`col-12 ${childClassObject.sliderClass}`} min={numVal.min} max={numVal.max} step={numVal.step} values={[parseFloat(numVal.currentValue.toString())]} 
                    // onUpdate={eventHandlers.onUpdate}
                    onUpdate={(val)=>eventHandlers.onChange(val, `sis-${rowIndex}-${columnIndex}@${name}`)}
                    onChange={(val)=>eventHandlers.onChange(val, `sis-${rowIndex}-${columnIndex}@${name}`)}
                     />

                  <NumberInput disabled={disabled} id={`sii-${rowIndex}-${columnIndex}@${name}`} className={`col-12 mr-2 ${childClassObject.inputClass}`} value={numVal.currentValue} min={numVal.min} max={numVal.max} precision={numVal.precision} step={numVal.step}
                    onMouseDown={(val)=>eventHandlers.onMouseDown!(val, `sii-${rowIndex}-${columnIndex}@${name}`)}
                    onMouseUp={(val)=>eventHandlers.onMouseUp!(val, `sii-${rowIndex}-${columnIndex}@${name}`)}
                    onChange={(val)=>eventHandlers.onChange(val, `sii-${rowIndex}-${columnIndex}@${name}`)} 
                    />
          </div>;
        break;
        case pecInputTagTypes.LABEL: 
        if(!data){
          field = <div key={columnIndex} className={`col-${size} ${styles!.parentClass}`}><p id={`label-${columnIndex}-${name.split(' ').join('')}`} className={`${styles!.childClass}`} key={columnIndex} style={{fontWeight: 550, fontSize: 14, marginTop: 6, marginBottom: 4, float:"left", height:  "20px"}} data-name={name}>{value.toString()}</p></div>;
          // field = <p id={`label-${columnIndex}-${name.split(' ').join('')}`} className={`col-${size} ${styles!.childClass}`} key={columnIndex} style={{}} data-name={name}>{value}</p>;
        }
        break;
        case pecInputTagTypes.NOTE: 
        if(!data){
          field = <div key={columnIndex} className={`col-${size} ${styles!.parentClass}`}><p id={`label-${columnIndex}-${name.split(' ').join('')}`} className={`${styles!.childClass}`} key={columnIndex} style={{marginTop: 6, marginBottom: 4, float:"left", height:  "20px"}} data-name={name}>{value.toString()}</p></div>;
          // field = <p id={`label-${columnIndex}-${name.split(' ').join('')}`} className={`col-${size} ${styles!.childClass}`} key={columnIndex} style={{}} data-name={name}>{value}</p>;
        }
        break;
        case pecInputTagTypes.TOGGLE: 
          field = <div key={columnIndex} className={`col-${size} ${styles!.parentClass}`}>
                    <ToggleSwitch defaultChecked icon={<SvgCheckmark />} />
                  </div>;
          // field = <p id={`label-${columnIndex}-${name.split(' ').join('')}`} className={`col-${size} ${styles!.childClass}`} key={columnIndex} style={{}} data-name={name}>{value}</p>;
          break;
        case pecInputTagTypes.BUTTON: 
          field = <div key={columnIndex} className={`col-${size} ${styles!.parentClass}`}>
          {/* <p id={`label-${columnIndex}-${name.split(' ').join('')}`} className={`${styles!.childClass}`} key={columnIndex} style={{marginTop: 6, marginBottom: 4, float:"left", height:  "20px"}} data-name={name}>{value}</p> */}
          <Button disabled={disabled} id={`${pecInputTagTypes.BUTTON}-${rowIndex}-${columnIndex}@${name.split(' ').join('')}`} className={`${styles!.childClass}`} key={columnIndex} style={{}} data-name={name} 
            // onClick={eventHandlers.onClick}
            onMouseDown={eventHandlers.onMouseDown!}
            onMouseUp={eventHandlers.onMouseUp!}
          >{value.toString()}</Button>
          </div>;
        // field = <p id={`label-${columnIndex}-${name.split(' ').join('')}`} className={`col-${size} ${styles!.childClass}`} key={columnIndex} style={{}} data-name={name}>{value}</p>;
        break;
        case pecInputTagTypes.INFOICON: 
          field = <div key={columnIndex} className={`col-${size} ${styles!.parentClass}`}>
          {/* <p id={`label-${columnIndex}-${name.split(' ').join('')}`} className={`${styles!.childClass}`} key={columnIndex} style={{marginTop: 6, marginBottom: 4, float:"left", height:  "20px"}} data-name={name}>{value}</p> */}
          <IconButton disabled={disabled} id={`${pecInputTagTypes.INFOICON}-${rowIndex}-${columnIndex}@${name.split(' ').join('')}`} className={`${styles!.childClass}`} title={data} key={columnIndex} style={{}} data-name={name} 
            onMouseDown={eventHandlers.onMouseDown!}
            onMouseUp={eventHandlers.onMouseUp!}
          ><SvgInfoCircularHollow/></IconButton>
          </div>;
        break;
        case pecInputTagTypes.INFOBADGE: 
          field = <div key={columnIndex} className={`col-${size} ${styles!.parentClass}`}>
          {/* <p id={`label-${columnIndex}-${name.split(' ').join('')}`} className={`${styles!.childClass}`} key={columnIndex} style={{marginTop: 6, marginBottom: 4, float:"left", height:  "20px"}} data-name={name}>{value}</p> */}
          <Badge id={`${pecInputTagTypes.INFOBADGE}-${rowIndex}-${columnIndex}@${name.split(' ').join('')}`} className={`${styles!.childClass}`} title={data} key={columnIndex} style={{}} data-name={name} 
          >i</Badge>
          </div>;
        break;
        case pecInputTagTypes.REFRESHICON: 
          field = <div key={columnIndex} className={`col-${size} ${styles!.parentClass}`}>
          {/* <p id={`label-${columnIndex}-${name.split(' ').join('')}`} className={`${styles!.childClass}`} key={columnIndex} style={{marginTop: 6, marginBottom: 4, float:"left", height:  "20px"}} data-name={name}>{value}</p> */}
          <IconButton disabled={disabled} id={`${pecInputTagTypes.REFRESHICON}-${rowIndex}-${columnIndex}@${name.split(' ').join('')}`} className={`${styles!.childClass}`} title={data} key={columnIndex} style={{}} data-name={name} 
            onMouseDown={eventHandlers.onMouseDown}
            onMouseUp={eventHandlers.onMouseUp}
            onClick={eventHandlers.onClick}
          ><SvgRefresh/></IconButton>
          </div>;
        // field = <p id={`label-${columnIndex}-${name.split(' ').join('')}`} className={`col-${size} ${styles!.childClass}`} key={columnIndex} style={{}} data-name={name}>{value}</p>;
        break;
    }
      // fields.push(field);
  
    return field;
  }

  // getGrid();
  // let getField = () => {
  //   const fields: any = [];
  //   Object.entries(props.fields).forEach((e, i)=>{
  //     const { value, styles, disabled, index, fieldType, inputType, data=[], eventHandlers } = e[1];
  //     let field: any;
  //     switch (inputType){
  //       case "input": 
  //         field = <div key={i} className={`${styles.parentClass}`}><input id={`input-${i}-${name.split(' ').join('')}`} className={`${styles.childClass}`} type={fieldType.match(/float|integer|degree/i) ? "number" : "text"} name={name} key={index} style={{}} placeholder={value as string} onChange={eventHandlers.onChange} disabled={disabled} data-name={name} value={value == null ? "N/A" : value as string}></input></div>;
  //         break;
  //       case "select": 
  //         const selVal = data.findIndex(f=>f==value);
  //         field = <div key={i} className={`${styles.parentClass}`}><select id={`select-${i}-${name.split(' ').join('')}`} className={`${styles.childClass}`} name={name} key={index} style={{width: "100%"}} value={ selVal } onChange={eventHandlers.onChange} disabled={disabled}>{data.map((f:any, i:number)=><option key={i} value={i}>{f}</option>)}</select></div>;
  //         break;
  //       case "checkbox": 
  //         field = <div key={i} className={`${styles.parentClass}`} style={{paddingLeft: 0}}><input id={`checkbox-${i}-${name.split(' ').join('')}`} checked={e[1].value as boolean} className={``} type="checkbox" name={name} key={index} style={{marginTop: 10, marginBottom: 4, width: "100%", float:"left", height:  "20px"}} onChange={eventHandlers.onChange} disabled={disabled} data-name={name} value={value == null ? "N/A" : value as string}></input></div>;
  //         break;
  //       case "label": 
  //         field = <div key={i} className={`${styles.parentClass}`}><p id={`label-${i}-${name.split(' ').join('')}`} className={`${styles.childClass}`} key={index} style={{marginTop: 6, marginBottom: 4, width: "100%", float:"left", height:  "20px"}} data-name={name}>{e[1].value}</p></div>;
  //         break;
  //     }
  //     fields.push(field);
  //   })   
  //   return fields; 
  // }

  return (
    <>

      {props.structureType == pecStrucType.STRUCTURE ? 
        // <div style={{height: "260px"}} className="container">
        //   <div className="row m-2 mt-3">
        //     <div className="col-4">Columns name</div>
        //     <div className="col-4">Simple structure 1</div>
        //     <div className="col-4">Simple structure 2</div>
        //   </div>
        //   <div className="row m-2 mt-3">
        //     <div className="col-6">Columns name</div>
        //     <div className="col-3">Simple structure 1</div>
        //     <div className="col-3">Simple structure 2</div>
        //   </div>
        //   <div className="row m-2 mt-3">
        //     <div className="col-6">Columns name</div>
        //     <div className="col-3">Simple structure 1</div>
        //     <div className="col-3">Simple structure 2</div>
        //   </div>
        //   <div className="row m-2 mt-3">
        //     <div className="col-6">Columns name</div>
        //     <div className="col-3">Simple structure 1</div>
        //     <div className="col-3">Simple structure 2</div>
        //   </div>
        // </div>
        <div className="imodel-viewport-dialog-container property-editor-dialog" style={{maxHeight: "350px",marginTop: "0px", marginLeft: "10px", paddingRight: "10px", marginRight: "-20px",bottom:"30px"}}>
              {/* <div className="row heading-propEditorComp"> */}
                {/* <div key={0} className={`text-left font-weight-bold border`}> */}
                  {/* <p className="" style={{marginLeft: -10}}>{title.label}</p> */}
                  {/* <hr style={{marginTop: 0, overflow: "hidden"}} className="img-border" />                                 */}
                {/* </div> */}
              {/* </div> */}

              {/* <div className="row heading-propEditorComp">
                <div key={0} className={`text-left font-weight-bold border`}>
                  <p className="" style={{marginLeft: -10}}>{title.label}</p>
                  <hr style={{marginTop: 0, overflow: "hidden"}} className="img-border" />                                
                </div>
              </div> */}
          {gridStickyHeading && gridStickyHeading.length > 0 && <div className="fixedElement">
            <div className="row">{getStickyHeading()}</div>
            <hr className="img-border" style={{height: 2, width: "100%", color: "black", marginTop: 10, overflow: "hidden"}}></hr>
          </div>
          }
        {getGrid().map((e: any)=>e)}
        {gridButtons.length > 0 && 
         <div style={{background:"white", position: "sticky", bottom:0}}>
          <hr style={{marginTop: 10, overflow: "hidden"}} className="img-border" />
          {(grid.rows.findIndex((e) => e.columns[0].name === "agl") > -1 && grid.rows[grid.rows.findIndex((e) => e.columns[0].name === "agl")].columns[0].data) ?
          <div className="row">
            <div className="col-4">
                <div style={{marginTop:10, fontWeight: 550, fontSize: 14, float:"left", height:  "20px"}}>*: <span title="Above Ground Level">AGL</span> is {ConfigManager.AGL ? "enable" : "disable"}
                </div>
            </div>
            <div className="col-8">
              <div style={{marginTop:10, display:'flex',justifyContent:"flex-end"}} className="btn-wrapper col-7">
                {getModalBtns()}
              </div>
            </div>
          </div> :
          <div style={{marginTop:10, display:'flex',justifyContent:"flex-end"}} className="btn-wrapper col-7">
                {getModalBtns()}
          </div>
        }
        </div>
}
      </div>
        
       : 
      openOClose ? 
      <ModalDialog customClasses="propEditorComp-modal-dialog" identifier={title.label.replace(/\s+/g,'')} X={screenLoc.x} Y={screenLoc.y} height={height} width={winWid} title={`${title.label}`} onClose={eventHandlers.onClick} > 
        <div className="imodel-viewport-dialog-container property-editor-dialog" style={{marginTop: "6px", marginBottom: "10px"}}>
          {/* <div className="row m-2 mt-3">
            <button className="col-4">Columns name</button>
            <button className="col-4">Button 1</button>
            <button className="col-4">Button 2</button>
          </div>
          <div className="row m-2 mt-3">
            <button className="col-6">Columns name</button>
            <button className="col-3">Button 1</button>
            <button className="col-3">Button 2</button>
          </div>
          <div className="row m-2 mt-3">
            <button className="col-3">Columns name</button>
            <button className="col-3">Button 1</button>
            <button className="col-3">Button 2</button>
            <button className="col-3">Button 2</button>
          </div>
          <div className="row m-2 mt-3">
            <button className="col-2">Columns name</button>
            <button className="col-5">Button 1</button>
            <button className="col-5">Button 2</button>
          </div> */}
          {getGrid().map(e=>e)}
        </div>
        {gridButtons.length > 0 && <>
          <hr style={{marginTop: 10, overflow: "hidden"}} className="img-border" />                                
          <div style={{marginTop:20, marginBottom:0,display:'flex',justifyContent:"flex-end"}} className="btn-wrapper">{getModalBtns()}</div>
          </>
        }

      </ModalDialog>
       : 
      null}

      {/* {openModal && <ModalDialog customClasses="propEditorComp-modal-dialog" identifier={title.label.replace(/\s+/g,'')} X={screenLoc.x} Y={screenLoc.y} height={height} width={winWid} title={`${title}`} onClose={eventHandlers.btnClickHandler} >
          <div className="imodel-viewport-dialog-container property-editor-dialog" style={{marginTop: -6}}>
              <div id="container" style={{width: "100%", marginTop: 0, marginBottom: 0, overflow: "auto", overflowX: "hidden"}}>
              <div className="row heading-propEditorComp">
                {cellHeadings.length > 0 && cellHeadings.map((e, i)=><div key={i} className={`${e.styles.parentClass} text-center font-weight-bold border`}>
                  <p className={`${e.styles.elementClass}`}>{e.name}</p>
                  </div>)}
              </div>
                  {editedVals}
                  {gridButtons.length > 0 && <>
                    <hr style={{marginTop: 10, overflow: "hidden"}} className="img-border" />                                
                    <div style={{marginTop:20, marginBottom:0,display:'flex',justifyContent:"flex-end"}} className="btn-wrapper">{getModalBtns()}</div>
                    </>
                  }
              </div>
          </div>
        </ModalDialog>
      } */}
    </>
  );
};




// export const PropertyEditorComponent: React.FC<PEMProps> = (props) => {
//   let {nameVals, openOClose, eventHandlers, btns, title, cellHeadings = [], screenLoc={x: 920, y: 100}, width: winWid = 500} = props;
//   winWid;
//   let height = (nameVals.length*47.4)+70;
//   height+=btns.length ? 70 : 0;
//   cellHeadings.length > 0 ? height+=40: null;
//   height = height > 600 ? 600 : height;
//   const [openModal, setOpenModal] = React.useState<boolean>(openOClose);
//   const editedVals: any = [];  


//   const getModalBtns = () => {
//     const mbs: any[] = [];
//     btns.forEach((b, i)=>{
//       mbs.push(<button key={i} style={{width:"100px", marginRight: "20px"}} disabled={b.disable} className={`${b.class}`} name={b.name} onClick={eventHandlers.btnClickHandler} >{b.name}</button>)
//     })
//     return mbs;
//   }
//   nameVals.forEach((e: NVRowsForPropEditModal, i:number)=>{
//     const eps: EditProps[]=[];

//     e.nvCells.forEach((f: nvCell, index: number)=>{
//       eps.push({
//         value: f.value,
//         disabled: f.disabled,
//         index: index,
//         inputType: f.inputType,
//         fieldType: f.type,
//         styles: f.styles,
//         eventHandlers: eventHandlers,
//         data: f.data ? f.data: []
//       });
//     });
//     editedVals.push(<PropertyEditor key={i} fieldTitle={{name:e.cellTitle.name, nameUnit: e.cellTitle.origName, styles: e.cellTitle.styles}} fields={[...eps]}></PropertyEditor>);
//   });

  
//   return (
//     <>
//       {openModal && <ModalDialog customClasses="propEditorComp-modal-dialog" identifier={title.replace(/\s+/g,'')} X={screenLoc.x} Y={screenLoc.y} height={height} width={winWid} title={`${title}`} onClose={eventHandlers.btnClickHandler} >
//           <div className="imodel-viewport-dialog-container property-editor-dialog" style={{marginTop: -6}}>
//               <div id="container" style={{width: "100%", marginTop: 0, marginBottom: 0, overflow: "auto", overflowX: "hidden"}}>
//               <div className="row heading-propEditorComp">
//                 {cellHeadings.length > 0 && cellHeadings.map((e, i)=><div key={i} className={`${e.styles.parentClass} text-center font-weight-bold border`}>
//                   <p className={`${e.styles.elementClass}`}>{e.name}</p>
//                   </div>)}
//               </div>
//                   {editedVals}
//                   {btns.length > 0 && <>
//                     <hr style={{marginTop: 10, overflow: "hidden"}} className="img-border" />                                
//                     <div style={{marginTop:20, marginBottom:0,display:'flex',justifyContent:"flex-end"}} className="btn-wrapper">{getModalBtns()}</div>
//                     </>
//                   }
//               </div>
//           </div>
//         </ModalDialog>
//       }
//     </>
//   );
// };



