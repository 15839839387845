import { UiFramework } from "@itwin/appui-react";
import { Logger } from "../api/logging";
import { getUnit, getValueInRequiredUnit } from "./Tables/BaseTable";

export const getFlatBarProperties = async() => {
    const records = await getFlatBarData();
    let flatBar:any = records && records.length? records[0]:[];
    let nameValuePair: {name: string, value: string}[] | any  = [];
    nameValuePair.push({name: "Name", value:flatBar.length!=0? flatBar[0]:""});     
    nameValuePair.push({name: "Depth "+ getUnit(), value:flatBar.length!=0?getValueInRequiredUnit( flatBar[1],true):""});
    nameValuePair.push({name: "User Label", value:flatBar.length!=0? flatBar[2]:""});     
    nameValuePair.push({name: "Width "+ getUnit(), value:flatBar.length!=0?getValueInRequiredUnit( flatBar[3],true):""});

     return nameValuePair;

}

const getFlatBarData = async() =>{
    try {
        const imodel : any= UiFramework.getIModelConnection();

        if (imodel.isBlank) {
            return undefined;
        }
        let records: any = []
        for await (const record of imodel.query("select Name,Depth,UserLabel,Width from OpenTower.FlatBar")) {
             records.push(record);
        }
        return records
    } catch (e) {
      Logger.error("Error while getting FlatBar data",e);
    }

    return undefined;
}