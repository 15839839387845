import axios, { AxiosRequestConfig, AxiosInstance, CancelTokenSource } from 'axios';
const BYTES_PER_CHUNK = 4000000;

interface FileChunk {
    blob: Blob;
    start: number;
    end: number;
    totalSize: number;
}

interface FileInfo {
    name: string;
    type: string;
}

export async function uploadBlob(storageUri: string, file: File) {
    let start = 0;
    let end: number = BYTES_PER_CHUNK;

    const chunks: FileChunk[] = [];
    if (file.size > 0) {
        while (start < file.size) {
            const chunk: FileChunk = {
                blob: file.slice(start, end),
                totalSize: file.size,
                start: start,
                end: end,
            };
            chunks.push(chunk);
            start = end;
            end = start + BYTES_PER_CHUNK > file.size ? file.size : start + BYTES_PER_CHUNK;
        }
    } else {
        // empty files
        const chunk: FileChunk = {
            blob: file.slice(start, end),
            totalSize: file.size,
            start: start,
            end: 0,
        };
    }

    return postChunk(
        storageUri,
        chunks,
        {
            name: file.name,
            type: file.type,
        });
}

async function postChunk(storageUri: string, chunks: FileChunk[], fileInfo: FileInfo) {
    for (const chunkNumber in chunks) {
        const blockId: string =
            chunks.length === 1 ? '0000' : '0000'.substring(0, '0000'.length - chunkNumber.length) + chunkNumber;

        const indexOfQueryStart: number = storageUri.indexOf('?');
        const url: string =
            storageUri.substring(0, indexOfQueryStart + 1) +
            '&comp=block&blockid=' +
            blockId +
            '&' +
            storageUri.substring(indexOfQueryStart + 1);

        const contentRange: string =
            'bytes ' + chunks[chunkNumber].start + '-' + chunks[chunkNumber].end + '/' + chunks[chunkNumber].totalSize;
        const headers: any = {
            'Content-Range': contentRange,
        };

        const response = await putChunk(url, chunks[chunkNumber].blob, headers);
    }
    return chunkUploadComplete(storageUri, chunks, fileInfo);
}

export async function putChunk(url: string, data: any, headers: any) {
    const config: AxiosRequestConfig = {
        headers: headers,
        url: url,
    };
    const httpClient: AxiosInstance = axios.create(config);
    return httpClient.put(url, data, config);
}

async function chunkUploadComplete(
    storageUri: string,
    chunks: FileChunk[],
    fileInfo: FileInfo
) {
    const indexOfQueryStart: number = storageUri.indexOf('?');
    const url: string =
        storageUri.substring(0, indexOfQueryStart + 1) +
        'comp=blocklist&' +
        storageUri.substring(indexOfQueryStart + 1);

    let requestBody = '<?xml version="1.0" encoding="utf-8"?><BlockList>';
    for (let i = 0; i < chunks.length; i++) {
        const chunkIndex = i.toString();
        const blockId: string =
            chunks.length === 1 ? '0000' : '0000'.substring(0, '0000'.length - chunkIndex.length) + chunkIndex;

        requestBody += '<Latest>' + blockId + '</Latest>';
    }
    requestBody += '</BlockList>';

    const headers: any = {
        'Content-Type': 'application/x-www-form-urlencoded',
        'x-ms-blob-content-type': fileInfo.type,
    };

    const responseData: any = await putChunk(url, requestBody, headers);
    return responseData;
}
